import { FormikContextType, useFormik } from 'formik'
import * as yup from 'yup'
import Field from 'libs/field'
import { WarehouseFormik } from '../add-edit-warehouses'
import { toast } from 'libs/toast'
import { useEditStorageMutation } from '../warehouses-endpoints/warehouses.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import Button from 'libs/button/Button'
import { useParams } from 'react-router-dom'
import MultiSelectDropdown from 'libs/multi-select-dropdown/MultiSelectDropdown'
import { t } from 'i18next'
import { trimStringValues } from 'utils/trimStringValues'

interface Props {
  closePanel: () => void
  storageArea: any
  warehouse?: any
  products?: any
  formik?: FormikContextType<WarehouseFormik>
  setKey: any
  DeletePopUp?: any
}

const EditStorage = ({ formik, warehouse, closePanel, storageArea, products, setKey, DeletePopUp }: Props) => {
  const { id } = useParams()

  const [editStorage, { isLoading }] = useEditStorageMutation()

  const selectedItem = useSelector(selectSelectedItem)
  const StorageFormik = useFormik({
    initialValues: {
      id: '',
      name: storageArea.name,
      products: storageArea.products,
    },
    validationSchema: yup.object({
      name: yup.string().required(t('warehouse.storage_area_error')),
      products: yup.array().min(1, t('warehouse.product_error')),
    }),
    onSubmit: (values) => {
      const storageAreas = formik ? formik.values.storage_areas : warehouse?.storage_areas

      if (storageAreas && !warehouse && !id) {
        const index = storageAreas.findIndex((item: any) => item.name === storageArea.name)
        const currentStorageAreaName = storageArea.name
        const newStorageAreaName = values.name.trim()
        if (
          storageAreas.some(
            (storage: any) => storage.name === newStorageAreaName && storage.name !== currentStorageAreaName,
          )
        ) {
          toast.error('Storage area name already exists.')
          return
        }
        storageAreas.splice(index, 1, values)
        const data = trimStringValues(storageAreas[0])
        formik?.setFieldValue('storageAreas', data)
        setKey((prevKey: any) => prevKey + 1)
        closePanel()
      } else {
        const updateProduct = values.products
        const payload = {
          name: values?.name,
          products: updateProduct.map((product: any) => ({
            _id: product._id,
          })),
        }
        const data = trimStringValues(payload)

        editStorage({
          data: data,
          plant_id: selectedItem?.id,
          storage_id: storageArea.id,
          warehouse_id: id,
        })
          .unwrap()
          .then(() => {
            toast.success('Storage area was updated successfully')
            closePanel()
          })
          .catch((err) => {
            toast.error(err?.data?.detail ?? 'Something went wrong, please try again.')
          })
      }
    },
  })

  const checkedData = id ? StorageFormik.values.products : storageArea.products
  const handleSelectedValuesChange = (values: any) => {
    StorageFormik.setFieldValue('products', values)
  }

  return (
    <div>
      <Field
        required={true}
        label={t('warehouse.storage_area')}
        formik={StorageFormik}
        name="name"
        placeholder={t('warehouse.storage_area_placeholder')}
        width="500"
      />
      <div className="mt-4 flex flex-col">
        <span className="mb-[10px] p-sm-regular">
          {t('warehouse.allowed_products')} <span className="text-red">*</span>
        </span>

        <MultiSelectDropdown
          options={products}
          onChange={(option) => {
            handleSelectedValuesChange(option)
          }}
          checkedData={checkedData}
          error={StorageFormik.touched.products && StorageFormik.errors.products ? StorageFormik.errors.products : null}
        />
      </div>
      <div className="mt-5 flex justify-between">
        <div onClick={DeletePopUp}>
          <Button color="error">{t('warehouse.delete')}</Button>
        </div>
        <div className="flex gap-3">
          <Button onClick={closePanel}>{t('warehouse.cancel')}</Button>
          <Button color="primary" loading={isLoading} onClick={() => StorageFormik.handleSubmit()}>
            {t('warehouse.save')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EditStorage
