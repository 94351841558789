import { useFormik } from 'formik'
import Field from 'libs/field'
import NewEditableTable from 'libs/table/NewEditableTable'
import { useEffect, useState } from 'react'

const AccessRights = () => {
  const [activeModuleId, setActiveModuleId] = useState<string | null>(null)
  const [_, setData] = useState([])
  const formik = useFormik({
    initialValues: {
      planningAccess: [],
      accessRights: [
        {
          view: false,
          edit: false,
          none: false,
        },
      ],
    },
    onSubmit: () => {
    },
  })
  const modules = [
    { name: 'Dashboard', id: 'dashboard' },
    { name: 'Inventory', id: 'inventory' },
    { name: 'Planning', id: 'planning' },
    { name: 'Production', id: 'production' },
    { name: 'Review', id: 'review' },
    { name: 'Orders', id: 'orders' },
    { name: 'Employee', id: 'employee' },
    { name: 'Contacts', id: 'contacts' },
  ]
  const handleModuleClick = (id: string) => {
    setActiveModuleId(id)
  }
  const options = [
    { label: 'None', value: 'none' },
    { label: 'Edit', value: 'edit' },
    { label: 'View', value: 'view' },
  ]
  const accessRightsColumns = [
    {
      title: 'Sub Modules',
      accessor: 'modules',
      flex: 2,
      require: true,
      placeholder: 'Enter address',
    },
    {
      title: 'View',
      accessor: 'view',
      fieldType: 'radio',
      editable: true,
      require: true,
    },
    {
      title: 'Edit',
      accessor: 'edit',
      fieldType: 'radio',
      editable: true,
      require: true,
    },
    {
      title: 'None',
      accessor: 'none',
      fieldType: 'radio',
      editable: true,
      require: true,
    },
  ]
  useEffect(() => {
    if (activeModuleId == null) {
      setActiveModuleId(modules[0]?.id)
    }
  }, [modules])

  // const handelAccessRights = () => {
  //   formik?.setFieldValue('billingAddresses', data ? data : [])
  //   formik.handleSubmit()
  // }
  return (
    <div className="px-2 sm:w-full lg:w-10/12">
      <h5 className="h5">Access Rights</h5>
      <div className="mt-4 grid grid-cols-4">
        <div className="col-span-1 border-r-2 border-border p-2">
          {modules.map((module) => (
            <div
              key={module.id}
              className={`cursor-pointer p-2 ${activeModuleId === module.id ? 'bg-[#E7F5FF]' : 'bg-white'} rounded-md`}
              onClick={() => handleModuleClick(module.id)}
            >
              <h1 className="text-md">{module.name}</h1>
            </div>
          ))}
        </div>
        {/* Planning Access */}
        <div className="col-span-3 ml-6  text-lg">
          <div className="flex items-center justify-between border-b-2 border-border p-2">
            <div>
              <h1 className="font-bold text-brand-primary-shade-3">Planning Access</h1>
              <h1 className="text-gray-800">( Sub-Modules)</h1>
            </div>
            <div className="w-20">
              <Field type="select" options={options} formik={formik} name="planningAccess" />
            </div>
          </div>
          <div className="mt-6">
            <NewEditableTable
              columns={accessRightsColumns}
              hideActions
              hideAddButton
              onChange={(values: any) => {
                setData(values)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccessRights
