import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const ViewAddressDetails = ({ employeeData, setPage }: any) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const AddressDetails = employeeData?.data
  const columns = [
    {
      Header: 'Address line 1',
      accessor: 'address1',
    },
    {
      Header: 'Address line 2',
      accessor: 'address2',
    },
    {
      Header: 'City',
      accessor: 'city',
      Cell: ({ value }: any) => {
        return <span>{value}</span>
      },
    },
    {
      Header: 'State',
      accessor: 'state',
      Cell: ({ value }: any) => {
        return <span>{value}</span>
      },
    },

    {
      Header: 'Country',
      accessor: 'country',
      Cell: ({ value }: any) => {
        return <span>{value}</span>
      },
    },
    {
      Header: 'Zip Code',
      accessor: 'zipCode',
    },
  ]

  useEffect(() => {
    setPage(2)
  }, [])

  return (
    <MotionDiv>
      <WhiteCard className=" flex min-h-[70vh] flex-col gap-6">
        <div className="flex w-full justify-between">
          <h5 className="h5">Address Details</h5>
          <div>
            <Button
              color="primary"
              onClick={() => {
                navigate(`/employee/employee-management/${id}/edit/address-details`)
              }}
            >
              Edit Address Details
            </Button>
          </div>
        </div>

        <PaginatedTable columns={columns} rows={AddressDetails} hidePagination={true} />
      </WhiteCard>
    </MotionDiv>
  )
}

export default ViewAddressDetails
