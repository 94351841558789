import Button from 'libs/button/Button'
import { getTableConfig } from '../columns/WorkOrderDetailsColumns'
import { useEffect, useState } from 'react'
import ProductTab from '../components/ProductTab'
import PaginatedTable from 'libs/table/PaginatedTable'
import Chip from 'libs/chip/Chip'
import {
  useGetWorkOrderByIDQuery,
  useGetWorkOrdersBarChartsQuery,
  useGetWorkOrdersQuantityProducedQuery,
  useGetWorkOrdersOperationStatusQuery,
} from '../work-orders-endpoints/workorders.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useParams } from 'react-router-dom'
import { useGetVerifiedEntriesByIdQuery } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'
import { WorkOrderChart } from '../work-orders-endpoints/workordersType'
import Popup from 'libs/popup/Popup'
import CancelWorkOrder from '../popup/CancelWorkOrder'
import DeleteWorkOrder from '../components/DeleteWorkOrder'
import StartWorkOrder from '../popup/StartWorkOrder'
import CompleteWorkOrder from '../popup/CompleteWorkOrder'
import { useNavigate } from 'react-router-dom'
import PieCharts from 'libs/charts/PieChart'
import CirclePieChart from 'libs/charts/CirclePieChart'
import ProgressChart from 'libs/charts/ProgressChart'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import FullPageLoader from 'libs/loader/FullPageLoader'
import useTabTitle from 'libs/hooks/useTabTitle'

const ViewWorkOrderDetails = () => {
  const { id: paramsId } = useParams()
  const { woTabs, assignedToColumns, operationColumns, productionEntriesColumns } = getTableConfig()
  const [activeTab, setActiveTab] = useState(woTabs[0])
  const plant = useSelector(selectSelectedItem)
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, workOrder: null })
  const [cancelPopUp, setCancelPopUp] = useState({ isPopUpOpen: false, workOrder: null })
  const [startPopUp, setStartPopUp] = useState({ isPopUpOpen: false, workOrder: null })
  const [completePopUp, setCompletePopUp] = useState({ isPopUpOpen: false, workOrder: null })
  const navigate = useNavigate()
  useTabTitle('View Work Order')

  const { data: WorkOrder, isLoading: workOrderLoading } = useGetWorkOrderByIDQuery(
    {
      plant_id: plant?.id,
      woID: paramsId,
    },
    {
      skip: !plant?.id || !paramsId,
      refetchOnMountOrArgChange: true, // Refetch data on every mount or argument change
    },
  )

  const { data: WorkOrderBarChartData, isLoading: statusBarLoading } = useGetWorkOrdersBarChartsQuery(
    {
      plantId: plant?.id,
      woID: paramsId,
    },
    {
      skip: !plant?.id || !paramsId,
    },
  )
  const { data: WorkOrderOperationProcess } = useGetWorkOrdersOperationStatusQuery(
    {
      plantId: plant?.id,
      woID: paramsId,
    },
    {
      skip: !plant?.id || !paramsId,
    },
  )
  const { data: WorkOrderEntries, isLoading: entriesLoading } = useGetVerifiedEntriesByIdQuery(
    {
      woID: paramsId,
    },
    {
      skip: !paramsId,
    },
  )

  const { data: WorkOrderQuantityProduced } = useGetWorkOrdersQuantityProducedQuery(
    {
      plantId: plant?.id,
      woID: paramsId,
    },
    {
      skip: !plant?.id || !paramsId,
    },
  )

  const quantityProduced = [
    {
      name: 'Completed',

      value:
        WorkOrderQuantityProduced?.produceQuantity != null
          ? parseInt(WorkOrderQuantityProduced.produceQuantity.toFixed(0))
          : 0,
    },

    {
      name: 'Remaining',
      value:
        WorkOrderQuantityProduced?.produceQuantity != null
          ? Math.max(0, 100 - parseInt(WorkOrderQuantityProduced.produceQuantity.toFixed(0)))
          : 100,
    },
  ]

  const numberOfOperations: WorkOrderChart[] = [
    {
      name: 'Completed',
      value: WorkOrderOperationProcess?.Completed != 0 ? Number(WorkOrderOperationProcess?.Completed?.toFixed(0)) : 0,
    },
    {
      name: 'In Progress',
      value: WorkOrderOperationProcess?.Inprogress != 0 ? Number(WorkOrderOperationProcess?.Inprogress?.toFixed(0)) : 0,
    },
    {
      name: 'Remaining',
      value: WorkOrderOperationProcess?.Remaining != 0 ? Number(WorkOrderOperationProcess?.Remaining?.toFixed(0)) : 0,
    },
  ]

  // Determine columns based on active tab
  const columns = (name: string) => {
    switch (name) {
      case 'Operations':
        return operationColumns
      case 'Assigned Operators':
        return assignedToColumns
      case 'Production Entries':
        return productionEntriesColumns
      default:
        return []
    }
  }

  const filteredData = (name: string) => {
    switch (name) {
      case 'Operations':
        return WorkOrder?.operations
      case 'Production Entries':
        return WorkOrderEntries?.data
      default:
        return []
    }
  }
  const columnsArray = columns(activeTab.name)
  const workOrderData = filteredData(activeTab.name)

  const handleCancelWorkOrder = () => {
    setCancelPopUp({ isPopUpOpen: true, workOrder: WorkOrder })
  }
  const handleDeleteWorkOrder = () => {
    setDeletePopUp({ isPopUpOpen: true, workOrder: WorkOrder })
  }
  const handleStartWorkOrder = () => {
    setStartPopUp({ isPopUpOpen: true, workOrder: WorkOrder })
  }
  const handleCompleteWorkOrder = () => {
    setCompletePopUp({ isPopUpOpen: true, workOrder: WorkOrder })
  }

  const [data, setData] = useState<any>([])

  useEffect(() => {
    if (WorkOrderBarChartData?.progressData) {
      const updateExpectedProgress = (date: string, value: number) => {
        return WorkOrderBarChartData?.progressData?.map((item: any) =>
          item?.date === date ? { ...item, expectedProgress: value } : item,
        )
      }
      const newData = updateExpectedProgress(WorkOrderBarChartData?.expectedDate, 100)
      const formattedData = newData?.map((item: any) => ({
        ...item,
        actualProgress: item.actualProgress !== null ? parseFloat(item.actualProgress.toFixed(2)) : null,
        expectedProgress: item.expectedProgress !== null ? parseFloat(item.expectedProgress.toFixed(2)) : null,
      }))

      setData(formattedData)
    }
  }, [WorkOrderBarChartData])

  const isLoading = workOrderLoading || statusBarLoading || entriesLoading

  return (
    <MotionDiv>
      {!isLoading ? (
        <WhiteCard>
          <div className="flex justify-between">
            <h5 className="h5">{WorkOrder?.workOrderID} Details</h5>
            <div className="flex gap-2">
              {WorkOrder?.status === 'NOT_STARTED' && <Button onClick={handleCancelWorkOrder}>Cancel Wo</Button>}
              {WorkOrder?.status === 'NOT_STARTED' && <Button onClick={handleStartWorkOrder}>Start</Button>}

              <Button
                onClick={() => {
                  navigate(`/planning/work-orders/edit/${paramsId}`)
                }}
              >
                Edit
              </Button>

              {WorkOrder?.status === 'NOT_STARTED' && <Button onClick={handleDeleteWorkOrder}>Delete</Button>}
              {WorkOrder?.status === 'IN_PROGRESS' && (
                <Button color="primary" onClick={handleCompleteWorkOrder}>
                  Mark as complete
                </Button>
              )}
            </div>
          </div>
          <div className="mt-[-5px] flex items-center gap-2">
            <h1 className="border-r pr-2 text-center">
              Manufacturing Order: {WorkOrder?.manufactureOrder?.manufactureOrderID}
            </h1>
            <Chip title={WorkOrder?.status} />
          </div>

          <div className="my-4 flex gap-4">
            {/* Quantity Produced */}
            <div className=" min-w-[260px] flex-1 rounded-lg border-2 border-background-2 p-4">
              <h1 className="my-2 text-center text-[18px]">Quantity Produced</h1>
              <PieCharts apiData={quantityProduced} />
            </div>

            {/* Number of Operations */}
            <div className=" min-w-[380px] flex-1 rounded-lg border-2 border-background-2 p-4">
              <h1 className="my-2 text-center text-[18px]">Number of Operations</h1>
              <CirclePieChart apiData={numberOfOperations} />
            </div>

            {/* Work Order Progress */}
            <div className=" flex-2 rounded-lg border-2 border-background-2 p-4">
              <h1 className="my-2 text-center text-[18px]">Work Order Progress</h1>
              {WorkOrderBarChartData?.progressData?.length > 0 ? (
                <ProgressChart data={data} highlightDate={WorkOrderBarChartData?.expectedDate} />
              ) : (
                <>
                  <p className="m-auto mt-10 w-[300px] text-center p-sm-regular">
                    No start date or deadline provided. Add a start date and deadline to the Work Order to track your
                    progress effectively.
                  </p>
                  <div className="mt-5 flex items-center justify-center">
                    <Button
                      color="primary"
                      onClick={() => {
                        navigate(`/planning/work-orders/edit/${paramsId}`)
                      }}
                    >
                      Edit Wo
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="my-4 mt-2 flex flex-wrap gap-3">
            {woTabs.map((tab: any) => (
              <ProductTab
                key={tab._id}
                onClick={() => {
                  setActiveTab(tab)
                }}
                tabText={tab.name}
                isActive={activeTab.name === tab.name}
              />
            ))}
          </div>
          <h1 className="my-2 text-xl font-medium">{`${activeTab?.name}`}</h1>
          <PaginatedTable
            columns={columnsArray}
            rows={workOrderData}
            emptyMessage="Data not found."
            hidePagination={true}
          />

          <Popup
            isOpen={cancelPopUp.isPopUpOpen}
            title={`Cancel: ${WorkOrder?.workOrderID}`}
            onClose={() => {
              setCancelPopUp({ isPopUpOpen: false, workOrder: null })
            }}
          >
            <CancelWorkOrder
              closePopUp={() => setCancelPopUp({ isPopUpOpen: false, workOrder: null })}
              workOrder={cancelPopUp.workOrder}
              plantId={plant?.id}
            />
          </Popup>
          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title={`Delete ${WorkOrder?.workOrderID}`}
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, workOrder: null })
            }}
          >
            <DeleteWorkOrder
              closePopUp={() => setDeletePopUp({ isPopUpOpen: false, workOrder: null })}
              workOrder={deletePopUp.workOrder}
              plantId={plant?.id}
            />
          </Popup>
          <Popup
            isOpen={startPopUp.isPopUpOpen}
            title={'Start Work Order'}
            onClose={() => {
              setStartPopUp({ isPopUpOpen: false, workOrder: null })
            }}
          >
            <StartWorkOrder
              closePopUp={() => setStartPopUp({ isPopUpOpen: false, workOrder: null })}
              workOrder={startPopUp.workOrder}
              plantId={plant?.id}
            />
          </Popup>
          <Popup
            isOpen={completePopUp.isPopUpOpen}
            title={'Complete Work Order'}
            onClose={() => {
              setCompletePopUp({ isPopUpOpen: false, workOrder: null })
            }}
          >
            <CompleteWorkOrder
              closePopUp={() => setCompletePopUp({ isPopUpOpen: false, workOrder: null })}
              workOrder={completePopUp.workOrder}
              plantId={plant?.id}
              quantityProduced={quantityProduced}
            />
          </Popup>
        </WhiteCard>
      ) : (
        <FullPageLoader />
      )}
    </MotionDiv>
  )
}

export default ViewWorkOrderDetails
