import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { NumberFormat } from 'utils/NumberFormat'
import humanize from 'humanize-plus'
import { useMemo } from 'react'

const QuantityChart = ({ data }: any) => {
  // Calculate x-axis ticks based on the data length

  const xTicks = useMemo(() => {
    if (data.length === 0) return []

    if (data.length === 30) {
      const ticks = [] as any

      for (let i = 0; i < data.length; i += 7) {
        ticks.push(data[i].date)
      }

      // Ensure the last date is always included if it isn't exactly on a 7-day boundary
      if (data.length % 7 !== 0) {
        ticks.push(data[data.length - 1].date)
      }

      return ticks
    }

    // If data length is not 30, show all dates
    return data.map((item: any) => item.date)
  }, [data])

  return (
    <div className="flex h-full w-full items-center justify-center">
      {data.length === 0 ? (
        <div>No data to show</div>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid vertical={false} horizontal={true} strokeDasharray="3 8" />
            <XAxis
              dataKey="date"
              // tickFormatter={(tick) => tick}
              ticks={xTicks}
              tick={{ fill: '#979797', fontSize: 12 }}
            />
            <YAxis
              className="text-wrap"
              tick={{ fill: '#979797', fontSize: 12 }}
              tickFormatter={(value: number) => humanize.compactInteger(value, 1)}
            />
            <Tooltip formatter={(value: number) => `${NumberFormat(value)}`} />
            <Line
              type="linear"
              dataKey="quantity"
              stroke="black"
              activeDot={{ r: 8, fill: '#1193F0' }}
              dot={{ r: 5, fill: '#2268D1', stroke: 'none' }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

export default QuantityChart
