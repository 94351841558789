import Field from 'libs/field'

import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import MotionDiv from 'libs/motionDiv'
import { useEffect } from 'react'

const AddressDetails = ({ employeeFormik, setPage }: any) => {
  const navigate = useNavigate()

  const { id } = useParams()
  const addressDetailFormik = useFormik({
    initialValues: {
      address1: '',
      address2: '',
      country: '',
      city: '',
      state: '',
      zipCode: '',
    },
    validationSchema: yup.object({
      address1: yup.string().required('Address line 1 is required'),
      zipCode: yup.string().required('Zip Code is required'),
      country: yup.string().required('Please select a country'),
      state: yup.string().required('Please select a state'),
      city: yup.string().required('Please select a city'),
    }),

    onSubmit: (values: any) => {
      const newValues = {
        address1: values.address1,
        address2: values.address2,
        country: values.country,
        state: values.state,
        city: values.city,
        zipCode: values.zipCode,
      }

      employeeFormik.setFieldValue('addressDetailsFormik', newValues)
      setPage(3)

      if (!id) {
        navigate('/employee/employee-management/add/job-details')
      } else {
        navigate(`/employee/employee-management/${id}/edit/job-details`)
      }
    },
  })

  useEffect(() => {
    addressDetailFormik.resetForm({
      values: {
        address1: employeeFormik.values.addressDetailsFormik?.address1 || '',
        address2: employeeFormik.values.addressDetailsFormik?.address2 || '',
        country: employeeFormik?.values?.addressDetailsFormik?.country || '',
        state: employeeFormik?.values?.addressDetailsFormik?.state || '',
        city: employeeFormik?.values?.addressDetailsFormik?.city || '',
        zipCode: employeeFormik.values.addressDetailsFormik?.zipCode || '',
      },
    })
  }, [employeeFormik])

  useEffect(() => {
    setPage(2)
  }, [])

  return (
    <MotionDiv>
      <div className=" flex min-h-[70vh] flex-col">
        <div className="flex flex-grow flex-col gap-3">
          <div>
            <h5 className="h5-1">{'Job Details'}</h5>
            <p className="p-sm-regular-2">
              {!id
                ? 'Add the basic details that are required to Add New Employee.'
                : 'Edit the existing employee details to make necessary changes and updates.'}
            </p>
          </div>
          <div className="grid max-w-[1200px] grid-cols-1 gap-3 md:grid-cols-2">
            <Field
              required={true}
              label={'Address line 1'}
              formik={addressDetailFormik}
              name="address1"
              placeholder={'Enter address'}
            />
            <Field
              label={'Address line 2'}
              formik={addressDetailFormik}
              name="address2"
              placeholder={'Enter address'}
            />
            <Field
              type="text"
              required={true}
              label={'Country'}
              formik={addressDetailFormik}
              defaultValue={{ label: addressDetailFormik?.values?.country } ?? { label: '' }}
              name="country"
              placeholder={'Enter country'}
            />
            <Field
              type="text"
              required={true}
              label={'State'}
              formik={addressDetailFormik}
              defaultValue={{ label: addressDetailFormik?.values?.state } ?? { label: '' }}
              name="state"
              placeholder={'Enter state'}
            />
            <Field
              type="text"
              required={true}
              label={'City'}
              formik={addressDetailFormik}
              defaultValue={{ label: addressDetailFormik?.values?.city } ?? { label: '' }}
              name="city"
              placeholder={'Enter city'}
            />
            <Field
              required={true}
              label={'Zip Code'}
              formik={addressDetailFormik}
              name="zipCode"
              placeholder={'Enter zip Code'}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between">
          <div>
            <Button
              type="button"
              onClick={() => {
                navigate('/employee/employee-management')
              }}
            >
              {'Cancel'}
            </Button>
          </div>
          <div className="flex gap-3">
            <Button
              type="button"
              onClick={() => {
                setPage(1)
                navigate(-1)
              }}
            >
              {'Previous'}
            </Button>
            <Button
              loading={false}
              color="success"
              type="submit"
              onClick={() => {
                addressDetailFormik.handleSubmit()
              }}
            >
              {'Next'}
            </Button>
          </div>
        </div>
      </div>
    </MotionDiv>
  )
}

export default AddressDetails
