import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { EmployeeFilters } from 'pages/employee/employees-endpoints/employeemanagementTypes'

import {
  EmployeeDepartmentOptions,
  EmployeeDesignationOptions,
} from 'pages/employee/Employee-Department/employee-departments-endpoints/employeedepartmentTypes'
import { useGetEmployeeDepartmentAndDesignationQuery } from 'pages/employee/Employee-Department/employee-departments-endpoints/employeesdepartment.endpoints'

interface Props {
  closePanel: () => void
  setFilters: any
  filters: EmployeeFilters
}

const EmployeeFilterPanel = ({ closePanel, filters, setFilters }: Props) => {
  const plantId = useSelector(selectSelectedItem)

  const { data } = useGetEmployeeDepartmentAndDesignationQuery(
    {
      plant_id: plantId?.id,
    },
    {
      skip: !plantId?.id,
    },
  )

  const initialValues = {
    designation: filters?.designation?.length === 0 ? [] : filters?.designation,
    department: filters?.department?.length == 0 ? [] : filters?.department,
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      status: undefined,
      sortBy: 'created_on',
      sortDir: 'DESC',
    })
    closePanel()
  }

  const filterFormik = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        department: values?.department,
        designation: values?.designation,
      }))
    },
  })

  const departmentsOptions =
    data?.department?.map((department: EmployeeDepartmentOptions) => ({
      label: department.name,
      value: department._id,
    })) ?? []

  const designationsOptions =
    data?.designation?.map((roles: EmployeeDesignationOptions) => ({
      label: roles.name,
      value: roles._id,
    })) ?? []

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="Department"
          placeholder="Show All"
          options={departmentsOptions}
          formik={filterFormik}
          name="department"
        />
        <Field
          type="multiselect"
          label="Designation"
          placeholder="Show All"
          options={designationsOptions}
          formik={filterFormik}
          name="designation"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset Filters
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            type="submit"
            color="primary"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default EmployeeFilterPanel
