import Sidenav from './LeftSideItems'
import menuData from '..'
import { Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsActive } from 'store/redux/navSlice'
import './sideMenu.css'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import icons from 'assets/index.ts'
import { getAuthTokenAndRole } from 'utils/CheckAuth'
import { configureState } from 'store/redux/navSlice'
import { Link } from 'react-router-dom'
import Breadcrumbs from 'libs/breadcrumbs/Breadcrumbs'

export default function Sidebar() {
  let isActive = useSelector(selectIsActive)
  let configure = useSelector(configureState)
  const location = useLocation()
  const routeName = location.pathname
  const isSettings = routeName.includes('settings')
  const color = isSettings ? '#1193F0' : '#75838D'
  const { role } = getAuthTokenAndRole()
  return (
    <div className="flex" style={{ height: '100vh' }}>
      <AnimatePresence mode="wait">
        {isActive && (
          <motion.div
            className="sidebar flex flex-col justify-between"
            style={{ zIndex: 1 }}
            initial={{ x: '-92px', width: 0 }}
            animate={{ x: 0, width: '92px' }}
            exit={{ x: '-92px', width: 0 }}
            transition={{
              type: 'spring',
              duration: 0.5,
              stiffness: 200,
              damping: 25,
            }}
          >
            <div>
              {menuData.map((item, index) =>  
                (item.title === 'configure' && configure) || item.title !== 'configure' ? (
                  <Sidenav key={index} item={item} role={role} />
                ) : null,
              )}
            </div>
            <div>
              <div className="sidebar-item-user flex flex-col items-center justify-between gap-2">
                <Link to={'/settings'}>
                  <div
                    className={`mb-3 flex cursor-pointer flex-col items-center justify-center gap-1 `}
                    onClick={() => {
                      // setClick(true)
                    }}
                  >
                    <span className="">
                      <icons.AppSetting color={color} />
                    </span>
                    <span className={` ${isSettings ? 'p-xs-bold' : 'p-xs-regular'}`}>Settings</span>
                  </div>
                </Link>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div style={{ maxHeight: '100vh', overflowY: 'auto', flex: 1 }} className="bg-[#EBEFF3]">
        <div style={{ padding: '65px 24px 24px 24px' }}>
          { location.pathname !== '/dashboard' && <Breadcrumbs />}
          <Outlet />
        </div>
      </div>
    </div>
  )
}
