import { useEffect, useMemo, useState } from 'react'
import MotionDiv from 'libs/motionDiv'
import { t } from 'i18next'
import Filter from 'assets/icons/camera-page/Filter'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { useNavigate } from 'react-router-dom'
import Button from 'libs/button/Button'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import Panel from 'libs/panel'
import SearchBox from 'libs/searchbox/SearchBox'
import WhiteCard from 'libs/card/WhiteCard'
import { useDebouncedSearch } from 'libs/hooks/useSearch'
import useTabTitle from 'libs/hooks/useTabTitle'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import { filters } from './customer-endpoints/customerTypes'
import Popup from 'libs/popup/Popup'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'

import PaginatedTable from 'libs/table/PaginatedTable'
import DeleteCustomer from './popup/DeleteCustomer'
import CustomerFilterPanel from './panel/CustomerFilterPanel'
import { formatString } from 'utils/trimStringValues'
import { useGetCustomersQuery } from './customer-endpoints/customer.endpoints'
import { countryPhoneNumber } from 'utils/phoneNumberValidator'
import Tooltip from 'libs/tool-tip/Tooltip'

const CustomerManagement = () => {
  useTabTitle('Customer Management')
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  const [key, setKey] = useState(0)
  const { searchValue, handleSearch, inputValue } = useDebouncedSearch()
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, customer: null })
  const [showFilter, setShowFilter] = useState(false)
  const [filters, setFilters] = useState<filters>({
    page_no: 1,
    page_size: 10,
    searchText: '',
    customerType: [],
    sortBy: 'created_on',
    sortDir: 'DESC',
  })

  const {
    data: customersData,
    isLoading: isCustomerLoading,
    isFetching: isCustomerFetching,
  } = useGetCustomersQuery({ plantId: plant?.id, filters }, { skip: !plant?.id })

  // handle sort for column
  const handleSort = (columnName: any) => {
    setFilters((prevFilters: any) => {
      const newSortDir = prevFilters?.sortBy === columnName ? (prevFilters?.sortDir === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
      return {
        ...prevFilters,
        sortBy: columnName,
        sortDir: newSortDir,
      }
    })
  }

  const handleFilterToggle = () => {
    setShowFilter(!showFilter)
  }

  const handlePageChange = (page_no: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: page_no,
    }))
    setKey((prevKey) => prevKey + 1)
  }

  const handlePageNumberChange = (newPageSize: any) => {
    const totalItemsCount = customersData?.paging?.total_items_count
    const currentPage = filters.page_no
    const startIndex = (currentPage - 1) * filters.page_size + 1
    const totalPages = totalItemsCount ? Math.ceil(totalItemsCount / newPageSize) : 0
    let newPageNo = Math.ceil(startIndex / newPageSize)
    newPageNo = Math.min(newPageNo, totalPages)
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: newPageNo,
      page_size: newPageSize,
    }))
    setKey((prevKey) => prevKey + 1)
  }

  //search
  useEffect(() => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: 1,
      searchText: searchValue ? searchValue : '',
    }))
  }, [searchValue])

  const activeFiltersCount = [filters?.customerType]?.filter((filter) => {
    if (Array.isArray(filter)) {
      return filter.length > 0
    } else {
      return Boolean(filter)
    }
  })?.length

  const columns = useMemo(
    () => [
      {
        Header: 'Customer Name',
        accessor: 'name',
        Cell: ({ row }: any) => {
          const customer = row?.original
          const customerId = customer?._id

          return (
            <div
              className="link-text"
              onClick={() => {
                navigate(`/contacts/customer-management/${customerId}/view-basic-details`)
              }}
            >
              {customer?.name}
            </div>
          )
        },
      },

      {
        Header: 'Customer Type',
        accessor: 'customerType',
        Cell: ({ value }: any) => formatString(value),
      },
      {
        Header: 'Email',
        accessor: 'businessEmailAddress',
        Cell: ({ value }: any) => (value ? value : '-'),
      },

      {
        Header: 'Contact Number',
        accessor: 'primaryContactNumber',
        Cell: ({ value }: any) => {
          const { phoneNumber } = countryPhoneNumber(value)

          return <span>{phoneNumber ? <span>{phoneNumber}</span> : '-'}</span>
        },
      },

      {
        Header: 'Primary Address',
        accessor: 'addresses',
        Cell: ({ value }: any) => {
          if (value && value?.length > 0) {
            const primaryAddress = value?.find((address: any) => address?.isPrimary) || value[0]

            return (
              <div>
                <p>
                  {primaryAddress?.addressLine1}, {primaryAddress?.city}
                </p>
              </div>
            )
          } else {
            return <div>-</div>
          }
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        width: 10,
        Cell: ({ row }: any) => (
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-row">
              <Tooltip content="Edit">
                <button
                  className="mr-2"
                  onClick={(e) => {
                    e.stopPropagation()
                    row?.original?._id &&
                      navigate(`/contacts/customer-management/${row?.original?._id}/edit/basic-details`)
                  }}
                >
                  <Pen />
                </button>
              </Tooltip>

              <Tooltip content="Delete">
                <button
                  className="mr-2"
                  onClick={(event) => {
                    event.stopPropagation()
                    setDeletePopUp({ isPopUpOpen: true, customer: row?.original })
                  }}
                >
                  <Bin />
                </button>
              </Tooltip>
            </div>
            <Tooltip content={'View'}>
              <div
                className="flex cursor-pointer items-center justify-center p-1"
                onClick={() =>
                  row?.original?._id &&
                  navigate(`/contacts/customer-management/${row?.original?._id}/view-basic-details`)
                }
              >
                <ClickableRowArrow />
              </div>
            </Tooltip>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Customer Management</h1>
        <div className="flex justify-between">
          <p className="w-[600px] p-sm-regular-1">
            View the details of your added Customers and easily add new ones as needed.
          </p>
        </div>

        <WhiteCard className="mt-4">
          <div className="mb-2 flex justify-between">
            <div className="flex w-full gap-3">
              <Button onClick={handleFilterToggle} startIcon={<Filter color="#3D4140" />}>
                {t('filters')}
                {activeFiltersCount === 0 ? '' : `(${activeFiltersCount})`}
              </Button>
              <SearchBox
                value={inputValue}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search Customers by name, type, email or address "
                onClearSearchBox={() => {
                  setFilters((prevFilters: any) => ({
                    ...prevFilters,
                    searchText: '',
                  }))
                  handleSearch('')
                }}
              />
            </div>
            <div className="relative  flex gap-3">
              {/* <ExportButton onOptionSelect={() => {}} disabled>
                {t('export_data')}
              </ExportButton>
              <ImportButton disabled onOptionSelect={() => {}}>
                {t('import_data')}
              </ImportButton> */}
              <Button
                startIcon={<PlusIcon />}
                color="primary"
                onClick={() => {
                  navigate('add/basic-details')
                }}
              >
                Add Customer
              </Button>
            </div>
          </div>
          <PaginatedTable
            onPageNumberChange={handlePageChange}
            key={key}
            columns={columns}
            sorting={{ sortBy: filters.sortBy, sortDir: filters.sortDir }}
            rows={customersData?.data || []}
            currentPageNumber={filters.page_no}
            pageSize={filters.page_size}
            onPageSizeChange={handlePageNumberChange}
            loading={isCustomerLoading || isCustomerFetching}
            pagination={customersData?.paging}
            emptyMessage={`${filters.searchText ? `No Results found for the search "${filters.searchText}" ` : activeFiltersCount > 0 && customersData?.data?.length === 0 ? 'No customer found . Please adjust your filters.' : 'No Customer are added. Please add Customer. '}`}
            onColumnSort={handleSort}
          />
          <Panel
            size="medium"
            isOpen={showFilter}
            title="Filters"
            onClose={() => {
              setShowFilter(false)
            }}
          >
            <CustomerFilterPanel
              filters={filters}
              setFilters={setFilters}
              closePanel={() => {
                setShowFilter(false)
              }}
            />
          </Panel>
          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Customer Details"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, customer: null })
            }}
          >
            <DeleteCustomer
              closePopUp={() => {
                setDeletePopUp({ isPopUpOpen: false, customer: null })
              }}
              data={deletePopUp}
            />
          </Popup>
        </WhiteCard>
      </>
    </MotionDiv>
  )
}

export default CustomerManagement
