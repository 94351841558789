import Button from 'libs/button/Button'
import Field from 'libs/field'
import React, { useMemo } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { formatString } from 'utils/trimStringValues'
import { phoneNumberValidator } from 'utils/phoneNumberValidator'
import { toast } from 'libs/toast'
import { usePostVendorMutation } from 'pages/contacts/vendor-management/vendor-endpoints/vendor.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'

type Props = {
  close: () => void
}

const QuickAddEditVendor: React.FC<Props> = ({ close }) => {
  const plantId = useSelector(selectSelectedItem)
  const [addVendor, { isLoading: addVendorLoading }] = usePostVendorMutation()
  // Initializing Formik
  const formik = useFormik({
    initialValues: {
      vendorType: '',
      category: '',
      name: '',
      primaryContactNumber: '',
    },
    validationSchema: yup.object({
      vendorType: yup.string().required('Vendor type is required'),
      category: yup.string().required('Material type is required'),
      name: yup.string().required('Vendor name is required'),
      primaryContactNumber: yup
        .string()
        .required('Primary contact number is required')
        .test('valid-phone', 'Please enter a phone number for the selected country.', phoneNumberValidator),
    }),
    onSubmit: (values) => {
      const payload = {
        category: values.category,
        vendorType: values.vendorType,
        name: values.name,
        primaryContactNumber: values.primaryContactNumber.toString(),
      }

      addVendor({ plant_id: plantId.id, data: payload })
        .unwrap()
        .then(() => {
          toast.success('The Vendor ' + payload.name + ' is added successfully')
          close()
        })
        .catch((error) => {
          const errorMessage = Array.isArray(error?.data?.detail) ? error?.data?.detail[0]?.msg : error?.data?.detail
          toast.error(errorMessage ?? 'Failed to add the Vendor ' + payload.name + ' Please try again.')
        })
    },
  })

  const defaultVendorType = useMemo(() => {
    return formik.values.vendorType ? formatString(formik.values.vendorType) : ''
  }, [formik.values.vendorType])
  const defaultCategory = useMemo(() => {
    return formik.values.category ? formatString(formik.values.category) : ''
  }, [formik.values.category])

  return (
    <div className="flex flex-col gap-2">
      <Field
        label="Vendor Type"
        placeholder="Select vendor Type"
        formik={formik}
        options={[
          { label: 'Individual', value: 'INDIVIDUAL' },
          { label: 'Government', value: 'GOVERNMENT' },
          { label: 'Private Company', value: 'PRIVATE_COMPANY' },
        ]}
        name="vendorType"
        type="select"
        defaultValue={{ label: defaultVendorType }}
        required
      />
      <Field
        label="Category"
        placeholder="Select category"
        formik={formik}
        options={[
          { label: 'Raw material', value: 'RAW_MATERIAL' },
          { label: 'Services', value: 'SERVICES' },
        ]}
        name="category"
        type="select"
        defaultValue={{ label: defaultCategory }}
        required
      />
      <Field label="Vendor Name" placeholder="Enter Vendor Name" formik={formik} name="name" type="text" required />
      <Field
        label="Primary Contact Number"
        placeholder="Enter Contact Number"
        formik={formik}
        name="primaryContactNumber"
        type="phonenumber"
        required
      />
      <div className="my-2 flex justify-end gap-2">
        <Button onClick={close}>Cancel</Button>
        <Button
          color="primary"
          loading={addVendorLoading}
          onClick={() => {
            formik.handleSubmit()
          }}
        >
          Add
        </Button>
      </div>
    </div>
  )
}

export default QuickAddEditVendor
