/* eslint-disable react/prop-types */
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { formatString } from 'utils/trimStringValues'
import { phoneNumberValidator } from 'utils/phoneNumberValidator'

const PersonalInformation = ({ employeeFormik, setPage }: any) => {
  const navigate = useNavigate()

  const { id } = useParams()

  const options = [
    {
      label: 'Male',
      value: 'MALE',
    },
    {
      label: 'Female',
      value: 'FEMALE',
    },
    {
      label: 'Other',
      value: 'OTHER',
    },
  ]

  const personalInfoFormik = useFormik({
    initialValues: {
      employeeID: '',
      employeeName: '',
      gender: '',
      governmentID: '',
      email: '',
      contactNumber: '',
      emergencyContactNumber: '',
      emergencyContactName: '',
    },
    validationSchema: Yup.object({
      employeeID: Yup.string().required('Employee ID is required'),
      employeeName: Yup.string().required('Employee Name is required'),
      gender: Yup.string().required('Gender is required'),
      email: Yup.string()
        .email('Invalid email format') // Standard email validation
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email must be a valid email (e.g., pankaj@1.com)')
        .required('Email is required'),

      emergencyContactName: Yup.string().required('Emergency Contact Name is required'),
      contactNumber: Yup.string()
        .required('Phone number is required')
        .test('valid-phone', 'Please enter a phone number for the selected country.', phoneNumberValidator),
      emergencyContactNumber: Yup.string()
        .required('Emergency Contact Number is required')
        .test('valid-phone', 'Please enter a phone number for the selected country.', phoneNumberValidator),
    }),

    onSubmit: (values) => {
      const newValue = {
        employeeID: values.employeeID,
        employeeName: values.employeeName,
        gender: values.gender,
        governmentID: values.governmentID,
        email: values.email,
        contactNumber: values.contactNumber,
        emergencyContactNumber: values.emergencyContactNumber,
        emergencyContactName: values.emergencyContactName,
      }

      employeeFormik.setFieldValue('personalInformationFormik', newValue)
      setPage(2)

      if (!id) {
        navigate('/employee/employee-management/add/address-details')
      } else {
        navigate(`/employee/employee-management/${id}/edit/address-details`)
      }
    },
  })

  useEffect(() => {
    personalInfoFormik.resetForm({
      values: {
        employeeID: employeeFormik.values.personalInformationFormik?.employeeID || '',
        employeeName: employeeFormik.values.personalInformationFormik?.employeeName || '',
        gender: employeeFormik.values.personalInformationFormik?.gender || '',
        governmentID: employeeFormik.values.personalInformationFormik?.governmentID || '',
        email: employeeFormik.values.personalInformationFormik?.email || '',
        contactNumber: employeeFormik.values.personalInformationFormik?.contactNumber || '',
        emergencyContactNumber: employeeFormik.values.personalInformationFormik?.emergencyContactNumber || '',
        emergencyContactName: employeeFormik.values.personalInformationFormik?.emergencyContactName || '',
      },
    })
  }, [employeeFormik])

  const GenderValue = { label: formatString(personalInfoFormik?.values?.gender) } ?? { label: '' }
  useEffect(() => {
    setPage(1)
  }, [])

  return (
    <div className=" flex min-h-[70vh] flex-col">
      <div className="flex flex-grow flex-col gap-3">
        <div>
          <h5 className="h5-1">{'Personal Information'}</h5>
          <p className="p-sm-regular-2">
            {!id
              ? 'Add the basic details that are required to Add New Employee.'
              : 'Edit the existing employee details to make necessary changes and updates.'}
          </p>
        </div>
        <div className="grid max-w-[1200px] grid-cols-1 gap-3 md:grid-cols-2">
          <Field
            required={true}
            label={'Employee Id'}
            formik={personalInfoFormik}
            name="employeeID"
            placeholder={'Enter employee id'}
          />
          <Field
            required={true}
            label={'Employee Name'}
            formik={personalInfoFormik}
            name="employeeName"
            placeholder={'Enter employee name '}
          />
          <Field
            type="select"
            required={true}
            label={'Gender'}
            formik={personalInfoFormik}
            options={options}
            name="gender"
            defaultValue={GenderValue}
            placeholder={'select gender'}
          />
          <Field label={'Government ID'} formik={personalInfoFormik} name="governmentID" placeholder={'Enter'} />
          <Field required={true} label={'Email'} formik={personalInfoFormik} name="email" placeholder={'Enter'} />
          <Field
            type="phonenumber"
            required={true}
            label={'Contact Number'}
            formik={personalInfoFormik}
            name="contactNumber"
            placeholder={'Enter contact number'}
          />
          <Field
            required={true}
            label={'Emergency contact name'}
            formik={personalInfoFormik}
            name="emergencyContactName"
            placeholder={'Enter emergency contact name'}
          />

          <Field
            type="phonenumber"
            required={true}
            label={'Emergency contact number'}
            formik={personalInfoFormik}
            name="emergencyContactNumber"
            placeholder={'Enter emergency contact number name'}
          />
        </div>
      </div>
      <div className="mt-4 flex justify-end gap-3">
        <div className="flex gap-3">
          <Button
            type="button"
            onClick={() => {
              navigate('/employee/employee-management')
            }}
          >
            {'Cancel'}
          </Button>
        </div>
        <div>
          <Button
            loading={false}
            color="success"
            type="submit"
            onClick={() => {
              personalInfoFormik.handleSubmit()
            }}
          >
            {'Next'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PersonalInformation
