import Panel from 'libs/panel'
import QuickAddEdit from 'libs/quick-add-edits/quick-add-edit-so/QuickAddEdit'
import { useEffect, useState } from 'react'

const QuickAddEditSalesOrder = ({ id, isOpen, onClose, manufactureOrderFormik, data }: any) => {
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (isOpen) {
      setValue(id)
    }
  }, [id, isOpen])

  const handleClose = () => {
    onClose(false)
    setValue(null)
  }

  return (
    <Panel
      size="large"
      isOpen={isOpen}
      title={value ? 'Quick Edit Sales Order' : 'Quick Add Sales Order'}
      onClose={handleClose}
    >
      <QuickAddEdit manufactureOrderFormik={manufactureOrderFormik} data={data} close={handleClose} salesOrderId={value} />
    </Panel>
  )
}

export default QuickAddEditSalesOrder
