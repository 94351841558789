import Cookies from 'js-cookie'

export const getAuthTokenAndRole = () => {
  return {
    role: Cookies.get('role'),
    access_token: Cookies.get('access_token'),
    refresh_token: Cookies.get('refresh_token'),
    username: Cookies.get('username'),
    start_time: Cookies.get('start_time'),
    end_time: Cookies.get('end_time'),
    expires_in: Cookies.get('expires_in'),
  }
}

export const getToken = () => {
  const { access_token } = getAuthTokenAndRole()
  return {
    Authorization: 'Bearer ' + access_token,
    'Content-Type': 'application/json',
  }
}
