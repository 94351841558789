import { useDebouncedSearch } from 'libs/hooks/useSearch'
import SearchBox from 'libs/searchbox/SearchBox'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { formatUnixDateMultipleWithTimeZone } from 'utils/unixConverter'
import ProgressBar from '../../../../libs/charts/ProgressBar'
import { formatString } from 'utils/trimStringValues'
import { useGetAllManufactureOrdersQuery } from 'pages/manufacture-orders/manufacture-orders-endpoints/manufacture_order.endpoints'

interface filters {
  page_no: number
  page_size: number
  searchText: string
  createdBetween: number[]
  sortBy: string
  sortDir: any
}

const ReportTable = ({ formik }: any) => {
  const { searchValue, handleSearch, inputValue } = useDebouncedSearch()
  const navigate = useNavigate()
  const [key, setKey] = useState(0)
  const plant = useSelector(selectSelectedItem)
  const [filters, setFilters] = useState<filters>({
    page_no: 1,
    page_size: 10,
    searchText: '',
    createdBetween:
      formik?.values?.dataRange?.length > 0 ? [formik?.values?.dateRange[0], formik?.values?.dateRange[1]] : [],
    sortBy: 'updated_on',
    sortDir: 'DESC',
  })

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      createdBetween:
        formik?.values?.dateRange?.length > 0 ? [formik?.values?.dateRange[0], formik?.values?.dateRange[1]] : [],
    }))
  }, [formik.values.dateRange])

  const { data: manufactureOrder, isLoading: isLoadingmanufactureOrder } = useGetAllManufactureOrdersQuery(
    {
      plantId: plant?.id,
      filters: filters,
    },
    {
      skip: !plant?.id,
    },
  )

  //search
  useEffect(() => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: 1,
      searchText: searchValue ? searchValue : '',
    }))
  }, [searchValue])

  const handlePageChange = (page_no: number) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: page_no,
    }))
    setKey((prevKey) => prevKey + 1)
  }
  // handle sort for column
  const handleSort = (columnName: string) => {
    setFilters((prevFilters: any) => {
      const newSortDir = prevFilters?.sortBy === columnName ? (prevFilters?.sortDir === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
      return {
        ...prevFilters,
        sortBy: columnName,
        sortDir: newSortDir,
      }
    })
  }

  const handlePageSizeChange = (newPageSize: number) => {
    const totalItemsCount = manufactureOrder?.paging?.total_items_count
    const currentPage = filters.page_no
    const startIndex = (currentPage - 1) * filters.page_size + 1
    const totalPages = totalItemsCount ? Math.ceil(totalItemsCount / newPageSize) : 0
    let newPageNo = Math.ceil(startIndex / newPageSize)
    newPageNo = Math.min(newPageNo, totalPages)
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: newPageNo,
      page_size: newPageSize,
    }))
    setKey((prevKey) => prevKey + 1)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'MO ID',
        accessor: 'manufactureOrderID',
        width: '10',
        Cell: ({ row }: any) => {
          const data = row?.original
          return (
            <div
              className="hyperlink"
              onClick={() => {
                navigate(`/planning/mo/view/${data?._id}`)
              }}
            >
              {data?.manufactureOrderID}
            </div>
          )
        },
      },
      {
        Header: 'Plan ID',
        accessor: 'plan',
        width: '10',
        Cell: ({ row }: any) => {
          const data = row?.original
          return (
            <div className="" onClick={() => {}}>
              {data?.plan?.planID ?? '-'}
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Finished Good',
        accessor: 'finishedGood',
        width: '10',
        Cell: ({ row }: any) => {
          return <div>{row?.original?.finishedGood?.name ?? '-'}</div>
        },
        disableSortBy: true,
      },
      {
        Header: 'Duration',
        accessor: '',
        width: '200',
        Cell: ({ row }: any) => {
          const data = row?.original
          const start = data?.startDate ? formatUnixDateMultipleWithTimeZone(data.startDate, plant.timezone) : null
          const end = data?.endDate ? formatUnixDateMultipleWithTimeZone(data.endDate, plant.timezone) : null

          return <>{start && end ? `${start} - ${end}` : '-'}</>
        },
      },

      {
        Header: 'Progress',
        accessor: 'status',
        width: '200',
        Cell: ({ row }: any) => {
          const data = row?.original
          const progressPercentage = parseInt(data?.MOProgressPercentage || 0).toFixed(0)
          return (
            <div className="w-[100px]">
              <span>
                {data?.status === 'CANCELLED' ? 'Cancelled' : `${progressPercentage}% ${formatString(data?.status)}`}
              </span>

              <ProgressBar
                percentage={parseInt(progressPercentage, 10)} // Convert the string back to a number
                title={data?.status} // Provide a fallback value
              />
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <div className="h-full">
      <div className="my-2 flex justify-between">
        <h1 className=" my-1 text-base font-bold">Manufacture Orders</h1>
        <SearchBox
          value={inputValue}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search Manufacture Order"
          onClearSearchBox={() => {
            setFilters((prevFilters: any) => ({
              ...prevFilters,
              searchText: '',
            }))
            handleSearch('')
          }}
        />
      </div>
      <div className="table-scroll-bar h-screen overflow-x-auto overflow-y-auto  ">
        <PaginatedTable
          onPageNumberChange={handlePageChange}
          key={key}
          loading={isLoadingmanufactureOrder}
          columns={columns}
          sorting={{ sortBy: filters.sortBy, sortDir: filters.sortDir }}
          rows={manufactureOrder?.data}
          pagination={manufactureOrder?.paging}
          onPageSizeChange={handlePageSizeChange}
          currentPageNumber={filters.page_no}
          pageSize={filters.page_size}
          emptyMessage="Data does not exist."
          onColumnSort={handleSort}
        />
      </div>
    </div>
  )
}

export default ReportTable
