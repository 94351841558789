import { useDispatch, useSelector } from 'react-redux'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import { togglePlantDetail } from 'store/redux/navSlice'
import { selectItem } from 'store/redux/navSlice'
import { SvgIcon } from '@mui/material'
import './rightSideNav.scss'
import { useEffect, useRef, useState } from 'react'
import { setPlantInactive } from 'store/redux/navSlice'
import { pathToRegexp } from 'path-to-regexp'
import { useNavigate } from 'react-router-dom'

import { toast } from 'libs/toast'
import FullPageLoader from 'libs/loader/FullPageLoader'
import {
  useUpdateUserPreferencesMutation,
  useUserInfoQuery,
} from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

const RightSideNav = () => {
  const { data: user } = useUserInfoQuery({})
  const [updateUserPreferences, { isLoading: updatingUserPref }] = useUpdateUserPreferencesMutation()

  const [plants, setPlants] = useState<any[]>([])
  const [_id, setId] = useState(null)

  useEffect(() => {
    if (user) {
      setPlants(user?.plants)
    }
  }, [user])

  const dynamicRoutePatterns = [
    '/productionV2/:department/:process',
    '/productionV2/:department/:process/input',
    '/productionV2/:department/:process/input/:entryId',
    '/productionV2/:department/:process/output',
    '/productionV2/:department/:process/output/:entryId',
    '/review/:department/:process',
    '/review/:department/:process/input',
    '/review/:department/:process/output',
    '/review/:department/:process/input/:entryId',
    '/review/:department/:process/output/:entryId',
    '/settings/configure/warehouses/:id',
    '/settings/configure/warehouses/edit/:id',
    '/planning/production-plans/edit/:planId/*',
    '/planning/production-plans/:planId',
    '/inventory/transactionhistory/:id',
    '/inventory/transactionhistory/edit/:id',
    '/inventory/new',
    '/settings/configure/operationalprocesses/form-fields/:processId/product/:productId',
    '/settings/configure/operationalprocesses/form-fields/:processId/product',
    '/settings/configure/operationalprocesses/details/:processId',

    // Add more patterns as needed
  ]
  const navigate = useNavigate()
  const selected = useSelector((state: any) => state?.nav?.selectedItem)

  const sortedData = plants
    ? [...plants]?.sort((a, b) => {
        const nameA = a?.name?.toLowerCase()
        const nameB = b?.name?.toLowerCase()
        return nameA?.localeCompare(nameB)
      })
    : []

  const dispatch = useDispatch()

  useEffect(() => {
    if (plants && sortedData && sortedData?.length) {
      const selectedPlant = _id ? _id : user?.preferences?.plantId

      const selectedPlantId = selectedPlant && selectedPlant !== 'undefined' ? selectedPlant : sortedData?.[0]?._id

      if (user.preferences?.plantId !== selectedPlantId) {
        updateUserPreferences({
          payload: {
            pref: {
              ...user.preferences,
              plantId: selectedPlantId,
            },
          },
        })
          .unwrap()
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Unable to update user preferences')
          })
          .finally(() => {
            handleClose()
          })
      }

      let selectedPlantData = plants.find((plant) => plant?._id == selectedPlantId)

      dispatch(selectItem(selectedPlantData))
    }
  }, [_id, plants])

  //dispach plant data to global state
  const handleRowClick = (item: any) => {
    const { _id } = item
    if (_id) {
      dispatch(selectItem(item))

      setId(_id)

      if (selected?.id !== _id) {
        const currentPath = location.pathname
        const pathSegments = currentPath.split('/').filter(Boolean)
        const startValue = pathSegments[0]
        const isDynamicRoute = (path: any, patterns: any) => {
          return patterns.some((pattern: any) => {
            const regexp = pathToRegexp(pattern)
            return regexp.test(path)
          })
        }

        const isDynamic = isDynamicRoute(currentPath, dynamicRoutePatterns)

        if (isDynamic) {
          navigate(startValue)
        } else {
          navigate(currentPath)
        }
      } else {
        return
      }
    } else {
      return
    }
  }
  //consume data from global state to show selected value
  //show hide side nav based on boolean from global state
  const isPlantDetailActive = useSelector((state: any) => state?.nav?.plantDetailActive)
  //dispach state: boolean to global state
  const handleClose = () => {
    dispatch(togglePlantDetail(isPlantDetailActive))
  }

  const popupRef = useRef<any>(null)

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Check if the click is outside the popup
      if (popupRef.current && !popupRef.current.contains(event.target) && !event.target.closest('.ignoreref2')) {
        dispatch(setPlantInactive())
      }
    }

    // Adding event listener to detect clicks outside the popups
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <>
      <div className={`rightSideNav ${isPlantDetailActive ? 'active' : ''}`} ref={popupRef}>
        <div className="rightSideNav-title">
          <h6 className="rightSideNav-h6">Switch Plant</h6>
          <SvgIcon onClick={handleClose} sx={{ cursor: 'pointer' }}>
            <CloseRoundedIcon />
          </SvgIcon>
        </div>

        {/* List of Plants */}

        {updatingUserPref && <FullPageLoader />}

        {sortedData?.map((item) => (
          <div key={item?._id}>
            <div
              className={`rightSideNav-content-container ${selected && selected?.id === item?._id ? 'active' : ''}`}
              onClick={() => handleRowClick(item)}
            >
              <div className="rightSideNav-text">
                <p className="rightSideNav-p-address mb-0  break-words p-xs-regular">{item?.name}</p>
                <p className="rightSideNav-p-plant mb-0">{item?.zip_code}</p>
              </div>
              <div className="rightSideNav-icon">
                {selected && selected?.id === item?._id && <DoneRoundedIcon fontSize="small" />}
              </div>
            </div>
            <div className="rightSideNav-line"></div>
          </div>
        ))}
      </div>
      <div className={isPlantDetailActive ? 'sideNav-overlay-dark' : 'sideNav-overlay'}></div>
    </>
  )
}

export default RightSideNav
