import Popup from 'libs/popup/Popup'
import WhiteCard from 'libs/card/WhiteCard'
import Button from 'libs/button/Button'
import PaginatedTable from 'libs/table/PaginatedTable'
import FullPageLoader from 'libs/loader/FullPageLoader'
import MotionDiv from 'libs/motionDiv'
import { toast } from 'libs/toast'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { formatUnixDate } from 'utils/unixConverter'
import useTabTitle from 'libs/hooks/useTabTitle'
import Chip from 'libs/chip/Chip'
import DeletePurchaseOrder from '../delete-purchase-order/DeletePurchaseOrder'
import { useGetPurchaseOrdersQuery } from '../purchase-orders-endpoints/purchaseOrders.endpoints'

const ViewPurchaseOrder = () => {
  const { id: paramsId } = useParams()
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)
  useTabTitle(paramsId ? paramsId : 'View Purchase Order')
  useTabTitle('View Purchase Order')
  const filters = {
    poID: paramsId,
    page_no: 1,
    page_size: 10,
    sortDir: 'DESC',
  }
  const [deletePopUp, setDeletePopUp] = useState<any>({ isPopUpOpen: false, purchaseOrderID: '' })
  const {
    data: purchaseOrder,
    isLoading: purchaseOrderLoading,
    isFetching,
  } = useGetPurchaseOrdersQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    {
      skip: !plantId?.id || !paramsId,
    },
  )

 
    useEffect(() => {
      if (purchaseOrder) {
        const totalItemsCount = purchaseOrder?.paging?.total_items_count
        const dataLength = purchaseOrder?.data?.length
        if (totalItemsCount <= 0 || dataLength === 0) {
          toast.error('Something went wrong, Please try again.')
          return
        }
      }
    }, [purchaseOrder])

  const productsColumns = [
    {
      Header: 'Product',
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        return (
          <div>
            <h1 className="p-xs-regular">{row.original.name}</h1>
            <h2 className="p-xs-regular-dim">{row.original.code}</h2>
          </div>
        )
      },
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      disableSortBy: true,
      align: 'right',
      Cell: ({ value }: any) => {
        return <div>{value}</div>
      },
    },
    {
      Header: 'Unit',
      disableSortBy: true,
      accessor: 'unit_of_measure',
      Cell: ({ value }: any) => {
        return <div>{value}</div>
      },
    },
  ]

  return (
    <MotionDiv>
      {!purchaseOrderLoading ? (
        <WhiteCard className="mt-3 flex flex-col gap-6">
          <span className="flex justify-between">
            <h5 className="h5">Purchase Order Details</h5>
            <div className="flex gap-4">
              <Button
                onClick={() => setDeletePopUp({ isPopUpOpen: true, purchaseOrderID: purchaseOrder?.data?.[0] || '' })}
                color="error"
              >
                Delete Purchase Order
              </Button>
              <Button color="primary" onClick={() => navigate(`/orders/purchase-order/edit/${paramsId}`)}>
                Edit Purchase Order
              </Button>
            </div>
          </span>
          <div className="grid grid-cols-[150px,1fr] gap-4 text-xs">
            <span className="text-gray-500">Purchase Order ID</span>
            <span>{purchaseOrder?.data?.[0]?.purchaseOrderID}</span>
            <span className="text-gray-500">Status</span>
            <span className="-my-1 w-fit">
              <Chip title={purchaseOrder?.data?.[0]?.status} />
            </span>
            <span className="text-gray-500">Supplier Name</span>
            <span>{purchaseOrder?.data?.[0]?.vendor?.name}</span>
            <span className="text-gray-500">Arrival Date</span>
            <span>{formatUnixDate(purchaseOrder?.data?.[0]?.arrivalDate)}</span>
          </div>

          <PaginatedTable
            rows={purchaseOrder?.data?.[0]?.products}
            columns={productsColumns}
            emptyMessage={'Product does not exist.'}
            loading={isFetching}
            hidePagination
          />

          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Purchase Order"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, purchaseOrderID: '' })
            }}
          >
            <DeletePurchaseOrder
              closePopUp={() => {
                setDeletePopUp({ isPopUpOpen: false, purchaseOrderID: '' })
              }}
              poID={deletePopUp.purchaseOrderID}
              plant_id={plantId?.id}
            />
          </Popup>
        </WhiteCard>
      ) : (
        <FullPageLoader />
      )}
    </MotionDiv>
  )
}

export default ViewPurchaseOrder
