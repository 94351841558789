import Panel from 'libs/panel'
import { useEffect, useState } from 'react'
import QuickAddEditPurchase from './QuickAddEditPurchase'

const QuickAddEditPurchaseOrder = ({ id, isOpen, onClose, manufactureOrderFormik, data }: any) => {
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (isOpen) {
      setValue(id)
    }
  }, [id, isOpen])

  const handleClose = () => {
    onClose(false)
    setValue(null)
  }

  return (
    <Panel
      size="large"
      isOpen={isOpen}
      title={value ? 'Quick Edit Purchase Order' : 'Quick Add Purchase Order'}
      onClose={handleClose}
    >
      <QuickAddEditPurchase close={handleClose} data={data} manufactureOrderFormik={manufactureOrderFormik} purchaseOrderID={value} />
    </Panel>
  )
}

export default QuickAddEditPurchaseOrder;
