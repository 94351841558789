import BlueTick from 'assets/icons/product/BlueTick'
import WhiteCard from 'libs/card/WhiteCard'
import { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import BasicDetails from './tabs/BasicDetail'
import AddressDetails from './tabs/AddressDeatil'
import { useFormik } from 'formik'
import { toast } from 'libs/toast'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import FullPageLoader from 'libs/loader/FullPageLoader'
import ViewBasicDetails from '../view-vendor/ViewBasicDetails'
import ViewAddressDetails from '../view-vendor/ViewAddressDetails'
import ViewPurchaseOrders from '../view-vendor/ViewPurchaseOrders'
import { useGetVendorQuery, usePostVendorMutation, usePutVendorMutation } from '../vendor-endpoints/vendor.endpoints'

// const steps = [
//   { label: 'Basic Details', path: 'basic-details' },
//   { label: 'Billing Address', path: 'address-details' },
// ]
const AddEditVendor = () => {
  const params = useParams()
  const plantId = useSelector(selectSelectedItem)
  const navigate = useNavigate()
  const vendorID = params?.id ?? ''

  const [page, setPage] = useState(1)
  const [addVendor, { isLoading: addVendorLoading }] = usePostVendorMutation()

  const {
    data: vendorsData,
    isLoading: vendorsLoading,
    isFetching: vendorFetching,
  } = useGetVendorQuery(
    { plantId: plantId?.id, vendorID: vendorID },
    {
      skip: !plantId?.id || !vendorID,
    },
  )

  const [updateVendor, { isLoading: updateVendorLoading }] = usePutVendorMutation()

  // const isEdit = location.pathname.includes('edit')
  // let loading = false
  const isView = location?.pathname?.includes('view')

  const steps = [
    { label: 'Basic Details', path: isView ? 'view-basic-details' : 'basic-details' },
    { label: 'Address Details', path: isView ? 'view-address-details' : 'address-details' },
    ...(isView ? [{ label: 'Purchase Orders', path: 'view-purchase-order' }] : []),
  ]

  const [lastValidPage, setLastValidPage] = useState(1)

  useEffect(() => {
    if (page > lastValidPage) {
      setLastValidPage(lastValidPage + 1)
    }
  }, [page])

  const handleNavigation = (stepIndex: number) => {
    const stepPath = steps[stepIndex].path

    if (vendorID) {
      setPage(stepIndex + 1)
      navigate(stepPath)
    } else if (lastValidPage === 2) {
      setPage(stepIndex + 1)
      navigate(stepPath)
    } else if (page === 1) {
      return
    }
  }

  const vendorFormik = useFormik({
    initialValues: {
      BasicDetails: {},
      AddressDetails: {},
    },
    onSubmit: (values: any) => {
      const payload = {
        ...values?.BasicDetails,
        ...values?.AddressDetails,
      }

      if (!vendorID) {
        addVendor({ plant_id: plantId.id, data: payload })
          .unwrap()
          .then(() => {
            navigate('/contacts/vendor-management')

            toast.success('The Vendor ' + payload.name + ' is added successfully')
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail) ? error?.data?.detail[0]?.msg : error?.data?.detail
            toast.error(errorMessage ?? 'Failed to add the Vendor ' + payload.name + ' Please try again.')
          })
      } else {
        updateVendor({ plant_id: plantId.id, vendorID: vendorID, data: payload })
          .unwrap()
          .then(() => {
            navigate('/contacts/vendor-management')

            toast.success('The Vendor ' + payload.name + ' is edited successfully')
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail) ? error?.data?.detail[0]?.msg : error?.data?.detail
            toast.error(errorMessage ?? 'Failed to edit the Vendor ' + payload.name + ' Please try again.')
          })
      }
    },
  })

  useEffect(() => {
    if (vendorsData) {
      vendorFormik.resetForm({
        values: {
          BasicDetails: {
            name: vendorsData?.name,
            vendorType: vendorsData?.vendorType,
            businessEmailAddress: vendorsData?.businessEmailAddress,
            primaryContactNumber: vendorsData?.primaryContactNumber,
            secondaryContactNumber: vendorsData?.secondaryContactNumber,
            category: vendorsData?.category,
            pointOfContact: vendorsData?.pointOfContact,
          },
          AddressDetails: {
            billingAddresses: vendorsData?.billingAddresses,
          },
          purchaseData: vendorsData?.purchaseOrder,
        },
      })
    }
  }, [vendorsData])

  return (
    <div className="flex flex-col gap-2">
      <h4 className="h4-bold-black">
        {' '}
        {vendorID && !isView ? 'Edit Vendor Management' : isView ? vendorsData?.name : 'Add New Vendor'}
      </h4>

      <WhiteCard>
        <div className="flex min-h-[70vh] w-full gap-6 rounded bg-white">
          <div className="relative w-1/5 border-r border-border-medium p-2">
            {steps.map((step, index) =>
              !isView ? (
                <div key={index}>
                  <button onClick={() => handleNavigation(index)} className="flex items-center">
                    <div
                      className={`h-6 w-6 rounded-3xl border p-2 ${
                        page === index + 1 ? 'border-primary ' : 'border-[#B9C4CC]'
                      } ${
                        page > index + 1 ? 'border-primary bg-primary' : 'border-[#B9C4CC]'
                      } flex items-center justify-center`}
                    >
                      <div className={`text-xs font-bold  `}>{page > index + 1 ? <BlueTick /> : index + 1}</div>
                    </div>
                    <p className={`whitespace-nowrap rounded px-2 text-sm`}>{step.label}</p>
                  </button>
                  {index < steps.length - 1 && (
                    <span
                      className={`ml-3 flex h-[16px] border-l-2 border-dashed ${
                        page > index + 1 ? 'border-primary' : 'border-[#B9C4CC]'
                      }`}
                    ></span>
                  )}
                </div>
              ) : (
                <div
                  onClick={() => handleNavigation(index)}
                  key={index}
                  className={` rounded-lg hover:bg-inputBg  ${page === index + 1 ? ' rounded-lg bg-brand-primary-200 font-bold' : ''}`}
                >
                  <button className={`flex items-center   `}>
                    <p className="whitespace-nowrap rounded px-2 py-3 text-sm">{step.label}</p>
                  </button>
                </div>
              ),
            )}
          </div>
          {!vendorsLoading ? (
            <div className="w-4/5" style={{ overflowX: 'auto' }}>
              <Routes>
                <Route
                  path="basic-details"
                  element={
                    <BasicDetails
                      vendorFormik={vendorFormik}
                      setPage={setPage}
                      loading={updateVendorLoading || addVendorLoading}
                      vendorID={vendorID}
                    />
                  }
                />
                <Route
                  path="address-details"
                  element={
                    <AddressDetails
                      vendorFormik={vendorFormik}
                      setPage={setPage}
                      loading={updateVendorLoading || addVendorLoading}
                      vendorID={vendorID}
                    />
                  }
                />
                <Route
                  path="view-address-details"
                  element={<ViewAddressDetails vendorsData={vendorsData} setPage={setPage} />}
                />

                <Route
                  path="view-basic-details"
                  element={<ViewBasicDetails vendorsData={vendorsData} setPage={setPage} />}
                />

                {/* <Route
                path="view-purchase-order"
                element={<ViewPurchaseOrders vendorsData={vendorsData} setPage={setPage} />}
               
              />
            </Routes> */}
                <Route
                  path="view-purchase-order"
                  element={<ViewPurchaseOrders vendorsData={vendorsData} setPage={setPage} loading={vendorFetching} />}
                />
              </Routes>
            </div>
          ) : (
            <FullPageLoader />
          )}
        </div>
      </WhiteCard>
    </div>
  )
}

export default AddEditVendor
