import Button from 'libs/button/Button'
import Field from 'libs/field'
import React, { useMemo } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { formatString } from 'utils/trimStringValues'
import { phoneNumberValidator } from 'utils/phoneNumberValidator'
import { toast } from 'libs/toast'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { usePostCustomerManagementMutation } from 'pages/contacts/customer-management/customer-endpoints/customer.endpoints'
import { CustomerType } from 'pages/contacts/customer-management/customer-endpoints/customerTypes'

type Props = {
  close: () => void
}

const QuickAddEditCustomer: React.FC<Props> = ({ close }) => {
  const plantId = useSelector(selectSelectedItem)
  const [addCustomer, { isLoading: addCustomerLoading }] = usePostCustomerManagementMutation()
  // Initializing Formik
  const formik = useFormik({
    initialValues: {
      customerType: '',
      name: '',
      primaryContactNumber: '',
    },
    validationSchema: yup.object({
      name: yup.string().required('Customer name is required'),
      customerType: yup.string().required('Customer type is required'),
      primaryContactNumber: yup
        .string()
        .required('Primary contact number is required')
        .test('valid-phone', 'Please enter a phone number for the selected country.', phoneNumberValidator),
    }),
    onSubmit: (values) => {
      const payload = {
        customerType: values.customerType as CustomerType,
        name: values.name,
        primaryContactNumber: values.primaryContactNumber.toString(),
      }

      addCustomer({ plant_id: plantId.id, data: payload })
        .unwrap()
        .then(() => {
          toast.success('The Customer ' + payload.name + ' is added successfully')
          close()
        })
        .catch((error) => {
          const errorMessage = Array.isArray(error?.data?.detail) ? error?.data?.detail[0]?.msg : error?.data?.detail
          toast.error(errorMessage ?? 'Failed to add the Customer ' + payload.name + ' Please try again.')
        })
    },
  })

  const label = useMemo(() => {
    return formik?.values?.customerType ? formatString(formik.values.customerType) : ''
  }, [formik?.values?.customerType])

  return (
    <div className="flex flex-col gap-2">
      <Field
        label="Customer Type"
        placeholder="Select Customer Type"
        formik={formik}
        options={[
          { label: 'Individual', value: 'INDIVIDUAL' },
          { label: 'Business', value: 'BUSINESS' },
        ]}
        defaultValue={label}
        name="customerType"
        type="select"
        required
      />
      <Field label="Customer Name" placeholder="Enter Customer Name" formik={formik} name="name" type="text" required />
      <Field
        type="phonenumber"
        label="Primary Contact Number"
        placeholder="Enter Contact Number"
        formik={formik}
        name="primaryContactNumber"
        required
      />

      <div className="my-2 flex justify-end gap-2">
        <Button onClick={close}>Cancel</Button>
        <Button
          color="primary"
          loading={addCustomerLoading}
          onClick={() => {
            formik.handleSubmit()
          }}
        >
          Add
        </Button>
      </div>
    </div>
  )
}

export default QuickAddEditCustomer
