import { useNavigate } from 'react-router-dom'
import { toast } from 'libs/toast'
import { useDeleteWareHouseMutation } from '../warehouses-endpoints/warehouses.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import Button from 'libs/button/Button'
import { t } from 'i18next'

const DeleteWareHouses = ({ closePopUp, data }: any) => {
  const selectedPlant = useSelector(selectSelectedItem)

  const navigate = useNavigate()
  const [deleteWarehouse, { isLoading }] = useDeleteWareHouseMutation()
  const handleDelete = () => {
    if (data?._id && data?.name && selectedPlant?.id) {
      deleteWarehouse({
        wareHouse_id: data?._id,
        plant_id: selectedPlant?.id,
      })
        .unwrap()
        .then(() => {
          toast.success(`The Warehouse “${data?.name}” is deleted successfully.`)
          closePopUp()
          navigate('/settings/configure/warehouses')
        })
        .catch((err) => {
          toast.error(err?.data?.detail ?? `Failed to delete the Warehouse “${data?.name}”. Please try again.`)
        })
    }
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('warehouse.warehouse_delete_message')} <span className="break-words p-xs-bold">“{data?.name}”. </span>
        {t('warehouse.warehouse_delete')}
      </p>

      <div className="mt-4 flex justify-end gap-2">
        <Button onClick={closePopUp}>{t('warehouse.close')}</Button>
        <Button color="error" type="button" loading={isLoading} onClick={handleDelete}>
          {t('warehouse.delete')}
        </Button>
      </div>
    </div>
  )
}

export default DeleteWareHouses
