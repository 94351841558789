import { t } from 'i18next'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { useNavigate } from 'react-router-dom'
import { useDeleteVendorMutation } from '../vendor-endpoints/vendor.endpoints'

interface Props {
  closePopUp: () => void
  VendorData: any
  plant_id: string
}

function DeleteVendor({ closePopUp, VendorData, plant_id }: Props) {
  const [deletevendor, { isLoading: deleteVendorMutation }] = useDeleteVendorMutation()
  const navigate = useNavigate()

  const handleDeleteVendor = () => {
    deletevendor({ plant_id, vendorID: VendorData?._id })
      .unwrap()
      .then(() => {
        toast.success(`Vendor “${VendorData?.name}” is removed successfully.`)
        closePopUp()
        navigate('/contacts/vendor-management')
      })
      .catch((error: any) => {
        toast.error(error?.data?.detail ?? `Failed to remove Vendor “${VendorData?.name}”. Please try again.`)
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')}{' '}
        <span className="break-words pr-1 p-xs-bold">“Vendor {VendorData?.name}”.</span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDeleteVendor} loading={deleteVendorMutation}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </div>
  )
}

export default DeleteVendor
