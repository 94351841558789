import { useState } from 'react'
import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useNavigate } from 'react-router-dom'
import FilterSalesOrder from './panel/FilterSalesOrder'
import { useGetAllSalesOrdersQuery } from './sales-order-endpoints/salesOrders.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { formatUnixDate } from 'utils/unixConverter'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import DeleteSalesOrder from './delete-sales-order/DeleteSalesOrder'
import Popup from 'libs/popup/Popup'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import Chip from 'libs/chip/Chip'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'

const additionalFilters = {
  createdBetween: [],
  updatedBetween: [],
  status: [],
  category: [],
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const SalesOrder = () => {
  const plantId = useSelector(selectSelectedItem)
  useTabTitle('Sales Orders')
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, salesOrderID: '' })
  const navigate = useNavigate()

  const column = [
    {
      Header: 'Sales Order ID',
      accessor: 'salesOrderID',
      width: 150,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/orders/sales-order/view/${data?._id}`)
            }}
          >
            {data?.salesOrderID}
          </div>
        )
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">Customer Name</div>,
      accessor: 'customerName',
      width: 150,
      Cell: ({ row }: any) => {
        return <div>{row.original.customer?.name}</div>
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">Products</div>,
      accessor: 'products',
      width: 150,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/orders/sales-order/view/${data?._id}`)
            }}
          >
            {data?.products.length} Products
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Delivery Date</div>,
      accessor: 'deliveryDate',
      width: 150,
      Cell: ({ value }: any) => formatUnixDate(value),
    },
    {
      Header: <div className="flex w-full items-center justify-end">Status</div>,
      accessor: 'status',
      width: 50,
      Cell: ({ value }: any) => <Chip title={value} />,
      disableSortBy: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 10,
      Cell: ({ row }: any) => (
        <div className="flex w-full items-center justify-between ">
          <div className="flex flex-row">
            <button
              className="mr-2"
              onClick={(e) => {
                e.stopPropagation()
                navigate(`edit/${row.original._id}`)
              }}
            >
              <Pen />
            </button>
            <button
              className="mr-2"
              onClick={(event) => {
                event.stopPropagation()
                setDeletePopUp({ isPopUpOpen: true, salesOrderID: row?.original })
              }}
            >
              <Bin />
            </button>
          </div>

          <span className="cursor-pointer" onClick={() => navigate(`/orders/sales-order/view/${row?.original?._id}`)}>
            <ClickableRowArrow />
          </span>
        </div>
      ),
      disableSortBy: true,
    },
  ]

  const handleAddSalesOrder = () => {
    navigate('newSalesOrder')
  }
  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Sales Orders</h1>
        <p className="p-sm-regular-1">View the details of your added sales orders and easily add new ones as needed.</p>
        <FilterableSearchTable
          useQuery={useGetAllSalesOrdersQuery}
          columns={column}
          addButton={{ label: 'Add Sales Order', onClick: handleAddSalesOrder }}
          placeholders={{
            emptyMessage: 'No Sales orders are added. Please add Sales order',
            filterEmptyMessage: 'No matching results found',
            search: 'Search by sales order ID, status or customers',
          }}
          filterPanelComponent={FilterSalesOrder}
          additionalFilters={additionalFilters}
        />
        <Popup
          isOpen={deletePopUp.isPopUpOpen}
          title="Delete Sales Order"
          onClose={() => {
            setDeletePopUp({ isPopUpOpen: false, salesOrderID: '' })
          }}
        >
          <DeleteSalesOrder
            closePopUp={() => setDeletePopUp({ isPopUpOpen: false, salesOrderID: '' })}
            soID={deletePopUp.salesOrderID}
            plant_id={plantId?.id}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default SalesOrder
