import Button from 'libs/button/Button'
import Field from 'libs/field'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import NewEditableTable from 'libs/table/NewEditableTable'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { formatString } from 'utils/trimStringValues'
import { phoneNumberValidator } from 'utils/phoneNumberValidator'
import { toast } from 'libs/toast'

interface BasicDetailsProps {
  vendorFormik: any
  setPage: React.Dispatch<React.SetStateAction<number>>
  loading: boolean
  vendorID: string
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const BasicDetails: React.FC<BasicDetailsProps> = ({ vendorFormik, vendorID }) => {
  const navigate = useNavigate()
  const [data, setData] = useState([])

  const BasicDetailsFormik = useFormik({
    initialValues: {
      vendorType: '',
      category: '',
      name: '',
      businessEmailAddress: '',
      primaryContactNumber: '',
      secondaryContactNumber: '',
      pointOfContact: [],
    },

    validationSchema: yup.object({
      vendorType: yup.string().required('Vendor Type is required'),
      category: yup.string().required('Material Type is required'),

      name: yup.string().required('Vendor Name is required'),
      primaryContactNumber: yup
        .string()
        .required('Primary Contact Number is required')
        .test('valid-phone', 'Please enter a phone number for the selected country.', phoneNumberValidator),
      secondaryContactNumber: yup
        .string()
        .nullable() // Allows the field to be null or undefined
        .test('valid-phone', 'Please enter a phone number for the selected country.', function (value) {
          // Perform validation only if value is not null or undefined
          if (value) {
            return phoneNumberValidator(value)
          }
          return true // Skip validation if value is null or undefined
        }),
      businessEmailAddress: yup
        .string()
        .optional() // Makes the field optional (it can be empty)
        .email('Invalid email format') // Standard email validation
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Email must be a valid email (e.g., abc@gmail.com)',
        ),
    }),
    onSubmit: (values) => {
      // Check if any contact has an empty field
      let hasEmptyField = false
      let hasInvalidEmail = false

      values?.pointOfContact?.forEach((fields: any) => {
        if (!fields.firstName || !fields.lastName || !fields.emailAddress || !fields.contactNumber || !fields.remarks) {
          hasEmptyField = true
        }

        if (fields.emailAddress && !emailRegex.test(fields.emailAddress)) {
          hasInvalidEmail = true
        }
        if (hasEmptyField || hasInvalidEmail) {
          return
        }
      })

      if (hasEmptyField && !pointOfContact.length) {
        toast.error('Please fill in all the fields of Point of Contacts')
      } else if (hasInvalidEmail) {
        toast.error('Please enter a valid email address for all contacts')
      } else {
        const newValues = {
          category: values?.category,
          vendorType: values?.vendorType,
          name: values?.name,
          businessEmailAddress: values?.businessEmailAddress,
          primaryContactNumber: values?.primaryContactNumber.toString(),
          secondaryContactNumber: values?.secondaryContactNumber.toString(),
          pointOfContact: values.pointOfContact.map((contact: any) => ({
            ...contact,
            contactNumber: contact.contactNumber?.toString() || '',
          })),
        }
        vendorFormik.setFieldValue('BasicDetails', newValues)
        if (!vendorID) {
          navigate('../address-details')
        } else {
          navigate(`/contacts/vendor-management/${vendorID}/edit/address-details`)
        }
      }
    },
  })

  const pointOfContact = () => {
    const filteredData = data?.filter((contact: any) => Object?.values(contact)?.some((value) => value !== ''))
    BasicDetailsFormik?.setFieldValue('pointOfContact', filteredData?.length > 0 ? filteredData : [])
    BasicDetailsFormik.handleSubmit()
  }

  useEffect(() => {
    BasicDetailsFormik.resetForm({
      values: {
        vendorType: vendorFormik.values.BasicDetails?.vendorType || '',
        category: vendorFormik.values.BasicDetails?.category || '',
        name: vendorFormik.values.BasicDetails?.name || '',
        businessEmailAddress: vendorFormik.values.BasicDetails?.businessEmailAddress || '',
        primaryContactNumber: vendorFormik.values.BasicDetails?.primaryContactNumber || '',
        secondaryContactNumber: vendorFormik.values.BasicDetails?.secondaryContactNumber || '',
        pointOfContact: vendorFormik.values.BasicDetails?.pointOfContact || '',
      },
    })
  }, [vendorFormik])

  const vendorsColumns = [
    {
      title: 'First Name',
      accessor: 'firstName',
      editable: true,
      placeholder: 'Enter first name',
      require: true,
    },

    {
      title: 'Last Name',
      accessor: 'lastName',
      editable: true,
      placeholder: 'Enter last name',
    },
    {
      title: 'Contact Number',
      accessor: 'contactNumber',
      editable: true,
      fieldType: 'number',
      placeholder: 'Enter contact number',
    },
    {
      title: 'Email Address',
      accessor: 'emailAddress',
      editable: true,
      placeholder: 'Enter email address',
    },
    {
      title: 'Remarks',
      accessor: 'remarks',
      editable: true,
      placeholder: '-',
    },
  ]

  return (
    <div className="flex h-full flex-col justify-between gap-4">
      <div className="flex flex-col gap-4">
        <span>
          <h4 className="h4">Basic Details</h4>

          <p className="text-sm text-primary-gray-4">
            {vendorID
              ? 'Edit the existing Vendor details to make necessary changes and updates.'
              : 'Add the basic details that are required to Add New Vendor.'}
          </p>
        </span>
        <div className="grid w-3/4 grid-cols-2 gap-4">
          <Field
            label="Vendor Type"
            placeholder="Select"
            formik={BasicDetailsFormik}
            options={[
              { label: 'Individual', value: 'INDIVIDUAL' },
              { label: 'Government', value: 'GOVERNMENT' },
              { label: 'Private Company', value: 'PRIVATE_COMPANY' },
            ]}
            name="vendorType"
            type="select"
            defaultValue={{ label: formatString(BasicDetailsFormik?.values?.vendorType) } ?? { label: '' }}
            required
          />

          <Field
            label="Category"
            placeholder="Select"
            formik={BasicDetailsFormik}
            options={[
              { label: 'Raw material', value: 'RAW_MATERIAL' },
              { label: 'Services', value: 'SERVICES' },
            ]}
            name="category"
            type="select"
            defaultValue={{ label: formatString(BasicDetailsFormik?.values?.category) } ?? { label: '' }}
            required
          />
        </div>
        <p className="border-b border-border-medium pb-2" />
        <div className="grid w-3/4 grid-cols-2 gap-4">
          <Field
            label="Vendor Name"
            placeholder="Enter Vendor Name"
            formik={BasicDetailsFormik}
            name="name"
            type="text"
            required
          />

          <Field
            label="Business Email Address"
            placeholder="Enter Business Email Address"
            formik={BasicDetailsFormik}
            name="businessEmailAddress"
            type="text"
          />
          <Field
            label="Primary Contact Number"
            placeholder="Enter Contact Number"
            formik={BasicDetailsFormik}
            name="primaryContactNumber"
            type="phonenumber"
            required
          />

          <Field
            label="Secondary Contact Number"
            placeholder="Enter Contact Number"
            formik={BasicDetailsFormik}
            name="secondaryContactNumber"
            type="phonenumber"
          />
        </div>{' '}
      </div>
      <hr className="mt-5" />
      <h5 className="mb-2 mt-3 h5">Point Of Contacts</h5>
      <NewEditableTable
        defaultRows={BasicDetailsFormik?.values?.pointOfContact}
        columns={vendorsColumns}
        onChange={(values: any) => {
          setData(values)
        }}
        addButtonTitle="Add New Contact"
      />

      <div className="flex justify-end">
        <span className="flex gap-3">
          {' '}
          <Button onClick={() => navigate('/contacts/vendor-management')}>Cancel</Button>
          <Button
            color="success"
            onClick={() => {
              pointOfContact()
            }}
          >
            Next
          </Button>
        </span>
      </div>
    </div>
  )
}

export default BasicDetails
