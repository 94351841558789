import { useMemo, useState } from 'react'
import MotionDiv from 'libs/motionDiv'
import { scaleMotionProps } from 'utils/FramerProps'
import Pen from 'assets/icons/camera-page/Pen'
import Panel from 'libs/panel'
import AddPlant from './add-plant/AddPlant'
import ViewPlant from './view-plant/ViewPlant'
import UpdatePlant from './update-plant/UpdatePlant'
import Bin from 'assets/icons/camera-page/Bin'
import { AnimatePresence, motion } from 'framer-motion'
import DeletePlant from './delete-plant/DeletePlant'
import { useGetPlantsQuery } from 'pages/plants/plants-endpoints/plants.endpoints'
import FilterPlant from './filter-plant/FilterPlant'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'

interface RowData {
  _id: string
  name: string
  timezone: string
  zip_code: string
  country: any
  countryName: any
  area_name: string
  is_active: boolean
}

const additionalFilters = {
  country: [],
  timezone: [],
  status: [],
  createdBetween: [],
  updatedBetween: [],
}

const Plants = () => {
  const [editPanel, setEditPanel] = useState(false)
  const [addPlant, setAddPlant] = useState(false)
  const [viewPanel, setViewPanel] = useState(false)
  const [selectedEntry, setSelectedEntry] = useState(null)
  const [plantToDelete, setPlantToDelete] = useState<RowData | null>(null)

  //toggle edit entry
  const openUpdatePanel = (entryData: any) => {
    setSelectedEntry(entryData)
    setEditPanel(!editPanel)
  }

  const handleAddPlantToggle = () => {
    setAddPlant(!addPlant)
  }

  const columns = useMemo(
    () => [
      {
        Header: <div className="flex h-2 w-full items-center">Plant name</div>,
        accessor: 'name',
        Cell: ({ row }: any) => {
          const data = row?.original
          const handlePlantsSelection = (entryData: any) => {
            setSelectedEntry(entryData)
            setViewPanel(!viewPanel)
          }
          return (
            <div className="hyperlink" onClick={() => handlePlantsSelection(data)}>
              {data?.name}
            </div>
          )
        },
        width: 200,
      },
      {
        Header: <div className="flex h-2 w-full items-center">Time zone</div>,
        accessor: 'timezone',
        width: 200,
        Cell: ({ row }: { row: { original: RowData } }) => {
          return <div>{row.original?.timezone}</div>
        },
      },
      {
        Header: <div className="flex h-2 w-full items-center">Zip code</div>,
        accessor: 'zip_code',
        width: 120,
        Cell: ({ row }: { row: { original: RowData } }) => {
          return <div>{row.original?.zip_code}</div>
        },
      },
      {
        Header: <div className="flex h-2 w-full items-center justify-end">Country</div>,
        accessor: 'country',
        width: 144,
        Cell: ({ row }: { row: { original: RowData } }) => {
          return <div className="flex items-center justify-end pr-6">{row.original?.country?.countryName}</div>
        },
        disableSortBy: false,
      },
      {
        Header: <div className="flex h-2 w-full items-center">Address</div>,
        accessor: 'area_name',
        width: 239,
        Cell: ({ row }: { row: { original: RowData } }) => {
          return <div className="flex items-center justify-end pr-6">{row.original?.area_name}</div>
        },
      },
      {
        Header: <div className="flex h-2 w-full items-center">Status</div>,
        accessor: 'is_active',
        width: 92,
        Cell: ({ row }: { row: { original: RowData } }) => {
          const isActive = row.original?.is_active
          return (
            <div className="flex items-center justify-end pr-6 ">
              {isActive ? (
                <span className="mr-1 whitespace-nowrap rounded-full bg-[#D1E7DD] px-2 py-1 text-xs text-[#015314]">
                  Active
                </span>
              ) : (
                <span className="mr-1 whitespace-nowrap rounded-full bg-[#F8D7DA] px-2 py-1 text-xs text-[#8E0000]">
                  Inactive
                </span>
              )}
            </div>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',

        width: 100,
        Cell: ({ row }: { row: { original: RowData } }) => {
          return (
            <>
              {!row.original.is_active ? (
                ''
              ) : (
                <div className="flex justify-end gap-1">
                  <button
                    onClick={(event) => {
                      event.stopPropagation()
                      openUpdatePanel(row?.original)
                    }}
                  >
                    <Pen />
                  </button>
                  <button
                    onClick={(event) => {
                      event.stopPropagation()
                      setPlantToDelete(row?.original)
                    }}
                  >
                    <Bin />
                  </button>
                </div>
              )}
            </>
          )
        },
      },
    ],
    [],
  )

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Plants</h1>
        <p className=" p-sm-regular-1">
          Upon adding a plant, you can define plant-specific products and processes. Easily switch between plants using
          the dedicated dropdown in the top bar for seamless navigation.
        </p>
        <FilterableSearchTable
          useOrgQuery={useGetPlantsQuery}
          addButton={{ label: 'Add Plant', onClick: handleAddPlantToggle }}
          columns={columns}
          placeholders={{
            search: 'Search plant by name or zip code',
            emptyMessage: 'No data found, please add a plant into the system.',
            filterEmptyMessage: 'No matching results found',
          }}
          filterPanelComponent={FilterPlant}
          additionalFilters={additionalFilters}
        />
        <Panel
          size="large"
          isOpen={addPlant}
          title="New Plant"
          onClose={() => {
            setAddPlant(false)
          }}
        >
          <AddPlant
            closePanel={() => {
              setAddPlant(false)
            }}
          />
        </Panel>
        <Panel size="large" isOpen={editPanel} title="Edit Plant" onClose={() => setEditPanel(false)}>
          <UpdatePlant close={() => setEditPanel(false)} handleDelete={setPlantToDelete} plant={selectedEntry} />
        </Panel>
        <Panel size="large" isOpen={viewPanel} title="Plant Details" onClose={() => setViewPanel(false)}>
          <ViewPlant
            handleUpdate={openUpdatePanel}
            handleDelete={setPlantToDelete}
            close={() => setViewPanel(false)}
            plant={selectedEntry}
          />
        </Panel>
        <div>
          <AnimatePresence>
            {plantToDelete && (
              <motion.div
                {...scaleMotionProps}
                className={`fixed left-0 top-[3rem] flex h-full w-full items-center justify-center bg-overlay  ${
                  plantToDelete ? '' : 'hidden'
                }`}
              >
                <DeletePlant plant={plantToDelete} close={() => setPlantToDelete(null)} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </>
    </MotionDiv>
  )
}

export default Plants
