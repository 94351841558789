import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { GetWorkOrdersParams, PostWorkOrderParams, PostWorkOrderResponse, WorkOrdersResponse } from './workordersType'
import { buildUrl } from 'utils/BuildUrl'

export const workorder = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getWorkOrders: builder.query<WorkOrdersResponse, GetWorkOrdersParams>({
      query: (params) => {
        let URL = ''
        params.filters
          ? (URL = buildUrl(`/plant/${params.plantId}/work-orders`, params.filters))
          : (URL = `/plant/${params.plantId}/work-orders`)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the work orders')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetWorkOrders'],
    }),
    getWorkOrderByID: builder.query({
      query: ({ plant_id, woID }) => {
        return {
          url: `/plant/${plant_id}/work-order/${woID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the work orders ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getSingleWorkOrder'],
    }),
    postWorkOrder: builder.mutation<PostWorkOrderResponse, PostWorkOrderParams>({
      query: ({ plantId, data }) => {
        return {
          url: `/plant/${plantId}/work-order`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['GetWorkOrders', 'getSingleWorkOrder'],
    }),
    putWorkOrder: builder.mutation<PostWorkOrderResponse, PostWorkOrderParams>({
      query: ({ plantId, data, woID }) => {
        return {
          url: `/plant/${plantId}/work-order/${woID}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['GetWorkOrders', 'getSingleWorkOrder', 'GetManufacturerOrder'],
    }),
    deleteWorkOrder: builder.mutation<any, { plantId: string; woID: string }>({
      query: ({ plantId, woID }) => ({
        url: `/plant/${plantId}/work-order/${woID}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['GetWorkOrders'],
    }),
    getWorkOrdersStats: builder.query<any, { plantId: string }>({
      query: ({ plantId }) => ({
        url: `/plant/${plantId}/work-order/stats`,
        method: 'GET',
        headers: getToken(),
      }),
      providesTags: ['GetWorkOrders'],
    }),
    getWorkOrdersBarStatus: builder.query<any, { plantId: string; filters: any }>({
      query: ({ plantId, filters }) => {
        const URL = buildUrl(`/plant/${plantId}/work-orders/bar`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
        }
      },
      providesTags: ['getSingleWorkOrder'],
    }),
    getWorkOrdersBarCharts: builder.query<any, { plantId: string; woID: string | undefined }>({
      query: ({ plantId, woID }) => ({
        url: `/plant/${plantId}/work-order/${woID}/progress-chart`,
        method: 'GET',
        headers: getToken(),
      }),
      providesTags: ['getSingleWorkOrder'],
    }),

    updateWorkOrderStatus: builder.mutation<any, { plantId: string; woID: string; status: string }>({
      query: ({ plantId, woID, status }) => ({
        url: `/plant/${plantId}/work-order/${woID}/change-status?status=${status}`,
        method: 'PATCH',
        headers: getToken(),
      }),
      invalidatesTags: ['GetWorkOrders', 'getSingleWorkOrder', 'GetManufacturerOrder'],
    }),
    getWorkOrderChartByPlantId: builder.query({
      query: ({ plant_id }) => {
        return {
          url: `/plant/${plant_id}/work-orders/on-time-chart`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Work Order Chart ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getSingleWorkOrder'],
    }),

    getWorkOrdersQuantityProduced: builder.query<any, { plantId: string; woID: string | undefined }>({
      query: ({ plantId, woID }) => ({
        url: `/plant/${plantId}/work-order/${woID}/quantity-produced`,
        method: 'GET',
        headers: getToken(),
      }),
      providesTags: ['getSingleWorkOrder'],
    }),
    getWorkOrdersOperationStatus: builder.query<any, { plantId: string; woID: string | undefined }>({
      query: ({ plantId, woID }) => ({
        url: `/plant/${plantId}/work-order/${woID}/operations/status`,
        method: 'GET',
        headers: getToken(),
      }),
      providesTags: ['getSingleWorkOrder'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetWorkOrdersQuery,
  useGetWorkOrderByIDQuery,
  useGetWorkOrdersBarStatusQuery,
  usePostWorkOrderMutation,
  usePutWorkOrderMutation,
  useDeleteWorkOrderMutation,
  useGetWorkOrdersStatsQuery,
  useGetWorkOrdersBarChartsQuery,
  useUpdateWorkOrderStatusMutation,
  useGetWorkOrderChartByPlantIdQuery,
  useGetWorkOrdersQuantityProducedQuery,
  useGetWorkOrdersOperationStatusQuery,
} = workorder
