import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import {
  addOperationalProcessEntryParams,
  getAllEntriesByProcessIdParams,
  getAllEntriesByProcessIdResponse,
  getProcessesByGroupParams,
  getProcessesByGroupResponse,
} from './operational_processesTypes'
import { buildUrl } from 'utils/BuildUrl'

export const operational_processes = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getProcessById: builder.query({
      query: ({ process_id }) => {
        return {
          url: `/operational-process/$${process_id}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the process')
            }
            return response.json()
          },
        }
      },
      providesTags: ['updateProcess'],
    }),
    deleteFormField: builder.mutation({
      query: ({ process_id, product_id, field_id }) => {
        return {
          url: `/operational-process/$${process_id}/product/$${product_id}/field/$${field_id}/delete`,
          method: 'DELETE',
          headers: getToken(),
        }
      },
      invalidatesTags: ['updateProcess'],
    }),
    postFromField: builder.mutation({
      query: ({ data, productId, processId }) => {
        return {
          url: `operational-process/$${processId}/product/$${productId}/field/create`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['updateProcess'],
    }),
    addOperationalProcessEntry: builder.mutation<any, addOperationalProcessEntryParams>({
      query: (data) => {
        return {
          url: 'operational-process-entries/${process_id}/entry/product/${product_id}',
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['updateProcessEntry'],
    }),
    getProcessesByGroup: builder.query<getProcessesByGroupResponse, getProcessesByGroupParams>({
      query: (params) => {
        return {
          url: `operational-process/plant/$${params.plantId}/group-by-department`,
          headers: getToken(),
          method: 'GET',
          params,
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the process by group ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['updateProcessEntry'],
    }),
    getAllEntriesByProcessId: builder.query<getAllEntriesByProcessIdResponse, getAllEntriesByProcessIdParams>({
      query: (params) => {
        const { process_id, ...rest } = params
        const URL = buildUrl(`operational-process-entries/$${process_id}/entry`, rest)
        return {
          url: URL,
          headers: getToken(),
          method: 'GET',
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the entries')
            }
            return response.json()
          },
        }
      },
      providesTags: ['updateProcessEntry'],
    }),
    getEntryById: builder.query({
      query: (entryId) => {
        return {
          url: `operational-process-entries/entry/$${entryId}`,
          headers: getToken(),
          method: 'GET',
          entryId,
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the entry')
            }
            return response.json()
          },
        }
      },
      providesTags: ['updateProcessEntry'],
    }),
    putEntryById: builder.mutation({
      query: ({ entry_id, data }) => {
        return {
          url: `operational-process-entries/entry/$${entry_id}`,
          headers: getToken(),
          method: 'PUT',
          body: data,
        }
      },
      invalidatesTags: ['updateProcessEntry'],
    }),
    deleteEntriesById: builder.mutation({
      query: ({ entry_id }) => {
        return {
          url: `operational-process-entries/delete`,
          headers: getToken(),
          method: 'DELETE',
          body: {
            data: entry_id,
          },
        }
      },
      invalidatesTags: ['updateProcessEntry'],
    }),
    approveBy: builder.mutation({
      query: ({ entryIds }) => {
        return {
          url: `operational-process-entries/verify`,
          headers: getToken(),
          method: 'PATCH',
          body: {
            data: entryIds,
          },
        }
      },
      //HERE INVALIDATE RECENT ACTIVITES AND TRANSACTIONS
      invalidatesTags: [
        'updateProcessEntry',
        'INVENTORY_TRANSACTIONS',
        'inventoryUpdate',
        'warehouseUpdate',
        'productUpdate',
        'approveEntries',
        'getSingleWorkOrder',
      ],
    }),

    postOperationalProcesses: builder.mutation({
      query: ({ data, plant_id }) => {
        return {
          url: `/operational-process/plant/$${plant_id}`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['updateProcess'],
    }),
    putOperationalProcess: builder.mutation({
      query: ({ data, processId }) => {
        return {
          url: `operational-process/$${processId}/update`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['updateProcess'],
    }),
    putOperationalFormField: builder.mutation({
      query: ({ data, processId, productId, fieldId }) => {
        return {
          url: `operational-process/$${processId}/product/$${productId}/field/$${fieldId}/update`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['updateProcess'],
    }),
    getAllProcesses: builder.query({
      query: (params) => {
        const { plantId, filters } = params
        const URL = buildUrl(`/operational-process/plant/$${plantId}`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the operational processes')
            }
            return response.json()
          },
        }
      },
      providesTags: ['updateProcess'],
    }),

    getOrganizationById: builder.query({
      query: ({ org_id }) => {
        return {
          url: `/organization/${org_id}`,
          headers: getToken(),
          method: 'GET',
        }
      },
    }),
    getVerifiedEntriesById: builder.query({
      query: ({ woID }) => {
        return {
          url: `/operational-process-entries/$${woID}/verified-wo-entry`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the verify Entryies')
            }
            return response.json()
          },
        }
      },
      providesTags: ['approveEntries'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useDeleteEntriesByIdMutation,
  useGetEntryByIdQuery,
  usePutOperationalProcessMutation,
  useDeleteFormFieldMutation,
  usePutOperationalFormFieldMutation,
  usePostOperationalProcessesMutation,
  useGetAllProcessesQuery,
  useGetProcessByIdQuery,
  usePutEntryByIdMutation,
  usePostFromFieldMutation,
  useAddOperationalProcessEntryMutation,
  useGetProcessesByGroupQuery,
  useGetAllEntriesByProcessIdQuery,
  useApproveByMutation,
  useGetVerifiedEntriesByIdQuery,
} = operational_processes
