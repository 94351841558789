import { useDeletePurchaseOrderMutation } from '../purchase-orders-endpoints/purchaseOrders.endpoints'
import { t } from 'i18next'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  poID: any
  plant_id: string
}

function DeletePurchaseOrder({ closePopUp, poID, plant_id }: Props) {
  const navigate = useNavigate()
  const [deletePurchaseOrder, { isLoading: deletePurchaseOrderLoading }] = useDeletePurchaseOrderMutation()
  const handleDeletePurchaseOrder = () => {
    deletePurchaseOrder({ plant_id, poID: poID?._id })
      .unwrap()
      .then(() => {
        toast.success(`Purchase Order “${poID?.purchaseOrderID}” is removed successfully.`)
        closePopUp()
        navigate('/orders/purchase-order')
      })
      .catch((error: any) => {
        toast.error(
          error?.data?.detail ?? `Failed to remove Purchase Order “${poID?.purchaseOrderID}”. Please try again.`,
        )
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')}{' '}
        <span className="break-words pr-1 p-xs-bold">“Purchase Order ID {poID?.purchaseOrderID}”.</span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDeletePurchaseOrder} loading={deletePurchaseOrderLoading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </div>
  )
}

export default DeletePurchaseOrder
