import Card from 'libs/card/Card'
import { useNavigate } from 'react-router-dom'
import MotionDiv from 'libs/motionDiv'
import Button from 'libs/button/Button'
import FullPageLoader from 'libs/loader/FullPageLoader'
import icons from 'assets/index.ts'
import { useGetCountEmployeeQuery } from './employees-endpoints/employees.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useGetEmployeeDepartmentCountQuery } from './Employee-Department/employee-departments-endpoints/employeesdepartment.endpoints'

const Employee = () => {
  const plant = useSelector(selectSelectedItem)

  const { data: employeeCount, isLoading: employeeLoading } = useGetCountEmployeeQuery({
    plant_id: plant?.id,
  })
  const { data: employeeDepartmentCount } = useGetEmployeeDepartmentCountQuery({
    plant_id: plant?.id,
  })
  const navigate = useNavigate()

  const cardDetails = [
    {
      icon: <img src={icons.Employee} alt="" />,
      title: 'Employee Directory',
      subtitle: `${employeeCount?.employee_count ?? 0} Employees`,
      route: '/employee/employee-management',
    },
    {
      icon: <img src={icons.EmployeeRoles} alt="" />,
      title: 'Employee Roles ',
      subtitle: `${employeeDepartmentCount?.employee_department_count || '0'} Departments | ${employeeDepartmentCount?.employee_designation_count || 0} Designations`,
      route: '/employee/employee-department',
    },
    {
      icon: <img src={icons.TimeSheet} alt="" />,
      title: 'Timesheets',
      disabled: true,
      subtitle: 'Timesheets',
      route: '/employee/employee-management',
    },
  ]

  return (
    <MotionDiv>
      <div className="h-full">
        <h1 className="h4-bold-black">Employee </h1>

        <div className="relative h-full w-full">
          {employeeLoading ? (
            <div className="absolute left-64 top-16 flex items-center justify-center">
              <FullPageLoader />
            </div>
          ) : (
            <div className="grid h-full w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 ">
              {cardDetails.map((card, index) => (
                <div key={`${index}_${card.title}`}>
                  <Card
                    key={`${index}_${card.title}`}
                    disabled={card.disabled}
                    title={card?.title}
                    subtitle={card.subtitle}
                    leftIcon={card.icon}
                    rightCenterIcon={<Button color="primary"> View </Button>}
                    onClick={() => navigate(card.route)}
                    titleStyle="font-medium text-gray-800"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </MotionDiv>
  )
}

export default Employee
