import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import Field from 'libs/field'
import * as Yup from 'yup'

interface Props {
  closePanel: () => void
  setFilters: any
  filters: any
}

const FilterUsers: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  

  const filterFormik = useFormik({
    initialValues: {
      userRole: filters?.userRole ? filters.userRole : [],
      lastUpdated: filters?.updatedBetween ? filters.updatedBetween : [],
    },
    onSubmit: (values) => {
     

      setFilters({
        ...filters,
        userRole: values.userRole,
        updatedBetween: values.lastUpdated,
      })
    },
    validationSchema: Yup.object({}),
  })
  const options = [
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'Operator',
      value: 'operator',
    },
  ]

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      updatedBetween: [],
      userRole: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })
    closePanel()
  }

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="User Role"
          formik={filterFormik}
          name="userRole"
          options={options}
          placeholder="Select Role"
        />
        <Field type="dateRange" label="Last Updated" shouldDisableFutureDate formik={filterFormik} name="lastUpdated" />
        <div className="flex justify-between">
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset Filters
          </Button>
          <div className="flex justify-end gap-2">
            <Button onClick={closePanel}>Cancel</Button>
            <Button
              color="primary"
              onClick={() => {
                filterFormik.handleSubmit()
                closePanel()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterUsers
