import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Field from 'libs/field'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints.ts'
import { selectSelectedItem } from 'store/redux/navSlice.ts'
import { useSelector } from 'react-redux'
import icons from 'assets/index'
import ProcurementForm from './transactiontype/procurement/ProcurementForm'
import TransferForm from './transactiontype/transfer/TransferForm'
import Button from 'libs/button/Button.tsx'
import AdjustmentForm from './transactiontype/adjustment/AdjustmentForm.tsx'
import ShipmentForm from './transactiontype/shipment/ShipmentForm.tsx'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetWarehouseQuery } from 'pages/warehouses/warehouses-endpoints/warehouses.endpoints.ts'
import {
  usePostInventoryMutation,
  useEditInventoryMutation,
  useGetTransactionByIdQuery,
} from 'pages/inventory/inventory-endpoints/inventory.endpoints.ts'
import { toast } from 'libs/toast/index.ts'
import { t } from 'i18next'
import Card from 'libs/card/Card.tsx'
import FullPageLoader from 'libs/loader/FullPageLoader.tsx'
import WhiteCard from 'libs/card/WhiteCard.tsx'
import { trackEvent } from 'libs/layouts/mixpanel/mixPanel.tsx'
import useTabTitle from 'libs/hooks/useTabTitle.ts'
import { trimStringValues } from 'utils/trimStringValues.ts'
import { NumberFormat } from 'utils/NumberFormat.ts'
import { isArray } from 'lodash'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint.ts'

interface Product {
  _id: string | null
  quantity: number | null
}

interface StorageArea {
  name: string
  id: string
}

interface Warehouse {
  name: string
  id: string
}

interface Transaction {
  adjustmentReason: string | null
  inventory: {
    product: Product | null
    quantity: number | null
    _id: string
  }
  inventoryId: string
  puchaseOrderNumber: string
  quantity: number
  salesOrderNumber: string
  storageAreaTo: StorageArea
  storageAreaFrom: StorageArea
  transactionDate: string
  transactionType: string
  updated_on: string
  warehouseTo: Warehouse
  warehouseFrom: Warehouse
  _id: string
}

const AddEditInventory = () => {
  const { data: userInfo } = useUserInfoQuery({})

  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()
  const plant = useSelector(selectSelectedItem)
  const [transaction, setTransaction] = useState<Transaction | null>(null)
  useTabTitle(transaction ? t('inventory_.update_inventory_transaction') : t('inventory_.new_inventory_transaction'))
  const cardDetails = [
    {
      icon: icons.ProcurementIcon,
      title: t('inventory_.procurement'),
      description: t('inventory_.procurement_desc'),
      type: 'Procurement',
    },
    {
      icon: icons.TransferIcon,
      title: t('inventory_.transfer'),
      description: t('inventory_.transfer_desc'),
      type: 'Transfer',
    },
    {
      icon: icons.AdjustmentIcon,
      title: t('inventory_.adjustment'),
      description: t('inventory_.adjustment_desc'),
      type: 'Adjustment',
    },
    {
      icon: icons.ShipmentIcon,
      title: t('inventory_.shipment'),
      description: t('inventory_.shipment_desc'),
      type: 'Shipment',
    },
  ]

  const filters = {
    page_no: 1,
    page_size: 1000,
    sortBy: 'created_on',
    sortDir: 'DESC',
    searchText: '',
  }

  const { data: products, isLoading: isProductLoading } = useGetProductQuery(
    {
      plantId: plant?.id,
      filters: filters,
    },
    {
      skip: !plant?.id,
    },
  )

  const warehouseFilters = {
    page_no: 1,
    page_size: 1000,
    searchText: '',
    sortBy: 'created_on',
    sortDir: 'DESC',
  }

  const { data: warehouses } = useGetWarehouseQuery(
    {
      plantId: plant?.id,
      filters: warehouseFilters,
    },
    {
      skip: !plant?.id,
    },
  )

  const [addInventory, { isLoading: inventoryLoading }] = usePostInventoryMutation()
  const [editInventory, { isLoading: editInventoryLoading }] = useEditInventoryMutation()
  const { data: transactionData, isLoading: transactionLoading } = useGetTransactionByIdQuery(
    {
      plant_id: plant?.id,
      transactionId: id,
    },
    {
      skip: !plant?.id || !id,
    },
  )
  useEffect(() => {
    if (transactionData) {
      const newTrans = { ...transactionData }
      const tempInventory = products?.data.find((product: any) => product?._id === transactionData?.inventoryId)
      newTrans.inventory = tempInventory
      setTransaction(newTrans)
    }
  }, [transactionData, products])

  const inventoryDetailsFormik = useFormik<any>({
    initialValues: {
      product: transaction?.inventory ? transaction?.inventory : null,
      transactionDate: null,
    },
    validationSchema: yup.object({
      product: yup.object().required(t('inventory_.product_name_cannot_be_empty')),
      transactionDate: yup.string().required(t('inventory_.transaction_date_is_required')),
    }),
    onSubmit: () => {
      transactionTypeFormik.handleSubmit()
    },
  })

  useEffect(() => {
    if (state) {
      inventoryDetailsFormik.setFieldValue('product', state?.inventory)
    }
  }, [state])

  const unitOfMeasure = inventoryDetailsFormik?.values?.product?.unit_of_measure

  // Transaction type tabs
  const [transactionTab, setTransactionTab] = useState<{
    isTabOn: boolean
    transactionType: string
  }>({ isTabOn: true, transactionType: 'Procurement' })

  //formik for transaction type tabs
  const transactionTypeFormik = useFormik({
    initialValues: {
      purchase_order_nu: '',
      quantity: 0,
      warehouse_from: null,
      warehouse_to: null,
      storagearea_from: null,
      storagearea_to: null,
      reason_for_adjustment: '',
      sales_order_nu: '',
    },
    validationSchema: () => {
      if (transactionTab.transactionType === 'Procurement') {
        return yup.object().shape({
          purchase_order_nu: yup.string().required(t('inventory_.purchase_order_no_is_required')),
          warehouse_to: yup.object().required(t('inventory_.warehouse_is_required')),
          storagearea_to: yup.object().required(t('inventory_.storage_area_is_required')),
          quantity: inventoryDetailsFormik.values.product?.is_accepting_decimal
            ? yup.number().required(t('inventory_.quantity_is_required'))
            : yup
                .number()
                .integer('This product does not accept decimal values. Please enter a whole number.')
                .required(t('inventory_.quantity_is_required')),
        })
      } else if (transactionTab.transactionType === 'Transfer') {
        return yup.object().shape({
          warehouse_to: yup.object().required(t('inventory_.warehouse_is_required')),
          warehouse_from: yup.object().required(t('inventory_.warehouse_is_required')),
          storagearea_to: yup.object().required(t('inventory_.storage_area_is_required')),
          storagearea_from: yup.object().required(t('inventory_.storage_area_is_required')),
          quantity: inventoryDetailsFormik.values.product?.is_accepting_decimal
            ? yup.number().required(t('inventory_.quantity_is_required'))
            : yup
                .number()
                .integer('This product does not accept decimal values. Please enter a whole number.')
                .required(t('inventory_.quantity_is_required')),
        })
      } else if (transactionTab.transactionType === 'Adjustment') {
        return yup.object().shape({
          warehouse_from: yup.object().required(t('inventory_.warehouse_is_required')),
          storagearea_from: yup.object().required(t('inventory_.storage_area_is_required')),
          quantity: inventoryDetailsFormik.values.product?.is_accepting_decimal
            ? yup.number().required(t('inventory_.quantity_is_required'))
            : yup
                .number()
                .integer('This product does not accept decimal values. Please enter a whole number.')
                .required(t('inventory_.quantity_is_required')),
          reason_for_adjustment: yup.string().required(t('inventory_.reason_for_adjustment')),
        })
      } else if (transactionTab.transactionType === 'Shipment') {
        return yup.object().shape({
          warehouse_from: yup.object().required(t('inventory_.warehouse_is_required')),
          storagearea_from: yup.object().required(t('inventory_.storage_area_is_required')),
          quantity: inventoryDetailsFormik.values.product?.is_accepting_decimal
            ? yup.number().required(t('inventory_.quantity_is_required'))
            : yup
                .number()
                .integer('This product does not accept decimal values. Please enter a whole number.')
                .required(t('inventory_.quantity_is_required')),
          sales_order_nu: yup.string().required(t('inventory_.sales_order_no_is_required')),
        })
      }
    },

    onSubmit: (values: any) => {
      const selectProduct: any = inventoryDetailsFormik.values

      const payload = {
        transactionType: transactionTab.transactionType.toUpperCase(),
        product_id: selectProduct?.product?._id,
        transactionDate: selectProduct.transactionDate,
        purchaseOrderNumber: values.purchase_order_nu,
        quantity: values.quantity,
        warehouseFrom: values.warehouse_from ? values?.warehouse_from?._id : '',
        warehouseTo: values.storagearea_to ? values?.warehouse_to?._id : '',
        storageAreaFrom: values.storagearea_from ? values?.storagearea_from?._id : '',
        storageAreaTo: values.storagearea_to ? values?.storagearea_to?._id : '',
        adjustmentReason: values.reason_for_adjustment,
        salesOrderNumber: values.sales_order_nu,
      }

      const filteredPayload = Object.entries(payload).reduce((acc: any, [key, value]) => {
        if (
          typeof value === 'object'
            ? Object.values(value).filter((v) => v !== '' && v != null)
            : value !== null && value !== undefined && value !== '' && value !== 0
        ) {
          acc[key] = value
        } else if (key === 'unit') {
          acc[key] = value
        }
        return acc
      }, {})

      const data = trimStringValues(filteredPayload)
      if (!id) {
        addInventory({
          data: data,
          plant_id: plant?.id,
        })
          .unwrap()
          .then((res) => {
            toast.success('Inventory Transaction added successfully.')
            if (isArray(res)) navigate(`/inventory/transactionhistory/${res[0].inventoryId}`)
            else navigate(`/inventory`)
            transactionTypeFormik.resetForm()
            trackEvent('Inventory', {
              Inventory: 'Add Inventory Entry',
              Count: '1',
              Plant: plant?.name,
              TransactionType: transactionTab.transactionType,
              organization: userInfo?.organization?.name,
            })
          })
          .catch((err) => {
            toast.error(err?.data?.detail ?? 'Failed to add Inventory Transaction. Please try again.')
          })
      } else {
        editInventory({
          data: data,
          plant_id: plant?.id,
          transaction_id: id,
        })
          .unwrap()
          .then(() => {
            toast.success(`${transactionTab.transactionType} transaction Updated successfully.`)
            navigate(-1)
            transactionTypeFormik.resetForm()
          })
          .catch((err) => {
            toast.error(err?.data?.detail ?? 'Failed to update Inventory Transaction. Please try again.')
          })
      }
    },
  })

  useEffect(() => {
    if (id && transaction) {
      const transactionType =
        transaction?.transactionType?.charAt(0)?.toUpperCase() + transaction?.transactionType.slice(1).toLowerCase()
      setTransactionTab({ isTabOn: true, transactionType: transactionType })

      transactionTypeFormik.resetForm({
        values: {
          purchase_order_nu: transaction?.puchaseOrderNumber || '',
          quantity: Math.abs(transaction?.quantity) || '-',
          storagearea_to: {
            storage_name: transaction?.storageAreaTo?.name || '',
            storage_id: transaction?.storageAreaTo?.id || '',
          },
          warehouse_from: {
            name: transaction?.warehouseFrom?.name || '',
            _id: transaction?.warehouseFrom?.id || '',
          },
          warehouse_to: {
            name: transaction?.warehouseTo?.name || '',
            _id: transaction?.warehouseTo?.id || '',
          },
          storagearea_from: {
            storage_name: transaction?.storageAreaFrom?.name || '',
            storage_id: transaction?.storageAreaFrom?.id || '',
          },

          reason_for_adjustment: transaction?.adjustmentReason || '',
          sales_order_nu: transaction?.salesOrderNumber || '',
        },
      })
      inventoryDetailsFormik.resetForm({
        values: {
          product: transaction?.inventory,
          transactionDate: transaction?.transactionDate,
        },
      })
    }
  }, [transaction, id])

  const productOptions = products?.data?.map((item: any) => ({
    label: item.name,
    value: item,
  }))

  const handleTransactionTabClick = (type: string) => {
    if (isDisabled()) {
      return
    }
    if (transactionTab.transactionType === type) {
      // If the same tab is clicked again, toggle its state to close
      setTransactionTab((prevState) => ({
        ...prevState,
        isTabOn: true,
      }))
      transactionTypeFormik.resetForm()
    } else {
      // If a different tab is clicked, open it and close any other open tab
      setTransactionTab({ isTabOn: true, transactionType: type })
      transactionTypeFormik.resetForm()
    }
  }

  const isDisabled = () => {
    if (transactionTab.transactionType) {
      return false
    }
    return !inventoryDetailsFormik.values.product
  }

  const [storageAreaToOptions, setStorageAreaToOptions] = useState<any>([])
  const [warehouseToOptions, setWarehouseToOptions] = useState<any>([])
  const [availableQuantity, setAvailableQuantity] = useState<any>(null)
  const [warehouseFromOptions, setWarehouseFromOptions] = useState<any>([])
  const [storageAreaFromOptions, setStorageAreaFromOptions] = useState<any>([])

  useEffect(() => {
    if (transactionTypeFormik.values.storagearea_from?._id) {
      const quantity = storageAreaFromOptions.find(
        (item: any) => item.value === transactionTypeFormik.values.storagearea_from?._id,
      )?.sublabel
      setAvailableQuantity(quantity)
    }
  }, [transactionTypeFormik.values.storagearea_from?._id])

  useEffect(() => {
    const selectedProduct = inventoryDetailsFormik.values.product?._id
    const warehouseToId = transactionTypeFormik.values.warehouse_to?._id

    const allowedWarehouses = warehouses?.data?.filter((warehouse: any) =>
      warehouse.storage_areas?.some((storageArea: any) =>
        storageArea.products?.some((product: any) => {
          return product._id === selectedProduct
        }),
      ),
    )

    const unsorted = allowedWarehouses?.map((warehouse: any) => {
      // Check if any storage area in the warehouse has a product with quantity less than 1

      const totalQuantity =
        warehouse.storage_areas?.reduce((acc: any, storageArea: any) => {
          const storageAreaQuantity =
            storageArea.products?.reduce((sum: any, product: any) => {
              if (product._id === selectedProduct) {
                return sum + product.quantity
              }
              return sum
            }, 0) || 0
          return acc + storageAreaQuantity
        }, 0) || 0

      const isDisabled = !warehouse.storage_areas?.some((storageArea: any) =>
        storageArea.products?.some((product: any) => {
          // if (type == 'OUTPUT') {
          return product._id === selectedProduct
          // } else {
          //   return product.quantity > 0 && product.product_id === selectedProduct
          // }
        }),
      )
      // Return the warehouse with the added disabled field
      return { ...warehouse, disabled: isDisabled, quantity: totalQuantity }
    })

    const updatedWarehouses = unsorted?.sort((a: any, b: any) => {
      if (a.disabled && !b.disabled) {
        return 1
      }
      if (!a.disabled && b.disabled) {
        return -1
      }
      return 0
    })

    const selectedWH = updatedWarehouses?.find((warehouse: any) => warehouse?._id === warehouseToId)

    const allowedStorageAreas = selectedWH?.storage_areas?.filter((storageArea: any) =>
      storageArea.products?.some((product: any) => {
        return product._id === selectedProduct
      }),
    )

    const unsortedStorageAreas = allowedStorageAreas?.map((storageArea: any) => {
      const quantity =
        storageArea.products?.reduce((acc: any, product: any) => {
          if (product._id === selectedProduct) {
            return acc + product.quantity
          }
          return acc
        }, 0) || 0

      const isDisabled = !storageArea.products?.some((product: any) => {
        // if (type == 'OUTPUT') {
        return product._id === selectedProduct
        // } else {
        // return product.quantity > 0 && product.product_id === selectedProduct
        // }
      })
      return { ...storageArea, disabled: isDisabled, quantity: quantity }
    })

    const storageAreas = unsortedStorageAreas?.sort((a: any, b: any) => {
      if (a.disabled && !b.disabled) {
        return 1
      }
      if (!a.disabled && b.disabled) {
        return -1
      }
      return 0
    })

    if (Array.isArray(storageAreas)) {
      setStorageAreaToOptions(
        storageAreas.map((item: any) => {
          return {
            label: item.name,
            value: item,
            disabled: item?.disabled,
            sublabel: 'Available quantity : ' + NumberFormat(item?.quantity),
          }
        }),
      )
    }
    if (Array.isArray(updatedWarehouses)) {
      setWarehouseToOptions(
        updatedWarehouses.map((item: any) => {
          return {
            label: item.name,
            value: item,
            disabled: item?.disabled,
            sublabel: 'Available quantity : ' + NumberFormat(item?.quantity),
          }
        }),
      )
    }
  }, [
    inventoryDetailsFormik.values.product?._id,
    transactionTypeFormik.values.warehouse_from?._id,
    transactionTypeFormik.values.warehouse_to?._id,
    warehouses?.data,
  ])

  useEffect(() => {
    const selectedProduct = inventoryDetailsFormik.values.product?._id
    const warehouseFromId = transactionTypeFormik.values.warehouse_from?._id
    const allowedWarehouses = warehouses?.data?.filter((warehouse: any) =>
      warehouse.storage_areas?.some((storageArea: any) =>
        storageArea.products?.some((product: any) => {
          return product._id === selectedProduct
        }),
      ),
    )

    const unsorted = allowedWarehouses?.map((warehouse: any) => {
      // Check if any storage area in the warehouse has a product with quantity less than 1
      const totalQuantity =
        warehouse.storage_areas?.reduce((acc: any, storageArea: any) => {
          const storageAreaQuantity =
            storageArea.products?.reduce((sum: any, product: any) => {
              if (product._id === selectedProduct) {
                return sum + product.quantity
              }
              return sum
            }, 0) || 0
          return acc + storageAreaQuantity
        }, 0) || 0

      const isDisabled = !warehouse.storage_areas?.some((storageArea: any) =>
        storageArea.products?.some((product: any) => {
          return product.quantity > 0 && product._id === selectedProduct
        }),
      )
      // Return the warehouse with the added disabled field
      return { ...warehouse, disabled: isDisabled, quantity: totalQuantity }
    })

    const updatedWarehouses = unsorted?.sort((a: any, b: any) => {
      if (a.disabled && !b.disabled) {
        return 1
      }
      if (!a.disabled && b.disabled) {
        return -1
      }
      return 0
    })

    const selectedWH = updatedWarehouses?.find((warehouse: any) => warehouse?._id === warehouseFromId)

    const allowedStorageAreas = selectedWH?.storage_areas?.filter((storageArea: any) =>
      storageArea.products?.some((product: any) => {
        return product._id === selectedProduct
      }),
    )

    const unsortedStorageAreas = allowedStorageAreas?.map((storageArea: any) => {
      const quantity =
        storageArea.products?.reduce((acc: any, product: any) => {
          if (product._id === selectedProduct) {
            return acc + product.quantity
          }
          return acc
        }, 0) || 0

      const isDisabled = !storageArea.products?.some((product: any) => {
        return product.quantity > 0 && product._id === selectedProduct
      })
      return { ...storageArea, disabled: isDisabled, quantity: quantity }
    })

    const storageAreas = unsortedStorageAreas?.sort((a: any, b: any) => {
      if (a.disabled && !b.disabled) {
        return 1
      }
      if (!a.disabled && b.disabled) {
        return -1
      }
      return 0
    })

    if (Array.isArray(storageAreas)) {
      setStorageAreaFromOptions(
        storageAreas.map((item: any) => {
          return {
            label: item.name,
            value: item,
            disabled: item?.disabled,
            sublabel: 'Available quantity : ' + NumberFormat(item?.quantity),
          }
        }),
      )
    }

    if (Array.isArray(updatedWarehouses)) {
      setWarehouseFromOptions(
        updatedWarehouses.map((item: any) => {
          return {
            label: item.name,
            value: item,
            disabled: item?.disabled,
            sublabel: 'Available quantity : ' + NumberFormat(item?.quantity),
          }
        }),
      )
    }
  }, [inventoryDetailsFormik.values.product?._id, transactionTypeFormik.values.warehouse_from?._id, warehouses?.data])

  return (
    <div>
      <h1 className="mb-2 h4-bold-black">
        {transaction ? t('inventory_.update_inventory_transaction') : t('inventory_.new_inventory_transaction')}
      </h1>

      {isProductLoading || transactionLoading ? (
        <FullPageLoader />
      ) : (
        <WhiteCard>
          <div>
            <h1 className="mb-2 h5">{t('inventory_.add_inventory_transactions')}</h1>
            <p className="p-sm-regular-1 ">{t('inventory_.add_inventory_desc')}</p>
          </div>
          <div className="flex  gap-4">
            <Field
              required={true}
              label={t('inventory_.product_name')}
              formik={inventoryDetailsFormik}
              name="product"
              placeholder={t('inventory_.product_name_placeholder')}
              type="select"
              options={productOptions}
              defaultValue={
                inventoryDetailsFormik.values.product
                  ? {
                      label: inventoryDetailsFormik.values.product.name,
                      value: inventoryDetailsFormik.values.product,
                    }
                  : null
              }
              width={'300px'}
            />
            <Field
              required={true}
              type="date"
              label={t('inventory_.transaction_date')}
              formik={inventoryDetailsFormik}
              name="transactionDate"
              width={'300px'}
              shouldDisableFutureDate={transactionTab.transactionType !== 'Shipment'}
            />
          </div>
          <div className="my-2">
            <hr></hr>
          </div>
          <div className=" flex h-full flex-col gap-2">
            <div>
              <h5 className={`h5 `}>{t('inventory_.transaction_type')}</h5>
              <p className={`p-sm-regular-1 `}>{t('inventory_.select_transaction_type')}</p>
            </div>
            {/* transaction type tabs */}
            <div className="flex gap-3">
              {cardDetails.map((card, index) => {
                return (
                  <Card
                    key={index}
                    onClick={() => handleTransactionTabClick(card.type)}
                    isActive={transactionTab.isTabOn && transactionTab.transactionType === card.type}
                    leftIcon={<img src={card.icon} alt="" />}
                    title={card.title}
                    subtitle={card.description}
                    titleStyle="font-semibold text-gray-800 text-lg"
                  />
                )
              })}
            </div>
            {/* forms of tab */}
            {transactionTab.isTabOn && transactionTab.transactionType === 'Procurement' && (
              <ProcurementForm
                storageAreaToOptions={storageAreaToOptions}
                warehouseToOptions={warehouseToOptions}
                selectedProduct={inventoryDetailsFormik.values.product}
                transaction={transaction}
                formik={transactionTypeFormik}
                warehouses={warehouses}
                unit={unitOfMeasure}
              />
            )}
            {transactionTab.isTabOn && transactionTab.transactionType === 'Transfer' && (
              <TransferForm
                storageAreaToOptions={storageAreaToOptions}
                warehouseToOptions={warehouseToOptions}
                storageAreaFromOptions={storageAreaFromOptions}
                warehouseFromOptions={warehouseFromOptions}
                selectedProduct={inventoryDetailsFormik.values.product}
                transaction={transaction}
                formik={transactionTypeFormik}
                warehouses={warehouses}
                unit={unitOfMeasure}
                quantity={{
                  data: availableQuantity,
                  isLoading: false,
                  isFetching: false,
                }}
              />
            )}

            {transactionTab.isTabOn && transactionTab.transactionType === 'Adjustment' && (
              <AdjustmentForm
                storageAreaFromOptions={storageAreaFromOptions}
                warehouseFromOptions={warehouseFromOptions}
                selectedProduct={inventoryDetailsFormik.values.product}
                transaction={transaction}
                formik={transactionTypeFormik}
                warehouses={warehouses}
                unit={unitOfMeasure}
                quantity={{
                  data: availableQuantity,
                  isLoading: false,
                  isFetching: false,
                }}
              />
            )}
            {transactionTab.isTabOn && transactionTab.transactionType === 'Shipment' && (
              <ShipmentForm
                storageAreaFromOptions={storageAreaFromOptions}
                warehouseFromOptions={warehouseFromOptions}
                selectedProduct={inventoryDetailsFormik.values.product}
                transaction={transaction}
                formik={transactionTypeFormik}
                warehouses={warehouses}
                unit={unitOfMeasure}
                quantity={{
                  data: availableQuantity,
                  isLoading: false,
                  isFetching: false,
                }}
              />
            )}
          </div>
          <div className="flex w-full justify-end gap-4">
            <Button onClick={() => navigate(-1)}>{t('inventory_.cancel')}</Button>
            {transaction ? (
              <Button
                color="success"
                type="submit"
                loading={editInventoryLoading}
                onClick={() => inventoryDetailsFormik.handleSubmit()}
              >
                {t('save')}
              </Button>
            ) : (
              <Button
                color="success"
                type="submit"
                loading={inventoryLoading}
                onClick={() => inventoryDetailsFormik.handleSubmit()}
              >
                {t('inventory_.add_transaction')}
              </Button>
            )}
          </div>
        </WhiteCard>
      )}
    </div>
  )
}

export default AddEditInventory
