import { useEffect, useState } from 'react'
import { useNavigate, Route, Routes, useParams, To } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import BlueTick from 'assets/icons/product/BlueTick'
import BasicDetails from './tabs/BasicDetails'
import SalesOrders from './tabs/SalesOrders'
import WorkOrder from './tabs/WorkOrder'
import PurchaseOrder from './tabs/PurchaseOrder'
import { scaleMotionProps2 } from 'utils/FramerProps'
import {
  useGetManufactureOrderByIdQuery,
  useEditManufactureOrderMutation,
  usePostManufactureOrderMutation,
} from './manufacture-orders-endpoints/manufacture_order.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { toast } from 'libs/toast'
import * as Yup from 'yup'
import { useLocation } from 'react-router-dom'
import useTabTitle from 'libs/hooks/useTabTitle'
import { isEndDateAfterStartDate } from 'utils/startDateEndDate'
import Chip from 'libs/chip/Chip'
import _ from 'lodash'

const steps = [
  { label: 'Basic Details', path: 'basic-details' },
  { label: 'Sales Orders', path: 'sales-orders' },
  { label: 'Purchase Orders', path: 'purchase-orders' },
  { label: 'Work Orders', path: 'work-orders' },
]

const getChangeValues = (currentValues: any, initialValues: any) => {
  return Object.keys(currentValues).reduce((value: any, key) => {
    if (!_.isEqual(currentValues[key], initialValues[key])) {
      value[key] = currentValues[key]
    }
    return value
  }, {})
}

const ManufactureOrderWizard = () => {
  const location = useLocation()
  const params = useParams()
  const { id } = params

  const [moid, setId] = useState({
    id: id ? id : undefined,
  })
  const isEdit = location.pathname.includes('edit')

  useTabTitle(isEdit ? 'Update Manufacturing Order' : 'Add Manufacturing Order')
  const plant = useSelector(selectSelectedItem)

  const navigate = useNavigate()

  const [addManufactureOrder, { isLoading: MoAddLoading }] = usePostManufactureOrderMutation()
  const [editManufactureOrder, { isLoading: MoUpadateLoading }] = useEditManufactureOrderMutation()

  const loading = !moid.id ? MoAddLoading : MoUpadateLoading

  const [page, setPage] = useState(1)

  const { data: moOrder } = useGetManufactureOrderByIdQuery(
    {
      plant_id: plant?.id,
      moID: moid?.id,
    },
    {
      skip: !plant?.id || !moid?.id,
    },
  )

  const validationSchema = Yup.object({
    manufactureOrderID: Yup.string().required('Manufacture Order ID is required'),
    finishedGood: Yup.object({
      name: Yup.string().required('Finished Good Name is required'),
    })
      .nullable()
      .required('Finished Good is required'),
    status: Yup.string().required('Status is required'),
    quantity: Yup.number().min(1, 'Quantity must be at least 1').required('Quantity is required'),
    startDate: Yup.string().required('Start date is required'),
    endDate: Yup.string().required('End date is required'),
  })

  const manufactureOrderFormik = useFormik<any>({
    initialValues: {
      manufactureOrderID: '',
      plan_id: undefined,
      startDate: null,
      endDate: null,
      finishedGood: null,
      status: '',
      quantity: 0,
      salesOrders: [],
      workOrders: [],
      purchaseOrders: [],
      isDraft: false,
    },
    validationSchema,

    onSubmit: (values: any) => {
      if (!isEndDateAfterStartDate(values.startDate, values.endDate)) {
        toast.error('End Date should be after Start Date')
        return
      }
      const Values = getChangeValues(values, manufactureOrderFormik.initialValues)

      const isEmpty = Object.keys(Values).length === 0

      const payload = {
        manufactureOrderID: values.manufactureOrderID,
        plan_id: values.plan_id,
        startDate: values.startDate,
        endDate: values.endDate,
        finishedGood: values.finishedGood._id,
        status: values.status,
        // is_draft: values.isDraft ? true : false,
        quantity: values.quantity,
        salesOrders: values?.salesOrders?.map((so: any) => so?.salesOrder?._id)?.filter(Boolean),
        workOrders: values?.workOrders?.length > 0 ? values.workOrders.map((item: any) => item?.workOrderID?._id) : [],
        purchaseOrders:
          values?.purchaseOrders?.length > 0
            ? values?.purchaseOrders
                ?.map((item: { purchaseOrderID: { _id: any } }) => item?.purchaseOrderID?._id)
                ?.filter(Boolean)
            : [],
      }
      
      

      if (!moid.id) {
        addManufactureOrder({
          plant_id: plant?.id,
          data: payload,
        })
          .unwrap()
          .then((res) => {
            toast.success(`Manufacture Order ${payload?.manufactureOrderID} is created successfully.`)
            setId({ id: res?.id })
            if (manufactureOrderFormik.values.isDraft) {
              navigate(`/planning/mo`)
            } else {
              if (!isEdit) {
                navigate(`/planning/mo/${res?.id}/add/sales-orders`)
              } else {
                navigate(`/planning/mo/${res?.id}/edit/sales-orders`)
              }
            }
            // manufactureOrderFormik.setFieldValue('isDraft', false)
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong. Please try again.')
            // manufactureOrderFormik.setFieldValue('isDraft', false)
          })
      } else {
        if (!isEmpty) {
          editManufactureOrder({
            plant_id: plant?.id,
            moId: moid.id,
            data: payload,
          })
            .unwrap()
            .then((res) => {
              toast.success(`Manufacture Order ${payload?.manufactureOrderID} is updated successfully.`)
              setId({ id: res._id })
              if (manufactureOrderFormik.values.isDraft) {
                navigate(`/planning/mo`)
              } else if (page === 1) {
                isEdit
                  ? navigate(`/planning/mo/${res?._id}/edit/sales-orders`)
                  : navigate(`/planning/mo/${res._id}/add/sales-orders`)
              } else if (page === 2) {
                isEdit
                  ? navigate(`/planning/mo/${res?._id}/edit/purchase-orders`)
                  : navigate(`/planning/mo/${res._id}/add/purchase-orders`)
              } else if (page === 3) {
                isEdit
                  ? navigate(`/planning/mo/${res?._id}/edit/work-orders`)
                  : navigate(`/planning/mo/${res._id}/add/work-orders`)
              } else {
                navigate(`/planning/mo`)
              }
            })
            .catch((error) => {
              toast.error(error?.data?.detail ?? 'Something went wrong. Please try again.')
              // manufactureOrderFormik.setFieldValue('isDraft', false)
            })
        } else {
          setId({ id: moid.id })
          if (manufactureOrderFormik.values.isDraft) {
            navigate(`/planning/mo`)
          } else if (page === 1) {
            isEdit
              ? navigate(`/planning/mo/${moid.id}/edit/sales-orders`)
              : navigate(`/planning/mo/${moid.id}/add/sales-orders`)
          } else if (page === 2) {
            isEdit
              ? navigate(`/planning/mo/${moid.id}/edit/purchase-orders`)
              : navigate(`/planning/mo/${moid.id}/add/purchase-orders`)
          } else if (page === 3) {
            isEdit
              ? navigate(`/planning/mo/${moid.id}/edit/work-orders`)
              : navigate(`/planning/mo/${moid.id}/add/work-orders`)
          } else if (page === 4) {
            navigate(`/planning/mo`)
          } else {
            navigate(`/planning/mo`)
          }
          // manufactureOrderFormik.setFieldValue('isDraft', false)
        }
      }
    },
  })

  useEffect(() => {
    if (moid.id && moOrder) {
      const data = moOrder

      manufactureOrderFormik.resetForm({
        values: {
          manufactureOrderID: data?.manufactureOrderID,
          plan_id: data?.plan?.planID,
          isDraft: data?.is_draft,
          startDate: data?.startDate,
          endDate: data?.endDate,
          finishedGood: data?.finishedGood,
          status: data?.status,
          quantity: data?.quantity,
          salesOrders: data?.salesOrders?.map((salesOrder) => ({
            salesOrder: salesOrder,
          })),
          workOrders: data?.workOrders?.map((workOrder) => ({
            workOrderID: workOrder,
          })),
          purchaseOrders: data?.purchaseOrders?.map((purchaseOrder) => ({
            purchaseOrderID: purchaseOrder,
          })),
        },
      })
    }
  }, [moid.id && moOrder])

  const handleNavigation = (stepIndex: number, path: To) => {
    if (moid.id) {
      setPage(stepIndex + 1)
      navigate(path)
    }
  }
  

  return (
    <AnimatePresence>
      <motion.div {...scaleMotionProps2}>
        <>
          <div className="flex justify-between">
            <div className="flex items-baseline gap-2">
              <h2 className="mb-2 max-w-[500px] truncate h4-bold-black">
                {isEdit ? 'Edit Manufacturing Order' : 'Add Manufacturing Order'}
              </h2>
            </div>
          </div>
          <div className="flex min-h-[80vh] rounded bg-white">
            <div className="relative w-1/4 border-r border-[#D2DADF] p-6">
              {steps.map((step, index) => (
                <div key={index}>
                  <button onClick={() => handleNavigation(index, step.path)} className="flex items-center">
                    <div
                      className={`h-6 w-6 rounded-3xl border p-2 ${
                        page === index + 1 ? 'border-primary ' : 'border-[#B9C4CC]'
                      } ${
                        page > index + 1 ? 'border-primary bg-primary' : 'border-[#B9C4CC]'
                      } flex items-center justify-center`}
                    >
                      <div className={`text-xs font-bold  `}>{page > index + 1 ? <BlueTick /> : index + 1}</div>
                    </div>
                    <p className={`whitespace-nowrap rounded px-2 text-sm  ${!moid?.id ? ' cursor-not-allowed' : ''}`}>
                      {step.label}
                    </p>
                  </button>
                  {index < steps.length - 1 && (
                    <span
                      className={`ml-3 flex h-[16px] border-l-2 border-dashed ${
                        page > index + 1 ? 'border-primary' : 'border-[#B9C4CC]'
                      }`}
                    ></span>
                  )}
                </div>
              ))}
            </div>
            <div className="w-3/4 p-6" style={{ overflowX: 'auto' }}>
              {false && manufactureOrderFormik.values.isDraft && (
                <div className="mb-1">
                  <Chip title="Draft" />
                </div>
              )}

              <Routes>
                <Route
                  path="basic-details"
                  element={
                    <BasicDetails
                      setId={setId}
                      moid={moid}
                      setPage={setPage}
                      manufactureOrderFormik={manufactureOrderFormik}
                      loading={loading}
                    />
                  }
                />
                <Route
                  path="sales-orders"
                  element={
                    <SalesOrders
                      setPage={setPage}
                      setId={setId}
                      manufactureOrderFormik={manufactureOrderFormik}
                      loading={loading}
                    />
                  }
                />
                <Route
                  path="purchase-orders"
                  element={
                    <PurchaseOrder
                      setPage={setPage}
                      setId={setId}
                      manufactureOrderFormik={manufactureOrderFormik}
                      loading={loading}
                    />
                  }
                />
                <Route
                  path="work-orders"
                  element={
                    <WorkOrder
                      setPage={setPage}
                      setId={setId}
                      manufactureOrderFormik={manufactureOrderFormik}
                      loading={loading}
                    />
                  }
                />
              </Routes>
            </div>
          </div>
        </>
      </motion.div>
    </AnimatePresence>
  )
}

export default ManufactureOrderWizard
