import { useState, useMemo, useEffect, useRef } from 'react'
import { Chips } from 'primereact/chips'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { toastSuccess } from 'libs/toast/toastSucess'
import { toastFailure } from 'libs/toast/toastFailure'
import AddProperty from './add-new-property/AddProperty'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import DeleteProperty from './delete-property/DeleteProperty'
import EditProperty from './edit-new-property/EditProperty'
import { useLocation } from 'react-router-dom'
import DeleteEntry from './delete-product/DeleteEntry'
import { useEditProductMutation } from './products-endpoints/products.endpoints'
import { useDeletePropertyMutation } from './products-endpoints/products.endpoints'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import AddBom from 'pages/products/add-bom/AddBom'
import DeleteBom from './delete-bom/DeleteBom'
import { mediumMotionProps, scaleMotionProps2, dropdownMotionProps } from 'utils/FramerProps'
import { useGetBomQuery } from './products-endpoints/products.endpoints'
import { useDeleteBomMutation } from './products-endpoints/products.endpoints'
import AddUom from './add-uom/AddUom'
import { t } from 'i18next'
import { NumberFormat } from 'utils/NumberFormat'
import Button from 'libs/button/Button'
import useTabTitle from 'libs/hooks/useTabTitle'
import Panel from 'libs/panel'
import { trimStringValues } from 'utils/trimStringValues'
import PaginatedTable from 'libs/table/PaginatedTable'
import { toast } from 'libs/toast'
import Popup from 'libs/popup/Popup'

const productCategory = [
  { product_category: 'Finished Goods', product_code: '' },
  { product_category: 'Raw Materials', product_code: '' },
]

const EditNewProducts = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state

  const [editProducts, { isLoading: editProductLoading }] = useEditProductMutation()
  const [deleteProperty, { isLoading: deletePropertyLoading }] = useDeletePropertyMutation()

  const [errors, setErrors] = useState<any>({})
  const [tagsValue, setValue] = useState(state?.tags)
  const [page, setPage] = useState(state?.page || 0)
  //add entry panel
  const [showPanel, setShowPanel] = useState(false)
  const [showBomPanel, setShowBomPanel] = useState(false)
  const [showUOMPanel, setShowUOMPanel] = useState(false)
  const [tableData, setTableData] = useState<any>([])
  //edit entry panel
  const [editPanel, setEditPanel] = useState(false)
  const [editBomPanel, setEditBomPanel] = useState(false)
  //show delete popup
  const [deletPopup, setDeletePopup] = useState(false)
  const [deletBomPopup, setDeleteBomPopup] = useState(false)
  const [deletPopup2, setDeletePopup2] = useState(false)
  const [entryToDelete, setEntryToDelete] = useState<any>([])
  const [selectedEntry, setSelectedEntry] = useState(null)
  const [isEdit, setIsEdit] = useState(state?.isEdit || false)
  const [propertyChanged, setPropertyChanged] = useState(false)
  const [searchInput, setSearchInput] = useState({
    product_category: state?.category,
  })
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [filteredData, setFilteredData] = useState(productCategory)
  const [checkboxState, setCheckboxState] = useState({
    propertiesChecked: state.is_properties,
    consumableChecked: state.is_consumable,
    bomChecked: state.is_bom,
    shouldAcceptDecimal: state.is_accepting_decimal,
  })
  const [formData, setFormData] = useState({
    productName: state?.name,
    productCode: state?.code,
    productCategory: state?.category,
    description: state?.description,
    UOM: state?.unit_of_measure,
  })
  useTabTitle(`${t('plannings.update')} ${formData.productName}`)

  const { data: bomData, isFetching: productBomLoading } = useGetBomQuery(
    {
      productId: state._id,
    },
    {
      skip: !state._id,
    },
  )

  const [deleteBom, { isLoading: bomDeleteLoading }] = useDeleteBomMutation()

  const popupRef = useRef<HTMLDivElement>(null)
  const updateOn = state?.updated_on
  const providedTimestamp = updateOn

  const currentDate = new Date()
  const currentTimestamp = Math.floor(currentDate.getTime() / 1000)

  // Calculate the time difference in seconds
  const timeDifferenceInSeconds = currentTimestamp - providedTimestamp
  // Function to format the time difference
  function formatTimeDifference(timeInSeconds: any) {
    if (timeInSeconds < 60) {
      return `${timeInSeconds} second${timeInSeconds !== 1 ? 's' : ''} ago`
    } else if (timeInSeconds < 3600) {
      const minutes = Math.floor(timeInSeconds / 60)
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`
    } else if (timeInSeconds < 86400) {
      const hours = Math.floor(timeInSeconds / 3600)
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`
    } else if (timeInSeconds < 604800) {
      const days = Math.floor(timeInSeconds / 86400)
      return `${days} day${days !== 1 ? 's' : ''} ago`
    } else if (timeInSeconds < 2628000) {
      const weeks = Math.floor(timeInSeconds / 604800)
      return `${weeks} week${weeks !== 1 ? 's' : ''} ago`
    } else if (timeInSeconds < 31536000) {
      const months = Math.floor(timeInSeconds / 2628000)
      return `${months} month${months !== 1 ? 's' : ''} ago`
    } else {
      const years = Math.floor(timeInSeconds / 31536000)
      return `${years} year${years !== 1 ? 's' : ''} ago`
    }
  }

  // Output the formatted time difference

  const handlePropertyChange = (changed: any) => {
    setPropertyChanged(changed)
  }

  if (propertyChanged) {
    const updatedState = {
      ...state,
      updated_on: currentTimestamp,
    }
    setPropertyChanged(false)
    navigate(location.pathname, { state: updatedState })
  }
  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Check if the click is outside the popup
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setEditPanel(false)
        setShowPanel(false)
        setIsDropdownVisible(false)
        setEditBomPanel(false)
        setShowBomPanel(false)
        setShowUOMPanel(false)
      }
    }

    // Adding event listener to detect clicks outside the popups
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  //toggle edit entry
  const toggleEditPanel = (entryData: any) => {
    setSelectedEntry(entryData)
    setEditPanel(!editPanel)
  }
  const toggleBomEditPanel = (entryData: any) => {
    setSelectedEntry(entryData)
    setEditBomPanel(!editBomPanel)
  }

  //toggle delete popup
  const toggleDelete = (entryData: any) => {
    setEntryToDelete(entryData)
    setDeletePopup(!deletPopup)
  }
  const toggleDelete2 = (entryData: any) => {
    setEntryToDelete(entryData.id)
    setDeletePopup2(!deletPopup2)
  }
  const toggleBomDelete = (entryData: any) => {
    setEntryToDelete(entryData)
    setDeleteBomPopup(!deletBomPopup)
  }
  const deleteBomData = () => {
    if (entryToDelete) {
      deleteBom({ productId: state._id, bomId: entryToDelete._id })
        .unwrap()
        .then(() => {
          toast.success(`BOM for Product "${entryToDelete?.bom_product_details?.name}" is removed successfully.`)
          setDeleteBomPopup(!deletBomPopup)
        })
        .catch((err) => {
          toast.error(
            err?.data?.detail ?? `Failed to delete BOM for Product "${entryToDelete?.bom_product_details?.name}`,
          )
        })
    }
  }

  const handleInputClick = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const handleSearchInputChange = (event: any) => {
    const searchValue = event.target.value.toLowerCase()
    setSearchInput(searchValue)

    const filtered = productCategory?.filter(
      (item: any) =>
        item.product_category.toLowerCase().includes(searchValue) ||
        (item.code && item.product_code.toLowerCase().includes(searchValue)),
    )

    setFilteredData(filtered)
  }
  const handleTitleClick = (title: any) => {
    setSearchInput(title)
    setIsDropdownVisible(false)
    setErrors({ ...errors, productCategory: '' })
  }

  //toggle add entry
  const togglePanel = () => {
    setShowPanel(!showPanel)
  }

  const toggleBomPanel = () => {
    setShowBomPanel(!showBomPanel)
  }
  //toggle UOM
  const toggleUOMPanel = () => {
    setShowUOMPanel(!showUOMPanel)
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    // Check if the name is 'containerName' and the length is more than 100 characters
    if (name === 'productName' && value.length >= 101) {
      setErrors({
        ...errors,
        [name]: 'Product Name cannot exceed 100 characters.',
      })
    } else {
      setFormData({ ...formData, [name]: value })
      setErrors({ ...errors, [name]: '' })
    }
  }

  const handleTagsChange = (e: any) => {
    setValue(e.value)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const newErrors: any = {}

    // Check each field for errors
    if (formData.productName.trim() === '') {
      newErrors.productName = 'The product name cannot be empty.'
    }
    if (formData.productCode.trim() === '') {
      newErrors.productCode = 'The product code cannot be empty.'
    }
    if (formData.UOM.trim() === '') {
      newErrors.UOM = 'The unit of measure cannot be empty.'
    }
    if (searchInput.product_category === undefined) {
      newErrors.productCategory = 'The product category cannot be empty.'
    }
    // Set the new errors
    setErrors(newErrors)

    // If there are no errors, submit the form
    if (Object.keys(newErrors).length === 0) {
      let productData = {
        name: formData.productName,
        code: formData.productCode,
        description: formData.description,
        unit_of_measure: formData.UOM,
        tags: tagsValue,
        category: searchInput.product_category,
        is_bom: !!checkboxState.bomChecked,
        is_consumable: checkboxState.consumableChecked,
        is_properties: checkboxState.propertiesChecked,
        is_accepting_decimal: checkboxState.shouldAcceptDecimal,
      }

      if (formData.productName !== state.name) {
        productData = {
          ...productData,
          name: formData.productName,
        }
      }
      if (formData.productCode !== state.code) {
        productData = {
          ...productData,
          code: formData.productCode,
        }
      }

      const data = trimStringValues(productData)

      editProducts({
        data: data,
        productId: state?._id,
      })
        .unwrap()
        .then((res) => {
          setFormData((prev) => ({ ...prev, productCategory: res?.category }))
          toastSuccess(`Product "${formData?.productName}" is updated successfully.`)
          setIsEdit(false)

          switch (page) {
            case 1:
              setPage(0)
              break
            default:
              break
          }
          const updatedState = {
            ...state,
            name: formData.productName,
            code: formData.productCode,
            description: formData.description,
            tags: tagsValue,
            category: searchInput.product_category,
            is_bom: checkboxState.bomChecked,
            is_consumable: checkboxState.consumableChecked,
            is_properties: checkboxState.propertiesChecked,
            updated_on: currentTimestamp,
            is_accepting_decimal: checkboxState.shouldAcceptDecimal,
          }

          navigate(location.pathname, { state: updatedState })
          navigate('/settings/configure/products')
        })
        .catch((err) => {
          toastFailure(err?.data?.detail ?? `Failed to update "${formData.productName}" product. Please try again.`)
        })
    }
  }

  // const handleSubmission = async () => {
  //   navigate("/products");
  //   toastSuccess("Product and it's property updated sucessfully");
  // };

  const addTableData = (newData: any) => {
    setTableData((prevTableData: any) => [...prevTableData, newData])
  }
  const editTableData = (updatedData: any) => {
    const indexToEdit = tableData.findIndex((entry: any) => entry.id === updatedData.id)

    if (indexToEdit !== -1) {
      // Create a new array with the entry updated at the specified index
      const updatedTableData: any = [...tableData]
      updatedTableData[indexToEdit] = updatedData

      // Update the table data with the edited entry
      setTableData(updatedTableData)
    }
  }

  const deleteTabledata = () => {
    deleteProperty({ propertyId: entryToDelete?.id })
      .unwrap()
      .then(() => {
        toastSuccess(`Property "${entryToDelete?.name}"  is removed successfully.`)
        //  toggleDelete(data.id);
        setDeletePopup(!deletPopup)
        setEditPanel(false)
      })
      .catch((err) => {
        toastFailure(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }

  const handleCheckboxChange = (checkboxName: any) => {
    setCheckboxState((prevState: any) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }))
  }

  const BOMColumns = useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'product',
        Cell: ({ row }: any) => (
          <div className="">
            <div className="p-xs-regular">{row.original?.bom_product_details?.name}</div>
            <div className="p-xs-regular-dim">{row.original?.bom_product_details?.code}</div>
          </div>
        ),
        disableSortBy: true,
      },

      {
        Header: 'Quantity',
        align: 'right',
        accessor: 'quantity',
        Cell: ({ row }: any) => {
          return <span className="font-mono">{NumberFormat(row.original?.quantity)}</span>
        },
      },
      {
        Header: 'Unit of Measure',
        accessor: 'unit',
        Cell: ({ row }: any) => (
          <span className="flex w-full justify-start">{row.original?.bom_product_details?.unit_of_measure}</span>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: any) => (
          <div className="flex justify-end gap-1">
            <button
              onClick={(event) => {
                event.stopPropagation()
                toggleBomEditPanel(row?.original)
              }}
            >
              <Pen />
            </button>
            <button
              onClick={(event) => {
                event.stopPropagation()
                toggleBomDelete(row?.original)
              }}
            >
              <Bin />
            </button>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  return (
    <AnimatePresence>
      <motion.div {...scaleMotionProps2}>
        <div className="flex justify-between ">
          <div className="flex items-baseline gap-2">
            <h2 className="mb-2 max-w-[500px]  truncate h2">{formData?.productName}</h2>
            <span className="p-md-regular">{formData?.productCode}</span>
          </div>
          <div className="mt-4 flex items-baseline gap-1 p-md-regular">
            <div>Last updated:</div>
            <div>{formatTimeDifference(timeDifferenceInSeconds)}</div>
          </div>
        </div>
        <div className="flex min-h-[70vh] w-full rounded  bg-[#FFFFFF] p-6">
          <div className="relative border-r border-[#D2DADF] py-2 pl-2  pr-6">
            <p
              className={`cursor-pointer  whitespace-nowrap rounded-md p-2  ${
                page === 0 || page === 1 ? 'bg-[#E7F5FF] p-xs-bold' : 'p-xs-regular'
              }`}
              onClick={() => {
                if (isEdit) setPage(1)
                if (!isEdit) setPage(0)
              }}
            >
              {t('product.product_details')}
            </p>

            <p
              className={`cursor-pointer  whitespace-nowrap rounded-md p-2  ${
                page === 2
                  ? 'bg-[#E7F5FF] p-xs-bold'
                  : state.is_bom || checkboxState.bomChecked
                    ? 'p-xs-regular'
                    : 'opacity-40 p-xs-regular'
              }`}
              onClick={state.is_bom || checkboxState.bomChecked ? () => setPage(2) : undefined}
            >
              {t('product.bill_of_materials')}
            </p>
          </div>
          <div className="basis-full">
            {page === 0 && (
              <div className="ml-6">
                <div className="flex w-full justify-between">
                  <h5 className="h5-1">{t('product.product_details')}</h5>
                  <div className="flex justify-end gap-2 ">
                    <Button color="error" onClick={toggleDelete2} loading={deletePropertyLoading}>
                      {t('product.product_delete')}
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        setPage(1)
                        setIsEdit(true)
                      }}
                      loading={editProductLoading}
                    >
                      {t('product.product_edit')}
                    </Button>
                  </div>
                </div>

                <p className="mb-4 w-[65%] p-sm-regular-1">{t('product.product_detail_subtitle')}</p>
                <div className="flex-col ">
                  {formData?.productName && (
                    <div className="flex items-center gap-5">
                      <h3 className="w-[8rem] p-xs-regular-dim ">{t('product.product_name')} </h3>
                      <div className="py-1 p-xs-regular">{formData?.productName ?? '-'}</div>
                    </div>
                  )}
                  {formData?.productCode && (
                    <div className="flex items-center gap-5 ">
                      <h3 className="w-[8rem] p-xs-regular-dim ">{t('product.product_code')} </h3>
                      <div className=" py-1 p-xs-regular">{formData?.productCode ?? '-'}</div>
                    </div>
                  )}
                  {formData?.productCategory && (
                    <div className="flex items-center gap-5 ">
                      <h3 className="w-[8rem] p-xs-regular-dim ">{t('product.product_category')}</h3>

                      <div className=" py-1 p-xs-regular">{formData?.productCategory ?? '-'}</div>
                    </div>
                  )}

                  <div className="flex items-center gap-5 ">
                    <h3 className="w-[8rem] p-xs-regular-dim ">{t('product.bill_of_materials')}</h3>

                    <div className=" py-1 p-xs-regular">{state.is_bom ? 'Enabled' : 'Disabled'}</div>
                  </div>

                  <div className="flex items-center gap-5 ">
                    <h3 className="w-[8rem] p-xs-regular-dim "> {t('product.consumable')}</h3>

                    <div className=" py-1 p-xs-regular">{state.is_consumable ? 'Yes' : 'No'}</div>
                  </div>

                  {formData?.description && (
                    <div className="flex  gap-5 ">
                      <h3 className="w-[8rem] p-xs-regular-dim ">{t('product.product_description')}</h3>
                      <div className="w-[30rem] py-1 p-xs-regular">{formData?.description ?? '-'}</div>
                    </div>
                  )}
                  {state?.process?.length > 0 && (
                    <div className="mb-1 flex items-center gap-5">
                      <h3 className="w-[8rem] p-xs-regular-dim  ">Associated Process</h3>

                      <div className=" flex w-[30rem] flex-wrap  py-1 p-xs-regular ">
                        {state?.process?.map((status: any, index: any) => (
                          <span key={index} className={`m-1 rounded-[28px] bg-[#F2F2F2] px-2 py-0.5 text-[#434C52] `}>
                            {status}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                  {tagsValue?.length > 0 && (
                    <div className="flex items-center gap-5 ">
                      <h3 className="w-[8rem] p-xs-regular-dim ">{t('product.product_tags')}</h3>
                      <div className=" w-[30rem] py-1 p-xs-regular">
                        {tagsValue?.map((status: any, index: number) => (
                          <span key={index} className={`m-1 rounded-[28px] bg-[#F2F2F2] px-2 py-0.5 text-[#434C52] `}>
                            {status}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {page === 1 && (
              <div className="ml-6">
                <div className="flex justify-between ">
                  <h5 className="h5-1">{t('product.product_details')}</h5>
                  <div className="flex gap-2 ">
                    <Button onClick={() => navigate(-1)}>{t('cancel')}</Button>
                    <Button color="primary" onClick={(e) => handleSubmit(e)} loading={editProductLoading}>
                      {t('save')}
                    </Button>
                  </div>
                </div>
                <p className="mb-7 p-sm-regular-1">{t('product.product_detail_subtitle')}</p>
                <div className="w-full flex-col">
                  <div className="flex w-[630px] flex-wrap justify-between gap-6">
                    <div>
                      <label className="mb-1 p-sm-regular">
                        {t('product.product_name')} <span className="text-[#DC3545]">*</span>
                      </label>
                      <div>
                        <div className="relative py-1">
                          <input
                            required
                            type="text"
                            name="productName"
                            value={formData.productName}
                            onChange={handleInputChange}
                            className={` h-[28px] border ${
                              errors.productName ? 'border-[#DC3545]' : 'border-none'
                            } w-[300px] rounded-md border border-solid bg-[#EBEFF3] p-2 pr-8  text-sm outline-none p-xs-regular`}
                            placeholder={t('product.product_name_placeholder')}
                            maxLength={100}
                          />
                          {errors.productName && (
                            <p className="pt-1 text-xs font-normal text-[#DC3545]">{errors.productName}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className="mb-1 p-sm-regular">
                        {t('product.product_code')} <span className="text-[#DC3545]">*</span>
                      </label>
                      <div>
                        <div className="relative py-1">
                          <input
                            required
                            type="text"
                            name="productCode"
                            value={formData.productCode}
                            onChange={handleInputChange}
                            className={`h-[28px] border ${
                              errors.productCode ? 'border-[#DC3545]' : 'border-none'
                            } w-[300px] rounded-md border border-solid bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular`}
                            placeholder={t('product.product_code_placeholder')}
                          />
                          {errors.productCode && (
                            <p className="pt-1 text-xs font-normal text-[#DC3545]">{errors.productCode}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className=" relative flex flex-col">
                        <label className="mb-1 p-sm-regular">
                          {t('product.product_category')}
                          <span className="text-[#DC3545]">*</span>
                        </label>
                        <input
                          required
                          name="product_category"
                          type="text"
                          className={`ignoreref h-[28px] w-[300px] ${
                            errors.productCategory ? 'border-[#DC3545]' : 'border-none'
                          } cursor-pointer rounded-md 
                          border bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular focus:border-[#1193F0]`}
                          placeholder={t('product.product_category_placeholder')}
                          autoComplete="off"
                          onClick={handleInputClick}
                          value={searchInput.product_category}
                          onChange={handleSearchInputChange}
                        />
                        <div className="absolute right-[0.5rem] top-[1.9rem]">
                          <ArrowDown color="" />
                        </div>
                        {errors.productCategory && (
                          <p className="pt-1 text-xs font-normal text-[#DC3545]">{errors.productCategory}</p>
                        )}
                        <AnimatePresence>
                          {isDropdownVisible && (
                            <motion.div
                              initial="closed"
                              animate="open"
                              exit="closed"
                              variants={dropdownMotionProps}
                              ref={popupRef}
                              className="absolute top-[3.9rem] max-h-[15rem] min-h-[20px] w-[300px] overflow-auto rounded bg-[#fff]"
                              style={{
                                zIndex: '3',
                                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
                              }}
                            >
                              {filteredData?.length > 0 ? (
                                filteredData.map((item) => (
                                  <div
                                    key={item.product_code}
                                    className="cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3
                                    py-2 hover:bg-[#f8f8f8]"
                                    onClick={() => handleTitleClick(item)}
                                  >
                                    <div className="p-xs-regular">{item.product_category}</div>
                                    <div className="p-xs-regular-dim">{item.product_code}</div>
                                  </div>
                                ))
                              ) : (
                                <div className="cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3 py-2 hover:bg-[#f8f8f8]">
                                  <div className="p-xs-regular">Category does not exist</div>
                                </div>
                              )}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    </div>
                    <div>
                      <label className="mb-1 p-sm-regular">
                        {t('product.unit_of_measure')} <span className="text-[#DC3545]">*</span>
                      </label>
                      <div>
                        <div className="relative mb-3 py-1">
                          <input
                            required
                            type="text"
                            name="UOM"
                            value={formData.UOM}
                            onChange={handleInputChange}
                            className={`h-[28px] w-[300px] border ${
                              errors.UOM ? 'border-[#DC3545]' : 'border-none'
                            } rounded-md  border p-2 pr-8 text-sm outline-none p-xs-regular background-input`}
                            placeholder={t('product.unit_of_measure_placeholder')}
                          />
                          {errors.UOM && <p className="pt-1 text-xs font-normal text-red">{errors.UOM}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-8 flex w-[630px] justify-between">
                      <span className="p-sm-regular"> {t('product.consumable')}</span>
                      <div>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={checkboxState.consumableChecked}
                            onChange={() => handleCheckboxChange('consumableChecked')}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="p-xs-regular-dim">{t('product.consumable_subtitle')}</p>

                    <div className="mt-8 flex w-[630px] justify-between">
                      <span className="p-sm-regular"> {t('product.bill_of_materials')}</span>
                      <div>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={checkboxState.bomChecked}
                            onChange={() => handleCheckboxChange('bomChecked')}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="p-xs-regular-dim">{t('product.bill_of_materials_subtitle')}</p>
                    <div className="mt-4 flex w-[630px] justify-between">
                      <span className="p-sm-regular">{'Accept Decimal'}</span>
                      <div>
                        <label className="switch">
                          <input
                            autoComplete="off"
                            type="checkbox"
                            checked={checkboxState?.shouldAcceptDecimal}
                            onChange={() => handleCheckboxChange('shouldAcceptDecimal')}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="p-xs-regular-dim">{'Enable this to add decimal quantity'}</p>
                  </div>
                  <div className="mt-5">
                    <label className="mb-1 p-sm-regular">{t('product.description')}</label>
                    <div>
                      <div className="relative py-1">
                        <textarea
                          required
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                          className="h-[80px] w-[630px] rounded-md border border-none bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular"
                          placeholder={t('product.product_description')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 mt-3 justify-between">
                    <label className="mb-1 p-sm-regular"> {t('product.product_tags')}</label>
                    <div className="w-[630px] rounded-md border border-none bg-[#EBEFF3] p-2 text-sm outline-none p-xs-regular">
                      <Chips
                        onChange={handleTagsChange}
                        value={tagsValue}
                        placeholder={tagsValue?.length > 0 ? undefined : t('product.tag_description')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {page === 2 && (
              <div className="ml-6">
                <div className="flex justify-between">
                  <h5 className="h5-1"> {t('product.bill_of_materials')}</h5>
                  <div className="flex gap-2">
                    {' '}
                    <Button
                      color="success"
                      onClick={() => {
                        navigate('/settings/configure/products')
                      }}
                    >
                      {' '}
                      Finish
                    </Button>
                    <Button color="primary" onClick={toggleBomPanel}>
                      <PlusIcon /> {t('product.add_material')}
                    </Button>
                  </div>
                </div>
                <p className="mb-7 w-2/3 p-sm-regular-1">{t('product.bill_of_materials_title')}</p>

                <div className="w-full flex-col">
                  <PaginatedTable
                    columns={BOMColumns}
                    emptyMessage={"Add properties by clicking on '+ Add Property'"}
                    rows={bomData && bomData[0]?.bill_of_materials}
                    onSingleClick={() => {}}
                    onPageNumberChange={() => {}}
                    loading={productBomLoading}
                    hidePagination={true}
                  />
                </div>
              </div>
            )}
          </div>

          <div style={{ overflowY: 'auto' }}>
            <Panel
              isOpen={showPanel}
              title={'New Property'}
              onClose={() => {
                setSelectedEntry(null)
                setShowPanel(false)
              }}
            >
              <AddProperty
                productId={state?.id}
                show={showPanel}
                removeEntry={togglePanel}
                addTableData={addTableData}
                tableData={tableData}
                onPropertyChange={handlePropertyChange}
              />
            </Panel>

            <Panel
              isOpen={editPanel}
              title={'Edit Property'}
              onClose={() => {
                setSelectedEntry(null)
                setEditPanel(false)
              }}
            >
              <EditProperty
                show={editPanel}
                data={selectedEntry}
                editTableData={editTableData}
                toggleDelete={toggleDelete}
                removeEntry={toggleEditPanel}
                onPropertyChange={handlePropertyChange}
              />
            </Panel>

            <Popup
              isOpen={deletPopup}
              title={'Remove Entry?'}
              onClose={() => {
                setSelectedEntry(null)
                setDeletePopup(false)
              }}
            >
              <DeleteProperty
                propertyId={state?.id}
                show={deletPopup}
                toggleDelete={toggleDelete}
                deleteData={deleteTabledata}
                entryToDelete={entryToDelete}
                onPropertyChange={handlePropertyChange}
              />
            </Popup>

            <Popup
              isOpen={deletPopup2}
              title={'Remove Entry?'}
              onClose={() => {
                setSelectedEntry(null)
                setDeletePopup2(false)
              }}
            >
              <DeleteEntry
                product={state}
                show={deletPopup2}
                toggleDelete={toggleDelete2}
                data={entryToDelete}
                entryToDelete={entryToDelete}
              />
            </Popup>

            <Panel
              isOpen={showBomPanel}
              title={t('product.new_material')}
              onClose={() => {
                setSelectedEntry(null)
                setShowBomPanel(false)
              }}
            >
              <AddBom
                show={showBomPanel}
                removeEntry={toggleBomPanel}
                addTableData={addTableData}
                tableData={tableData}
                productId={state._id}
                isEdit={false}
              />
            </Panel>
            <Panel
              isOpen={editBomPanel}
              title={t('product.edit_material')}
              onClose={() => {
                setSelectedEntry(null)
                setEditBomPanel(false)
              }}
            >
              <AddBom
                show={editBomPanel}
                data={selectedEntry}
                editTableData={editTableData}
                toggleDelete={toggleDelete}
                removeEntry={toggleBomEditPanel}
                productId={state._id}
                isEdit={true}
              />
            </Panel>

            <Popup
              isOpen={deletBomPopup}
              title={'Remove Entry?'}
              onClose={() => {
                setSelectedEntry(null)
                setDeleteBomPopup(false)
              }}
            >
              <DeleteBom
                isLoading={bomDeleteLoading}
                entryToDelete={entryToDelete}
                show={deletBomPopup}
                toggleDelete={toggleBomDelete}
                deleteData={deleteBomData}
              />
            </Popup>
            <AnimatePresence>
              {showUOMPanel && (
                <motion.div
                  {...mediumMotionProps}
                  ref={popupRef}
                  className={`rightSideNav ${showUOMPanel ? 'active' : ''} p-3`}
                >
                  <AddUom
                    show={showUOMPanel}
                    removeEntry={toggleUOMPanel}
                    productId={state._id}
                    data={state?.unit_of_measure}
                    state={state}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <Panel
              isOpen={showUOMPanel}
              title={t('product.unit_of_measure')}
              onClose={() => {
                setSelectedEntry(null)
                setShowUOMPanel(false)
              }}
            >
              <AddUom
                show={showUOMPanel}
                removeEntry={toggleUOMPanel}
                productId={state._id}
                data={state?.unit_of_measure}
                state={state}
              />
            </Panel>
          </div>
          <div
            className={
              showPanel || editPanel || showUOMPanel || showBomPanel ? 'sideNav-overlay-dark' : 'sideNav-overlay'
            }
          ></div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default EditNewProducts
