import { getToken } from 'utils/CheckAuth'

import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'

import { buildUrl } from 'utils/BuildUrl'
import { GetVendorsParams, VendorsResponse } from './vendorTypes'


export const vendor = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getVendors: builder.query<VendorsResponse, GetVendorsParams>({
      query: (params) => {
        let URL = ''
        params.filters
          ? (URL = buildUrl(`/plant/${params.plantId}/vendors`, params.filters))
          : (URL = `/plant/${params.plantId}/vendors`)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the vendors')
            }
            return response.json()
          },
        }
      },
      providesTags: ['vendorUpdate'],
    }),

    putVendor: builder.mutation({
      query: ({ data, plant_id, vendorID }) => ({
        url: `/plant/${plant_id}/vendor/${vendorID}`,
        method: 'PUT',
        headers: getToken(),
        body: data,
      }),
      invalidatesTags: ['vendorUpdate', 'getSingleVendor'],
    }),
    deleteVendor: builder.mutation({
      query: ({ plant_id, vendorID }) => ({
        url: `/plant/${plant_id}/vendor/${vendorID}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['vendorUpdate'],
    }),

    postVendor: builder.mutation({
      query: ({ plant_id, data }) => {
        return {
          url: `/plant/${plant_id}/vendor`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['vendorUpdate', 'getSingleVendor'],
    }),
    getVendor: builder.query({
      query: ({ plantId, vendorID }) => {
        return {
          url: `/plant/${plantId}/vendor/${vendorID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the vendor ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['purchaseOrderUpdate', 'getSingleVendor'],
    }),
    getVendorCount: builder.query({
      query: ({ plantId }) => {
        return {
          url: `/plant/${plantId}/vendor`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the vendor ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['vendorUpdate'],
    }),
  }),
  overrideExisting: false,
})

export const {
  usePostVendorMutation,
  useDeleteVendorMutation,
  useGetVendorCountQuery,
  useGetVendorQuery,
  usePutVendorMutation,
  useGetVendorsQuery,
} = vendor
