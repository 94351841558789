import { useEffect, useState } from 'react'
import BlueTick from 'assets/icons/product/BlueTick'
import { useNavigate, Route, Routes, useParams, To } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { scaleMotionProps2 } from 'utils/FramerProps'
import Summary from './tabs/summary'
import PlanDetails from './tabs/plan-details'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toast } from 'libs/toast'
import Scheduling from './tabs/scheduling/index.tsx'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice.js'
import {
  useEditPlanMutation,
  useGetProductionPlanQuery,
  usePostProductionPlanMutation,
} from './production-plans-endpoints/productionPlan.endpoints.ts'
import { t } from 'i18next'
import useTabTitle from 'libs/hooks/useTabTitle.ts'
import ManufacturingOrders from './tabs/manufacturing-order/ManufacturingOrder.tsx'

export interface PlanForm {
  planID: string
  description: string
  startDate: number | undefined
  endDate: number | undefined
  status: string | undefined
  manufactureOrders: string[]
  scheduledDate: string[]
}

const steps = [
  { label: 'Plan Details', path: 'plan-details' },
  { label: 'Scheduling', path: 'scheduling' },
  { label: 'Manufacturing Order', path: 'mo' },
  { label: 'Summary', path: 'summary' },
]
const getChangeValues = (currentValues: any, initialValues: any) => {
  return Object.keys(currentValues).reduce((value: any, key) => {
    if (currentValues[key] !== initialValues[key]) {
      value[key] = currentValues[key]
    }
    return value
  }, {})
}
const PlanningWizard = () => {
  const plantId = useSelector(selectSelectedItem)
  const { id } = useParams()
  const navigate = useNavigate()
  const [addPlans, { isLoading: addPlansLoading }] = usePostProductionPlanMutation()
  const [editPlans] = useEditPlanMutation()
  const [planId, setPlanId] = useState({
    id: id ? id : null,
  })
  const isEdit = location.pathname.includes('edit')

  useTabTitle(isEdit ? `Update Plan` : t('plannings.add_plan'))
  const filters = {
    planID: planId.id,
    page_no: 1,
    page_size: 10,
    sortBy: 'created_on',
    sortDir: 'DESC',
  }

  const { data: plans } = useGetProductionPlanQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !plantId?.id && !planId.id,
    },
  )

  const [page, setPage] = useState<number>(1)

  const productionPlansFormik = useFormik<any>({
    initialValues: {
      planID: '',
      description: '',
      startDate: undefined,
      endDate: undefined,
      status: undefined,
      manufactureOrders: [],
      scheduledDate: [],
    },
    validationSchema: Yup.object().shape({
      planID: Yup.string().required('Plan ID is required'),
    }),

    onSubmit: (values) => {
      const Values = getChangeValues(values, productionPlansFormik.initialValues)

      const isEmpty = Object.keys(Values).length === 0
      const payload = {
        planID: values.planID,
        description: values.description,
        manufactureOrders:
          values?.manufactureOrders?.length > 0
            ? values.manufactureOrders.map((item: any) => item?.manufactureOrderID?._id)?.filter(Boolean)
            : [],
        scheduledDate: values.endDate ? [values.startDate, values.endDate] : undefined,
        status: values.status,
      }

      if (!planId.id) {
        addPlans({
          plant_id: plantId?.id,
          data: payload,
        })
          .unwrap()
          .then((res) => {
            setPlanId({ id: res.id as string })
            toast.success(`Plan ${payload?.planID} is created successfully.`)
            if (!isEdit) {
              navigate(`/planning/production-plans/${res.id}/add/scheduling`)
            } else {
              navigate(`/planning/production-plans/${res.id}/edit/scheduling`)
            }
            productionPlansFormik.setFieldValue('isDraft', false)
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong. Please try again.')
          })
      } else {
        if (!isEmpty) {
          editPlans({
            plant_id: plantId?.id,
            pID: planId.id,
            data: payload,
          })
            .unwrap()
            .then((res) => {
              toast.success(`Plan ${payload?.planID} is updated successfully.`)
              setPlanId({ id: res._id })
              if (page === 1) {
                isEdit
                  ? navigate(`/planning/production-plans/${res._id}/edit/scheduling`)
                  : navigate(`/planning/production-plans/${res._id}/add/scheduling`)
              } else if (page === 2) {
                isEdit
                  ? navigate(`/planning/production-plans/${res._id}/edit/mo`)
                  : navigate(`/planning/production-plans/${res._id}/add/mo`)
              } else if (page === 3) {
                isEdit
                  ? navigate(`/planning/production-plans/${res._id}/edit/summary`)
                  : navigate(`/planning/production-plans/${res._id}/add/summary`)
              } else {
                navigate(`/planning/production-plans`)
              }
              productionPlansFormik.setFieldValue('isDraft', false)
            })
            .catch((error) => {
              toast.error(error?.data?.detail ?? 'Something went wrong. Please try again.')
              productionPlansFormik.setFieldValue('isDraft', false)
            })
        } else {
          setPlanId({ id: planId.id })
          if (page === 1) {
            isEdit
              ? navigate(`/planning/production-plans/${planId.id}/edit/scheduling`)
              : navigate(`/planning/production-plans/${planId.id}/add/scheduling`)
          } else if (page === 2) {
            isEdit
              ? navigate(`/planning/production-plans/${planId.id}/edit/mo`)
              : navigate(`/planning/production-plans/${planId.id}/add/mo`)
          } else if (page === 3) {
            isEdit
              ? navigate(`/planning/production-plans/${planId.id}/edit/summary`)
              : navigate(`/planning/production-plans/${planId.id}/add/summary`)
          } else if (page === 4) {
            navigate(`/planning/production-plans`)
          } else {
            navigate(`/planning/production-plans`)
          }
        }
      }
    },
  })

  useEffect(() => {
    if (planId.id && plans) {
      const data = plans?.data[0]

      productionPlansFormik.resetForm({
        values: {
          planID: data?.planID,
          status: data?.status,
          startDate: data?.scheduledDate ? data.scheduledDate[0] : null,
          endDate: data?.scheduledDate ? data.scheduledDate[1] : null,
          description: data?.description,
          manufactureOrders:
            data?.manufactureOrders?.map((manufactureOrder: any) => ({
              manufactureOrderID: { name: manufactureOrder?.manufactureOrderID, ...manufactureOrder },
              finishedGood: manufactureOrder?.finishedGood?.name,
              purchaseOrder: manufactureOrder?.purchaseOrders?.length,
              workOrder: manufactureOrder?.workOrders?.length,
              salesOrder: manufactureOrder?.salesOrders?.length,
              startDate: manufactureOrder?.startDate,
              endDate: manufactureOrder?.endDate,
              status: manufactureOrder?.status,
            })) ?? [],
        },
      })
    }
  }, [planId.id && plans])

  const handleNavigation = (stepIndex: number, path: To) => {
    if (planId.id) {
      setPage(stepIndex + 1)
      navigate(path)
    }
  }

  return (
    <AnimatePresence>
      <motion.div {...scaleMotionProps2}>
        <div className="flex justify-between">
          <div className="flex items-baseline gap-2 ">
            <h2 className="mb-2 max-w-[500px] truncate h4-bold-black">{isEdit ? 'Edit Plan' : 'New Plan'}</h2>
          </div>
          <div className="mt-4 flex items-baseline gap-1 p-md-regular">
            <div>{page === 2 ? 'Last updated :' : null}</div>
            <div>{page === 2 ? 'Just now' : null}</div>
          </div>
        </div>
        <div className="flex min-h-[65vh] w-full rounded  bg-[#FFFFFF] p-6">
          <div className="relative w-1/4 border-r border-[#D2DADF] p-2">
            {steps.map((step, index) => (
              <div key={index}>
                <button onClick={() => handleNavigation(index, step.path)} className="flex items-center">
                  <div
                    className={`h-6 w-6 rounded-3xl border p-2 ${
                      page === index + 1 ? 'border-primary ' : 'border-[#B9C4CC]'
                    } ${
                      page > index + 1 ? 'border-primary bg-primary' : 'border-[#B9C4CC]'
                    } flex items-center justify-center`}
                  >
                    <div className={`text-xs font-bold  `}>{page > index + 1 ? <BlueTick /> : index + 1}</div>
                  </div>
                  <p
                    className={`whitespace-nowrap rounded px-2 text-sm ${page == index + 1 ? 'font-bold' : ''} ${!planId.id ? ' cursor-not-allowed' : ''}`}
                  >
                    {step.label}
                  </p>
                </button>
                {index < steps.length - 1 && (
                  <span
                    className={`ml-3 flex h-[16px] border-l-2 border-dashed ${
                      page > index + 1 ? 'border-primary' : 'border-[#B9C4CC]'
                    }`}
                  ></span>
                )}
              </div>
            ))}
          </div>
          <div className="ml-2  flex w-full " style={{ overflowX: 'auto' }}>
            <Routes>
              <Route
                path="plan-details"
                element={
                  <PlanDetails
                    setId={setPlanId}
                    setPage={setPage}
                    createLoading={addPlansLoading}
                    formik={productionPlansFormik}
                  />
                }
              />
              <Route
                path="scheduling"
                element={
                  <Scheduling
                    setId={setPlanId}
                    setPage={setPage}
                    createLoading={addPlansLoading}
                    formik={productionPlansFormik}
                  />
                }
              />
              <Route
                path="mo"
                element={
                  <ManufacturingOrders
                    setId={setPlanId}
                    setPage={setPage}
                    createLoading={addPlansLoading}
                    formik={productionPlansFormik}
                  />
                }
              />
              <Route
                path="summary"
                element={
                  <Summary
                    setId={setPlanId}
                    setPage={setPage}
                    createLoading={addPlansLoading}
                    formik={productionPlansFormik}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default PlanningWizard
