import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import AccessRights from '../add-edit-roles/AccessRights'

const ViewRolesDetail = () => {
  return (
    <MotionDiv>
      <>
        <WhiteCard>
          <div className="flex justify-between">
            <h5 className="h5">User Role Detail</h5>
            <div className="flex gap-2">
              <Button color="error">Delete User Role</Button>
              <Button color="primary">Update User Role</Button>
            </div>
          </div>
          <div className="my-2 grid grid-cols-[150px,1fr] gap-4 text-xs">
            <span className="text-gray-500">User Role</span>
            <span>Super Admin</span>
            <span className="text-gray-500">Plants</span>
            <span>Plants</span>
          </div>
          <div className="mb-6">
            <AccessRights />
          </div>
        </WhiteCard>
      </>
    </MotionDiv>
  )
}

export default ViewRolesDetail
