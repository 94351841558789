import React, { useState, useRef, useEffect, ChangeEvent } from 'react'
import Search from 'assets/icons/top-navigation/Search'
import { AnimatePresence, motion } from 'framer-motion'
import { dropdownMotionProps } from 'utils/FramerProps'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import { t } from 'i18next'
import { NumberFormat } from 'utils/NumberFormat'
import Button from 'libs/button/Button'

interface Props {
  options: any[]
  onChange: (selectedOptions: []) => void
  isLoading?: boolean
  checkedData?: any[]
  width?: number
  error?: any
  placeholder?: string
}

const MultiSelectDropdown: React.FC<Props> = ({
  options = [],
  onChange,
  isLoading,
  checkedData,
  width,
  error,
  placeholder,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<any>(checkedData ? checkedData : [])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>()
  const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>('bottom')
  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLDivElement>(null)

  const handleOptionClick = (option: any) => {
    const isSelected = selectedOptions?.some((o: any) => o._id === option._id)
    if (isSelected) {
      setSelectedOptions(selectedOptions?.filter((o: any) => o._id !== option._id))
    } else {
      setSelectedOptions([...selectedOptions, option])
    }
  }

  useEffect(() => {
    if (options?.length > 0 && options?.length === selectedOptions?.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedOptions, selectAll])

  const handleSelectAllOption = () => {
    if (selectAll) {
      setSelectedOptions([])
    } else {
      setSelectedOptions(options)
    }
    setSelectAll(!selectAll)
  }

  useEffect(() => {
    if (isLoading) {
      setSelectedOptions([])
      setSelectAll(false)
    }
  }, [isLoading])

  useEffect(() => {
    const filteredOptions = searchTerm
      ? options?.filter((option) => {
          const lowerSearchTerm = searchTerm.toLowerCase()
          const lowerName = option?.name.toLowerCase()
          for (let i = 0; i < lowerSearchTerm.length; i++) {
            if (lowerName[i] !== lowerSearchTerm[i]) {
              return false
            }
          }
          return true
        })
      : options

    setFilterData(filteredOptions)
  }, [searchTerm, options])

  const handleCloseDropdown = () => {
    setSelectedOptions([])
    setSelectAll(false)
    setIsOpen(false)
  }

  const handleApplySelection = () => {
    onChange(selectedOptions)
    setSearchTerm('')
    setIsOpen(false)
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      !inputRef.current?.contains(event.target as Node)
    ) {
      handleApplySelection()
    }
  }

  const adjustDropdownPosition = () => {
    const inputRect = inputRef.current?.getBoundingClientRect()
    const dropdownRect = dropdownRef.current?.getBoundingClientRect()
    const windowHeight = window.innerHeight

    if (inputRect && dropdownRect) {
      const spaceBelow = windowHeight - inputRect.bottom
      const spaceAbove = inputRect.top

      if (spaceBelow < dropdownRect.height && spaceAbove > dropdownRect.height) {
        setDropdownPosition('top')
      } else {
        setDropdownPosition('bottom')
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true)
    window.addEventListener('resize', adjustDropdownPosition)
    adjustDropdownPosition()

    return () => {
      document.removeEventListener('click', handleOutsideClick, true)
      window.removeEventListener('resize', adjustDropdownPosition)
    }
  }, [handleOutsideClick])

  const handleInputClick = () => {
    setIsOpen(!isOpen)
    if (!isOpen) adjustDropdownPosition()
  }

  return (
    <>
      <div className="relative" ref={inputRef}>
        <input
          type="text"
          placeholder={placeholder}
          value={
            selectedOptions.length > 0
              ? NumberFormat(selectedOptions.length) + ' ' + t('warehouse.product_selected')
              : ''
          }
          onClick={handleInputClick}
          className={` ${width ? `w-[${width}px]` : 'w-full'} h-[32px] border bg-white ${
            error ? 'border-error' : ''
          } rounded border p-2 pr-8 text-sm outline-none p-xs-regular `}
        />

        <div
          onClick={handleInputClick}
          className="absolute right-[0.5rem] top-1/2 flex -translate-y-1/2 transform items-center"
        >
          <ArrowDown color="black" />
        </div>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={dropdownMotionProps}
              className={` ${width ? `w-[${width}px]` : 'w-full'} absolute ${
                dropdownPosition === 'bottom' ? 'top-[2.3rem]' : 'bottom-[2.3rem]'
              } min-h-[20px] rounded bg-white`}
              style={{
                zIndex: '3',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
              }}
              ref={dropdownRef}
            >
              <div className="mt-2 flex items-center px-3">
                <input
                  type="text"
                  placeholder={t('warehouse.search_products')}
                  value={searchTerm}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                  className={` ${
                    width ? `w-[${width}px]` : 'w-full'
                  } h-[28px] rounded border border-border bg-white px-8 text-sm outline-none p-xs-regular`}
                />
                <div className="absolute left-5 top-3 ">
                  <Search />
                </div>
              </div>
              {searchTerm === '' && (
                <div className="border-b border-gray-100 px-4 py-2 p-xs-regular" onClick={handleSelectAllOption}>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllOption}
                    className="mr-2 bg-primary"
                  />
                  {t('warehouse.select_all')}
                </div>
              )}
              {filterData && filterData.length > 0 ? (
                <div className="scroll-bar max-h-[10rem] overflow-auto">
                  {filterData.map((option: any) => (
                    <div key={option.id} className="ignoreref cursor-pointer px-4 py-2">
                      <div className="flex border-b border-gray-100" onClick={() => handleOptionClick(option)}>
                        <input
                          type="checkbox"
                          checked={selectedOptions.some((o: any) => o._id === option._id)}
                          onChange={() => {
                            handleOptionClick(option)
                          }}
                          className="mb-4 mr-2 cursor-pointer"
                        />
                        <div className="mb-1 flex flex-col">
                          <span className="p-xs-regular">
                            {option.name.split(new RegExp(`(${searchTerm})`, 'gi')).map((part: any, index: any) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.toLowerCase() === searchTerm.toLowerCase() ? 'bold' : 'normal', // Bold if matches searchTerm
                                }}
                              >
                                {part}
                              </span>
                            ))}
                          </span>
                          <span className="p-xs-regular-dim">{option.code}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="border-b border-gray-100 px-4 py-2 text-center p-xs-regular">
                  <span className="p-xs-regular-dim">{t('warehouse.no_result_found')}</span>
                </div>
              )}

              <div className="flex justify-between p-2">
                <Button
                  onClick={handleCloseDropdown}
                  style={{
                    paddingLeft: '3rem',
                    paddingRight: '3rem',
                  }}
                >
                  {t('warehouse.close')}
                </Button>
                <Button
                  color="primary"
                  onClick={handleApplySelection}
                  style={{
                    paddingLeft: '3rem',
                    paddingRight: '3rem',
                  }}
                >
                  {t('warehouse.apply')}
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {error && <p className="p-xs-regular text-red">{error}</p>}
    </>
  )
}

export default MultiSelectDropdown
