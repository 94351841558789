import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import Field from 'libs/field'
import * as Yup from 'yup'

const FilterUserRoles = () => {
  const formik = useFormik({
    initialValues: {
      plants: [],
      lastUpdated: [],
    },
    onSubmit: (values) => {
      console.log(values)
    },
    validationSchema: Yup.object({}),
  })
  const options = [
    {
      label: 'Admin',
      value: 'Admin',
    },
    {
      label: 'User',
      value: 'User',
    },
  ]
  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="select"
          label="User Role"
          formik={formik}
          name="plants"
          options={options}
          placeholder="Enter Status"
        />
        <Field
          type="dateRange"
          label="Last Updated"
          placeholder="select"
          formik={formik}
          name="lastUpdated"
        />
        <div className="flex justify-between">
          <Button>Reset Filters</Button>
          <div className="flex justify-end gap-2">
            <Button onClick={close}>Cancel</Button>
            <Button color="primary">Apply</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterUserRoles
