import Button from 'libs/button/Button'

const ViewUser = ({ userData, close, handleUpdate, handleDelete }: any) => {
  const toggleUpdate = () => {
    handleUpdate(userData)
  }

  return (
    <>
      <div className="my-4 rounded-lg bg-slate-200 p-4">
        <div className="flex justify-between">
          <div className="mb-2 p-xs-regular-dim">User Name</div>
          <div>
            <div className="w-60 break-words p-xs-regular">{userData?.username}</div>
          </div>
        </div>
        <div className="flex justify-between ">
          <div className="mb-2 p-xs-regular-dim">Email ID</div>
          <div>
            <div className="w-60 break-words p-xs-regular">{userData?.email}</div>
          </div>
        </div>
        <div className="flex justify-between ">
          <div className="mb-2 p-xs-regular-dim">User Role</div>
          <div>
            <div className="w-60 break-words p-xs-regular">{userData?.role}</div>
          </div>
        </div>

        <div className="flex justify-end">
          <div className="mt-2"></div>
        </div>
      </div>
      <div className="mt-5 flex justify-between ">
        <div>
          <Button onClick={() => handleDelete(userData)} color="error">
            {' '}
            Delete
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={close}>Close</Button>
          <Button onClick={toggleUpdate} color="primary">
            Edit
          </Button>
        </div>
      </div>
    </>
  )
}

export default ViewUser
