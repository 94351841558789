import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import Field from 'libs/field'
import NewEditableTable from 'libs/table/NewEditableTable'
import { toast } from 'libs/toast'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { phoneNumberValidator } from 'utils/phoneNumberValidator'
import { formatString } from 'utils/trimStringValues'
import * as yup from 'yup'

interface BasicDetailsProps {
  customerFormik: any
  setPage: React.Dispatch<React.SetStateAction<number>>
  loading: boolean
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const BasicDetails: React.FC<BasicDetailsProps> = ({ customerFormik, setPage }) => {
  const navigate = useNavigate()

  const { id } = useParams()

  const BasicDetailsFormik = useFormik({
    initialValues: {
      customerType: '',
      name: '',
      businessEmailAddress: '',
      primaryContactNumber: '',
      secondaryContactNumber: '',
      pointOfContact: [],
    },

    validationSchema: yup.object({
      customerType: yup.string().required('Customer Type is required'),
      businessEmailAddress: yup
        .string()
        .optional() // Makes the field optional (it can be empty)
        .email('Invalid email format') // Standard email validation
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Email must be a valid email (e.g., pankaj@1.com)',
        ),
      primaryContactNumber: yup
        .string()
        .required('Primary Contact Number is required')
        .test('valid-phone', 'Please enter a phone number for the selected country.', phoneNumberValidator),
      secondaryContactNumber: yup
        .string()
        .nullable() // Allows the field to be null or undefined
        .test('valid-phone', 'Please enter a phone number for the selected country.', function (value) {
          // Perform validation only if value is not null or undefined
          if (value) {
            return phoneNumberValidator(value)
          }
          return true // Skip validation if value is null or undefined
        }),
    }),
    onSubmit: (values) => {
      let hasEmptyField = false
      let hasInvalidEmail = false

      values?.pointOfContact?.forEach((fields: any) => {
        if (!fields.firstName || !fields.lastName || !fields.emailAddress || !fields.contactNumber || !fields.remarks) {
          hasEmptyField = true
        }

        if (fields.emailAddress && !emailRegex.test(fields.emailAddress)) {
          hasInvalidEmail = true
        }
        if (hasEmptyField || hasInvalidEmail) {
          return
        }
      })

      if (hasEmptyField && !pointOfContact.length) {
        toast.error('Please fill in all the fields of Point of Contacts')
      } else if (hasInvalidEmail) {
        toast.error('Please enter a valid email address for all contacts')
      } else {
        const newValues = {
          customerType: values?.customerType,
          name: values?.name,
          businessEmailAddress: values?.businessEmailAddress ? values?.businessEmailAddress : null,
          primaryContactNumber: values?.primaryContactNumber.toString(),
          secondaryContactNumber: values?.secondaryContactNumber.toString(),
          pointOfContact: values.pointOfContact.map((contact: any) => ({
            ...contact,
            contactNumber: contact.contactNumber?.toString() || '',
          })),
        }
        customerFormik.setFieldValue('BasicDetails', newValues)
        setPage(2)
        if (!id) {
          navigate('../address-details')
        } else {
          navigate(`/contacts/customer-management/${id}/edit/address-details`)
        }
      }
    },
  })

  const [data, setData] = useState([])

  useEffect(() => {
    BasicDetailsFormik.resetForm({
      values: {
        customerType: customerFormik.values.BasicDetails?.customerType || '',
        name: customerFormik.values.BasicDetails?.name || '',
        businessEmailAddress: customerFormik.values.BasicDetails?.businessEmailAddress || '',
        primaryContactNumber: customerFormik.values.BasicDetails?.primaryContactNumber || '',
        secondaryContactNumber: customerFormik.values.BasicDetails?.secondaryContactNumber || '',
        pointOfContact: customerFormik.values.BasicDetails?.pointOfContact || [],
      },
    })
  }, [customerFormik])

  const customerColumns = [
    {
      title: 'First Name',
      accessor: 'firstName',
      editable: true,
      placeholder: 'Enter first name',
    },

    {
      title: 'Last Name',
      accessor: 'lastName',
      editable: true,
      placeholder: 'Enter last name',
    },
    {
      title: 'Contact Number',
      accessor: 'contactNumber',
      editable: true,
      fieldType: 'number',
      placeholder: 'Enter contact number',
    },
    {
      title: 'Email Address',
      accessor: 'emailAddress',
      editable: true,
      placeholder: 'Enter email address',
    },
    {
      title: 'Remarks',
      accessor: 'remarks',
      editable: true,
      placeholder: 'Enter remarks',
    },
  ]

  const pointOfContact = () => {
    const filteredData = data?.filter((contact: any) => Object.values(contact).some((value) => value !== ''))

    BasicDetailsFormik.setFieldValue('pointOfContact', filteredData?.length > 0 ? filteredData : [])
    BasicDetailsFormik.handleSubmit()
  }

  return (
    <div className="flex h-full flex-col justify-between gap-4">
      <div className="flex flex-col gap-4">
        <span>
          <h4 className="h4-bold-black">Basic Details</h4>
          <p className="text-sm text-primary-gray-4">
            {!id
              ? 'Add the basic details that are required to Add New Customer.'
              : 'Edit the existing Customer details to make necessary changes and updates.'}
          </p>
        </span>
        <div className="flex w-2/5">
          <Field
            label="Customer Type"
            placeholder="Select Customer Type"
            formik={BasicDetailsFormik}
            options={[
              { label: 'Individual', value: 'INDIVIDUAL' },
              { label: 'Business', value: 'BUSINESS' },
            ]}
            defaultValue={{ label: formatString(BasicDetailsFormik?.values?.customerType) } ?? { label: '' }}
            name="customerType"
            type="select"
            required
          />
        </div>
        <p className="border-b border-border-medium pb-2" />
        <div className="grid w-3/4 grid-cols-2 gap-4">
          <Field
            label="Customer Name"
            placeholder="Enter Customer Name"
            formik={BasicDetailsFormik}
            name="name"
            type="text"
            required
          />
          <Field
            label="Business Email Address"
            placeholder="Enter Business Email Address"
            formik={BasicDetailsFormik}
            name="businessEmailAddress"
            type="text"
          />
          <Field
            type="phonenumber"
            label="Primary Contact Number"
            placeholder="Enter Contact Number"
            formik={BasicDetailsFormik}
            name="primaryContactNumber"
            required
          />

          <Field
            label="Secondary Contact Number"
            placeholder="Enter Contact Number"
            formik={BasicDetailsFormik}
            name="secondaryContactNumber"
            type="phonenumber"
          />
        </div>
        <div className="border "></div>
        <h2 className="h4-bold-black">Point Of Contacts</h2>

        <NewEditableTable
          defaultRows={BasicDetailsFormik?.values?.pointOfContact}
          columns={customerColumns}
          addButtonTitle="Add New Contact"
          onChange={(values: any) => {
            setData(values)
          }}
        />
      </div>
      <div className="flex justify-end">
        <span className="flex gap-3">
          {' '}
          <Button onClick={() => navigate('/contacts/customer-management')}>Cancel</Button>
          <Button type="button" color="success" onClick={pointOfContact}>
            Next
          </Button>
        </span>
      </div>
    </div>
  )
}

export default BasicDetails
