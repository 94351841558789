import TopNavigation from 'navigation/top-navigation/TopNavigation'
import RightSideNav from 'navigation/right-side-nav/RightSideNav'
import { useLocation, useNavigate } from 'react-router-dom'
import { setNavActive } from 'store/redux/navSlice'
import { useDispatch } from 'react-redux'
import { useEffect, useRef } from 'react'
import { useRefreshTokenMutation } from 'libs/layouts/auth/login/auth.endpoints'
import Cookies from 'js-cookie'
import { getPages } from './routes/paths'
import useTabFocus from 'libs/hooks/useTabFocus'
import { getAuthTokenAndRole } from 'utils/CheckAuth'
import { handleLogout } from 'libs/layouts/auth/login/modal/LogoutModal'
import PageTracker from 'libs/application-insights/PageTracker'

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { role, refresh_token, username, end_time } = getAuthTokenAndRole()
  const tabHasFocus = useTabFocus()
  const previousPath = useRef(location.pathname)

  getPages()

  if (location.pathname === '/' || location.pathname === '/cameras') {
    dispatch(setNavActive())
  }

  useEffect(() => {
    const handleNavigation = () => {
      let pathToNavigate = previousPath.current

      // Regex to match paths where the last segment
      const match = pathToNavigate.match(/^(\/.*)\/([^\/]*([0-9]{4,}|%)[^\/]*)$/)
      if (match) {
        pathToNavigate = match[1]
      }
      if (location.pathname === '/' && (role === 'supervisor' || role === 'admin')) {
        navigate('/dashboard')
      } else if (location.pathname === '/' && role == 'operator') {
        navigate('/productionV2')
      }
    }

    handleNavigation()
    previousPath.current = location.pathname
  }, [location.pathname, navigate])

  useEffect(() => {
    if (!role) navigate('/login')
  }, [tabHasFocus])

  const [addRefreshToken] = useRefreshTokenMutation()

  const handleRefreshToken = async () => {
    addRefreshToken({
      username: username,
      refresh_token: refresh_token,
    })
      .unwrap()
      .then((response) => {
        const expires = Cookies.get('expires_in')
        const cookieOptions = {
          expires: expires ? expires : undefined,
        }

        Cookies.set('access_token', response.access_token, cookieOptions)
      })
      .catch(() => {
        handleLogout()
      })
  }

  const refreshSessionTokens = async () => {
    const currentTime = Math.floor(Date.now() / 1000)
    const remainingTime = end_time - currentTime

    if (remainingTime < 300) {
      await handleRefreshToken()
    }
  }

  setInterval(
    () => {
      refreshSessionTokens()
    },
    1 * 60 * 1000,
  )

  return (
    <div>
      <PageTracker />
      <TopNavigation />
      <RightSideNav />
    </div>
  )
}

export default App
