import { AnimatePresence, motion } from 'framer-motion'
import Loader from 'libs/loader/customloader/Loader'
import React, { useEffect } from 'react'
import { dropdownMotionProps } from 'utils/FramerProps'

/**
 * Button component with customizable color, size, and children and it also takes default button props like type , onClick etc
 * @param {Object} props - The component props.
 * @param {"primary"|"success"|"error"} [props.color] - The background color of the button. Defaults to "gray" if not specified.
 * @param {"sm"} [props.size] - The size of the button. "sm" for small size default it will be "md".
 * @param {boolean} [props.loading] - boolean value that make button loading effect default value is false
 * @param {React.ReactNode} [props.startIcon] - it is the icon thats come before the label
 * @param {React.ReactNode} props.children - JSX element that will render button label
 * @returns {JSX.Element} The rendered button component.
 */

const Button: React.FC<ButtonProps> = ({
  options,
  color,
  size = 'md',
  startIcon,
  children,
  loading = false,
  isOpen,
  closeDropdown,
  onOptionSelect,
  disabled,
  onClick,
  ...btnprops
}) => {
  let className =
    'text-nowrap min-w-[96px]	 flex items-center justify-center gap-[6px] py-[6px] px-[12px] rounded-[5px] overflow-hidden  '

  // Add color-specific classes
  switch (color) {
    case 'primary':
      className += ' bg-primary font-bold text-white '
      break
    case 'success':
      className += ' bg-success font-bold text-white '
      break
    case 'error':
      className += ' bg-error font-bold text-white '
      break
    case 'outlined':
      className += ' bg-primary-200 text-primary border border-primary '
      break
    default:
      className += ' bg-gray-100 text-closebtn '
      break
  }

  if (disabled || loading) {
    className += ' opacity-40 cursor-default '
  }

  // Add size-specific class
  className += size === 'sm' ? ' text-xs ' : ' text-[14px] '

  const handleSelectOption = (option: Option) => {
    if (onOptionSelect) {
      onOptionSelect(option)
      closeDropdown && closeDropdown()
    }
  }
  // closing the dropdown on outside click
  const handleOutsideClick = (event: any) => {
    if (!event.target.closest('#dropdown')) {
      closeDropdown && closeDropdown()
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <>
      <div className="relative" id="dropdown">
        <button
          className={className}
          onClick={(e) => {
            if (disabled) return
            onClick && onClick(e)
          }}
          {...btnprops}
        >
          {startIcon}
          {children}
          {loading && <Loader size={size} color={color === 'outlined' || color == null ? 'primary' : 'default'} />}
        </button>

        {/* dropdown */}
        <AnimatePresence>
          {options && isOpen && (
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={dropdownMotionProps}
              className="relative mt-[2px] flex w-[232px] rounded-md bg-white "
              style={{
                position: 'absolute',
                right: '2px',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
                backgroundColor: 'primary-white',
                zIndex: '10',
              }}
            >
              <div className="w-full p-3">
                {options.map((option) => (
                  <div
                    key={option.key}
                    className="flex cursor-pointer  rounded p-[6px] hover:bg-gray-100"
                    onClick={() => handleSelectOption(option)}
                  >
                    <span className="mr-2 ">{option.icon}</span>
                    <span className="p-xs-regular">{option.label}</span>
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}

interface Option {
  key: string
  label: string
  icon: React.ReactNode
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'success' | 'error' | 'outlined'
  size?: 'sm' | 'md'
  options?: Option[]
  children: React.ReactNode
  startIcon?: React.ReactNode
  loading?: boolean
  isOpen?: boolean
  closeDropdown?: () => void
  onOptionSelect?: (option: Option) => void
}

export default Button
