import { useState } from 'react'
function Pen({ color, disabled }: { color?: string; disabled?: boolean }) {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  const fillColor = disabled ? color || '#75838D' : isHovered ? '#1193F0' : color || '#75838D'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      opacity={disabled ? 0.5 : 1}
    >
      <mask
        id="mask0_1005_3430"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H18V18H0z"></path>
      </mask>
      <g mask="url(#mask0_1005_3430)">
        <path
          fill={fillColor}
          d="M3.75 14.25H4.8l6.469-6.469-1.05-1.05L3.75 13.2v1.05zm10.725-7.556l-3.188-3.15 1.05-1.05a1.44 1.44 0 011.06-.432c.419 0 .772.144 1.06.432l1.05 1.05c.287.287.437.634.45 1.04.012.407-.126.754-.413 1.041l-1.069 1.069zM3 15.75a.726.726 0 01-.534-.216A.726.726 0 012.25 15v-2.119a.776.776 0 01.225-.543L10.2 4.613 13.388 7.8l-7.726 7.725a.75.75 0 01-.543.225H3z"
        ></path>
      </g>
    </svg>
  )
}

export default Pen
