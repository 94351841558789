// timeUtils.ts
import { t } from 'i18next'
export function FormatTimeDifference(updatedOn: number): string {
  const currentTimestamp: number = Math.floor(new Date().getTime() / 1000)
  const timeDifferenceInSeconds: number = currentTimestamp - updatedOn

  if (timeDifferenceInSeconds < 60 && timeDifferenceInSeconds >= 0) {
    return `${timeDifferenceInSeconds} ${timeDifferenceInSeconds !== 1 ? t('seconds') : t('second')} ${t('ago')}`
  } else if (timeDifferenceInSeconds < 3600) {
    const minutes: number = Math.floor(timeDifferenceInSeconds / 60)
    return `${minutes} ${minutes !== 1 ? t('minutes') : t('minute')} ${t('ago')}`
  } else if (timeDifferenceInSeconds < 86400) {
    const hours: number = Math.floor(timeDifferenceInSeconds / 3600)
    return `${hours} ${hours !== 1 ? t('hours') : t('hour')} ${t('ago')}`
  } else if (timeDifferenceInSeconds < 604800) {
    const days: number = Math.floor(timeDifferenceInSeconds / 86400)
    return `${days} ${days !== 1 ? t('days') : t('day')} ${t('ago')}`
  } else if (timeDifferenceInSeconds < 2628000) {
    const weeks: number = Math.floor(timeDifferenceInSeconds / 604800)
    return `${weeks} ${weeks !== 1 ? t('weeks') : t('week')} ${t('ago')}`
  } else if (timeDifferenceInSeconds < 31536000) {
    const months: number = Math.floor(timeDifferenceInSeconds / 2628000)
    return `${months} ${months !== 1 ? t('months') : t('month')} ${t('ago')}`
  } else {
    const years: number = Math.floor(timeDifferenceInSeconds / 31536000)
    return `${years} ${years !== 1 ? t('years') : t('year')} ${t('ago')}`
  }
}
