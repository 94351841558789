import React from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  category: string[]
  updatedBetween: number[]
  createdBetween: number[]
  status: string[]
}
interface FormValues {
  category: string[]
  status: string[]
  updatedBetween: number[]
  createdBetween: number[]
}

const FilterPurchaseOrder: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const initialValues: FormValues = {
    category: filters.category ?? [],
    status: filters.status ?? [],
    createdBetween: filters?.createdBetween?.length == 0 ? [] : filters?.createdBetween,
    updatedBetween: filters?.updatedBetween?.length == 0 ? [] : filters?.updatedBetween,
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      category: [],
      status: [],
      updatedBetween: [],
      createdBetween: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })

    closePanel()
  }

  const filterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        createdBetween: values.createdBetween == null ? [] : values.createdBetween,
        updatedBetween: values.updatedBetween == null ? [] : values.updatedBetween,
        category: values.category,
        status: values.status,
      }))
    },
  })

  const category = [
    {
      name: 'Finished Goods',
      value: 'FINISHED_GOOD',
      _id: '101',
    },
    {
      name: 'Raw Material',
      value: 'RAW_MATERIAL',
      _id: '102',
    },
  ]

  const categoryOptions = category.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  const verificaton = [
    {
      name: 'In Progress',
      value: 'IN_PROGRESS',
      _id: '101',
    },
    {
      name: 'Not Started',
      value: 'NOT_STARTED',
      _id: '102',
    },
    {
      name: 'Completed',
      value: 'COMPLETED',
      _id: '104',
    },
    {
      name: 'Cancelled',
      value: 'CANCELLED',
      _id: '105',
    },
  ]

  const verificatonOptions = verificaton.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="Category"
          placeholder="Select category"
          multiSelectPlaceholderTexts={['Category', 'category']}
          options={categoryOptions}
          formik={filterFormik}
          name="category"
        />
        {/* Status */}
        <Field
          type="multiselect"
          label="Status"
          placeholder="Select Status"
          options={verificatonOptions}
          formik={filterFormik}
          name="status"
        />
        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Date Created"
          formik={filterFormik}
          name="createdBetween"
        />
        {/* Update Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Last Edited"
          formik={filterFormik}
          name="updatedBetween"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterPurchaseOrder
