import React, { Dispatch, SetStateAction } from 'react'
import Cookies from 'js-cookie'
import Popup from 'libs/popup/Popup'
import LogoutPopUP from './LogoutPopUP'

interface LogoutModalProps {
  showLogout: boolean
  setShowLogout: Dispatch<SetStateAction<boolean>>
}

export const handleLogout = async () => {
  Cookies.remove('access_token')
  Cookies.remove('refresh_token')
  Cookies.remove('role')
  Cookies.remove('username')
  Cookies.remove('start_time')
  Cookies.remove('end_time')
  Cookies.remove('expires_in')
  // dispatch(logout())
  localStorage.removeItem('plantId')
  // Set a flag in localStorage indicating user has logged out
  localStorage.setItem('logout', Date.now().toString())
  // Broadcast the logout event to other tabs
  window.dispatchEvent(new Event('storage'))
  setTimeout(() => {
    window.location.reload()
  }, 1000)
}

const LogoutModal: React.FC<LogoutModalProps> = ({ showLogout, setShowLogout }) => {
  return (
    <Popup
      isOpen={showLogout}
      title="Confirm Logout"
      onClose={() => {
        setShowLogout(false)
      }}
    >
      <LogoutPopUP
        closePopUp={() => {
          setShowLogout(false)
        }}
        logout={handleLogout}
      />
    </Popup>
  )
}

export default LogoutModal
