import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Keyboard from 'assets/icons/shift-management/Keyboard'
import Clock from 'assets/icons/shift-management/Clock'
import Button from 'libs/button/Button'

interface Props {
  onClose: () => void
  formik: any
  index: number
  field: string
}

const TimePicker = ({ onClose, formik, index, field }: Props) => {
  const [hour, setHour] = useState(7)
  const [minute, setMinute] = useState(0)
  const [period, setPeriod] = useState('AM')
  const [inputHour, setInputHour] = useState(hour.toString().padStart(2, '0')) // Always 3 digits
  const [inputMinute, setInputMinute] = useState(minute.toString().padStart(2, '0'))
  const [isManualEntry, setIsManualEntry] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const handleHourChange = (newHour: string) => {
    const numericHour = Number(newHour)
    if (numericHour >= 1 && numericHour <= 12) {
      setHour(numericHour)
      setInputHour(numericHour.toString().padStart(2, '0')) // Pad the hour to 2 digits
    } else {
      setInputHour('') // Clear the field if the input is invalid
    }
  }

  const handleMinuteChange = (e: any) => {
    const newMinute = Number(e.target.value)
    if (newMinute >= 0 && newMinute < 60) {
      setMinute(newMinute)
      setInputMinute(newMinute.toString().padStart(2, '0'))
    }
  }

  const togglePeriod = () => {
    setPeriod(period === 'AM' ? 'PM' : 'AM')
  }

  const calculateDuration = (start: number, end: number) => {
    if (end < start) {
      end += 24 * 60 * 60 * 1000 // Add 24 hours if time crosses midnight
    }
    const durationMs = Math.abs(end - start)
    const hours = Math.floor(durationMs / (1000 * 60 * 60))
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60))
    return `${hours}h ${minutes}m`
  }

  const switchToManualEntry = () => {
    setIsAnimating(true)
    setTimeout(() => {
      setIsManualEntry(true)
      setIsAnimating(false)
    }, 50)
  }

  const switchToClockView = () => {
    setIsAnimating(true)
    setTimeout(() => {
      setIsManualEntry(false)
      setIsAnimating(false)
    }, 50)
  }

  const getTimeStamp = () => {
    const now = new Date()
    const hoursIn24 = period === 'AM' ? hour % 12 : (hour % 12) + 12
    now.setHours(hoursIn24, minute, 0, 0)
    return now.getTime()
  }

  const formatTime = () => {
    return `${inputHour}:${inputMinute} ${period}`
  }

  const handleSave = () => {
    const timestamp = getTimeStamp()
    const formattedTime = formatTime()

    formik.setFieldValue(`shifts.${index}.${field + 'ing'}`, timestamp)
    formik.setFieldValue(`shifts.${index}.${field}`, formattedTime)
    const updatedShift = {
      ...formik.values.shifts[index],
      [field]: formattedTime,
      [`${field}ing`]: timestamp,
    }

    const startTime = updatedShift?.startTimeing || null
    const endTime = updatedShift?.endTimeing || null

    if (startTime && endTime) {
      const duration = calculateDuration(startTime, endTime)
      formik.setFieldValue(`shifts.${index}.duration`, duration)
    }
    onClose()
  }

  // Handle backspace in the hour input
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && inputHour.length === 1) {
      setInputHour('') // Clear the hour if it's a single digit and backspace is pressed
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div>
        <h2 className="mb-4 text-sm">{isManualEntry ? 'Enter Time' : 'Select time'}</h2>
        <div className="mb-4 flex items-center justify-between">
          <div className="ml-6 flex">
            <input
              type="number"
              value={inputHour}
              onChange={(e) => handleHourChange(e.target.value)}
              onKeyDown={handleKeyDown} // Handle backspace key
              className="focus: h-16 w-16  rounded-lg bg-primary text-center text-4xl text-white outline-none"
              min="1"
              max="12"
              disabled={isAnimating}
            />
            <div className="mx-1 my-2 text-4xl">:</div>
            <input
              type="number"
              value={inputMinute}
              onChange={handleMinuteChange}
              className="h-16 w-16  rounded-lg bg-gray-350 text-center text-4xl text-black outline-none"
              min="0"
              max="59"
              disabled={isAnimating}
            />
          </div>

          <div className="ml-4 flex flex-col ">
            <button
              onClick={togglePeriod}
              className={`rounded-t-lg p-1 text-lg font-semibold ${period === 'AM' ? 'bg-primary text-white' : 'bg-gray-350 text-black'}`}
            >
              AM
            </button>
            <button
              onClick={togglePeriod}
              className={`rounded-b-lg p-1 text-lg font-semibold ${period === 'PM' ? 'bg-primary text-white' : 'bg-gray-350 text-black'}`}
            >
              PM
            </button>
          </div>
        </div>
        <AnimatePresence>
          {!isManualEntry && (
            <motion.div
              className="relative mx-auto flex h-56 w-56 items-center justify-center rounded-full bg-blue-50"
              initial={{ opacity: 1, height: 0 }}
              animate={{ opacity: 1, height: '14rem' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div
                className="absolute"
                style={{
                  transform: `rotate(${(hour % 12) * 30}deg)`,
                  height: '30%',
                  width: '2px',
                  alignItems: 'center',
                  backgroundColor: '#1193F0',
                  transformOrigin: 'bottom center',
                  top: '20%',
                }}
              />
              {[...Array(12).keys()].map((i) => {
                const hourNumber = i + 1
                const isSelected = hourNumber === hour
                return (
                  <div
                    key={i}
                    className={`absolute flex items-center justify-center ${
                      isSelected ? 'text-white' : 'text-black'
                    } cursor-pointer`}
                    style={{
                      transform: `rotate(${i * 30 + 30}deg) translate(0, -5.5rem) rotate(-${i * 30 + 30}deg)`,
                      width: '3rem',
                      height: '3rem',
                      borderRadius: '50%',
                      backgroundColor: isSelected ? '#1193F0' : 'transparent',
                    }}
                    onClick={() => handleHourChange(hourNumber.toString())}
                  >
                    {hourNumber}
                  </div>
                )
              })}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className="flex w-full items-center justify-between p-2">
        <button onClick={isManualEntry ? switchToClockView : switchToManualEntry}>
          {isManualEntry ? (
            <div
              className={`h-10 w-10 active:rounded-full active:bg-blue-500  ${!isManualEntry ? 'text-blue-600' : 'text-gray-400'}`}
            >
              <Clock />
            </div>
          ) : (
            <div
              className={`h-10 w-10 active:rounded-full active:bg-blue-500   ${isManualEntry ? 'text-blue-600' : 'text-gray-400'}`}
            >
              <Keyboard />
            </div>
          )}
        </button>
        <div className="flex items-center justify-center gap-2">
          <Button size="sm" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button color="primary" size="sm" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TimePicker
