import { useState } from 'react'
import { FormatTimeDifference } from 'utils/FormatTimeDifference'
import { caseSensitiveSort } from 'utils/SortingColumn'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGetAllProcessesQuery } from './operational-process-endpoints/operational_process.endpoints'
import Panel from 'libs/panel'
import ViewMoreCard from './component/ViewMoreCard'
import MotionDiv from 'libs/motionDiv'
import FilterOperationalProcess from './panel/FilterOperationalProcess'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import useTabTitle from 'libs/hooks/useTabTitle'

const additionalFilters = {
  createdBetween: [],
  updatedBetween: [],
}

const OperationalProcesses = () => {
  const { t } = useTranslation()
  useTabTitle(t('operational_processes'))
  const navigate = useNavigate()
  const [selectedOperationalProcess, setSelectedOperationalProcess] = useState(null)

  const [viewMorePanel, setViewMorePanel] = useState(false)
  const handleViewMorePanel = (e: any, operationalProcess: any) => {
    e.stopPropagation()
    setSelectedOperationalProcess(operationalProcess)
    setViewMorePanel(true)
  }

  const columns = [
    {
      Header: <p>{t('process_name')}</p>,
      accessor: 'name',
      sortType: caseSensitiveSort,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/settings/configure/operationalprocesses/editdetails/${data?._id}`)
            }}
          >
            {data?.name}
          </div>
        )
      },
    },
    {
      Header: <p>{t('input_products')}</p>,
      accessor: 'input_products',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        let value = row?.original?.input_products
        let displayProducts = value?.slice(0, 4).map((product: any) => product.product.name)
        const remainingCount = value?.length - displayProducts?.length

        return displayProducts?.length > 0 ? (
          <div className="flex flex-wrap">
            {displayProducts?.map((productName: any, index: any) => (
              <span key={index} className={`m-1 rounded-[28px] bg-warning-100 px-2 py-0.5 text-warning-900`}>
                {productName}
              </span>
            ))}
            <div>
              {remainingCount > 0 && (
                <button
                  onClick={(e) => handleViewMorePanel(e, row?.original)}
                  className="ml-4 mt-1 text-primary-700 underline underline-offset-2 hover:font-semibold"
                >
                  +{remainingCount} more
                </button>
              )}
            </div>
          </div>
        ) : (
          <div>-</div>
        )
      },
    },
    {
      Header: <p>{t('output_products')}</p>,
      accessor: 'output_products',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        let value = row?.original?.output_products
        let displayProducts = value?.slice(0, 4).map((product: any) => product.product.name)
        const remainingCount = value?.length - displayProducts?.length
        return displayProducts?.length > 0 ? (
          <div className="flex flex-wrap">
            {displayProducts?.map((productName: any, index: any) => (
              <span
                key={index}
                className={` m-1 rounded-[28px] bg-brand-primary-200 px-2 py-0.5 text-brand-primary-shade-3 p-xs-regular`}
              >
                {productName}
              </span>
            ))}
            <div>
              {remainingCount > 0 && (
                <button
                  onClick={(e) => handleViewMorePanel(e, row?.original)}
                  className="ml-4 mt-1 text-primary-700 underline underline-offset-2 hover:font-semibold"
                >
                  +{remainingCount} more
                </button>
              )}
            </div>
          </div>
        ) : (
          <div>-</div>
        )
      },
    },
    {
      Header: <p>{t('department')}</p>,
      accessor: 'department.name',
      Cell: ({ value }: any) => <div>{value}</div>,
    },

    {
      Header: <p>{t('last_updated')}</p>,
      accessor: 'updated_on',
      Cell: ({ value }: any) => {
        const formattedDate = FormatTimeDifference(value)
        return formattedDate
      },
    },
  ]

  const handleAddOperationalProcess = () => {
    navigate('details')
  }

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black">{t('operational_processes')}</h1>
        <div className="flex justify-between">
          <p className="w-3/4 p-sm-regular-2">
            {t(
              "set_up_and_oversee_processes_that_aren't_related_to_manufacturing_Operational_tasks_like_accounting_general_maintenance_and_checklists_are_examples_of_these_processes_which_are_separate_from_core_manufacturing_activities",
            )}
          </p>
        </div>
        <FilterableSearchTable
          useQuery={useGetAllProcessesQuery}
          columns={columns}
          addButton={{ label: t('add_process'), onClick: handleAddOperationalProcess }}
          placeholders={{
            emptyMessage: t('no_process_found_please_add_process_into_the_system'),
            filterEmptyMessage: 'No matching results found',
            search: 'Search by Process Name',
          }}
          filterPanelComponent={FilterOperationalProcess}
          additionalFilters={additionalFilters}
        />
        <Panel isOpen={viewMorePanel} title={t('view_operational_process')} onClose={() => setViewMorePanel(false)}>
          <ViewMoreCard selectedOperationalProcess={selectedOperationalProcess} />
        </Panel>
      </>
    </MotionDiv>
  )
}

export default OperationalProcesses
