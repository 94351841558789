import Inventory from 'assets/icons/dashboard/Inventory'
import Production from 'assets/icons/dashboard/Production'
import Review from 'assets/icons/dashboard/Review'
import Planning from 'assets/icons/dashboard/Planning'
import Dashboard from 'assets/icons/dashboard/Dashboard'
import Order from 'assets/icons/dashboard/Order'
import Employee from 'assets/icons/dashboard/Employee'
import Contact from 'assets/icons/dashboard/Contact'

const menuData = [
  {
    title: 'dashboard',
    icon: <Dashboard color={undefined} />,
    path: '/dashboard',
    value: true,
    role: ['supervisor', 'admin'],
  },
  {
    title: 'production',
    icon: <Production color={undefined} />,
    path: '/productionV2',
    value: true,
    role: ['operator', 'supervisor', 'admin'],
  },
  {
    title: 'review',
    path: '/review',
    icon: <Review color={undefined} />,
    value: true,
    role: ['supervisor', 'admin'],
  },
  {
    title: 'planning',
    path: '/planning',
    icon: <Planning color={undefined} />,
    value: true,
    role: ['supervisor', 'admin'],
  },
  {
    title: 'inventory',
    icon: <Inventory color={undefined} />,
    path: '/inventory',
    value: true,
    role: ['supervisor', 'admin'],
  },
  {
    title: 'Orders',
    icon: <Order color={undefined} />,
    path: '/orders',
    value: true,
    role: ['supervisor', 'admin'],
  },
  {
    title: 'Employee',
    icon: <Employee />,
    path: '/employee',
    value: true,
    role: ['supervisor', 'admin'],
  },
  {
    title: 'Contacts',
    icon: <Contact />,
    path: '/contacts',
    value: true,
    role: ['supervisor', 'admin'],
  },
]

export default menuData
