import { FormikContextType, useFormik } from 'formik'
import Button from 'libs/button/Button'
import NewEditableTable from 'libs/table/NewEditableTable'

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AddressData } from '../../customer-endpoints/customerTypes'

import { toast } from 'libs/toast'
interface AddressDetailsProps {
  customerFormik: FormikContextType<any>
  setPage: React.Dispatch<React.SetStateAction<number>>
  loading: boolean
}

interface AddressFormValues {
  addresses: AddressData[]
}

export const combineAddressTypes = (addresses: AddressData[]): AddressData[] => {
  const combinedAddresses: AddressData[] = []
  const shippingAddresses: { [key: string]: AddressData } = {}
  const billingAddresses: { [key: string]: AddressData } = {}

  addresses?.forEach((address) => {
    if (address.addressType === 'SHIPPING' || address.addressType === 'BILLING') {
      const key = `${address.addressLine1}_${address.addressLine2}_${address.city}_${address.state}_${address.country}_${address.zipCode}`
      if (address.addressType === 'SHIPPING') {
        if (!shippingAddresses[key]) {
          shippingAddresses[key] = address
        }
      } else {
        if (!billingAddresses[key]) {
          billingAddresses[key] = address
        }
      }
    } else {
      combinedAddresses.push(address)
    }
  })

  Object.keys(shippingAddresses).forEach((key) => {
    if (billingAddresses[key]) {
      const combinedAddress: AddressData = {
        ...shippingAddresses[key],
        addressType: 'BOTH',
      }
      combinedAddresses.push(combinedAddress)
      delete billingAddresses[key]
    } else {
      combinedAddresses.push(shippingAddresses[key])
    }
  })

  combinedAddresses.push(...Object.values(billingAddresses))

  return combinedAddresses
}
export const splitAddressTypes = (addresses: AddressData[]) => {
  const result: AddressData[] = []
  addresses?.forEach((address: AddressData) => {
    if (address.addressType === 'BOTH') {
      // Create two new objects for "SHIPPING" and "BILLING"
      const shippingAddress = { ...address, addressType: 'SHIPPING' }
      const billingAddress = { ...address, addressType: 'BILLING' }
      result.push(shippingAddress, billingAddress)
    } else {
      result.push(address)
    }
  })

  return result
}

const AddressDetails: React.FC<AddressDetailsProps> = ({ customerFormik, setPage, loading }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const AddressDetailsFormik = useFormik<AddressFormValues>({
    initialValues: {
      addresses: [
        {
          addressType: '',
          addressLine1: '',
          addressLine2: '',
          country: '',
          state: '',

          city: '',
          zipCode: '',
          isPrimary: false,
        },
      ],
    },
    onSubmit: (values: AddressFormValues) => {
      let isEmpty = true
      if (values?.addresses.length == 0) {
        isEmpty = false
      }

      values?.addresses?.forEach((address: any) => {
        if (
          !address.city ||
          !address.addressLine1 ||
          !address.addressLine2 ||
          !address.zipCode ||
          !address.addressType ||
          !address.country ||
          !address.state
        ) {
          isEmpty = false
        }
      })

      if (isEmpty) {
        customerFormik.setFieldValue('AddressDetails', { addresses: splitAddressTypes(values?.addresses) })
        customerFormik.handleSubmit()
      } else {
        toast.error('Please fill in all the address fields of billing addresses')
      }
    },
  })

  useEffect(() => {
    AddressDetailsFormik.resetForm({
      values: {
        addresses: combineAddressTypes(customerFormik?.values?.AddressDetails?.addresses),
      },
    })
  }, [customerFormik])

  const addressOptions = [
    {
      id: 'BILLING',
      value: 'BILLING',
      label: 'Billing',
    },
    {
      id: 'SHIPPING',
      value: 'SHIPPING',
      label: 'Shipping',
    },
    {
      id: 'BOTH',
      value: 'BOTH',
      label: 'Both',
    },
  ]

  const addressDetails = [
    {
      title: 'Address Type',
      accessor: 'addressType',
      options: addressOptions,
      fieldType: 'select',
      flex: 2,
      editable: true,
      placeholder: 'select address type',
    },

    {
      title: 'Address line 1',
      accessor: 'addressLine1',
      editable: true,
      placeholder: 'Enter address',
    },
    {
      title: 'Address line 2',
      accessor: 'addressLine2',
      editable: true,
      placeholder: 'Enter address',
    },
    {
      title: 'Country',
      accessor: 'country',

      editable: true,
      placeholder: 'Enter country',
    },
    {
      title: 'State',
      accessor: 'state',

      editable: true,
      placeholder: 'Enter state',
    },
    {
      title: 'City',
      accessor: 'city',

      editable: true,
      placeholder: 'Enter city',
    },
    {
      title: 'Zip Code',
      accessor: 'zipCode',
      editable: true,
      placeholder: 'Enter zip code',
    },
    {
      title: 'Primary',
      accessor: 'isPrimary',
      editable: true,
      fieldType: 'radio',
    },
  ]

  const [data, setData] = useState([])

  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     data.forEach((item: any) => {
  //       if (item.country?.code) {
  //         setSelectedCountry(item.country.code.toLowerCase())
  //       }
  //       if (item.state?.code) {
  //         setSelectedState(item.state.code.toLowerCase())
  //       }
  //     })
  //   }
  // }, [data])

  const handelAddress = () => {
    AddressDetailsFormik?.setFieldValue('addresses', data ? data : [])
    AddressDetailsFormik.handleSubmit()
  }

  return (
    <div className="flex h-full flex-col justify-between gap-4 ">
      <div>
        <span>
          <h4 className="h4-bold-black">Address Details</h4>
          <p className="text-sm text-primary-gray-4">
            {!id
              ? 'Add the address details that are required to add new customer.'
              : 'Edit the existing Customer details to make necessary changes and updates.'}
          </p>
        </span>
        <div className="mt-4">
          <NewEditableTable
            defaultRows={AddressDetailsFormik?.values?.addresses}
            columns={addressDetails}
            addButtonTitle="Add Address"
            onChange={(values: any) => {
              setData(values)
            }}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <Button
          onClick={() => {
            setPage(1)
            navigate('../basic-details')
          }}
        >
          Previous
        </Button>
        <span className="flex gap-3">
          {' '}
          <Button onClick={() => navigate('/contacts/customer-management')}>Cancel</Button>
          {!id ? (
            <Button
              color="success"
              loading={loading}
              onClick={() => {
                handelAddress()
              }}
            >
              Add
            </Button>
          ) : (
            <Button
              color="success"
              loading={loading}
              onClick={() => {
                handelAddress()
              }}
            >
              Save
            </Button>
          )}
        </span>
      </div>
    </div>
  )
}

export default AddressDetails
