import { useState, useRef, useEffect } from 'react'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { dropdownMotionProps } from 'utils/FramerProps'

import { useTranslation } from 'react-i18next'
import { convertFormula } from '../utils'

const CalculateField = ({ onFormDataChange, errors, setErrors, CalculateFieldData, numberFields }: any) => {
  const { t } = useTranslation()
  // const { data, isLoading, refetch } = useGetCalculateFieldQuery({
  //   processId: processId,
  //   fieldType: JSON.stringify(['Calculated field', 'Number input']),
  // })

  const [shouldRoundOff, setShouldRoundOff] = useState(CalculateFieldData?.should_round_off || false)
  const [override, setOverride] = useState(CalculateFieldData?.can_override || false)
  const [selectedTitle, setSelectedTitle] = useState(CalculateFieldData?.formula || '')
  const [hasUnit, setHasUnit] = useState(CalculateFieldData?.has_unit || false)
  const [formData, setFormData] = useState({
    formula: selectedTitle || '',
    round_off_decimals_to: CalculateFieldData?.round_off_decimals_to || 0,
    unit: CalculateFieldData?.unit || '',
    can_override: override,
    should_round_off: shouldRoundOff,
    has_unit: hasUnit,
    help_text: CalculateFieldData?.help_text || '',
  })
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const handleHasNumberChange = () => {
    const newValue = !shouldRoundOff
    setShouldRoundOff(newValue)
    setFormData((prevData) => ({
      ...prevData,
      should_round_off: newValue,
      round_off_decimals_to: newValue ? prevData.round_off_decimals_to : '',
    }))
    onFormDataChange({
      ...formData,
      should_round_off: newValue, // Pass updated value to onFormDataChange
    })
    if (!newValue) {
      setErrors({ ...errors, round_off_decimals_to: '' })
    }
  }

  const handleOverrideChange = () => {
    const newValue = !override
    setOverride(newValue)
    setFormData((prevData) => ({
      ...prevData,
      can_override: newValue,
    }))
    onFormDataChange({
      ...formData,
      can_override: newValue,
    })
  }

  const handleHasUnitChange = () => {
    const newValue = !hasUnit
    setHasUnit(newValue)
    setFormData((prevData) => ({
      ...prevData,
      has_unit: newValue,
      unit: newValue ? prevData.unit : '',
    }))
    onFormDataChange({
      ...formData,
      has_unit: newValue,
    })
    if (!newValue) {
      setErrors({ ...errors, unit: '' })
    }
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: '',
    }))
    onFormDataChange(formData)
  }

  const popupRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Check if the click is outside the popup
      if (popupRef.current && !event.target.closest('.ignoreref')) {
        setIsDropdownVisible(false)
      }
    }

    // Adding event listener to detect clicks outside the popups
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleInputChange1 = (event: any) => {
    const { name, value } = event.target
    setSelectedTitle(value)
    let braceCount = 0
    let foundOpenCurly = false

    for (let i = 0; i < value.length; i++) {
      if (value[i] === '{') {
        braceCount++
        if (braceCount === 1) {
          foundOpenCurly = true
        }
      } else if (value[i] === '}') {
        braceCount--
        if (braceCount === 0 && foundOpenCurly) {
          foundOpenCurly = false
        }
      }
    }
    // set formula field
    setFormData((prevData) => ({
      ...prevData,
      [name]: convertFormula(value, numberFields),
    }))

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: '',
    }))

    // Check if curly brackets are present
    if (foundOpenCurly) {
      setIsDropdownVisible(true)
    } else {
      setIsDropdownVisible(false)
    }
  }

  const handleTitleClick = (title: any) => {
    const updatedTitle = `${selectedTitle.substring(0, selectedTitle.lastIndexOf('{') + 1)}${title}}`
    setSelectedTitle(updatedTitle)
    setIsDropdownVisible(false)
    setFormData((prevData) => ({
      ...prevData,
      formula: convertFormula(updatedTitle, numberFields),
      formula_value: updatedTitle,
    }))
  }
  useEffect(() => {
    onFormDataChange(formData)
  }, [selectedTitle, formData])

  return (
    <>
      <div>
        <div className="relative mt-5">
          <label className={`mb-1 text-[#000] p-sm-regular-400`}>
            {t('formula')} <span className="text-error">*</span>
          </label>
          <p className={`w-[340px] p-xs-regular-dim `}>
            {t(
              'type_in_the_formula_for_this_field_to_reference_other_fields_start_typing_within_curly_brackets_and_we_will_show_the_existing_fields',
            )}
          </p>
          <input
            required
            name="formula"
            type="text"
            autoComplete="off"
            className={`ignoreref h-[28px] w-full border ${
              isDropdownVisible ? 'bg-white' : 'bg-gray-100'
            } mt-2 cursor-pointer rounded-md border-solid p-2 pr-8 text-sm outline-none p-xs-regular`}
            placeholder={t('select')}
            onChange={handleInputChange1}
            value={selectedTitle}
            id="formulaInput"
          />
          <div className="absolute right-[1rem] top-[5.8rem]">
            <ArrowDown color={''} />
          </div>
          {errors.formula && <p className="w-[360px] pt-1 text-xs font-normal text-error">{errors.formula}</p>}
          <AnimatePresence>
            {isDropdownVisible && (
              <motion.div
                initial="closed"
                animate="open"
                exit="closed"
                variants={dropdownMotionProps}
                ref={popupRef}
                className="scroll-bar absolute max-h-[12rem] min-h-[20px] w-[360px] overflow-auto rounded bg-white "
                style={{
                  zIndex: '3',
                  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
                }}
              >
                {numberFields
                  ?.filter((item: any) => item && item.type === 'Number input' && item.id !== CalculateFieldData?.id)
                  .map((item: any) => (
                    <div
                      key={item?.id}
                      className={`cursor-pointer border-b border-[#EBEFF3] px-3 py-2`}
                      onClick={() => handleTitleClick(item?.name)}
                    >
                      <div className="p-xs-regular">{item?.name}</div>
                    </div>
                  ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div>
        <div className="mt-6 flex gap-24">
          <div>
            <div className="p-sm-regular">{t('round_off')}</div>
            <p className="p-xs-regular-dim">
              {t('enable_this_if_you_want_the_value_to_round_off_to_certain_decimals')}
            </p>
          </div>
          <div>
            <label className="switch">
              <input type="checkbox" checked={shouldRoundOff} onChange={handleHasNumberChange} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        {shouldRoundOff && (
          <div className="mt-5">
            <label className="mb-1 p-sm-regular ">
              {t('round_off_decimals_to')}
              <span className="text-error">*</span>
            </label>
            <div>
              <p className="p-xs-regular-dim">{t('guide_the_person_filling_this_field')}</p>
              <div className="relative py-1">
                <input
                  type="text"
                  name="round_off_decimals_to"
                  value={formData.round_off_decimals_to}
                  onChange={handleInputChange}
                  className={`h-[28px] w-full border ${
                    errors.prefixText ? 'border-error' : 'border-border'
                  } rounded-md border border-solid bg-gray-100 p-2 pr-8 text-sm outline-none p-xs-regular`}
                  placeholder={t('enter_value')}
                />
                <div className="absolute right-[1px] top-[5px] flex h-[26px] items-center justify-center rounded bg-[#434C52] px-1 text-[#F8FCFF] p-xs-regular-dim1">
                  {t('digits')}
                </div>
                {errors.number && <p className="pt-1 text-xs font-normal text-error">{errors.number}</p>}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="mt-6 flex gap-28">
          <div>
            <div className="p-sm-regular">Allow Override</div>
            <p className="p-xs-regular-dim">{t('enable_this_if_people_will_need_to_adjust_the_value')}</p>
          </div>
          <div>
            <label className="switch">
              <input type="checkbox" checked={override} onChange={handleOverrideChange} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div className="mt-6 flex justify-between gap-28">
        <div>
          <div className="p-sm-regular">{t('has_units')}</div>
          <p className="p-xs-regular-dim">{t('enable_this_if_the_input_has_units')}</p>
        </div>
        <div>
          <label className="switch">
            <input type="checkbox" checked={hasUnit} onChange={handleHasUnitChange} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      {hasUnit && (
        <div className="mt-5">
          <label className="mb-1 p-sm-regular ">
            {t('units_of_measure')}
            <span className="text-error">*</span>
          </label>
          <div>
            <div className="relative py-1">
              <input
                type="text"
                name="unit"
                value={formData.unit}
                onChange={handleInputChange}
                className={`h-[28px] w-full border ${
                  errors.unit ? 'border-error' : 'border-border'
                } rounded-md border border-solid bg-gray-100 p-2 pr-8 text-sm outline-none p-xs-regular`}
                placeholder={t('enter_units_of_measure')}
              />
              {errors.unit && <p className="pt-1 text-xs font-normal text-error">{errors.unit}</p>}
            </div>
          </div>
        </div>
      )}
      <div className="mt-5">
        <label className="mb-1 p-sm-regular ">{t('help_text')}</label>
        <div>
          <p className="p-xs-regular-dim">{t('guide_the_person_filling_this_field')}</p>
          <div className="relative py-1">
            <input
              type="text"
              name="help_text"
              value={formData.help_text}
              onChange={handleInputChange}
              className={`h-[28px] w-full rounded-md border border-solid border-border bg-gray-100 p-2 pr-8 text-sm outline-none p-xs-regular`}
              placeholder={t('enter_help_text')}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CalculateField
