import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { filters } from '../customer-endpoints/customerTypes'

interface Props {
  closePanel: () => void
  setFilters: any
  filters: filters
}

const CustomerFilterPanel = ({ closePanel, filters, setFilters }: Props) => {
  const initialValues = {
    customerType: filters.customerType ?? [],
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      customerType: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })
    closePanel()
  }

  const filterFormik = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        customerType: values?.customerType,
      }))
    },
  })

 

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="Customer Type"
          placeholder="Customer Type"
          options={[
            { label: 'Individual', value: 'INDIVIDUAL' },
            { label: 'Business', value: 'BUSINESS' },
          ]}
          formik={filterFormik}
          name="customerType"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset Filters
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            type="submit"
            color="primary"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default CustomerFilterPanel
