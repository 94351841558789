import { t } from 'i18next'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import MotionDiv from 'libs/motionDiv'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import EditableTable from 'libs/table/EditableTable'
import {
  useGetPurchaseOrdersQuery,
  usePostPurchaseOrderMutation,
  usePutPurchaseOrderMutation,
} from '../purchase-orders-endpoints/purchaseOrders.endpoints'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import WhiteCard from 'libs/card/WhiteCard'
import { toast } from 'libs/toast'
import { useEffect, useState } from 'react'
import FullPageLoader from 'libs/loader/FullPageLoader'
import * as Yup from 'yup'
import Popup from 'libs/popup/Popup'
import PurchaseSavePopup from 'pages/manufacture-orders/saved-changes-popup/PurchaseSavePopup'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useGetVendorsQuery } from 'pages/contacts/vendor-management/vendor-endpoints/vendor.endpoints'
import Panel from 'libs/panel'
import QuickAddEditVendor from 'libs/quick-add-edits/quick-add-edit-vendor/QuickAddEditVendor'

type productsFormik = {
  products: string[]
}

const AddEditPurchaseOrder = () => {
  const plantId = useSelector(selectSelectedItem)
  const [savedChangesPopUp, setSavedChangesPopUp] = useState({ isPopUpOpen: false })
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const { id: paramsId } = useParams()
  const location = useLocation()
  // Access the state object
  const { from } = location.state || {}
  const navigate = useNavigate()
  const filters = {
    poID: paramsId,
    page_no: 1,
    page_size: 10,
    sortDir: 'DESC',
  }
  const { data: vendors } = useGetVendorsQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    { skip: !plantId?.id },
  )

  const vendorsDataOption = vendors?.data?.map((vendor: any) => ({
    _id: vendor._id,
    label: vendor.name,
    sublabel: vendor.primaryContactNumber,
    value: vendor?._id,
  }))
  const { data: purchaseOrder, isLoading: purchaseOrderLoading } = useGetPurchaseOrdersQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    {
      skip: !plantId?.id || !paramsId,
    },
  )
  const [addPurchaseOrder, { isLoading: addPurchaseOrderLoading }] = usePostPurchaseOrderMutation()
  const [updatePurchaseOrder, { isLoading: updatePurchaseOrderLoading }] = usePutPurchaseOrderMutation()
  const purchaseOrderFormik = useFormik({
    initialValues: {
      purchaseOrderID: '',
      status: '',
      vendorID: '',
      arrivalDate: null,
      description: '',
    },
    validationSchema: Yup.object().shape({
      purchaseOrderID: Yup.string().required('Purchase Order ID is required'),
      status: Yup.string().required('Status is required'),
      vendorID: Yup.string().required('Supplier Name is required'),
      arrivalDate: Yup.string().required('Arrival Date is required'),
    }),
    onSubmit: (values) => {
      const product =
        productsFormik?.values?.products
          ?.filter((product: any) => product?.product?._id)
          ?.map((product: any) => ({
            product_id: product?.product?._id,
            quantity: product?.quantity == null ? 0 : product?.quantity,
          })) || []
      const payload = {
        vendorID: values.vendorID,
        purchaseOrderID: values.purchaseOrderID,
        arrivalDate: values.arrivalDate,
        status: values.status,
        description: values.description,
        products: product,
      }
      if (!paramsId) {
        addPurchaseOrder({ plant_id: plantId?.id, data: payload })
          .unwrap()
          .then(() => {
            toast.success('Purchase order - ' + payload.purchaseOrderID + ' created successfully')
            if (from) {
              setSavedChangesPopUp({ isPopUpOpen: true })
            } else {
              navigate(-1)
            }
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong while creating the sales order')
          })
      } else {
        updatePurchaseOrder({ plant_id: plantId.id, poID: paramsId, data: payload })
          .unwrap()
          .then(() => {
            toast.success('Purchase order - ' + payload.purchaseOrderID + ' updated successfully')
            if (from) {
              setSavedChangesPopUp({ isPopUpOpen: true })
            } else {
              navigate(-1)
            }
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong while updating the sales order')
          })
      }
    },
  })
  const productfilters = {
    page_no: 1,
    page_size: 1000,
    sortBy: 'updated_on',
    sortDir: 'DESC',
    searchText: '',
  }

  const { data: products, isLoading: productLoading } = useGetProductQuery(
    {
      plantId: plantId?.id,
      filters: productfilters,
    },
    {
      skip: !plantId?.id,
    },
  )

  const productOpitons = products?.data
    ?.filter((item: any) => item.category === 'Raw Materials')
    .map((item: any) => ({
      label: item.name,
      value: item,
    }))

  const newProductRow = {
    product: null,
    quantity: null,
    unit_of_measure: null,
  }
  const productsFormik = useFormik<productsFormik>({
    initialValues: {
      products: [],
    },
    onSubmit: () => {},
  })

  const productColumns = [
    {
      title: 'Raw Material',
      flex: 2,
      content: {
        main: true,
        uniquePropertyInOption: 'name',
        options: productOpitons,
        name: 'product',
        placeholder: 'Select Product',
        type: 'select',
      },
    },
    {
      title: 'Quantity',
      align: 'right',
      hideRightBorder: true,
      content: {
        placeholder: '0',
        type: 'number',
        name: 'quantity',
      },
    },

    {
      title: 'UOM',
      flex: 1,
      content: {
        placeholder: 'Unit',
        type: 'text',
        name: 'unit_of_measure',
        readOnly: true,
      },
    },
  ]
  let statusOptions = [
    { label: 'Not Started', value: 'NOT_STARTED' },
    { label: 'In Progress', value: 'IN_PROGRESS' },
    { label: 'Completed', value: 'COMPLETED' },
    { label: 'Cancelled', value: 'CANCELLED' },
  ]

  // filter out canceeled status for add work order
  if (!paramsId) {
    statusOptions = statusOptions.filter((option) => option.value != 'CANCELLED')
  }

  // updating sales order
  useEffect(() => {
    if (purchaseOrder) {
      const totalItemsCount = purchaseOrder?.paging?.total_items_count
      const dataLength = purchaseOrder?.data?.length
      if (totalItemsCount <= 0 || dataLength === 0) {
        toast.error('Something went wrong, Please try again.')
        return
      }
      const purchaseOrderData: any = purchaseOrder?.data?.[0]

      if (purchaseOrderData) {
        purchaseOrderFormik.resetForm({
          values: {
            purchaseOrderID: purchaseOrderData.purchaseOrderID,
            status: purchaseOrderData.status,
            arrivalDate: purchaseOrderData?.arrivalDate,
            description: purchaseOrderData?.description,
            vendorID: purchaseOrderData.vendor?._id,
          },
        })
        productsFormik.resetForm({
          values: {
            products: purchaseOrderData.products.map((product: any) => ({
              product: { _id: product?._id, name: product?.name },
              unit_of_measure: product?.unit_of_measure,
              quantity: product?.quantity,
            })),
          },
        })
      }
    }
  }, [purchaseOrder])

  useTabTitle(paramsId ? 'Edit Purchase Order' : 'Add Purchase Order')

  const openPanel = () => {
    setTimeout(() => {
      setIsPanelOpen(!isPanelOpen)
    }, 300)
  }

  return (
    <MotionDiv>
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="h4-bold-black ">{paramsId ? 'Edit Purchase Order' : 'Add Purchase Order'}</h1>
          </div>
        </div>
        {purchaseOrderLoading ? (
          <FullPageLoader />
        ) : (
          <WhiteCard className="mt-3 flex flex-col gap-6">
            <span>
              <h5 className="h5">Purchase Order Details</h5>
              <p className="p-sm-regular-1">
                {paramsId
                  ? 'Edit the existing Purchase Order details to make necessary changes and updates.'
                  : 'Add the basic details that are required to create new Purchase Order.'}
              </p>
            </span>
            <div className=" flex flex-col gap-6">
              {/* --- process name and department */}
              <div className="grid  grid-cols-2 gap-x-4 gap-y-2 sm:w-full lg:w-10/12">
                <Field
                  type="text"
                  required={true}
                  label="Purchase Order ID"
                  formik={purchaseOrderFormik}
                  name="purchaseOrderID"
                  placeholder="Enter Purchase Order ID"
                />
                <Field
                  type="select"
                  required={true}
                  label="Status"
                  formik={purchaseOrderFormik}
                  options={statusOptions}
                  name="status"
                  defaultValue={statusOptions?.find((option) => option?.value === purchaseOrderFormik?.values?.status)}
                  placeholder="Enter Status"
                />

                <Field
                  type="select"
                  required={true}
                  label="Supplier Name"
                  // loading={vendorsLoading}
                  formik={purchaseOrderFormik}
                  options={vendorsDataOption}
                  defaultValue={vendorsDataOption?.find(
                    (option) => option?._id === purchaseOrderFormik?.values?.vendorID,
                  )}
                  name="vendorID"
                  placeholder="Enter Supplier Name"
                  title={'Add Supplier'}
                  onAdd={openPanel}
                />
                <Field
                  type="date"
                  required={true}
                  label="Arrival Date"
                  shouldDisablePastDate
                  formik={purchaseOrderFormik}
                  name="arrivalDate"
                />
                <Field
                  type="text"
                  label="Description"
                  formik={purchaseOrderFormik}
                  name="description"
                  placeholder="Enter Description"
                />
              </div>

              {/* ---- border ----- */}
              <hr></hr>
              {/* ---- border ----- */}

              {/* editable - product */}
              <EditableTable
                formik={productsFormik as any}
                heading={'Raw Materials'}
                onSelect={(product: any, index: number) => {
                  productsFormik.setFieldValue(`products.${index}.unit_of_measure`, product.unit_of_measure)
                  productsFormik.setFieldValue(`products.${index}.quantity`, null)
                }}
                loading={productLoading}
                columns={productColumns}
                emptyMessage="+ Click on the Add Product button to add a new product"
                newRow={newProductRow}
                name="products"
                addButtonTitle="Add Product"
              />
              <div className={`flex h-[4rem] items-center justify-end gap-2 bg-white px-6`}>
                <Button data-testid="cancel-button" onClick={() => navigate(-1)}>
                  {t('cancel')}
                </Button>
                <Button
                  color="success"
                  loading={addPurchaseOrderLoading || updatePurchaseOrderLoading}
                  onClick={() => purchaseOrderFormik.handleSubmit()}
                >
                  {paramsId ? 'Save' : 'Add'}
                </Button>
              </div>
            </div>
            {/* Saved Changes Popup */}
            <Popup
              isOpen={savedChangesPopUp.isPopUpOpen}
              title="Saved Changes"
              onClose={() => {
                setSavedChangesPopUp({ isPopUpOpen: false })
              }}
            >
              <PurchaseSavePopup />
            </Popup>
            <Panel size="large" title="Add Vendor" isOpen={isPanelOpen} onClose={() => setIsPanelOpen(false)}>
              <QuickAddEditVendor close={() => setIsPanelOpen(false)} />
            </Panel>
          </WhiteCard>
        )}
      </>
    </MotionDiv>
  )
}

export default AddEditPurchaseOrder
