import Chip from 'libs/chip/Chip'
import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const ViewAddressDetails = ({ vendorsData, setPage }: any) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const Vendor = vendorsData
  const columns = [
    {
      Header: 'Address line 1',
      accessor: 'addressLine1',
      Cell: ({ row }: any) => (
        <>
          {row.values.addressLine1}

          {row.original.isPrimary ? (
            <div className="ml-2">
              <Chip title="PRIMARY" />{' '}
            </div>
          ) : null}
        </>
      ),
    },

    {
      Header: 'Address line 2',
      accessor: 'addressLine2',
      disableSortBy: true,
    },
    {
      Header: 'City',
      accessor: 'city',
      disableSortBy: true,
      
      Cell: ({ value }: any) => {
        return <span>{value}</span>
      },
    },
    {
      Header: 'State',
      accessor: 'state',
      disableSortBy: true,
      Cell: ({ value }: any) => {
        return <span>{value}</span>
      },
    },
    {
      Header: 'Zip Code',
      accessor: 'zipCode',
      disableSortBy: true,
    },
    {
      Header: 'Country',
      disableSortBy: true,
      accessor: 'country',
      Cell: ({ value }: any) => {
        return <span>{value}</span>
      },
    },
  ]

  useEffect(() => {
    setPage(2)
  }, [])

  return (
    <MotionDiv>
      <WhiteCard className=" flex min-h-[70vh] flex-col gap-6">
        <div className="flex w-full justify-between">
          <h5 className="h5">Address Details</h5>
          <div>
            <Button
              color="primary"
              onClick={() => {
                navigate(`/contacts/vendor-management/${id}/edit/address-details`)
              }}
            >
              Edit Address Details
            </Button>
          </div>
        </div>

        <PaginatedTable columns={columns} rows={Vendor?.billingAddresses} hidePagination={true} emptyMessage={'No Address details have been added yet. Please add new ones'}/>
      </WhiteCard>
    </MotionDiv>
  )
}

export default ViewAddressDetails
