import Field from 'libs/field'
import { useEffect, useRef } from 'react'
import { t } from 'i18next'
import InlineLoader from 'libs/loader/customloader/Loader'
import { NumberFormat } from 'utils/NumberFormat'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetAllSalesOrdersQuery } from 'pages/order/sales-order/sales-order-endpoints/salesOrders.endpoints'

const ShipmentForm = ({
  selectedProduct,
  formik,
  unit,
  transaction,
  quantity,
  storageAreaFromOptions,
  warehouseFromOptions,
}: any) => {
  const plantId = useSelector(selectSelectedItem)
  const filters = {
    page_no: 1,
    page_size: 1000,
    sortDir: 'DESC',
    productID:selectedProduct?._id
  }
  const { data: salesOrdersData } = useGetAllSalesOrdersQuery(
    {
      plantId: plantId?._id,
      filters: filters
    },
    { skip: !plantId?._id || !selectedProduct},
  )
  
  

  
  const salesOrders = salesOrdersData?.data?.map((salesOrder: any) => ({
    _id: salesOrder._id,
    value :salesOrder._id,
    label : salesOrder.salesOrderID,
 
  })) || [];
  const warehouseFrom =
    transaction?.warehouseFrom?.id === formik?.values?.warehouse_from?._id
      ? { label: transaction?.warehouseFrom?.name, value: transaction?.warehouseFrom?.id }
      : { label: '', value: '' }

  const storageAreaFrom =
    transaction?.warehouseFrom?.id === formik?.values?.warehouse_from?._id
      ? { label: transaction?.storageAreaFrom?.name, value: transaction?.storageAreaFrom?.id }
      : { label: '', value: '' }

  const isInitialRenderFrom = useRef(true)

  useEffect(() => {
    if (isInitialRenderFrom.current) {
      isInitialRenderFrom.current = false
    } else {
      formik.setFieldValue('storagearea_from', null)
    }
  }, [formik?.values?.warehouse_from, selectedProduct?._id])

  return (
    <div className="grid w-2/3 grid-cols-2 gap-2 gap-x-4">
      <Field
        label={t('inventory_.sales_order_number')}
        name="sales_order_nu"
        type="select"
        options={salesOrders}
        defaultValue={salesOrders?.find((option) => option?._id === formik?.values?.sales_order_nu)}
        required={true}
        placeholder={t('inventory_.select')}
        formik={formik}
      />
      <Field
        label={t('inventory_.warehouse_from')}
        disabled={!selectedProduct}
        name="warehouse_from"
        type="select"
        required={true}
        placeholder={t('inventory_.select')}
        formik={formik}
        defaultValue={
          warehouseFrom.value == formik?.values.warehouse_from?._id
            ? warehouseFrom
            : formik?.values.warehouse_from === null
              ? ''
              : formik?.values.warehouse_from?.name
        }
        options={warehouseFromOptions}
      />
      <Field
        label={t('inventory_.storage_area_from')}
        name="storagearea_from"
        disabled={!formik?.values?.warehouse_from}
        type="select"
        required={true}
        placeholder={t('inventory_.select')}
        formik={formik}
        defaultValue={
          storageAreaFrom.value == formik?.values.storagearea_from?._id
            ? storageAreaFrom
            : formik?.values.storagearea_from === null
              ? ''
              : formik?.values.storagearea_from?.name
        }
        description={t('inventory_.storage_area_desc')}
        options={storageAreaFromOptions}
      />
      <div>
        <Field
          label={t('inventory_.quantity')}
          name="quantity"
          suffix={unit}
          type="number"
          required={true}
          disabled={!formik?.values.storagearea_from?._id}
          placeholder={t('inventory_.quantity_placeholder') + ' shipped'}
          formik={formik}
        />
        {quantity.isFetching || quantity.isLoading ? (
          <InlineLoader color="primary" />
        ) : (
          quantity.data != null && (
            <span className="flex items-center gap-1 text-base font-bold">
              <p>Available Quantity : </p>
              <p>{NumberFormat(quantity.data)}</p>
            </span>
          )
        )}
      </div>
    </div>
  )
}

export default ShipmentForm
