import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import Popup from 'libs/popup/Popup'
import { useState } from 'react'
import DeletePopup from './DeletePopup'

const ActionColumn = ({ onEdit, rows, formik, getReturnableRow, row, rowIndex }: any) => {
  const handleDelete = (index: number) => {
    const updatedRows = rows?.filter((_: any, i: number) => i !== index)
    formik.setFieldValue('formikRows', [...updatedRows])
  }
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  return (
    <>
      <div className={`flex h-[50px] flex-row items-center justify-start `}>
        {onEdit && (
          <div className="flex h-8 w-8  items-center justify-center rounded-3xl duration-300 ease-in-out hover:bg-primary-200">
            <button
              className="px-2"
              type="button"
              onClick={() => {
                onEdit && onEdit(getReturnableRow(row))
              }}
            >
              <Pen />
            </button>
          </div>
        )}

        <div className="flex  h-8 w-8  items-center justify-center rounded-3xl duration-300 ease-in-out hover:bg-error-100">
          <button className="px-2" type="button" onClick={() => setIsPopupOpen(true)}>
            <Bin />
          </button>
        </div>
        <Popup
          isOpen={isPopupOpen}
          title="Remove?"
          onClose={() => {
            setIsPopupOpen(false)
          }}
        >
          <DeletePopup
            closePopUp={() => {
              setIsPopupOpen(false)
            }}
            handleDelete={handleDelete}
            rowIndex={rowIndex}
          />
        </Popup>
      </div>
    </>
  )
}

export default ActionColumn
