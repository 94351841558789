import { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Field from 'libs/field'
import MotionDiv from 'libs/motionDiv'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import StorageView from '../panel/StorageView'
import Panel from 'libs/panel'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import EditStorage from '../panel/StorageEdit'
import DeleteStorage from '../popup/StorageDelete'
import { useNavigate, useParams } from 'react-router-dom'
import {
  usePostWarehouseMutation,
  usePostStorageAreaMutation,
  useDeleteStorageAreaMutation,
  useEditWarehouseMutation,
  useGetWarehouseByIdQuery,
} from '../warehouses-endpoints/warehouses.endpoints'
import { toast } from 'libs/toast'
import Popup from 'libs/popup/Popup'
import Button from 'libs/button/Button'
import { t } from 'i18next'
import WhiteCard from 'libs/card/WhiteCard'
import { NumberFormat } from 'utils/NumberFormat'
import useTabTitle from 'libs/hooks/useTabTitle'
import { trimStringValues } from 'utils/trimStringValues'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'

export type WarehouseFormik = {
  name: string
  storage_areas: {
    id: string
    name: string
    products: {
      name: string
      code: string
      _id: string
      category: string
      quantity?: string
    }[]
  }[]
}

interface Product {
  _id: string
  name: string
  code: string
  category: string
}

const AddEditWarehouses = () => {
  const { id } = useParams()
  const selectedItem = useSelector(selectSelectedItem)
  const [addWarehouse, { isLoading: loadingWarehouse }] = usePostWarehouseMutation()
  const [editWarehouse, { isLoading: loadingEditWarehouse }] = useEditWarehouseMutation()
  const { data: wareHouseData } = useGetWarehouseByIdQuery(
    {
      warehouse_id: id,
      plant_id: selectedItem?.id,
    },
    {
      skip: !selectedItem.id || !id,
    },
  )
  useTabTitle(`${id ? t('warehouse.edit_warehouse') : t('warehouse.add_warehouse')}`)

  const [deleteStorage, { isLoading: storageDeleteLoading }] = useDeleteStorageAreaMutation()
  const [addStorage, { isLoading: storageLoading }] = usePostStorageAreaMutation()
  const navigate = useNavigate()

  const filters = {
    page_no: 1,
    page_size: 1000,
    sortBy: 'created_on',
    sortDir: 'DESC',
    searchText: '',
  }

  const { data: productsObj } = useGetProductQuery({
    plantId: selectedItem?.id,
    filters: filters,
  })
  const productOpitons = productsObj?.data.map((item: any) => ({
    label: item.name,
    value: item,
  }))

  const [key, setKey] = useState(0)
  const [viewPanel, setViewPanel] = useState<any>(false)
  const [storageArea, setSelectedStorageArea] = useState<any>(null)
  const [editPanel, setEditPanel] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false)

  const warehouseFormik = useFormik<WarehouseFormik>({
    initialValues: {
      name: '',
      storage_areas: [],
    },
    validationSchema: yup.object({
      name: yup.string().required(t('warehouse.warehouse_error')),
    }),
    onSubmit: (values) => {
      if (values.storage_areas.length > 0) {
        const payload = {
          name: values.name,
          storage_areas: values.storage_areas.map((area) => ({
            name: area.name.trim(),
            products: area.products.map((product) => ({
              _id: product._id,
            })),
          })),
        }

        const data = trimStringValues({ name: values.name })

        if (id) {
          editWarehouse({
            data: data,
            plant_id: selectedItem?.id,
            warehouse_id: id,
          })
            .unwrap()
            .then(() => {
              toast.success(`The Warehouse “${values.name}” is updated successfully.`)
              navigate('/settings/configure/warehouses')
            })
            .catch((err: { data: { detail: any } }) => {
              toast.error(err?.data?.detail ?? 'Something went wrong. Please try again.')
            })
        } else {
          const data = trimStringValues(payload)

          addWarehouse({
            data: data,
            plant_id: selectedItem?.id,
          })
            .unwrap()
            .then(() => {
              toast.success(`The Warehouse “${values.name}” is added successfully.`)
              navigate('/settings/configure/warehouses')
            })
            .catch((err) => {
              toast.error(err?.data?.detail ?? 'Something went wrong. Please try again.')
            })
        }
      } else {
        toast.error('Please add at least one storage area')
      }
    },
  })

  useEffect(() => {
    if (id) {
      warehouseFormik.resetForm({
        values: {
          name: wareHouseData?.name,
          storage_areas: wareHouseData?.storage_areas?.map((area: any) => ({
            id: area._id ? area._id : '',
            name: area?.name,
            products: area?.products.map((product: any) => {
              return {
                _id: product._id,
                name: product.name,
                code: product.code,
                category: product.category,
                quantity: product.quantity,
              }
            }),
          })),
        },
      })
    }
  }, [wareHouseData])

  const storageAreaFormik = useFormik({
    initialValues: {
      id: '',
      name: '',
      products: [],
    },
    validationSchema: yup.object({
      name: yup.string().required(t('warehouse.storage_area_error')),
      products: yup.array().min(1, t('warehouse.product_error')),
    }),
    onSubmit: (values) => {
      if (!id) {
        const newStorageAreaName = values.name.trim()
        if (warehouseFormik.values.storage_areas.some((storage) => storage.name === newStorageAreaName)) {
          toast.error('Storage area name already exists.')
          return
        }
        const storageAreas = warehouseFormik.values.storage_areas
        storageAreas.push(values)

        warehouseFormik.setFieldValue('storage_areas', storageAreas)
        setKey((prevKey) => prevKey + 1)
        storageAreaFormik.resetForm()
      } else {
        const payload = {
          name: values.name,
          products: values?.products?.map((product: Product) => ({
            _id: product?._id,
          })),
        }

        const data = trimStringValues(payload)

        addStorage({
          data: data,
          plant_id: selectedItem?.id,
          wareHouse_id: id,
        })
          .unwrap()
          .then(() => {
            toast.success(`The Storage Area “${values.name}” is added successfully.`)
            storageAreaFormik.resetForm()
          })
          .catch((err) => {
            toast.error(err?.data?.detail ?? 'Something went wrong. Please try again.')
          })
      }
    },
  })

  const columns = useMemo(
    () => [
      {
        Header: t('warehouse.storage_area_name'),
        accessor: 'name',
        width: 200,
      },
      {
        Header: t('warehouse.allow_products_name'),
        width: 250,
        accessor: 'products',
        Cell: ({ value }: any) => {
          let products = value?.slice(0, 4)
          const remainingCount = value?.length - products?.length

          return products?.length > 0 ? (
            <div className="flex flex-wrap">
              {products?.map((product: any, index: any) => (
                <span key={index} className={`m-1 rounded-[28px] bg-primary-200 px-2 py-0.5 text-primary-700`}>
                  {product?.name ?? '-'} ({NumberFormat(product?.quantity)})
                </span>
              ))}
              <div>
                {remainingCount > 0 && (
                  <div
                    className="ml-4 mt-1 text-primary-700 underline underline-offset-2 hover:font-semibold"
                    // onClick={() => toggleViewBomPanel(row?.original)}
                  >
                    +{NumberFormat(remainingCount)} {t('warehouse.more')}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>-</div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'action',
        width: 20,
        align: 'center',
        Cell: ({ row }: any) => (
          <div className="flex gap-2">
            <div className="flex  gap-1">
              <button
                onClick={(event) => {
                  event.stopPropagation()
                  toggleEditPanel(row?.original)
                }}
              >
                <Pen />
              </button>
            </div>
            <div className="flex  gap-1">
              <button
                onClick={(event) => {
                  event.stopPropagation()
                  toggleDelete(row?.original)
                }}
              >
                <Bin />
              </button>
            </div>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  const toggleEditPanel = (entryData: any) => {
    setSelectedStorageArea(entryData)
    setEditPanel(!editPanel)
  }
  const toggleDelete = (entryData: any) => {
    setSelectedStorageArea(entryData)
    setDeletePopup(!deletePopup)
  }
  const toggleViewPanel = (entryData: any) => {
    setSelectedStorageArea(entryData)
    setViewPanel(!viewPanel)
  }

  const toggleDeleteEdit = () => {
    setDeletePopup(!deletePopup)
  }
  const handleDelete = (storage_name: any, storage_id: any) => {
    if (!id) {
      const storageAreas = warehouseFormik.values.storage_areas
      const updatedStorage = storageAreas.filter((storage) => storage.name !== storage_name)
      warehouseFormik.setFieldValue('storage_areas', updatedStorage)
      setKey((prevKey) => prevKey + 1)
      setDeletePopup(false)
      setEditPanel(false)
    } else {
      deleteStorage({
        wareHouse_id: id,
        plant_id: selectedItem?.id,
        storage_id: storage_id,
      })
        .unwrap()
        .then(() => {
          toast.success(`The Storage Area“${storageArea?.name}” is deleted successfully.`)
          setDeletePopup(false)
          setEditPanel(false)
        })
        .catch((err) => {
          toast.error(err?.data?.detail ?? 'Something went wrong. Please try again.')
        })
    }
  }

  return (
    <MotionDiv>
      <>
        <h1 className="mb-2 h4-bold-black ">{id ? t('warehouse.edit_warehouse') : t('warehouse.new_warehouse')}</h1>
        <WhiteCard className="gap-4">
          <div>
            <h1 className="h5">{t('warehouse.warehouse_details')}</h1>
            <p className="p-sm-regular-1">{id ? t('warehouse.add_description') : t('warehouse.edit_description')}</p>
          </div>
          <Field
            required={true}
            label={t('warehouse.warehouse_name')}
            formik={warehouseFormik}
            name="name"
            placeholder={t('warehouse.warehouse_name_placeholder')}
            width="300"
          />
          <hr></hr>
          <div className="flex items-end gap-3">
            <Field
              required={true}
              label={t('warehouse.storage_area')}
              formik={storageAreaFormik}
              name="name"
              placeholder={t('warehouse.storage_area_placeholder')}
              width="300"
            />
            <Field
              required={true}
              type="multiselect"
              label={t('warehouse.allowed_products')}
              formik={storageAreaFormik}
              options={productOpitons}
              name="products"
              placeholder={t('warehouse.product_placeholder')}
              width="300"
            />
            <div className="text-sm text-transparent">
              <Button
                color="primary"
                type="submit"
                size="sm"
                loading={storageLoading}
                onClick={() => {
                  storageAreaFormik.handleSubmit()
                }}
              >
                <PlusIcon />
                {t('warehouse.add_storage_area')}
              </Button>
              {storageAreaFormik.touched.products && storageAreaFormik.errors.products ? 'd' : null}
            </div>
          </div>
          <div className="mt-3 w-full flex-col">
            <PaginatedTable
              columns={columns}
              emptyMessage={t('warehouse.table_placeholder')}
              rows={warehouseFormik.values.storage_areas}
              key={key}
              onSingleClick={toggleViewPanel}
              onPageNumberChange={() => {}}
              loading={false}
              hidePagination={true}
            />
          </div>
          <div className="flex h-full justify-end gap-3">
            <Button onClick={() => navigate('/settings/configure/warehouses')}>{t('warehouse.cancel')}</Button>
            <Button
              color="success"
              onClick={() => {
                warehouseFormik.handleSubmit()
              }}
              loading={id ? loadingEditWarehouse : loadingWarehouse}
            >
              {t('warehouse.save')}
            </Button>
          </div>
        </WhiteCard>
        <Panel
          isOpen={viewPanel}
          title={t('warehouse.storage_area')}
          onClose={() => {
            setSelectedStorageArea([])
            setViewPanel(false)
          }}
          size="veryLarge"
        >
          <StorageView data={storageArea} warehouseName={warehouseFormik.values.name} />
        </Panel>
        <Panel
          isOpen={editPanel}
          title={t('warehouse.edit_storage_area')}
          onClose={() => {
            setSelectedStorageArea([])
            setEditPanel(false)
          }}
        >
          <EditStorage
            closePanel={() => {
              setSelectedStorageArea([])
              setEditPanel(false)
            }}
            storageArea={storageArea}
            products={productsObj?.data}
            formik={warehouseFormik}
            setKey={setKey}
            DeletePopUp={toggleDeleteEdit}
          />
        </Panel>
        <Popup
          isOpen={deletePopup}
          title={t('warehouse.delete_storage_area')}
          onClose={() => {
            setSelectedStorageArea([])
            setDeletePopup(false)
          }}
        >
          <DeleteStorage
            closePopUp={toggleDelete}
            data={storageArea}
            onDelete={() => {
              if (storageArea) {
                const storage_id = storageArea?.id
                handleDelete(storageArea?.name, storage_id)
              }
            }}
            isLoading={storageDeleteLoading}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default AddEditWarehouses
