export function trimStringValues(obj: Record<string, any>): Record<string, any> {
  const trimmedObj: Record<string, any> = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]
      trimmedObj[key] = typeof value === 'string' ? value.trim() : value
    }
  }
  return trimmedObj
}

export function formatString(input: string = ''): string {
  // Ensure input is a string
  if (typeof input !== 'string') {
    return ''
  }
  let formatted = input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
  formatted = formatted.replace(/_([a-zA-Z])/g, (_, letter) => ` ${letter.toUpperCase()}`)
  return formatted
}
