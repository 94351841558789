import { useState, useEffect } from 'react'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { t } from 'i18next'
import MotionDiv from 'libs/motionDiv'
import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import Filter from 'assets/icons/camera-page/Filter'
import PaginatedTable from 'libs/table/PaginatedTable'
import SearchBox from 'libs/searchbox/SearchBox'
import { useDebouncedSearch } from 'libs/hooks/useSearch'
import Panel from 'libs/panel'
import DeleteEmployeeDepartment from './popup/DeleteEmployeeDepartment'
import FilterPanel from './panel/FilterPanel'
import { useNavigate } from 'react-router-dom'
// import Popup from 'libs/Popup'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import Popup from 'libs/popup/Popup'

import Chip from 'libs/chip/Chip'
import { FormatTimeDifference } from 'utils/FormatTimeDifference'
import { useGetEmployeeDepartmentQuery } from './employee-departments-endpoints/employeesdepartment.endpoints'
import { EmployeeDepartmentFilters } from './employee-departments-endpoints/employeedepartmentTypes'

const EmployeeDepartmentPage = () => {
  const plant = useSelector(selectSelectedItem)
  const { searchValue, handleSearch, inputValue } = useDebouncedSearch()
  const [isEmployeeDepartmentPopup, setIsEmployeeDepartmentPopup] = useState(false)
  const [employeeDepartmentData, setEmployeeDepartmentData] = useState('')

  const navigate = useNavigate()

  const [filters, setFilters] = useState<EmployeeDepartmentFilters>({
    page_no: 1,
    page_size: 10,
    searchText: '',
    roles: [],
    department: [],
    sortBy: 'created_on',
    sortDir: 'DESC',
  })

  const {
    data,
    isLoading: addLoading,
    isFetching,
  } = useGetEmployeeDepartmentQuery(
    {
      plant_id: plant?.id,
      filters: filters,
    },
    {
      skip: !plant?.id,
    },
  )

  const [isShowFilter, setIsShowFilter] = useState(false)

  const handlePageNumberChange = (newPageSize: number) => {
    const totalItemsCount = data?.paging?.total_items_count
    const currentPage = filters?.page_no
    const startIndex = (currentPage - 1) * filters?.page_size + 1
    const totalPages = totalItemsCount ? Math.ceil(totalItemsCount / newPageSize) : 0
    let newPageNo = Math.ceil(startIndex / newPageSize)
    newPageNo = Math.min(newPageNo, totalPages)
    setFilters((prevFilters) => ({
      ...prevFilters,
      page_no: newPageNo,
      page_size: newPageSize,
    }))
  }

  // ****************************Filter *****************************//

  //  Filter toggle
  const handleFilterToggle = () => {
    setIsShowFilter(!isShowFilter)
  }

  const handleDepartmentData = (page_no: number) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page_no: page_no,
    }))
  }

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page_no: 1,
      searchText: searchValue ? searchValue : '',
    }))
  }, [searchValue])

  const activeFiltersCount = [filters?.department, filters?.roles]?.filter((filter) => {
    if (Array.isArray(filter)) {
      return filter.length > 0
    } else {
      return Boolean(filter) // checks for non-null and non-undefined values
    }
  })?.length

  // handle sort for column
  const handleSort = (columnName: string) => {
    setFilters((prevFilters: EmployeeDepartmentFilters) => {
      const newSortDir = prevFilters?.sortBy === columnName ? (prevFilters?.sortDir === 'ASC' ? 'DESC' : 'ASC') : 'ASC'

      return {
        ...prevFilters,
        sortBy: columnName,
        sortDir: newSortDir,
      }
    })
  }

  //delete mo

  //columns

  const columns = [
    {
      Header: 'Department',
      accessor: 'name',
      Cell: ({ row }: any) => (
        <span
          className="hyperlink"
          onClick={() => {
            navigate(`/employee/employee-department/${row?.original?._id}/view-employee-designation`)
          }}
        >
          {row?.original?.name}
        </span>
      ),
    },

    {
      Header: 'Employees',
      accessor: 'department_counts',
      width: 100,
      Cell: ({ value }: { value: number }) => value || '-',
    },
    {
      Header: 'Designations',
      accessor: 'designations',
      width: 350,
      Cell: ({ value }: { value: { name: string }[] }) => {
        if (!value || value.length === 0) {
          return <div>-</div>
        }
        const employeesRoles = value.slice(0, 4).map((role) => role.name)
        const remainingCount = value.length - employeesRoles.length

        return (
          <div className="flex flex-wrap">
            {employeesRoles.map((role, index) => (
              <span key={index} className="mr-1 rounded-[28px]">
                <Chip title={role} isFormatting={false} />
              </span>
            ))}
            {remainingCount > 0 && (
              <button className="ml-4 mt-1 text-primary-700 underline underline-offset-2 hover:font-semibold">
                +{remainingCount} more
              </button>
            )}
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Last updated',
      accessor: 'updated_on',
      Cell: ({ value }: { value: number }) => {
        const formattedDate = FormatTimeDifference(value)
        return formattedDate
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 10,
      Cell: ({ row }: any) => (
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-row">
            <button
              className="mr-2"
              onClick={(e) => {
                e.stopPropagation()
                navigate(`/employee/employee-department/${row?.original?._id}/edit-employee-department`)
              }}
            >
              <Pen />
            </button>
            <button
              className="mr-2"
              onClick={(event) => {
                event.stopPropagation()
                setIsEmployeeDepartmentPopup(true)
                setEmployeeDepartmentData(row.original)
              }}
            >
              <Bin />
            </button>
          </div>
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate(`/employee/employee-department/${row?.original?._id}/view-employee-designation`)
            }}
          >
            <ClickableRowArrow />
          </span>
        </div>
      ),
      disableSortBy: true,
    },
  ]
  return (
    <MotionDiv>
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="h4-bold-black ">{'Employee Roles'}</h1>
            <p className="w-4/4 p-sm-regular-1">{'Manage all Employee Roles within your company.'}</p>
          </div>
        </div>

        <WhiteCard className="mt-4">
          <div className="flex justify-between gap-4">
            <div className="flex w-full gap-4">
              <Button onClick={handleFilterToggle}>
                <Filter color="#3D4140" />
                {t('filters')} {activeFiltersCount === 0 ? '' : `(${activeFiltersCount})`}
              </Button>
              <SearchBox
                value={inputValue}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search designation and department"
                onClearSearchBox={() => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    searchText: '',
                  }))
                  handleSearch('')
                }}
              />
            </div>

            {/* <div className="relative  flex gap-3 ">
              <ExportButton disabled onOptionSelect={() => {}} loading={false}>
                {t('inventory_.export_data')}
              </ExportButton>

              <ImportButton disabled onOptionSelect={() => {}}>
                {t('inventory_.import_data')}
              </ImportButton>
            </div> */}

            <Button
              startIcon={<PlusIcon />}
              color="primary"
              onClick={() => {
                navigate('/employee/employee-department/add-employee-department')
              }}
            >
              {'Add Department'}
            </Button>
          </div>
          <div className="mt-3">
            <PaginatedTable
              onPageNumberChange={handleDepartmentData}
              columns={columns}
              rows={data?.data}
              currentPageNumber={filters.page_no}
              sorting={{ sortBy: filters.sortBy, sortDir: filters.sortDir }}
              pageSize={filters.page_size}
              onPageSizeChange={handlePageNumberChange}
              // onSingleClick={handleViewMo}
              emptyMessage={`${filters.searchText ? `No Results found for the search "${filters.searchText}" ` : activeFiltersCount > 0 && data?.data?.length === 0 ? 'No department found . Please adjust your filters.' : 'No department are added. Please add department. '}`}
              loading={addLoading || isFetching}
              pagination={data?.paging}
              onColumnSort={handleSort}
            />
          </div>
        </WhiteCard>

        <Panel
          isOpen={isShowFilter}
          title="Filters"
          onClose={() => {
            setIsShowFilter(false)
          }}
        >
          <FilterPanel
            filters={filters}
            setFilters={setFilters}
            closePanel={() => {
              setIsShowFilter(false)
            }}
          />
        </Panel>

        <Popup
          isOpen={isEmployeeDepartmentPopup}
          title="Delete Department?"
          onClose={() => {
            setIsEmployeeDepartmentPopup(false)
          }}
        >
          <DeleteEmployeeDepartment
            closePopUp={() => {
              setIsEmployeeDepartmentPopup(false)
            }}
            data={employeeDepartmentData}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default EmployeeDepartmentPage
