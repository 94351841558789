import { useFormik } from 'formik'
import WhiteCard from 'libs/card/WhiteCard'
import Field from 'libs/field'
import FullPageLoader from 'libs/loader/FullPageLoader'
import MotionDiv from 'libs/motionDiv'
import AccessRights from './AccessRights'
import Button from 'libs/button/Button'
import { useNavigate } from 'react-router-dom'

const AddEditRoles = () => {
  const loading = false
  const paramsId = ''
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      userRole: '',
      plants: [],
      lastUpdated: [],
    },
    onSubmit: (values) => {
      console.log(values)
    },
  })
  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black">Add User Roles</h1>
        {loading ? (
          <FullPageLoader />
        ) : (
          <WhiteCard>
            <span className="px-2">
              <h5 className="h5">User Role Details</h5>
              <p className="p-sm-regular-1">
                {paramsId
                  ? 'Edit the existing Sales Order details to make necessary changes and updates.'
                  : 'Create a new user role with specific permissions'}
              </p>
            </span>
            <div className="grid grid-cols-2 gap-x-4  gap-y-2 px-2 sm:w-full lg:w-10/12">
              <Field
                type="text"
                required={true}
                placeholder="Enter User Role"
                label="User Role"
                formik={formik}
                name="userRole"
              />
              <Field
                type="select"
                required={true}
                placeholder="select"
                label="Applicable Plants"
                formik={formik}
                name="plants"
              />
            </div>
            {/* Border */}
            <div className="my-3 border-[1px] bg-border sm:w-full lg:w-10/12"></div>
            {/* Access Rights */}
            <AccessRights />
            <div className={`flex h-[4rem] items-center justify-end gap-2 bg-white px-6`}>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button
                color="success"
              >
                {paramsId ? 'Save' : 'Add'}
              </Button>
            </div>
          </WhiteCard>
        )}
      </>
    </MotionDiv>
  )
}

export default AddEditRoles
