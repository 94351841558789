import { useMemo, useState } from 'react'
import MotionDiv from 'libs/motionDiv'
import { useNavigate } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import useTabTitle from 'libs/hooks/useTabTitle'
import { formatUnixDate } from 'utils/unixConverter'
import { useGetWorkOrdersQuery } from './work-orders-endpoints/workorders.endpoints'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import FilterWorkOrders from './panel/FilterWorkOrders'
import Popup from 'libs/popup/Popup'
import DeleteWorkOrder from './components/DeleteWorkOrder'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import Chip from 'libs/chip/Chip'
import FilterableSearchTable from '../../libs/table/filterable-search-table/FilterableSearchTable'

const additionalFilters = {
  status: undefined,
  assignedTo: [],
  createdBetween: [],
  updatedBetween: [],
  startBetween: [],
  endBetween: [],
}

const WorkOrders = () => {
  useTabTitle('Work Orders')
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, workOrder: null })

  // For Filter Panel

  const columns = useMemo(
    () => [
      {
        Header: 'Work Order ID',
        accessor: 'workOrderID',
        Cell: ({ row }: any) => {
          const data = row?.original
          return (
            <div
              className="hyperlink"
              onClick={() => {
                navigate(`/planning/work-orders/view/${data?._id}`)
              }}
            >
              {data?.workOrderID}
            </div>
          )
        },
      },
      {
        Header: 'Operations',
        accessor: 'operations',
        Cell: ({ row }: any) => {
          const data = row?.original
          let operationCount =
            row?.original.operations?.length == 1 && !row?.original.operations[0]._id
              ? 0
              : row?.original.operations?.length
          return (
            <div
              className="hyperlink"
              onClick={() => {
                navigate(`/planning/work-orders/view/${data?._id}`)
              }}
            >
              {operationCount} Operations
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: ({ value }: any) => formatUnixDate(value),
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        Cell: ({ value }: any) => formatUnixDate(value),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <Chip title={value} />,
        disableSortBy: true,
      },
      {
        Header: 'Assigned To',
        accessor: 'assignedTo',
        Cell: ({ row }: any) => {
          const data = row?.original
          return (
            <>
              {data?.assignedTo?.length > 0 ? (
                <div
                  className="hyperlink"
                  onClick={() => {
                    navigate(`/planning/work-orders/view/${data?._id}`)
                  }}
                >
                  {data?.assignedTo?.length} Assignees
                </div>
              ) : (
                <div>-</div>
              )}
            </>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'id',
        width: 10,
        Cell: ({ row }: any) => (
          <div className="flex w-full items-center justify-between ">
            <div className="flex flex-row">
              <button
                className="mr-2"
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`edit/${row?.original?._id}`)
                }}
              >
                <Pen />
              </button>
              <button
                className="mr-2"
                onClick={(e) => {
                  e.stopPropagation()
                  setDeletePopUp({ isPopUpOpen: true, workOrder: row?.original })
                }}
              >
                <Bin />
              </button>
            </div>
            <span
              className="cursor-pointer"
              onClick={() => navigate(`/planning/work-orders/view/${row?.original?._id}`)}
            >
              <ClickableRowArrow />
            </span>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  const handleAddWorkOrder = () => {
    navigate('/planning/work-orders/add')
  }

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Work Order</h1>

        <p className="w-[600px] p-sm-regular-1">
          View the details of your added work orders and easily add new ones as needed.
        </p>

        <FilterableSearchTable
          useQuery={useGetWorkOrdersQuery}
          columns={columns}
          addButton={{ label: 'Add Work Order', onClick: handleAddWorkOrder }}
          placeholders={{
            search: 'Search Work Orders by ID',
            emptyMessage: 'No Work orders are added. Please add Work orders.',
            filterEmptyMessage: 'No results found. Please try a different query.',
          }}
          filterPanelComponent={FilterWorkOrders}
          additionalFilters={additionalFilters}
        />
        {/* Delete Popup */}
        <Popup
          isOpen={deletePopUp.isPopUpOpen}
          title="Delete Work Order"
          onClose={() => {
            setDeletePopUp({ isPopUpOpen: false, workOrder: null })
          }}
        >
          <DeleteWorkOrder
            closePopUp={() => setDeletePopUp({ isPopUpOpen: false, workOrder: null })}
            workOrder={deletePopUp.workOrder}
            plantId={plant?.id}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default WorkOrders
