import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/BuildUrl'
import { PurchaseOrderResponse , PurchaseOrderParams, PostPurchaseOrderResponse, PostPurchaseOrderParams, PurchaseOrderRequest } from './purchaseOrdersTypes'

export const purchaseorder = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getPurchaseOrders: builder.query<PurchaseOrderResponse, PurchaseOrderRequest>({
      query: ({ plantId, filters }) => {
        
        
        const URL = buildUrl(`/plant/${plantId}/purchase-order`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Purchase Orders ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['purchaseOrderUpdate'],
    }),
    getPurchaseOrderByID: builder.query<PostPurchaseOrderResponse, PurchaseOrderParams>({
      query: ({ plant_id, poID }) => {
        return {
          url: `/plant/${plant_id}/purchase-order/${poID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Sales Orders ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['purchaseOrderUpdate'],
    }),
    postPurchaseOrder: builder.mutation<PostPurchaseOrderResponse, PostPurchaseOrderParams>({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/purchase-order`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['purchaseOrderUpdate'],
    }),
    putPurchaseOrder: builder.mutation({
      query: ({ plant_id, poID, data }) => {
        return {
          url: `/plant/${plant_id}/purchase-order/${poID}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['purchaseOrderUpdate'],
    }),
    deletePurchaseOrder: builder.mutation({
      query: ({ plant_id, poID }) => ({
        url: `/plant/${plant_id}/purchase-order/${poID}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['purchaseOrderUpdate'],
    }),

    getPurchaseOrdersStats: builder.query<any, { plantId: string }>({
      query: ({ plantId }) => ({
        url: `/plant/${plantId}/purchase-order/stats`,
        method: 'GET',
        headers: getToken(),
      }),
      providesTags: ['purchaseOrderUpdate'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetPurchaseOrdersQuery,
  usePostPurchaseOrderMutation,
  useGetPurchaseOrderByIDQuery,
  usePutPurchaseOrderMutation,
  useDeletePurchaseOrderMutation,
  useGetPurchaseOrdersStatsQuery,
} = purchaseorder
