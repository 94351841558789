import { createBrowserRouter } from 'react-router-dom'
import { Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import ProductionV2 from 'pages/productionV2/ProductionV2.jsx'
import OperationalProcesses from 'pages/operational-processes/OperationalProcesses.jsx'
import Department from 'pages/department/Department.jsx'
import Inventory from 'pages/inventory/index.tsx'
import AddEditInventory from 'pages/inventory/inventory-details/add-edit-inventory/index.tsx'
import Cookies from 'js-cookie'
import Products from 'pages/products/Products.jsx'
import NewProducts from 'pages/products/NewProducts.jsx'
import EditNewProducts from 'pages/products/EditNewProducts.tsx'
import AppSetting from 'pages/settings/AppSetting'
import Employee from 'pages/employee/Employee.tsx'
import CustomerManagement from 'pages/contacts/customer-management/CustomerManagement.tsx'
import AddEditCustomer from 'pages/contacts/customer-management/add-edit-customer/AddEditCustomer.tsx'
import EmployeeDepartmentPage from 'pages/employee/Employee-Department/EmployeeDepartmentPage.tsx'
import ViewDesignations from 'pages/employee/Employee-Department/view-department/ViewDepartment.tsx'
import ManufactureOrderDashboard from 'pages/dashboard/manufacture-order-dashboard/ManufactureOrderDashboard.tsx'

// Lazy loading components
const SuspenseFallback = (
  <div className="flex min-h-screen items-center justify-center">
    <FullPageLoader />
  </div>
)

import { Login, ForgotPasswordPage, VerificationCodePage, LoginPage } from 'libs/layouts/auth/login'
import PrivateRoutes from './PrivateRoutes.tsx'
import TempPassword from 'libs/layouts/auth/login/TempPassword.tsx'
import Review from 'pages/review/Review.jsx'
import Planning from 'pages/planning/index.jsx'
import Plans from 'pages/planning/production-plans/index.jsx'
import PlanningWizard from 'pages/planning/production-plans/planning-wizard.js'
import WareHouses from 'pages/warehouses/index.tsx'
import AddEditWarehouses from 'pages/warehouses/add-edit-warehouses/index.tsx'
import ViewDetails from 'pages/warehouses/view-warehouse-details/index.tsx'
import TransactionHistory from 'pages/inventory/inventory-details/transactionhistory/TransactionHistory.tsx'
import AddEditOperationalProcess from 'pages/operational-processes/add-edit-operational-process/AddEditOperationalProcess.tsx'
import EntriesDashboard from 'pages/productionV2/entries/EntriesDashboard.tsx'
import Dashboard from 'pages/dashboard/Dashboard.tsx'
import AddInputOutput from 'pages/productionV2/entries/AddInputOutput.tsx'
import Plants from 'pages/plants/Plants.tsx'
import ManufactureOrders from 'pages/manufacture-orders/ManufactureOrdersPage.tsx'
import ManufactureOrderWizard from 'pages/manufacture-orders/ManufactureOrderWizard.tsx'
import WorkOrders from 'pages/work-orders/WorkOrders.tsx'
import AddEditWorkOrder from 'pages/work-orders/add-edit-work-order/AddEditWorkOrder.tsx'
import Order from 'pages/order/Order.tsx'
import SalesOrder from 'pages/order/sales-order/SalesOrder.tsx'
import AddEditSalesOrder from 'pages/order/sales-order/add-sales-order/AddEditSalesOrder.tsx'
import FullPageLoader from 'libs/loader/FullPageLoader.tsx'
import ViewSalesOrder from 'pages/order/sales-order/view-sales-order/ViewSalesOrder.tsx'
import ViewManufactureOrder from 'pages/manufacture-orders/view-manufacture-order.tsx/ViewManufactureOrder.tsx'
import PurchaseOrder from 'pages/order/purchase-order/PurchaseOrder.tsx'
import ViewPurchaseOrder from 'pages/order/purchase-order/view-purchase-order/ViewPurchaseOrder.tsx'
import AddEditPurchaseOrder from 'pages/order/purchase-order/add-edit-purchase-order/AddEditPurchaseOrder.tsx'

import VendorManagement from 'pages/contacts/vendor-management/VendorManagement.tsx'
import AddEditVendor from 'pages/contacts/vendor-management/add-edit-vendor/AddEditVendor.tsx'
import ViewPlan from 'pages/planning/production-plans/details/ViewPlan.js'
import EmployeeManagementPage from 'pages/employee/Employee-Management/EmployeeManagementPage.tsx'
import EmployeeManagementWizard from 'pages/employee/Employee-Management/EmployeeManagementWizard.tsx'
import Contact from 'pages/contacts/Contact.tsx'
import AddEmployeeDepartment from 'pages/employee/Employee-Department/add-department/AddEditEmployeeDepartment.tsx'
import InventoryDashboard from 'pages/dashboard/inventory-dashboard/InventoryDashboard.tsx'
import WorkOrderDashboard from 'pages/dashboard/workorder-dashboard/WorkOrderDashboard.tsx'
import ViewWorkOrderDetails from 'pages/work-orders/view-work-order/ViewWorkOrderDetails.tsx'
import Users from 'pages/settings/user-management/users/Users.tsx'
import UserRoles from 'pages/settings/user-management/roles/UserRoles.tsx'
import AddEditRoles from 'pages/settings/user-management/roles/add-edit-roles/AddEditRoles.tsx'
import ViewRolesDetail from 'pages/settings/user-management/roles/view-roles-detail/ViewRolesDetail.tsx'
import ShiftPolicyPage from 'pages/shift-management/ShiftPolicyPage.tsx'
import ViewShiftPolicy from 'pages/shift-management/view-shift-management/ViewShiftPolicy.tsx'

const role = Cookies.get('role')

const loginPage = [
  {
    path: 'auth',
    element: role ? <Navigate to="/login" replace /> : <Login />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'verify-code',
        element: <VerificationCodePage />,
      },
      {
        path: 'temp-password',
        element: <TempPassword />,
      },
    ],
  },
]

const settings = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'settings/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AppSetting />
          </Suspense>
        ),
        handle: 'settings',
      },
    ],
  },
]

const productionV2 = [
  {
    path: '/',
    element: <PrivateRoutes />,
    handle: ['production', 'process', 'entries', 'add_entry', 'edit_entry'],
    children: [
      {
        path: 'productionV2',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ProductionV2 />
          </Suspense>
        ),
        handle: 'production',
      },
      {
        path: '/productionV2/:department/:process',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <EntriesDashboard />
          </Suspense>
        ),
        handle: 'entries',
      },
      {
        path: '/productionV2/:department/:process/input',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddInputOutput type="INPUT" />
          </Suspense>
        ),
        handle: 'add/input',
      },
      {
        path: '/productionV2/:department/:process/input/:entryId',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddInputOutput type="INPUT" />
          </Suspense>
        ),
        handle: 'add/input',
      },
      {
        path: '/productionV2/:department/:process/output/:entryId',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddInputOutput type="OUTPUT" />
          </Suspense>
        ),
        handle: 'add/output',
      },
      {
        path: '/productionV2/:department/:process/output',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddInputOutput type="OUTPUT" />
          </Suspense>
        ),
        handle: 'add/output',
      },
    ],
  },
]

const shiftManagement = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'settings/shift-management/shift-policy',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ShiftPolicyPage />
          </Suspense>
        ),
        handle: 'add_shift_policy',
      },
      {
        path: 'settings/shift-management/view/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ViewShiftPolicy />
          </Suspense>
        ),
        handle: 'view_shift_policy',
      },
      {
        path: 'settings/shift-management/edit/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
           <ShiftPolicyPage />
          </Suspense>
        ),
        handle: 'edit_shift_policy',
      }
    ],
  },
]

const configure = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      // operational-processes - start
      {
        path: 'settings/configure/operationalprocesses',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <OperationalProcesses />
          </Suspense>
        ),
        handle: 'configure_process',
      },
      {
        path: 'settings/configure/operationalprocesses/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditOperationalProcess />
          </Suspense>
        ),
        handle: 'new_processes',
      },
      {
        path: 'settings/configure/preview/:process/input',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddInputOutput type="INPUT" />
          </Suspense>
        ),
        handle: 'preview',
      },
      {
        path: 'settings/configure/preview/:process/output',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddInputOutput type="OUTPUT" />
          </Suspense>
        ),
        handle: 'preview',
      },
      {
        path: 'settings/configure/department',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Department />
          </Suspense>
        ),
        handle: 'configure_department',
      },

      // operational-processes - products -start
      {
        path: 'settings/configure/products',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Products />
          </Suspense>
        ),
        handle: 'configure_products',
      },
      {
        path: 'settings/configure/products/newProducts',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <NewProducts />
          </Suspense>
        ),
        handle: 'configure_products_new',
      },
      {
        path: 'settings/configure/products/editProducts',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <EditNewProducts />
          </Suspense>
        ),
        handle: 'configure_products_edit',
      },
      // operational-processes - products -end

      // operational-processes - warehouse -start
      {
        path: 'settings/configure/warehouses',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <WareHouses />
          </Suspense>
        ),
        handle: 'configure_warehouses',
      },
      {
        path: 'settings/configure/warehouse/new',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditWarehouses />
          </Suspense>
        ),
        handle: 'new_warehouses',
      },
      {
        path: 'settings/configure/warehouses/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ViewDetails />
          </Suspense>
        ),
        handle: 'view_warehouses',
      },
      {
        path: 'settings/configure/warehouses/edit/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditWarehouses />
          </Suspense>
        ),
        handle: 'edit_warehouses',
      },
      // Plants routes
      {
        path: 'settings/configure/plants',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Plants />
          </Suspense>
        ),
        handle: 'plants',
      },
    ],
  },
]

const userManagement = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'settings/user-management/users',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Users />
          </Suspense>
        ),
        handle: 'users',
      },
      {
        path: 'settings/user-management/roles',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <UserRoles />
          </Suspense>
        ),
        handle: 'roles',
      },
      {
        path: 'settings/user-management/roles/add',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditRoles />
          </Suspense>
        ),
        handle: 'add_roles',
      },
      {
        path: 'settings/user-management/roles/view/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ViewRolesDetail />
          </Suspense>
        ),
        handle: 'view_roles',
      },
    ],
  },
]
const review = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'review',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Review />
          </Suspense>
        ),
        handle: 'review',
      },
      {
        path: '/review/:department/:process',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <EntriesDashboard />
          </Suspense>
        ),
        handle: 'review_entries',
      },
      {
        path: '/review/:department/:process/input',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddInputOutput type="INPUT" />
          </Suspense>
        ),
        handle: 'review_add_input',
      },
      {
        path: '/review/:department/:process/output',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddInputOutput type="OUTPUT" />
          </Suspense>
        ),
        handle: 'review_add_output',
      },
      {
        path: '/review/:department/:process/input/:entryId',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddInputOutput type="INPUT" />
          </Suspense>
        ),
        handle: 'review_add_input',
      },
      {
        path: '/review/:department/:process/output/:entryId',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddInputOutput type="OUTPUT" />
          </Suspense>
        ),
        handle: 'review_add_output',
      },
    ],
  },
]
const planning = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'planning',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Planning />
          </Suspense>
        ),
        handle: 'production_planning',
      },
      {
        path: 'planning/production-plans',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Plans />
          </Suspense>
        ),
        handle: 'production_plans',
      },
      {
        path: 'planning/production-plans/add/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <PlanningWizard />
          </Suspense>
        ),
        handle: 'new_plan',
      },
      {
        path: 'planning/production-plans/:id/add/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <PlanningWizard />
          </Suspense>
        ),
        handle: 'new_plan',
      },
      {
        path: 'planning/production-plans/:id/edit/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <PlanningWizard />
          </Suspense>
        ),
        handle: 'select_plan',
      },
      {
        path: 'planning/production-plans/view/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ViewPlan />
          </Suspense>
        ),
        handle: 'edit_plan',
      },

      //ManufactureOrders

      {
        path: 'planning/mo',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ManufactureOrders />
          </Suspense>
        ),
        handle: 'manufacture_orders',
      },
      {
        path: 'planning/mo/add/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ManufactureOrderWizard />
          </Suspense>
        ),
        handle: 'new_manufacture_order',
      },
      {
        path: 'planning/mo/:id/add/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ManufactureOrderWizard />
          </Suspense>
        ),
        handle: 'new_manufacture_order',
      },
      {
        path: 'planning/mo/:id/edit/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ManufactureOrderWizard />
          </Suspense>
        ),
        handle: 'edit_manufacture_order',
      },
      {
        path: 'planning/mo/view/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ViewManufactureOrder />
          </Suspense>
        ),
        handle: 'view_manufacture_order',
      },

      // work-orders
      {
        path: 'planning/work-orders',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <WorkOrders />
          </Suspense>
        ),
        handle: 'work_orders',
      },
      {
        path: 'planning/work-orders/add',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditWorkOrder />
          </Suspense>
        ),
        handle: 'add_work_order',
      },
      {
        path: 'planning/work-orders/edit/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditWorkOrder />
          </Suspense>
        ),
        handle: 'edit_work_order',
      },
      {
        path: 'planning/work-orders/view/:id/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ViewWorkOrderDetails />
          </Suspense>
        ),
        handle: 'view_work_order',
      },
    ],
  },
]
const inventory = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'inventory',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Inventory />
          </Suspense>
        ),
        handle: 'inventory',
      },
      {
        path: 'inventory/new',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditInventory />
          </Suspense>
        ),
        handle: 'inventory_new',
      },
      {
        path: 'inventory/transactionhistory/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <TransactionHistory />
          </Suspense>
        ),
        handle: 'inventory_transaction_history',
      },
      {
        path: 'inventory/transactionhistory/edit/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditInventory />
          </Suspense>
        ),
        handle: 'inventory_transaction_history_edit',
      },
    ],
  },
]

const order = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'orders',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Order />
          </Suspense>
        ),
        handle: 'order',
      },
      {
        path: 'orders/sales-order',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <SalesOrder />
          </Suspense>
        ),
        handle: 'sales_order',
      },
      {
        path: 'orders/sales-order/newSalesOrder',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditSalesOrder />
          </Suspense>
        ),
        handle: 'sales_order_new',
      },
      {
        path: 'orders/sales-order/edit/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditSalesOrder />
          </Suspense>
        ),
        handle: 'edit_sales_order',
      },
      {
        path: 'orders/sales-order/view/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ViewSalesOrder />
          </Suspense>
        ),
        handle: 'view_sales_order',
      },
      {
        path: 'orders/purchase-order',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <PurchaseOrder />
          </Suspense>
        ),
        handle: 'purchase_order',
      },
      {
        path: 'orders/purchase-order/view/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ViewPurchaseOrder />
          </Suspense>
        ),
        handle: 'view_purchase_order',
      },
      {
        path: 'orders/purchase-order/edit/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditPurchaseOrder />
          </Suspense>
        ),
        handle: 'edit_purchase_order',
      },
      {
        path: 'orders/purchase-order/add',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditPurchaseOrder />
          </Suspense>
        ),
        handle: 'add_purchase_order',
      },
    ],
  },
]

//employee

const employee = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'employee',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Employee />
          </Suspense>
        ),
        handle: 'employee',
      },
      {
        path: 'employee/employee-management',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <EmployeeManagementPage />
          </Suspense>
        ),
        handle: 'employee_management',
      },
      {
        path: 'employee/employee-management/add/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <EmployeeManagementWizard />
          </Suspense>
        ),
        handle: 'add_employee',
      },
      {
        path: 'employee/employee-management/:id/edit/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <EmployeeManagementWizard />
          </Suspense>
        ),
        handle: 'edit_employee',
      },
      {
        path: 'employee/employee-management/:id/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <EmployeeManagementWizard />
          </Suspense>
        ),
        handle: 'view_employee',
      },

      {
        path: 'employee/employee-department',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <EmployeeDepartmentPage />
          </Suspense>
        ),
        handle: 'employee_department',
      },
      {
        path: 'employee/employee-department/add-employee-department',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEmployeeDepartment />
          </Suspense>
        ),
        handle: 'add_employee_department',
      },
      {
        path: 'employee/employee-department/:id/edit-employee-department',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEmployeeDepartment />
          </Suspense>
        ),
        handle: 'add_employee_designation',
      },
      {
        path: 'employee/employee-department/:id/view-employee-designation',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ViewDesignations />
          </Suspense>
        ),
        handle: 'view_employee_designation',
      },
    ],
  },
]

const contact = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: '/contacts',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Contact />
          </Suspense>
        ),
        handle: 'contact',
      },
      {
        path: '/contacts/customer-management',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <CustomerManagement />
          </Suspense>
        ),
        handle: 'customer',
      },
      {
        path: 'contacts/customer-management/add/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditCustomer />
          </Suspense>
        ),
        handle: 'add_customer',
      },
      {
        path: 'contacts/customer-management/:id/edit/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditCustomer />
          </Suspense>
        ),
        handle: 'edit_customer',
      },
      {
        path: 'contacts/customer-management/:id/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditCustomer />
          </Suspense>
        ),
        handle: 'view_customer',
      },

      //*************************************************************** */
      {
        path: 'contacts/vendor-management',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <VendorManagement />
          </Suspense>
        ),
        handle: 'vendor_management',
      },
      {
        path: 'contacts/vendor-management/add/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditVendor />
          </Suspense>
        ),
        handle: 'add_vendor',
      },
      // {
      //   path: 'contact/vendor-management/:id/add/*',
      //   element: (
      //     <Suspense fallback={SuspenseFallback}>
      //       <AddEditVendor />
      //     </Suspense>
      //   ),
      //   handle: 'add_vendor',
      // },
      {
        path: 'contacts/vendor-management/:id/edit/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditVendor />
          </Suspense>
        ),
        handle: 'edit_vendor',
      },
      {
        path: 'contacts/vendor-management/:id/*',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditVendor />
          </Suspense>
        ),
        handle: 'view_vendor',
      },
    ],
  },
]

//employee

const dashboard = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: '/dashboard',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Dashboard />
          </Suspense>
        ),
        handle: 'dashboard',
      },
      {
        path: '/dashboard/inventory',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <InventoryDashboard />
          </Suspense>
        ),
        handle: 'inventory_dashboard',
      },
      {
        path: '/dashboard/work-order',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <WorkOrderDashboard />
          </Suspense>
        ),
        handle: 'workorder_dashboard',
      },
      {
        path: '/dashboard/manufacture-order',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ManufactureOrderDashboard />
          </Suspense>
        ),
        handle: 'manufacture_dashboard',
      },
    ],
  },
]

export const getPages = () => {
  const role = Cookies.get('role')
  if (role === 'admin' || role === 'supervisor') {
    return createBrowserRouter([
      ...review,
      ...configure,
      ...shiftManagement,
      ...userManagement,
      ...productionV2,
      ...settings,
      ...planning,
      ...inventory,
      ...dashboard,
      ...order,
      ...employee,
      ...contact,
      { path: '*', element: <Navigate to="/dashboard" replace /> },
    ])
  }
  if (role === 'operator') {
    return createBrowserRouter([
      ...productionV2,
      ...settings,
      { path: '*', element: <Navigate to="/productionV2" replace /> },
    ])
  }
  return createBrowserRouter([
    ...loginPage,
    ...settings,
    ...productionV2,
    ...configure,
    ...shiftManagement,
    ...userManagement,
    ...review,
    ...planning,
    ...inventory,
    ...dashboard,
    ...order,
    ...employee,
    ...contact,
    { path: '*', element: <Navigate to="/dashboard" replace /> },
  ])
}

export const router = getPages()
