export const caseSensitiveSort = (
  rowA: any,
  rowB: any,
  columnId: string
): number => {
  const valueA: string = rowA.values[columnId];
  const valueB: string = rowB.values[columnId];

  if (!valueA && !valueB) return 0;
  if (!valueA) return 1;
  if (!valueB) return -1;

  return valueA.localeCompare(valueB, undefined, {
    sensitivity: "base",
    caseFirst: "lower",
  });
};
