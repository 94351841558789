import React from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'

// Define the type for a single data point
interface DataPoint {
  date: string // Use ISO date string or adjust if using a different format
  actualProgress: number
  expectedProgress: number
}

// Define the type for the props
interface ProgressChartProps {
  data: DataPoint[]
  highlightDate?: string // Optional prop
}

// Custom Legend Component with Enhanced Styling
const CustomLegend = ({ payload }: any) => (
  <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
    {payload.map((entry: any, index: number) => (
      <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
        <div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: entry.color,
            marginRight: '5px',
          }}
        />
        {/* Customizing the Legend Text */}
        <span
          className="text-lg"
          style={{
            color: entry.color, // Match text color to the legend color
            fontWeight: 400,
            fontSize: '12px',
            textAlign: 'center',
          }}
        >
          {entry.value === 'Actual Progress' ? entry.value : entry.value}
        </span>
      </div>
    ))}
  </div>
)

// Custom tick formatter for dates
// const formatDate = (dateStr: string) => {
//   // Split the dateStr into day, month, and year components
//   const [day, month, year] = dateStr.split('-').map(Number)
//   // Create a new Date object using the components
//   const date = new Date(year, month - 1, day) // month is zero-indexed, so subtract 1
//   const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' }
//   return date.toLocaleDateString('en-GB', options) // Format as "3 Aug"
// }
const formatDate = (dateStr: string) => {
  const date = new Date(dateStr)
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' }
  return date.toLocaleDateString('en-GB', options) // Format as "3 Aug"
}

// Custom Tooltip Component
const CustomTooltip = ({ payload, label }: any) => {
  if (!payload || payload.length === 0) return null

  // Format date
  const date = new Date(label)
  const dateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' }
  const formattedDate = date.toLocaleDateString('en-GB', dateOptions)

  return (
    <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '4px' }}>
      <p style={{ margin: 0 }}>{formattedDate}</p>
      {payload.map((entry: any, index: number) => (
        <div key={`item-${index}`} style={{ margin: '5px 0' }}>
          <span style={{ color: entry.color }}>{entry.name}:</span> {entry.value}%
        </div>
      ))}
    </div>
  )
}

const ProgressChart: React.FC<ProgressChartProps> = ({ data, highlightDate }) => {
  // Calculate the maximum value for the Y axis
  const maxProgress = Math.max(...data.map((d) => Math.max(d.actualProgress, d.expectedProgress)))

  // Ensure that the Y axis max is 100 if the calculated max is less than 100
  const yAxisDomain = [0, Math.max(maxProgress, 100)]

  return (
    <ResponsiveContainer height={240}>
      <AreaChart data={data} margin={{ top: -10, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorActual" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#00AF82" stopOpacity={1} />
            <stop offset="95%" stopColor="#00AF82" stopOpacity={1} />
          </linearGradient>
          <linearGradient id="colorExpected" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#2268D1" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#2268D1" stopOpacity={0.8} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          tickFormatter={formatDate} // Use custom tick formatter
          tick={{ fill: '#666', fontSize: 12 }}
          tickSize={0}
          tickMargin={10}
          padding={{ left: 1, right: 10 }}
        />
        <YAxis
          tickFormatter={(tick) => `${tick}%`}
          domain={yAxisDomain} // Use computed domain
          tickMargin={10} // Adjust this value to change spacing between ticks
          tickSize={0}
          tick={{ fill: '#666', fontSize: 12 }}
        />
        <CartesianGrid vertical={false} horizontal={false} />
        <Tooltip content={<CustomTooltip />} />
        <Legend content={<CustomLegend />} />
        <ReferenceLine y={50} stroke="#D2DADF" strokeWidth={1} />
        <ReferenceLine y={100} stroke="#D2DADF" strokeWidth={1} />

        {highlightDate && <ReferenceLine x={highlightDate} stroke="#2268D1" strokeDasharray="5 5" />}
        <Area
          type="monotone"
          dataKey="actualProgress"
          stroke="#00AF82"
          fill="url(#colorActual)"
          strokeWidth={2}
          dot={true}
          connectNulls
          name="Actual Progress"
        />
        <Area
          type="monotone"
          dataKey="expectedProgress"
          stroke="#2268D1"
          strokeDasharray="5 5"
          fill="#fff"
          dot={false}
          connectNulls
          name="Expected Progress"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default ProgressChart
