import { useState } from 'react'
import MotionDiv from 'libs/motionDiv'
import { t } from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import Bin from 'assets/icons/camera-page/Bin'
import Popup from 'libs/popup/Popup'
import { useGetInventoryByIdQuery } from '../../inventory-endpoints/inventory.endpoints'
import { FormatTimeDifference } from 'utils/FormatTimeDifference'
import Pen from 'assets/icons/camera-page/Pen'
import { NumberFormat } from 'utils/NumberFormat'
import FilterTransaction from './panel/FilterTransaction'
import useTabTitle from 'libs/hooks/useTabTitle'
import { formatUnixDate } from 'utils/unixConverter'
import DeleteTransactionHistoryPopUp from './popup/DeleteTransactionHistoryPopUp'
import HeaderCard from 'libs/card/HeaderCard'
import Chip from 'libs/chip/Chip'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'

export function convertTimestampToDate(timestamp: any) {
  const date = new Date(timestamp * 1000) // Convert Unix timestamp to milliseconds
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = date.getUTCFullYear()
  return `${day}/${month}/${year}`
}

export interface Filters {
  page_no: number
  page_size: number
  searchText: string
  updatedBetween: number[]
  quantityBetween: number[]
  createdBetween: number[]
  transactionDate: number[]
  transactionTypes: string[]
  sortBy: string
  sortDir: string
}

const TransactionHistory = () => {
  useTabTitle(t('inventory_.inventory_transaction_history'))
  const navigate = useNavigate()
  const selectedItem = useSelector(selectSelectedItem)
  const [deletePopUp, setDeletePopUp] = useState(false)
  const { id } = useParams()
  const [selectTransactionHistory, setSelectTransactionHistory] = useState<any>(null)
  const additionalFilters = {
    inventoryId: id,
    createdBetween: [],
    updatedBetween: [],
    transactionDate: [],
    transactionTypes: [],
    quantityBetween: [],
  }
  const { data } = useGetInventoryByIdQuery(
    {
      plantId: selectedItem?.id,
      filters: additionalFilters,
    },
    {
      skip: !id || !selectedItem?.id,
      refetchOnMountOrArgChange: true,
    },
  )

  const handleDoubleClick = (entryData: any) => {
    setSelectTransactionHistory(entryData)
  }

  const toggleDeletePopUp = (entryData: any) => {
    setSelectTransactionHistory(entryData)
    setDeletePopUp(!deletePopUp)
  }

  const handleEdit = (entryData: any) => {
    if (entryData.transactionType !== 'TRANSFORMATION') {
      setSelectTransactionHistory(entryData)
      navigate(`/inventory/transactionhistory/edit/${entryData?._id}`)
    }
  }

  const columns = [
    {
      Header: t('inventory_.transaction_date'),
      accessor: 'transactionDate',
      Cell: ({ value }: any) => {
        return <span>{formatUnixDate(value)}</span>
      },
    },
    {
      Header: 'Product',
      accessor: 'inventory.name',
    },
    {
      Header: t('inventory_.transaction_type'),
      accessor: 'transactionType',
      Cell: ({ value }: any) => <Chip title={value} />,
      disableSortBy: true,
    },
    {
      Header: t('inventory_.quantity'),
      accessor: 'quantity',
      align: 'right',
      disableSortBy: false,
      Cell: ({ value, row }: any) => {
        let _value = NumberFormat(value)

        return (
          <span className="font-mono">
            {row?.original?.warehouseFrom ? '-' : '+'}
            {_value}
          </span>
        )
      },
    },
    {
      Header: t('inventory_.unit'),
      accessor: 'inventory.unit_of_measure',
      align: 'left',
    },
    {
      Header: t('inventory_.warehouse'),
      accessor: 'warehouse',
      Cell: ({ row }: any) => {
        if (row?.original?.warehouseTo) {
          return <span>{row?.original?.warehouseTo?.name ? row?.original?.warehouseTo?.name : '-'}</span>
        } else if (row?.original?.warehouseFrom) {
          return <span>{row?.original?.warehouseFrom?.name ? row?.original?.warehouseFrom?.name : '-'}</span>
        }
      },
      disableSortBy: true,
    },
    {
      Header: 'Storage Area',
      accessor: 'storage',
      Cell: ({ row }: any) => {
        if (row?.original?.storageAreaTo) {
          return <span>{row?.original?.storageAreaTo?.name ? row?.original?.storageAreaTo?.name : '-'}</span>
        } else if (row?.original?.storageAreaFrom) {
          return <span>{row?.original?.storageAreaFrom?.name ? row?.original?.storageAreaFrom?.name : '-'}</span>
        }
      },
      disableSortBy: true,
    },
    {
      Header: t('inventory_.purchase_order.no'),
      accessor: 'purchaseOrder',
      Cell: ({ value }: any) => (value ? value.purchaseOrderID : '-'),
      disableSortBy: true,
    },
    {
      Header: t('inventory_.sales_order_no'),
      accessor: 'salesOrder',
      Cell: ({ value }: any) => (value ? value.salesOrderID : '-'),
      disableSortBy: true,
    },
    {
      Header: t('inventory_.adjustment_reason'),
      accessor: 'adjustmentReason',
      Cell: ({ value }: any) => (value ? value : '-'),
      disableSortBy: true,
    },
    {
      Header: t('inventory_.last_updated'),
      accessor: 'updated_on',
      Cell: ({ row }: any) => {
        const formattedDate = FormatTimeDifference(row.original?.updated_on)
        const userData = row.original?.updated_by ? row.original?.updated_by : row.original?.created_by
        const firstName = userData?.first_name
        const lastName = userData?.last_name
        const fullName = ` by ${firstName} ${lastName}`
        return (
          <div className="min-w-48">
            {formattedDate}
            {fullName}
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: t('inventory_.actions'),
      accessor: 'id',
      width: 10,
      Cell: ({ row }: any) => (
        <div className="flex justify-end gap-1 ">
          {/* TODO: DO NOT DELETE THIS CODE -- */}
          <button
            className={row.original.transactionType === 'TRANSFORMATION' ? 'opacity-40' : ''}
            onClick={(event) => {
              if (row.original.transactionType !== 'TRANSFORMATION') {
                event.stopPropagation()
                handleEdit(row?.original)
              } else undefined
            }}
          >
            <Pen />
          </button>
          <button
            onClick={(event) => {
              event.stopPropagation()
              toggleDeletePopUp(row?.original)
            }}
          >
            <Bin />
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ]
  const currentStock = data?.data?.[0]?.inventory?.quantity
  const handleTransaction = () => {
    navigate('/inventory/new', { state: data.data[0] })
  }
  return (
    <MotionDiv>
      <>
        <div className="flex justify-between">
          <div>
            <h1 className="h4-bold-black ">{t('inventory_.inventory_transaction_history')}</h1>
            <div className="flex justify-between">
              <p className="w-[600px] p-sm-regular">{t('inventory_.inventory_transaction_history_desc')}</p>
            </div>
          </div>
          <HeaderCard
            value={currentStock != null ? NumberFormat(currentStock) : '-'}
            title={t('inventory_.current_stock')}
            backgroundColor="#FFFFFF"
          />
        </div>
        <FilterableSearchTable
          useQuery={useGetInventoryByIdQuery}
          columns={columns}
          excludeKey={['inventoryId']}
          addButton={{ label: t('inventory_.add_transactions'), onClick: handleTransaction }}
          placeholders={{
            search: t('inventory_.transaction_search_placeholder'),
            emptyMessage: t('inventory_.no_transactions_added'),
            filterEmptyMessage: 'No matching results found',
          }}
          onDoubleClick={handleDoubleClick}
          additionalFilters={additionalFilters}
          filterPanelComponent={FilterTransaction}
        />
        <Popup
          isOpen={deletePopUp}
          title={t('inventory_.delete_transaction_history')}
          onClose={() => {
            setSelectTransactionHistory([])
            setDeletePopUp(false)
          }}
        >
          <DeleteTransactionHistoryPopUp
            closePopUp={toggleDeletePopUp}
            transaction={selectTransactionHistory}
            plantId={selectedItem?.id}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default TransactionHistory
