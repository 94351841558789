import ActionColumn from './ActionColumn'
import Column from './Column'

const ColumnWrapper = ({
  column,
  colIndex,
  columns,
  rowIndex,
  row,
  rows,
  formik,
  onEdit,
  options,
  remainingOptions,
  getReturnableRow,
  uniqueIdInOptions,
  mainAccessor,
  hideActions,
  cornerRadius,
}: any) => {
  // If Column accessor is 'action' and hideActions is true, then do not show the column
  if (column.accessor === 'action' && hideActions) {
    return null
  }
  const cellWrapperClassName = ` ${!colIndex ? 'border-l' : 'border-l-0'}
          ${
            rows.length - 1 === rowIndex
              ? `${
                  !colIndex
                    ? `rounded-bl-[${cornerRadius}]`
                    : `
                        ${colIndex === columns.length ? `rounded-br-[${cornerRadius}]` : ''}
                      `
                }`
              : ''
          }
        border-b  border-r px-2 py-1  text-${column.align ?? 'left'}`

  return (
    <div
      key={column.title}
      style={{
        gridColumn: `span ${column?.flex ?? 1}`,
      }}
      className={cellWrapperClassName}
    >
      {column.accessor === 'action' ? (
        <ActionColumn
          onEdit={onEdit}
          formik={formik}
          getReturnableRow={getReturnableRow}
          row={row}
          rows={rows}
          rowIndex={rowIndex}
        />
      ) : (
        <Column
          col={column}
          options={options}
          remainingOptions={remainingOptions}
          row={row}
          rows={rows}
          rowIndex={rowIndex}
          formik={formik}
          uniqueIdInOptions={uniqueIdInOptions}
          mainAccessor={mainAccessor}
        />
      )}
    </div>
  )
}

export default ColumnWrapper
