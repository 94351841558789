import WhiteCard from 'libs/card/WhiteCard'
import Button from 'libs/button/Button'

import MotionDiv from 'libs/motionDiv'

import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PaginatedTable from 'libs/table/PaginatedTable'


const ViewBasicDetails = ({ vendorsData, setPage }: any) => {
  const Vendor = vendorsData

  

  const { id } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    setPage(1)
  },[])

  const columns = [
    {
      Header: 'First Name',
      accessor: 'firstName',
      disableSortBy: true,
    
    },

    {
      Header: 'Last Name',
      accessor: 'lastName',
      disableSortBy: true,
     
    },
    {
      Header: 'Contact Number',
      accessor: 'contactNumber',
      disableSortBy: true,
    },
    {
      Header: 'Email Address',
      accessor: 'emailAddress',
      disableSortBy: true,
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
      disableSortBy: true,
    },
  ]

  const VendorInfo = [
    { label: 'Vendor Name', key: 'name' },
    { label: 'Vendor Type', key: 'vendorType' },
    // { label: 'Material Type', key: 'materialType' },
    { label: 'Business Email Address', key: 'businessEmailAddress' },
    { label: 'Primary Contact Number', key: 'primaryContactNumber' },
    { label: 'Secondary Contact Number', key: 'secondaryContactNumber' },
  ]

  return (
    <MotionDiv>
      <WhiteCard className=" flex min-h-[70vh] flex-col gap-6">
        <div className="flex w-full justify-between">
          <h5 className="h5">Personal Information</h5>
          <div>
            <Button
              color="primary"
              onClick={() => {
                navigate(`/contacts/vendor-management/${id}/edit/basic-details`)
              }}
            >
              Edit Vendor Details
            </Button>
          </div>
        </div>

        <div className="flex  max-w-[800px] justify-between">
          <div className="grid grid-cols-2 gap-4 text-xs">
            {VendorInfo.map((field, fieldIndex) => (
              <React.Fragment key={fieldIndex}>
                <span className="text-gray-500">{field.label}</span>
                <span>{Vendor?.[field.key] || '-'}</span>
              </React.Fragment>
            ))}
          </div>
        </div>
        
        <h2 className="h4-bold-black">Point Of Contacts</h2>

        <PaginatedTable columns={columns} rows={Vendor?.pointOfContact} hidePagination={true} 
        emptyMessage={'No contact details have been added yet. Please add new ones'}/>
      </WhiteCard>
    </MotionDiv>
  )
}

export default ViewBasicDetails
