import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import MotionDiv from 'libs/motionDiv'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { useNavigate } from 'react-router-dom'
import { FormatTimeDifference } from 'utils/FormatTimeDifference'
import FilterUserRoles from './panel/FilterUserRoles'

const UserRoles = () => {
  const navigate = useNavigate()
  const column = [
    {
      Header: <div className="flex w-full items-center justify-end">User Roles</div>,
      accessor: 'userName',
      width: 150,
      Cell: ({ row }: any) => {
        return <div>{row.original.customer?.name}</div>
      },
    },
    {
      Header: 'Applicable Plants',
      accessor: 'plants',
      width: 150,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/orders/sales-order/view/${data?._id}`)
            }}
          >
            {data?.salesOrderID}
          </div>
        )
      },
    },
    {
      Header: 'Last updated',
      accessor: 'updated_on',
      Cell: ({ value }: any) => {
        const formattedDate = FormatTimeDifference(value)
        return formattedDate
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 10,
      Cell: () => (
        <div className="flex w-full items-center justify-between ">
          <div className="flex flex-row">
            <button
              className="mr-2"
              //   onClick={(e) => {
              //     e.stopPropagation()
              //     navigate(`edit/${row.original._id}`)
              //   }}
            >
              <Pen />
            </button>
            <button
              className="mr-2"
              //   onClick={(event) => {
              //     event.stopPropagation()
              //     setDeletePopUp({ isPopUpOpen: true, salesOrderID: row?.original })
              //   }}
            >
              <Bin />
            </button>
          </div>
        </div>
      ),
      disableSortBy: true,
    },
  ]
  const handleAddUserRoles = () => {
    navigate('/settings/user-management/roles/add')
  }
  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black">Users</h1>
        <p className="p-sm-regular-1">Manage and overview all user accounts</p>
        <FilterableSearchTable
          columns={column}
          addButton={{ label: 'Add User Role', onClick: handleAddUserRoles }}
          placeholders={{
            emptyMessage: 'No User Role are added. Please add User Role.',
            filterEmptyMessage: 'No matching results found',
            search: 'Search user roles by name ',
          }}
          filterPanelComponent={FilterUserRoles}
        />
      </>
    </MotionDiv>
  )
}

export default UserRoles
