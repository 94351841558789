import { FormatTimeDifference } from 'utils/FormatTimeDifference'
import { t } from 'i18next'

const ViewMoreCard = ({ selectedOperationalProcess }: any) => {
  return (
    <div className="mt-3 grid w-full grid-cols-[1fr,2fr] gap-2 rounded-lg bg-gray-100 p-3 text-xs">
      <p className="text-gray-500">{t('process_name')}</p>
      <p className="text-gray-900">{selectedOperationalProcess?.name}</p>

      <p className="text-gray-500">{t('input_products')}</p>
      <div className="flex flex-wrap gap-1 text-gray-900">
        {selectedOperationalProcess?.input_products?.map((product: any, index: any) => (
          <span key={index}>
            {product?.product?.name}
            {index !== selectedOperationalProcess?.input_products?.length - 1 ? ',' : ''}
          </span>
        ))}
      </div>

      <p className="text-gray-500">{t('output_products')}</p>
      <div className="flex flex-wrap gap-1 text-gray-900">
        {selectedOperationalProcess?.output_products?.map((product: any, index: any) => (
          <span key={index}>
            {product?.product?.name}
            {index !== selectedOperationalProcess?.output_products?.length - 1 ? ',' : ''}
          </span>
        ))}
      </div>

      <p className="text-gray-500">{t('department')}</p>
      <p className="text-gray-900">{selectedOperationalProcess?.department?.name}</p>

      <p className="text-gray-500">{t('last_updated')}</p>
      <p className="text-gray-900">{FormatTimeDifference(selectedOperationalProcess?.updated_on)}</p>
    </div>
  )
}

export default ViewMoreCard
