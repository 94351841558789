import Popup from 'libs/popup/Popup'
import WhiteCard from 'libs/card/WhiteCard'
import Button from 'libs/button/Button'
import PaginatedTable from 'libs/table/PaginatedTable'
import FullPageLoader from 'libs/loader/FullPageLoader'
import MotionDiv from 'libs/motionDiv'
import { toast } from 'libs/toast'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { formatUnixDate } from 'utils/unixConverter'
import useTabTitle from 'libs/hooks/useTabTitle'
import Chip from 'libs/chip/Chip'
import DeleteSalesOrder from '../delete-sales-order/DeleteSalesOrder'
import { useGetAllSalesOrdersQuery } from '../sales-order-endpoints/salesOrders.endpoints'

const ViewSalesOrder = () => {
  const { id: paramsId } = useParams()
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)
  useTabTitle('View Sales Order')
  const filters = {
    soID: paramsId,
    page_no: 1,
    page_size: 10,
    sortDir: 'DESC',
  }
  const [deletePopUp, setDeletePopUp] = useState<any>({ isPopUpOpen: false, salesOrderID: null })

  const {
    data: salesOrder,
    isLoading: SalesOrderLoading,
    isFetching,
  } = useGetAllSalesOrdersQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    {
      skip: !plantId?.id || !paramsId,
    },
  )

  useEffect(() => {
    if (salesOrder) {
      const totalItemsCount = salesOrder?.paging?.total_items_count
      const dataLength = salesOrder?.data?.length
      if (totalItemsCount <= 0 || dataLength === 0) {
        toast.error('Something went wrong, Please try again.')
        return
      }
    }
  }, [salesOrder])

  const productsColumns = [
    {
      Header: 'Product',
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        return (
          <div>
            <h1 className="p-xs-regular">{row.original.name}</h1>
            <h2 className="p-xs-regular-dim">{row.original.code}</h2>
          </div>
        )
      },
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      disableSortBy: true,
      align: 'right',
      Cell: ({ value }: any) => {
        return <div>{value}</div>
      },
    },
    {
      Header: 'Unit',
      disableSortBy: true,
      accessor: 'unit_of_measure',
      Cell: ({ value }: any) => {
        return <div>{value}</div>
      },
    },
  ]

  return (
    <MotionDiv>
      {!SalesOrderLoading ? (
        <WhiteCard className="mt-3 flex flex-col gap-6">
          <span className="flex justify-between">
            <h5 className="h5">Sales Order Details</h5>
            <div className="flex gap-4">
              <Button
                onClick={() => setDeletePopUp({ isPopUpOpen: true, salesOrderID: salesOrder?.data?.[0] })}
                color="error"
              >
                Delete Sales Order
              </Button>
              <Button color="primary" onClick={() => navigate(`/orders/sales-order/edit/${paramsId}`)}>
                Edit Sales Order
              </Button>
            </div>
          </span>
          <div className="grid grid-cols-[150px,1fr] gap-4 text-xs">
            <span className="text-gray-500">Sales Order ID</span>
            <span>{salesOrder?.data?.[0]?.salesOrderID}</span>
            <span className="text-gray-500">Status</span>
            <span className="-my-1 w-fit">
              <Chip title={salesOrder?.data?.[0]?.status} />
            </span>
            <span className="text-gray-500">Customer Name</span>
            <span>{salesOrder?.data?.[0]?.customer?.name}</span>
            <span className="text-gray-500">Expected Delivery Date</span>
            <span>{formatUnixDate(salesOrder?.data?.[0]?.deliveryDate)}</span>
          </div>

          <PaginatedTable
            rows={salesOrder?.data?.[0]?.products}
            columns={productsColumns}
            emptyMessage={'Product does not exist.'}
            loading={isFetching}
            hidePagination
          />

          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Sales Order"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, salesOrderID: '' })
            }}
          >
            <DeleteSalesOrder
              closePopUp={() => {
                setDeletePopUp({ isPopUpOpen: false, salesOrderID: '' })
              }}
              soID={deletePopUp.salesOrderID}
              plant_id={plantId?.id}
            />
          </Popup>
        </WhiteCard>
      ) : (
        <FullPageLoader />
      )}
    </MotionDiv>
  )
}

export default ViewSalesOrder
