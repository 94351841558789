import { useState, useEffect, useRef } from 'react'
import { SvgIcon } from '@mui/material'
import { selectSelectedItem, selectPlantDetailActive } from 'store/redux/navSlice'
import { useDispatch, useSelector } from 'react-redux'
import { togglePlantDetail } from 'store/redux/navSlice'
import Search from 'assets/icons/top-navigation/Search'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import './topNavigation.scss'
import icons from 'assets/index.ts'
import { useCurrentDateTime } from 'utils/CurrentDateTime'
import ProfileDetail from './ProfileDetail'
import catalystLogo from 'assets/icons/top-navigation/CatalystLogo.svg'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

const TopNavigation = () => {
  const dispatch = useDispatch<any>()
  const popupRef = useRef<any>(null)

  const { data } = useUserInfoQuery({})

  //search bar
  const [searchText, setSearchText] = useState<any>('')
  //state to toggle right side nav
  const [plantDetailActive, setPlantDetailActive] = useState(false)
  const [userPopup, setUserPopup] = useState(false)

  //get and update current state of right side nav
  const isPlantDetailActive = useSelector(selectPlantDetailActive)
  //get state of plants
  const selectedItem = useSelector(selectSelectedItem)

  useEffect(() => {
    setPlantDetailActive(isPlantDetailActive)
  }, [isPlantDetailActive])

  //toggle sideNav
  const handlePlantDetail = () => {
    setPlantDetailActive(!plantDetailActive)
    dispatch(togglePlantDetail())
  }

  //handle search submission
  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
  }

  // format date and time

  const openUserPopup = () => {
    setUserPopup(!userPopup)
  }

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Check if the click is outside the popup
      if (popupRef.current && !popupRef.current.contains(event.target) && !event.target.closest('.ignoreref')) {
        setUserPopup(false)
      }
    }

    // Adding event listener to detect clicks outside the popups
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <div className="topNav-container">
      <div className="topNav-left">
        <div className="flex gap-2">
          <div className="topNav-title ">
            {data?.organization?.logo ? (
              <img src={data?.organization?.logo} alt="catalyst logo" />
            ) : (
              <>
                {/* write skeleton */}
                <div className="h-8 w-32 animate-pulse  rounded-md bg-gray-200"></div>
              </>
            )}
          </div>
        </div>
        <div className="topNav-divider"></div>
        <img src={catalystLogo} alt="catalyst logo" />
      </div>
      <div className="topNav-right">
        {false && (
          <form onSubmit={handleSearchSubmit}>
            <div className="relative">
              <input
                className=" h-[34px] rounded border border-[#D2DADF] px-2 pl-8 outline-none p-xs-regular focus:border-[#1193F0] sm:w-[7rem] md:w-[8rem] lg:w-[17rem] xl:w-[19rem]"
                type="text"
                value={searchText || ''}
                onChange={(e) => {
                  setSearchText(e.target.value || undefined)
                }}
                placeholder="Search your products, orders and inventory"
              />
              <div className="absolute left-0 top-0 flex h-[38px] w-[31px] items-center justify-center ">
                <Search />
              </div>
              {searchText && (
                <div className="absolute left-[275px] top-2 cursor-pointer" onClick={() => setSearchText('')}>
                  <img src={icons.CloseIcon} alt="" />
                </div>
              )}
            </div>
          </form>
        )}

        {/* Plant dropdown */}
        {true && (
          <div className="ignoreref2">
            <div
              className={plantDetailActive ? 'topNav-plantDetail-container ' : 'topNav-plantDetail-container'}
              onClick={handlePlantDetail}
            >
              {selectedItem?.zip_code && (
                <>
                  <p className="topNav-address-label m-0 px-1 ">{selectedItem?.zip_code}</p>
                  <div className="topNav-border-left-line" />
                </>
              )}

              <p className="topNav-address-label m-0 px-1">{selectedItem?.name}</p>

              <SvgIcon className="topNav-arrowDown">
                <ArrowDropDownRoundedIcon />
              </SvgIcon>
            </div>
          </div>
        )}

        <DateTimeSection />

        <div className="notification flex cursor-pointer">
          <img src={icons.NotificationIcon} alt="" />
        </div>
        <div
          className="ignoreref relative mr-4 inline-flex h-7 w-7 cursor-pointer items-center justify-center gap-2  rounded-3xl border border-[#1193F0] bg-[#F8FCFF] p-2"
          onClick={openUserPopup}
        >
          <div className="text-center text-xs">{data?.first_name ? data?.first_name.charAt(0).toUpperCase() : ''}</div>
        </div>
        {userPopup && (
          <div className="absolute" ref={popupRef}>
            <ProfileDetail />
          </div>
        )}
      </div>
    </div>
  )
}

const DateTimeSection = () => {
  const plant = useSelector(selectSelectedItem)

  const { currentDate, currentTime } = useCurrentDateTime(plant?.timezone)

  return (
    <>
      <div className="topNav-datetime">
        <p className="topNav-date m-0">{currentDate}</p>
        <p className="topNav-time m-0">{currentTime}</p>
      </div>
    </>
  )
}

export default TopNavigation
