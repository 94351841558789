import { t } from 'i18next'
import Button from 'libs/button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetAllSalesOrdersQuery } from 'pages/order/sales-order/sales-order-endpoints/salesOrders.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { formatUnixDate } from 'utils/unixConverter'
import { formatString } from 'utils/trimStringValues'
import { useLocation } from 'react-router-dom'
import NewEditableTable from 'libs/table/NewEditableTable'
import QuickAddEditSalesOrder from 'libs/quick-add-edits/quick-add-edit-so/QuickAddEditSoPanel'
import FullPageLoader from 'libs/loader/FullPageLoader'
import Popup from 'libs/popup/Popup'
import SaveChanges from '../popup/SaveChanges'

const SalesOrderColumns = [
  {
    title: 'Sales Order',
    accessor: 'salesOrder',
    flex: 2,
    main: true,
    editable: true,
    placeholder: 'Select Sales Order',
  },

  {
    title: 'Customer Name',
    accessor: 'customerName',
  },
  {
    title: 'Product',
    accessor: 'products',
    render(value: any) {
      return value ? `${value?.length} products` : '-'
    },
  },
  {
    title: 'Delivery Date',
    accessor: 'deliveryDate',
    render(value: any) {
      return formatUnixDate(value)
    },
  },
  {
    title: 'Status',
    accessor: 'status',
    render(value: any) {
      return formatString(value)
    },
  },
]

const salesFilters = {
  page_no: 1,
  page_size: 1000,
  searchText: '',
  createdBetween: [],
  updatedBetween: [],
  status: [],
  category: [],
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const SalesOrders = ({ setPage, setId, manufactureOrderFormik, loading }: any) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [selectedSalesOrder, setSelectedSalesOrder] = useState<any>(null)
  const location = useLocation()
  const isEditMO = location.pathname.includes('edit')

  const [ saveChangesPopUp, setSaveChangesPopUp] = useState<{ isPopUpOpen: boolean; moOrder: any }>({
    isPopUpOpen: false,
    moOrder: null,
  })

  const { data: SoOrder, isLoading: isSalesOrderLoading } = useGetAllSalesOrdersQuery(
    {
      plantId: plantId?.id,
      filters: salesFilters,
    },
    {
      skip: !plantId?.id,
    },
  )

  const salesOrderOptions = useMemo(
    () =>
      SoOrder?.data?.map((item: any) => ({
        id: item?.salesOrderID,
        label: item?.salesOrderID,
        value: {
          _id: item?._id,
          plant_id: item?.plant?._id,
          salesOrderID: item?.salesOrderID,
          status: item?.status,
          deliveryDate: item?.deliveryDate,
          customerName: item?.customer?.name,
          products: item?.products?.map((product: any) => ({
            product_id: product?._id,
            quantity: product?.quantity,
          })),
        },
      })),

    [SoOrder?.data],
  )

  useEffect(() => {
    setId({ id: id })
    setPage(2)
  }, [])

  const handleEdit = useCallback((salesOrder: any) => {
    setSelectedSalesOrder(salesOrder)
    setIsPanelOpen(true)
  }, [])

  const [data, setData] = useState<any[]>()

  useEffect(() => {
    const d = manufactureOrderFormik.values.salesOrders
    if (d != null && d.length > 0) {
      setData(d)
    }
  }, [manufactureOrderFormik.values.salesOrders])

  const handleSaveChanges = () => {
    setSaveChangesPopUp({ isPopUpOpen: true, moOrder: manufactureOrderFormik })
  }
  
  return (
    <>
      <div className="relative flex min-h-[70vh] flex-col  justify-between ">
        {isSalesOrderLoading ? (
          <div className="absolute left-1/2 top-1/2">
            {' '}
            <FullPageLoader />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <div>
                  <h5 className="h5-1">{'Sales Orders '}</h5>
                  <p className="p-sm-regular-2">{'Select any sales orders you have in connection with this MO.'}</p>
                </div>

                <Button
                  startIcon={<PlusIcon />}
                  color="primary"
                  onClick={() => {
                    setIsPanelOpen(true)
                    setSelectedSalesOrder(null)
                  }}
                >
                  {'Add Sales Order'}
                </Button>
              </div>

              <NewEditableTable
                defaultRows={manufactureOrderFormik.values.salesOrders}
                options={salesOrderOptions}
                columns={SalesOrderColumns}
                uniqueIdInOptions="salesOrderID"
                addButtonTitle="Select Sales Order"
                onChange={(values: any) => {
                  setData(values)
                }}
                onEdit={handleEdit}
              />
              <QuickAddEditSalesOrder
                id={selectedSalesOrder?.salesOrder?._id}
                onClose={() => setIsPanelOpen(false)}
                isOpen={isPanelOpen}
                manufactureOrderFormik={manufactureOrderFormik}
                data={data}
              />
            </div>

            <div className="flex justify-between">
              <div className="flex gap-3">
                <Button type="button" onClick={handleSaveChanges}>
                  {t('cancel')}
                </Button>
                <Popup
                isOpen={saveChangesPopUp.isPopUpOpen}
                title={'Save Changes?'}
                onClose={() => {
                  setSaveChangesPopUp({ isPopUpOpen: false, moOrder: null })
                }}
                >
                  <SaveChanges
                    manufactureOrderFormik={manufactureOrderFormik}
                    data={data}
                    setPage={setPage}
                    tabName="salesOrders"
                    loading={!manufactureOrderFormik.values.isDraft ? loading : false}
                  />
                </Popup>
                {false && !isEditMO && (
                  <Button
                    loading={manufactureOrderFormik.values.isDraft ? loading : false}
                    color="primary"
                    type="submit"
                    onClick={() => {
                      // salesOrdersFormik.setFieldValue('should_is_draft', true)
                      // salesOrdersFormik.handleSubmit()
                    }}
                  >
                    {t('plannings.save_as_draft')}
                  </Button>
                )}
              </div>
              <div className="flex gap-3">
                <Button
                  onClick={() => {
                    setPage(3)
                    isEditMO
                      ? navigate(`/planning/mo/${id}/edit/purchase-orders`)
                      : navigate(`/planning/mo/${id}/add/purchase-orders`)
                  }}
                >
                  {'Skip this step'}
                </Button>
                <Button
                  onClick={() => {
                    setPage(1)
                    isEditMO
                      ? navigate(`/planning/mo/${id}/edit/basic-details`)
                      : navigate(`/planning/mo/${id}/add/basic-details`)
                  }}
                >
                  {'Previous'}
                </Button>
                <Button
                  loading={!manufactureOrderFormik.values.isDraft ? loading : false}
                  color="success"
                  type="submit"
                  onClick={() => {
                    manufactureOrderFormik.setFieldValue('salesOrders', data)
                    manufactureOrderFormik.handleSubmit()
                  }}
                >
                  {t('next')}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default SalesOrders
