import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'

import { buildUrl } from 'utils/BuildUrl'
import { CustomersResponse, GetCustomersParams, PostCustomerParams, PostCustomerResponse } from './customerTypes'

export const customer = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<CustomersResponse, GetCustomersParams>({
      query: (params) => {
        let URL = ''
        params.filters
          ? (URL = buildUrl(`/plant/${params.plantId}/customers`, params.filters))
          : (URL = `/plant/${params.plantId}/customers`)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the customers')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetCustomers', 'salesOrderUpdate'],
    }),

    postCustomerManagement: builder.mutation<PostCustomerResponse, PostCustomerParams>({
      query: ({ data, plant_id }) => {
        return {
          url: `plant/${plant_id}/customer`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['GetCustomers'],
    }),

    deleteCustomers: builder.mutation<any, { plantId: string; customerID: string }>({
      query: ({ plantId, customerID }) => ({
        url: `/plant/${plantId}/customer/${customerID}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['GetCustomers'],
    }),
    putCustomer: builder.mutation<any, any>({
      query: ({ data, plantId, customerID }) => {
        return {
          url: `/plant/${plantId}/customer/${customerID}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['GetCustomers'],
    }),

    getCountCustomer: builder.query({
      query: ({ plant_id }) => {
        return {
          url: `plant/${plant_id}/customer-count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the count Customer')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetCustomers'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetCustomersQuery,
  usePostCustomerManagementMutation,
  useDeleteCustomersMutation,
  useGetCountCustomerQuery,
  usePutCustomerMutation,
} = customer
