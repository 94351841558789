import Field from 'libs/field'

const Column = ({
  col,
  options,
  mainAccessor,
  remainingOptions,
  uniqueIdInOptions,
  rows,
  rowIndex,
  row,
  formik,
}: any) => {
  let defaultValue = null
  if (col.main && options) {
    const label = row?.[col.accessor]?.[uniqueIdInOptions]
    defaultValue = options.find((op: any) => op.id === label)
  }


  const defaultProps: any = {}
  if (col.fieldType === 'select') {
    defaultProps['defaultValue'] = col?.options?.find((op: any) => op.id === row?.[col.accessor])
  }

  return (
    <>
      {col.main ? (
        <>
          {remainingOptions && (
            <Field
              alignText={col?.align}
              placeholder={col?.placeholder}
              type={'select'}
              name={`formikRows[${rowIndex}].${col.accessor}`}
              formik={formik}
              options={remainingOptions}
              defaultValue={defaultValue}
              hideborder
            />
          )}
        </>
      ) : (
        <>
          {col.editable ? (
            col.fieldType === 'radio' ? (
              <div
                className="flex h-[50px] items-center justify-center"
                onClick={() => {
                  formik.setFieldValue(
                    'formikRows',
                    rows.map((r: any) => ({ ...r, [col.accessor]: false })),
                  )
                  formik.setFieldValue(`formikRows[${rowIndex}].${col.accessor}`, true)
                }}
              >
                <div
                  className={`h-4 w-4 rounded-full border${row?.[col.accessor] ? '-4' : ''}  box-border flex items-center justify-center border-primary`}
                />
              </div>
            ) : (
              <Field
                alignText={col?.align}
                placeholder={col?.placeholder ?? 'Enter'}
                type={col?.fieldType}
                options={col?.options}
                name={`formikRows[${rowIndex}].${col.accessor}`}
                formik={formik}
                {...defaultProps}
                hideborder
              />
            )
          ) : (
            <div className="flex h-[50px] items-center justify-center">
              {col.render
                ? col.render(row?.[mainAccessor]?.[col.accessor])
                : row?.[mainAccessor]?.[col.accessor] ?? '-'}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Column
