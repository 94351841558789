import React, { useState, ReactNode } from 'react'

interface TooltipProps {
  content: ReactNode
  children: ReactNode
  position?: 'top' | 'right' | 'bottom' | 'left' | 'center'
}

const Tooltip: React.FC<TooltipProps> = ({ content, children, position = 'top' }) => {
  const [visible, setVisible] = useState(false)

  const showTooltip = () => setVisible(true)
  const hideTooltip = () => setVisible(false)

  const getPositionStyles = () => {
    switch (position) {
      case 'top':
        return { bottom: '100%', left: '50%', transform: 'translateX(-50%)' }
      case 'right':
        return { top: '50%', left: '100%', transform: 'translateY(-50%)' }
      case 'bottom':
        return { top: '100%', left: '50%', transform: 'translateX(-50%)' }
      case 'left':
        return { top: '50%', right: '100%', transform: 'translateY(-50%)' }
      case 'center':
        return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }
      default:
        return { bottom: '100%', left: '50%', transform: 'translateX(-50%)' }
    }
  }

  return (
    <div className="relative inline-block w-full" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {visible && (
        <div
          className="z-9999 absolute w-auto overflow-hidden text-ellipsis whitespace-nowrap rounded bg-black p-2 text-xs text-white"
          style={getPositionStyles()}
        >
          {content}
        </div>
      )}
    </div>
  )
}

export default Tooltip
