import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import Field from 'libs/field'
import { toast } from 'libs/toast'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import * as Yup from 'yup'
import { usePostUsersMutation, usePutUsersMutation, useUserInfoQuery } from '../user-endpoints/user.endpoint'

const AddEditUser = ({ userData, close }: any) => {
  const [addUser, { isLoading: isAddUserLoading }] = usePostUsersMutation()
  const [updateUser, { isLoading: isUpdateUserLoading }] = usePutUsersMutation()
  const plant = useSelector(selectSelectedItem)
  const { data: userInfo } = useUserInfoQuery({})

  const formik = useFormik({
    initialValues: {
      userName: userData?.username || '',
      emailID: userData?.email || '',
      firstName: userData?.first_name || '',
      lastName: userData?.last_name || '',
      phoneNumber: userData?.phone_number || '',
      userRole: userData?.role || '',
    },
    onSubmit: (values) => {
      const payload = {
        username: values.userName,
        email: values.emailID,
        first_name: values.firstName,
        last_name: values.lastName,
        phone_number: values.phoneNumber.toString(),
        organization_id: userInfo?.organization?._id,
        plants: [plant?._id],
        role: values.userRole,
      }

      if (userData) {
        updateUser({
          userId: userData?._id,
          data: payload,
          org_id: userInfo?.organization?._id,
        })
          .unwrap()
          .then((data) => {
            toast.success(`User ${data.username} updated successfully`)
            close()
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong. Please try again.')
          })
      } else {
        addUser({
          org_id: userInfo?.organization?._id,
          data: payload,
        })
          .unwrap()
          .then((data) => {
            toast.success(`New User ${data.username} created successfully`)
            close()
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong. Please try again.')
          })
      }
    },
    validationSchema: Yup.object({
      userName: Yup.string().required('User Name is required'),
      emailID: Yup.string().required('Email ID is required'),
    }),
  })
  const roleOptions = [
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'Operator',
      value: 'operator',
    },
  ]

  useEffect(() => {
    if (formik.values.userRole) {
      const userRole = roleOptions.find((option) => option.value === formik.values.userRole)
     

      if (userRole) {
        formik.setFieldValue('userRole', userRole.value)
      }
    }
  }, [formik.values.userRole])

  const userRole = { label: formik?.values?.userRole, value: formik?.values?.userRole }

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="text"
          label="User Name"
          placeholder="Enter User Name"
          formik={formik}
          required={true}
          name="userName"
        />
        <Field
          type="text"
          label="First Name"
          placeholder="Enter First Name"
          required={true}
          formik={formik}
          name="firstName"
        />
        <Field
          type="text"
          label="Last Name"
          placeholder="Enter Last Name"
          required={true}
          formik={formik}
          name="lastName"
        />
        <Field
          type="text"
          label="Email ID"
          placeholder="Enter Email ID"
          required={true}
          formik={formik}
          name="emailID"
        />
        <Field
          type="number"
          label="Phone Number"
          placeholder="Enter Phone Number"
          required={true}
          formik={formik}
          name="phoneNumber"
        />
        <Field
          type="select"
          label="User Role"
          formik={formik}
          required={true}
          name="userRole"
          options={roleOptions}
          defaultValue={formik?.values?.userRole !== '' ? userRole : ''}
          placeholder="select"
        />
        {/* Do not delete this comment */}
        <button className="flex cursor-not-allowed  text-sm text-gray-600 ">Add User Role +</button>
        <div className="flex justify-end gap-2">
          <Button onClick={close}>Cancel</Button>
          <Button
            color="primary"
            loading={isAddUserLoading || isUpdateUserLoading}
            onClick={() => formik.handleSubmit()}
          >
            {!userData ? 'Add' : 'Edit'}
          </Button>
        </div>
      </div>
    </>
  )
}

export default AddEditUser
