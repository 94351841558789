import Button from 'libs/button/Button'
import { t } from 'i18next'

interface Props {
  onDelete: () => void
  closePopUp: any
  data: any
  isLoading?: boolean
}

const DeleteStorage = ({ closePopUp, data, onDelete, isLoading }: Props) => {
  return (
    <div>
      <p className="p-xs-regular">
        {t('warehouse.storage_delete_message')}
        <span className="break-words p-xs-bold"> “{data?.name}”. </span>
        {t('warehouse.storage_delete')}
      </p>

      <div className="mt-4 flex justify-end gap-2">
        <Button onClick={closePopUp}>{t('warehouse.close')}</Button>
        <div className="ignoreref">
          <Button color="error" onClick={onDelete} loading={isLoading}>
            {t('warehouse.delete')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DeleteStorage
