import React, { useState } from 'react'

const ClickableRowArrow: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ fill: isHovered ? '#1193F0' : '#75838D' }}
    >
      <path d="M9.58331 2.99996L2.16665 10.4166C2.01387 10.5694 1.81942 10.6458 1.58331 10.6458C1.3472 10.6458 1.15276 10.5694 0.99998 10.4166C0.847202 10.2638 0.770813 10.0694 0.770813 9.83329C0.770813 9.59718 0.847202 9.40274 0.99998 9.24996L8.41665 1.83329H2.08331C1.8472 1.83329 1.64929 1.75343 1.48956 1.59371C1.32984 1.43399 1.24998 1.23607 1.24998 0.999959C1.24998 0.763848 1.32984 0.565932 1.48956 0.406209C1.64929 0.246487 1.8472 0.166626 2.08331 0.166626H10.4166C10.6528 0.166626 10.8507 0.246487 11.0104 0.406209C11.1701 0.565932 11.25 0.763848 11.25 0.999959V9.33329C11.25 9.5694 11.1701 9.76732 11.0104 9.92704C10.8507 10.0868 10.6528 10.1666 10.4166 10.1666C10.1805 10.1666 9.98262 10.0868 9.8229 9.92704C9.66317 9.76732 9.58331 9.5694 9.58331 9.33329V2.99996Z" />
    </svg>
  )
}

export default ClickableRowArrow
