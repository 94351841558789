import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'

interface Props {
  closePanel: () => void
  setFilters: any
  filters: any
}

const FilterPanel = ({ closePanel, filters, setFilters }: Props) => {
  const plantId = useSelector(selectSelectedItem)
  // const [productfilters, setProductFilters] = useState({
  //   page_no: 1,
  //   page_size: 10,
  //   sortBy: 'created_on',
  //   sortDir: 'DESC',
  //   searchText: '',
  // })
  const productFilters = {
    page_no: 1,
    page_size: 10,
    sortBy: 'created_on',
    sortDir: 'DESC',
    searchText: '',
  }
  const { data: productsData } = useGetProductQuery(
    {
      plantId: plantId?.id,
      filters: productFilters,
    },
    {
      skip: !plantId?.id,
    },
  )

  // Convert the array of timestamps into an array of formatted dates

  const initialValues = {
    createdBetween: filters.createdBetween.length == 0 ? [] : filters?.createdBetween,
    updatedBetween: filters.updatedBetween.length == 0 ? [] : filters?.updatedBetween,
    allowedProducts: filters.allowedProducts.length == 0 ? [] : filters.allowedProducts,
    noOfStorageAreaRange: [],
    storage_from: filters.noOfStorageAreaRange.length == 0 ? '' : filters.noOfStorageAreaRange[0],
    storage_to: filters.noOfStorageAreaRange.length == 0 ? '' : filters.noOfStorageAreaRange[1],
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      createdBetween: [],
      updatedBetween: [],
      allowedProducts: [],
      noOfStorageAreaRange: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })
    closePanel()
  }

  const filterFormik = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        createdBetween: values.createdBetween == null ? [] : values.createdBetween,
        updatedBetween: values.updatedBetween == null ? [] : values.updatedBetween,
        allowedProducts: values.allowedProducts,
        noOfStorageAreaRange:
          values.storage_from == 0 && values.storage_to == 0
            ? []
            : [values.storage_from == '' ? 0 : values.storage_from, values.storage_to],
      }))
    },
  })

  const productOptions = productsData?.data?.map((item: any) => ({
    label: item.name,
    sublabel: item.code,
    value: item?._id,
  }))

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Allowed Products */}
        <Field
          type="multiselect"
          label="Allowed products"
          placeholder="Select products"
          options={productOptions}
          formik={filterFormik}
          name="allowedProducts"
        />

        {/* Storage Area Range */}
        <div className="flex flex-col py-1">
          <div className="flex w-full justify-between gap-4">
            <Field
              type="number"
              label="Number of storage area range from"
              formik={filterFormik}
              placeholder="Enter minimum number of storage area"
              name="storage_from"
            />
            <Field
              type="number"
              label="Number of storage area range to"
              placeholder="Enter maximum number of storage area"
              formik={filterFormik}
              name="storage_to"
            />
          </div>
        </div>
        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Date created between"
          formik={filterFormik}
          name="createdBetween"
        />
        {/* Update Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Last edited between"
          formik={filterFormik}
          name="updatedBetween"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            type="submit"
            color="primary"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterPanel
