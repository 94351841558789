import Bin from 'assets/icons/camera-page/Bin'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import Pen from 'assets/icons/camera-page/Pen'
import MotionDiv from 'libs/motionDiv'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormatTimeDifference } from 'utils/FormatTimeDifference'
import AddEditUser from './panel/AddEditUser'
import Panel from 'libs/panel'
import FilterUsers from './panel/FilterUsers'
import ViewUser from './panel/ViewUser'
import { useGetUsersQuery } from './user-endpoints/user.endpoint'
import DeleteUserPopup from './delete-user/DeleteUserPopup'
import Popup from 'libs/popup/Popup'
import { User, UserPopUpState } from './user-endpoints/user'

const Users = () => {
  const navigate = useNavigate()

  // *********************************** Common State for Add Edit View pannel & delete popUp  ************************ //

  const [userPopup, setUserPopup] = useState<UserPopUpState>({ isPopupOpen: false, userData: null, type: 'add-edit' })

  const additionalFilters = {
    userRole: [],
    updatedBetween: [],
  }

  // *********************************** Update User Logic   ************************ //

  const handleUpdateUser = (userData: User) => {
    setUserPopup({ isPopupOpen: true, userData: userData, type: 'add-edit' })
  }

  // *********************************** Delete User Logic  ************************ //

  const handleDeleteUser = (userData: User) => {
    setUserPopup({ isPopupOpen: true, userData: userData, type: 'delete' })
  }

  // *********************************** View User Logic   ************************ //

  const handleViewUser = (userData: User) => {
    setUserPopup({ isPopupOpen: true, userData: userData, type: 'view' })
  }

  // *********************************** Add user Logic   ************************ //
  const handleAddUser = () => {
    setUserPopup({ isPopupOpen: true, userData: null, type: 'add-edit' })
  }

  // *********************************** Column  ************************ //

  const column = [
    {
      Header: <div className="flex w-full items-center justify-end">User Name</div>,
      accessor: 'username',
      width: 150,
      Cell: ({ row }: any) => {
        return <div>{row?.original?.username}</div>
      },
    },
    {
      Header: 'Email ID',
      accessor: 'email',
      width: 150,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className=""
            onClick={() => {
              navigate(`/orders/sales-order/view/${data?._id}`)
            }}
          >
            {row?.original?.email}
          </div>
        )
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">User Role</div>,
      accessor: 'role',
      width: 150,
      Cell: ({ row }: any) => {
        return <div>{row?.original?.role}</div>
      },
    },
    {
      Header: 'Last updated',
      accessor: 'updated_on',
      Cell: ({ value }: any) => {
        const formattedDate = FormatTimeDifference(value)
        return formattedDate
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 10,
      Cell: ({ row }: any) => (
        <div className="flex w-full items-center justify-between ">
          <div className="flex flex-row">
            <button
              className="mr-2"
              onClick={(e) => {
                e.stopPropagation()
                handleUpdateUser(row?.original)
              }}
            >
              <Pen />
            </button>
            <button
              className="mr-2"
              onClick={(event) => {
                event.stopPropagation()
                handleDeleteUser(row?.original)
              }}
            >
              <Bin />
            </button>
          </div>
          <span
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation()
              handleViewUser(row?.original)
            }}
          >
            <ClickableRowArrow />
          </span>
        </div>
      ),
      disableSortBy: true,
    },
  ]

  return (
    <MotionDiv>
      <>
        {
          // *********************************** Filterable Table  ************************ //
        }
        <h1 className="h4-bold-black">Users</h1>
        <p className="p-sm-regular-1">Manage and overview all user accounts</p>
        <FilterableSearchTable
          useOrgQuery={useGetUsersQuery}
          columns={column}
          addButton={{ label: 'Add New User', onClick: handleAddUser }}
          placeholders={{
            emptyMessage: 'No User are added. Please add User.',
            filterEmptyMessage: 'No matching results found',
            search: 'Search Users by Names, Email ID',
          }}
          additionalFilters={additionalFilters}
          filterPanelComponent={FilterUsers}
        />

        {
          // *********************************** Add Edit User Pannel ************************ //
        }
        <Panel
          size="large"
          isOpen={userPopup.isPopupOpen && userPopup.type === 'add-edit'}
          onClose={() => setUserPopup({ ...userPopup, isPopupOpen: false, userData: null })}
          title={userPopup.userData ? 'Edit User' : 'Add New User'}
          subTitle={
            userPopup.userData
              ? 'Modify the details and roles of an existing user account'
              : 'Create a new user account by entering the required personal details, assigning specific roles.'
          }
        >
          <AddEditUser
            userData={userPopup.userData}
            close={() => setUserPopup({ ...userPopup, isPopupOpen: false, userData: null })}
          />
        </Panel>

        {
          // *********************************** View User Pannel ************************ //
        }
        <Panel
          size="large"
          isOpen={userPopup.isPopupOpen && userPopup.type === 'view'}
          onClose={() => setUserPopup({ ...userPopup, isPopupOpen: false, userData: null })}
          title={'User Details'}
        >
          <ViewUser
            userData={userPopup.userData}
            close={() => setUserPopup({ ...userPopup, isPopupOpen: false, userData: null })}
            handleDelete={{}}
            handleUpdate={handleUpdateUser}
          />
        </Panel>

        {
          // *********************************** Delete User Pannel ************************ //
        }
        <Popup
          isOpen={userPopup.isPopupOpen && userPopup.type === 'delete'}
          title="Delete User"
          onClose={() => {
            setUserPopup({ ...userPopup, isPopupOpen: false, userData: null })
          }}
        >
          <DeleteUserPopup
            closePopUp={() => setUserPopup({ ...userPopup, isPopupOpen: false, userData: null })}
            data={userPopup.userData}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default Users
