import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import Field from 'libs/field'
import MotionDiv from 'libs/motionDiv'
import EditableTable from 'libs/table/EditableTable'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TimePicker from './time-picker/TimePicker'
import TimepickerPopup from 'libs/popup/TimepickerPopup'
import { formatUnixTimeWithoutSecond } from 'utils/unixConverter'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { selectSelectedItem } from 'store/redux/navSlice'
import {
  useGetShiftPolicyByIDQuery,
  usePostShiftPolicyMutation,
  usePutShiftPolicyMutation,
} from './shift-mangement-endpoints/shiftManagement.endpoints'
import { toast } from 'libs/toast'
import FullPageLoader from 'libs/loader/FullPageLoader'
import useTabTitle from 'libs/hooks/useTabTitle'
export const calculateDuration = (start: number, end: number) => {
  // If the end time is less than the start time, it means the time crosses midnight
  if (end < start) {
    end += 24 * 60 * 60 * 1000 // Add 24 hours in milliseconds to the end time
  }

  const durationMs = Math.abs(end - start)
  const hours = Math.floor(durationMs / (1000 * 60 * 60))
  const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60))

  return `${hours}h ${minutes}m`
}
const ShiftPolicyPage = () => {
  const navigate = useNavigate()
  const paramId = useParams()

  useTabTitle(paramId?.id ? 'Edit Shift Policy' : 'Add Shift Policy')
  const { timezone, id } = useSelector(selectSelectedItem)
  const [addShiftPolicy, { isLoading: isAddShiftPolicyLoading }] = usePostShiftPolicyMutation()
  const [updateShiftPolicy, { isLoading: isUpdateShiftPolicyLoading }] = usePutShiftPolicyMutation()
  const [overlappingShifts, setOverlappingShifts] = useState<any>([])
  const [emptyShifts, setEmptyShifts] = useState(false)
  const { data: shiftPolicy, isLoading: isShiftPolicyLoading } = useGetShiftPolicyByIDQuery(
    {
      plantId: id,
      shiftPolicyID: paramId?.id,
    },
    {
      skip: !paramId?.id || !id,
    },
  )
  const [timePickerPopup, setTimePickerPopup] = useState({ isPopUpOpen: false, index: 0, time: '' })
  const formik = useFormik({
    initialValues: {
      name: '',
      shifts: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Policy name is required'),
    }),
    onSubmit: (values) => {
      let hasEmptyField = false
      let hasTimeEmptyField = false
      let hasTimeEmptyFields = false
      shiftPolicyFormik?.values?.shifts?.forEach((fields: any) => {
        if (!fields.shiftName) {
          hasEmptyField = true
        }
        if (!fields?.startTimeing && !fields?.endTimeing) {
          hasTimeEmptyFields = true
          return
        }
        if (!fields?.startTimeing || !fields?.endTimeing) {
          hasTimeEmptyField = true
        }
      })
      if (hasEmptyField) {
        toast.error('Shift name is required')
      } else if (hasTimeEmptyField) {
        toast.error('field is required')
      } else if (hasTimeEmptyFields) {
        toast.error('fields are required')
      } else if (emptyShiftPolicy(shiftPolicyFormik.values.shifts)) {
        setEmptyShifts(true)
      } else {
        const payload = {
          policyName: values?.name,
          shifts: shiftPolicyFormik.values.shifts.map((shift: any) => ({
            shiftName: shift.shiftName,
            startTime: shift.startTimeing,
            endTime: shift.endTimeing,
          })),
        }
        if (paramId?.id) {
          updateShiftPolicy({ plantId: id, shiftPolicyID: paramId?.id, data: payload })
            .unwrap()
            .then(() => {
              toast.success('Policy - ' + payload.policyName + ' updated successfully')
              navigate(-1)
            })
            .catch((error) => {
              const errorMessage = Array.isArray(error?.data?.detail)
                ? error?.data?.detail[0]?.msg
                : error?.data?.detail
              toast.error(errorMessage ?? 'Something went wrong while creating the Shift Policy')
            })
        } else {
          addShiftPolicy({ plantId: id, data: payload })
            .unwrap()
            .then(() => {
              toast.success('Policy - ' + payload.policyName + ' created successfully')
              navigate(-1)
            })
            .catch((error) => {
              const errorMessage = Array.isArray(error?.data?.detail)
                ? error?.data?.detail[0]?.msg
                : error?.data?.detail
              toast.error(errorMessage ?? 'Something went wrong while creating the Shift Policy')
            })
        }
      }
    },
  })

  const policyColumns = [
    {
      title: 'Shift Name',
      flex: 2,
      content: {
        name: 'shiftName',
        placeholder: 'Enter shift name',
        type: 'text',
      },
    },
    {
      title: 'Start Time',
      content: {
        placeholder: 'Select start time',
        main: true,
        name: 'startTime',
        format: (value: number) => {
          return formatUnixTimeWithoutSecond(value)
        },
      },
      onClick: (_: any, rowIndex: number) => {
        setTimePickerPopup({ isPopUpOpen: true, index: rowIndex, time: 'startTime' })
      },
    },
    {
      title: 'End Time',
      content: {
        placeholder: 'Select end time',
        main: true,
        name: 'endTime',
        format: (value: number) => {
          return formatUnixTimeWithoutSecond(value)
        },
      },
      onClick: (_: any, rowIndex: number) => {
        setTimePickerPopup({ isPopUpOpen: true, index: rowIndex, time: 'endTime' })
      },
    },
    {
      title: 'Duration',
      content: {
        type: 'text',
        main: true,
        name: 'duration',
        readOnly: true,
        placeholder: 'Duration',
      },
    },
    {
      title: 'Time Zone',
      flex: 1,
      content: {
        type: 'text',
        main: true,
        name: 'timeZone',
        readOnly: true,
        value: (rowItem: any) => rowItem.timeZone,
      },
    },
  ]

  const shiftPolicyFormik = useFormik<any>({
    initialValues: {
      shifts: [
        {
          shiftName: '',
          startTime: '',
          endTime: '',
          duration: '',
          timeZone: timezone || '', // Set timezone initially
        },
      ],
    },

    onSubmit: () => {},
  })
  useEffect(() => {
    // Update the timeZone in formik values whenever the timezone changes
    shiftPolicyFormik.setFieldValue(
      'shifts',
      shiftPolicyFormik.values.shifts.map((shift: any) => ({
        ...shift,
        timeZone: timezone,
      })),
    )
  }, [timezone])

  // Handle adding a new row
  const addShift = () => {
    const newShift = {
      shiftName: '',
      startTime: '',
      endTime: '',
      duration: '',
      timeZone: timezone || '', // Set timezone for new shift
    }

    // Update the formik state with the new row
    shiftPolicyFormik.setFieldValue('shifts', [...shiftPolicyFormik.values.shifts, newShift])
  }
  useEffect(() => {
    if (shiftPolicy) {
      formik.resetForm({
        values: {
          name: shiftPolicy.policyName,
          shifts: shiftPolicy.shifts.map((shift: any) => ({
            shiftName: shift.shiftName,
            startTime: formatUnixTimeWithoutSecond(shift.startTime / 1000),
            startTimeing: shift.startTime,
            endTimeing: shift.endTime,
            endTime: formatUnixTimeWithoutSecond(shift.endTime / 1000),
            duration: calculateDuration(shift.startTime, shift.endTime),
            timeZone: timezone || '', // Set timezone for existing shifts
          })),
        },
      })
    }
  }, [shiftPolicy])

  useEffect(() => {
    if (shiftPolicy) {
      shiftPolicyFormik.resetForm({
        values: {
          shifts: shiftPolicy.shifts.map((shift: any) => ({
            shiftName: shift.shiftName,
            startTime: formatUnixTimeWithoutSecond(shift.startTime / 1000),
            startTimeing: shift.startTime,
            endTimeing: shift.endTime,
            endTime: formatUnixTimeWithoutSecond(shift.endTime / 1000),
            duration: calculateDuration(shift.startTime, shift.endTime),
            timeZone: timezone || '', // Set timezone for existing shifts
          })),
        },
      })
    }
  }, [shiftPolicy])

  // to check if the shift is overlapping
  const checkForOverlap = (shifts: any) => {
    const overlappingShifts: string[] = [] // To store overlapping shift names

    for (let i = 0; i < shifts?.length; i++) {
      for (let j = i + 1; j < shifts?.length; j++) {
        const shift1 = shifts[i]
        const shift2 = shifts[j]

        let shift1Start = shift1.startTimeing
        let shift1End = shift1.endTimeing
        let shift2Start = shift2.startTimeing
        let shift2End = shift2.endTimeing

        // Handle shifts that cross midnight
        if (shift1End <= shift1Start) {
          shift1End += 24 * 60 * 60 * 1000 // Add 24 hours in milliseconds to shift1End
        }
        if (shift2End <= shift2Start) {
          shift2End += 24 * 60 * 60 * 1000 // Add 24 hours in milliseconds to shift2End
        }

        // Check if the shifts overlap
        if (
          (shift1Start < shift2End && shift1End > shift2Start) || // Shift 1 overlaps with Shift 2
          (shift2Start < shift1End && shift2End > shift1Start) // Shift 2 overlaps with Shift 1
        ) {
          overlappingShifts.push(
            `${shift1?.shiftName} and ${shift2?.shiftName} are overlapping. Please Adjust Timings.`,
          )
        }

        // If shift2 starts exactly when shift1 ends, this is not an overlap
        if (shift1End === shift2Start || shift2End === shift1Start) {
          continue // Adjacent shifts, not overlapping
        }
      }
    }

    return overlappingShifts.length > 0 ? overlappingShifts : [] // Return false if no overlaps found
  }

  useEffect(() => {
    const overlaps: any = checkForOverlap(shiftPolicyFormik.values.shifts)
    if (overlaps) {
      setOverlappingShifts(overlaps)
    } else {
      setOverlappingShifts([])
    }
  }, [shiftPolicyFormik.values.shifts])

  const emptyShiftPolicy = (shifts: any) => {
    if (shifts.length === 0) {
      return true
    } else {
      return false
    }
  }

  return (
    <MotionDiv>
      <>
        <h1 className="my-1 text-2xl font-bold  text-closebtn">
          {paramId?.id ? 'Edit Shift Policy' : 'Add Shift Policy'}
        </h1>
        <WhiteCard className="min-h-[600px]">
          {isShiftPolicyLoading ? (
            <FullPageLoader />
          ) : (
            <>
              <h1 className="text-xl font-medium text-closebtn">Shift Policy</h1>
              <div className="w-1/3">
                <Field
                  type="text"
                  label="Policy name"
                  placeholder="Enter policy name"
                  name="name"
                  formik={formik}
                  required={true}
                />
              </div>
              <EditableTable
                formik={shiftPolicyFormik}
                heading=""
                loading={false}
                columns={policyColumns}
                newRow={[]}
                name="shifts"
                addButtonTitle="Add Shift"
                onAddRow={() => addShift()}
                isOverlap={overlappingShifts}
                error={emptyShifts}
              />
              <div className={`mt-auto flex h-[4rem] items-center justify-end gap-2 bg-white px-6`}>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                  type="submit"
                  color="success"
                  loading={isAddShiftPolicyLoading || isUpdateShiftPolicyLoading}
                  onClick={() => formik.handleSubmit()}
                >
                  {paramId?.id ? 'Save' : 'Add'}
                </Button>
              </div>
              <TimepickerPopup
                isOpen={timePickerPopup.isPopUpOpen}
                onClose={() => {
                  setTimePickerPopup({ isPopUpOpen: false, index: 0, time: '' })
                }}
              >
                <TimePicker
                  onClose={() => setTimePickerPopup({ isPopUpOpen: false, index: 0, time: '' })}
                  formik={shiftPolicyFormik}
                  index={timePickerPopup.index}
                  field={timePickerPopup.time}
                />
              </TimepickerPopup>
            </>
          )}
        </WhiteCard>
      </>
    </MotionDiv>
  )
}

export default ShiftPolicyPage
