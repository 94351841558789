import { useMemo } from 'react'
import { t } from 'i18next'
import { NumberFormat } from 'utils/NumberFormat'
import PaginatedTable from 'libs/table/PaginatedTable'

interface Props {
  data: any
  warehouseName?: string
}

const StorageView = ({ data, warehouseName }: Props) => {
  const formData = data

  const columns = useMemo(
    () => [
      {
        Header: t('product.product_name'),
        accessor: 'name',
      },

      {
        Header: t('product.product_code'),
        accessor: 'code',
      },
      {
        Header: t('product.product_category'),
        accessor: 'category',
      },
      {
        Header: t('product.quantity'),
        accessor: 'quantity',
        align: 'right',
        Cell: ({ value }: any) => <span className="font-mono">{value != null ? NumberFormat(value) : '-'}</span>,
      },
      {
        Header: t('product.uom'),
        accessor: 'uom',
        align: 'right',
        Cell: ({ value }: any) => <span className="font-mono">{value != null ? NumberFormat(value) : '-'}</span>,
      },
    ],
    [],
  )

  return (
    <div>
      <div className="flex flex-col gap-2 rounded-lg bg-gray-100 p-3">
        {warehouseName && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">{t('warehouse.warehouse_name')}</div>
            <div>
              <div className="w-[610px] break-words p-xs-regular">{warehouseName}</div>
            </div>
          </div>
        )}
        <div className="flex justify-between">
          <div className="p-xs-regular-dim">{t('warehouse.storage_area')}</div>
          <div>
            <div className="w-[610px] break-words p-xs-regular">{formData?.name}</div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className=" p-xs-regular-dim">{t('warehouse.total_product')}</div>
          <div>
            <div className="w-[610px] break-words p-xs-regular">{NumberFormat(formData?.products?.length)}</div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <PaginatedTable columns={columns} rows={data?.products} hidePagination={true} />
      </div>
    </div>
  )
}

export default StorageView
