import { scaleMotionProps2 } from 'utils/FramerProps'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import arrowRight from 'assets/icons/misc/arrowRight.svg'
import handyIcon from 'assets/icons/productionPlanning/handyIcon.svg'
import ManufactureOrder from 'assets/icons/productionPlanning/ManufactureOrder.svg'
import DatePickerIcon from 'assets/icons/productionPlanning/DatePickerIcon.svg'
import { useGetCountPlanQuery } from './production-plans/production-plans-endpoints/productionPlan.endpoints'
import { useGetManufactureOrdersStatsQuery } from 'pages/manufacture-orders/manufacture-orders-endpoints/manufacture_order.endpoints'
import { useGetWorkOrdersStatsQuery } from 'pages/work-orders/work-orders-endpoints/workorders.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice.js'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import FullPageLoader from 'libs/loader/FullPageLoader'
import useTabTitle from 'libs/hooks/useTabTitle'
import Card from 'libs/card/Card'
import Chip from 'libs/chip/Chip'

const Planning = () => {
  const selectItem = useSelector(selectSelectedItem)
  useTabTitle(t('planning'))
  const navigate = useNavigate()

  const { data: getProductionPlanCount, isLoading: isProductionPlanCountLoading } = useGetCountPlanQuery(
    {
      plant_id: selectItem?.id,
    },
    {
      skip: !selectItem?.id,
    },
  )
  const { data: WorkOrdersStats, isLoading: isWorkOrdersStatsLoading } = useGetWorkOrdersStatsQuery(
    {
      plantId: selectItem?.id,
    },
    {
      skip: !selectItem?.id,
    },
  )

  const { data: ManufactureOrdersStats, isLoading: isMoStatsLoading } = useGetManufactureOrdersStatsQuery(
    {
      plantId: selectItem?.id,
    },
    {
      skip: !selectItem?.id,
    },
  )

  const cardDetails = [
    {
      icon: DatePickerIcon,
      title: 'plannings.production_plans',
      year: 'FY 2024 - 2025',
      route: '/planning/production-plans',
      disabled: false,
      productionPlans: [
        {
          name: 'IN_PROGRESS',
          count: `${getProductionPlanCount?.IN_PROGRESS ?? 0}`,
        },
        {
          name: 'DELAYED',
          count: `${getProductionPlanCount?.DELAYED ?? 0}`,
        },
        {
          name: 'COMPLETED',
          count: `${getProductionPlanCount?.COMPLETED ?? 0}`,
        },

        {
          name: 'NOT_STARTED',
          count: `${getProductionPlanCount?.NOT_STARTED ?? 0}`,
        },
      ],
    },

    {
      icon: ManufactureOrder,
      title: 'Manufacturing Orders',
      year: 'FY 2024 - 2025',
      route: '/planning/mo',
      productionPlans: [
        {
          name: 'IN_PROGRESS',
          count: ManufactureOrdersStats?.IN_PROGRESS ?? '-',
        },
        {
          name: 'CANCELLED',
          count: ManufactureOrdersStats?.CANCELLED ?? '-',
        },
        {
          name: 'COMPLETED',
          count: ManufactureOrdersStats?.COMPLETED ?? '-',
        },
        {
          name: 'NOT_STARTED',
          count: ManufactureOrdersStats?.NOT_STARTED ?? '-',
        },
      ],
    },
    {
      icon: handyIcon,
      disabled: false,
      title: 'plannings.work_orders',
      year: 'FY 2024 - 2025',
      route: '/planning/work-orders',
      productionPlans: [
        {
          name: 'IN_PROGRESS',
          count: WorkOrdersStats?.IN_PROGRESS ?? '-',
        },
        {
          name: 'CANCELLED',
          count: WorkOrdersStats?.CANCELLED ?? '-',
        },
        {
          name: 'COMPLETED',
          count: WorkOrdersStats?.COMPLETED ?? '-',
        },
        {
          name: 'NOT_STARTED',
          count: WorkOrdersStats?.NOT_STARTED ?? '-',
        },
      ],
    },
  ]

  const isLoading = isProductionPlanCountLoading || isWorkOrdersStatsLoading || isMoStatsLoading
  return (
    <AnimatePresence>
      <motion.div {...scaleMotionProps2}>
        <div>
          <h1 className="h4-bold-black ">{t('planning')}</h1>
          <p className="p-sm-regular-1">{t('plannings.subtitle')}</p>
          {isLoading ? (
            <FullPageLoader />
          ) : (
            <div className="md:grid-flow-col-2 mt-2 grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
              {cardDetails.map((card) => (
                <Card
                  key={card.title}
                  disabled={card?.disabled}
                  title={card.title}
                  subtitle={card.year}
                  content={
                    <div className="grid grid-cols-2 gap-2 pt-4">
                      {card.productionPlans.map((subCard: any) => {
                        return (
                          <Chip
                            type="oval"
                            key={subCard.name}
                            title={subCard.name}
                            color={subCard.color}
                            value={subCard.count}
                          />
                        )
                      })}
                    </div>
                  }
                  rightIcon={<img src={arrowRight} alt="" style={{ height: '15px' }} />}
                  leftIcon={<img src={card.icon} alt="" style={{ height: '24px' }} />}
                  titleStyle="font-medium text-gray-800"
                  subtitleStyle="text-gray-600  text-xs"
                  onClick={() => {
                    navigate(`${card.route}`)
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default Planning
