import ScrollableTable from 'libs/table/ScrollableTable'
import SearchBox from 'libs/searchbox/SearchBox'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import {
  useGetInventoryByIdQuery,
  useGetInventoryQuery,
  useGetReportingGraphByIdQuery,
} from 'pages/inventory/inventory-endpoints/inventory.endpoints'
import { SingleInventory } from 'pages/inventory/inventory-endpoints/inventoryTypes'
import QuantityChart from './components/QuantityChart'
import Field from 'libs/field'
import { useFormik } from 'formik'
import { t } from 'i18next'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'
import { useDebouncedSearch } from 'libs/hooks/useSearch'
import useColumn from './components/column'
import ViewProduct from './components/panel/ViewProduct'
import Panel from 'libs/panel'
import ViewTransactions from './components/panel/ViewTransactions'
import { formatUnixDate } from 'utils/unixConverter'
import useTabTitle from 'libs/hooks/useTabTitle'
import Loader from 'libs/loader/customloader/Loader'

const InventoryDashboard = () => {
  useTabTitle(t('Inventory Dashboard'))
  const plantId = useSelector(selectSelectedItem)
  const { stockColumns, activitiesColumns } = useColumn(plantId.timezone)
  const { searchValue, handleSearch, inputValue } = useDebouncedSearch()
  const [selectedProduct, setSelectedProduct] = useState<any>({
    showPanel: false,
    product: '',
  })
  const [selectedTransaction, setSelectedTransaction] = useState({
    showPanel: false,
    transaction: '',
  })

  const inventoryDetailsFormik = useFormik<any>({
    initialValues: {
      products: null,
      date_range_type: null,
    },
    onSubmit: () => {},
  })

  const selectedItem = useSelector(selectSelectedItem)

  /*************************************** Product Stock ****************************************/

  const [filters, setFilters] = useState({
    page_no: 1,
    page_size: 25,
    sortBy: 'created_on',
    sortDir: 'DESC',
    searchText: '',
  })
  const {
    data,
    isFetching,
    isLoading: inventoryLoading,
  } = useGetInventoryQuery(
    {
      plantId: selectedItem?.id,
      filters: filters,
    },
    {
      skip: !selectedItem?.id,
    },
  )

  const [tableData, setTableData] = useState<SingleInventory[]>([])
  useEffect(() => {
    if (data?.data?.length) {
      if (filters.page_no === 1) {
        setTableData(data.data) // Reset table data for new search
      } else {
        setTableData((prev) => [...prev, ...data.data])
      }
    } else {
      setTableData([])
    }
  }, [data])

  const handleTableScroll = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page_no: prevFilters.page_no + 1,
    }))
  }

  //search
  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page_no: 1,
      searchText: searchValue ? searchValue : '',
    }))
  }, [searchValue])

  /*************************************** Product Stock End ****************************************/

  /***************************************  Graph ****************************************/

  const {
    data: graphData,
    isLoading: graphLoading,
    isUninitialized,
  } = useGetReportingGraphByIdQuery(
    {
      plant_id: selectedItem?.id,
      inventory_id: inventoryDetailsFormik.values?.products?.value?._id || inventoryDetailsFormik.values?.products?._id,
      date_range_type:
        inventoryDetailsFormik.values?.date_range_type?.value?.value ||
        inventoryDetailsFormik.values?.date_range_type?.value ||
        'MONTH',
    },
    {
      skip: !(inventoryDetailsFormik.values?.products?.value?._id || inventoryDetailsFormik.values?.products?._id),
    },
  )

  const [formattedGraphData, setFormattedGraphData] = useState<any[]>([])

  useEffect(() => {
    if (Array.isArray(graphData) && graphData.length > 0) {
      const formattedData = graphData.map((item: any) => {
        return {
          date: formatUnixDate(item.date),
          quantity: item.quantity,
        }
      })
      setFormattedGraphData(formattedData)
    }
  }, [graphData])

  const { data: products, isLoading: productsLoading } = useGetProductQuery(
    {
      plantId: selectedItem?.id,
      filters: {
        page_no: 1,
        page_size: 1000,
        sortBy: 'created_on',
        sortDir: 'DESC',
        searchText: '',
      },
    },
    {
      skip: !selectedItem?.id,
    },
  )

  const productOptions =
    products?.data
      ?.filter((item: any) => item.category === 'Finished Goods')
      .map((item: any) => ({
        label: item.name,
        value: item,
      })) || []

  const dateRangeTypeOptions = [
    { label: 'Last Week', value: { label: 'Last Week', value: 'Last_Week' } },
    { label: 'Last Month', value: { label: 'Last Month', value: 'Last_Month' } },
    { label: 'Last Year', value: { label: 'Last Year', value: 'Last_Year' } },
  ] as any

  useEffect(() => {
    if (productOptions.length)
      inventoryDetailsFormik.resetForm({
        values: {
          products: { label: productOptions[0]?.label, value: productOptions[0]?.value },
          date_range_type: { label: dateRangeTypeOptions[0].label, value: dateRangeTypeOptions[0].value },
        },
      })
  }, [products])

  /***************************************  Graph End ****************************************/

  /*************************************** Recent Activity  ****************************************/

  const [activityFilters, setActivityFilters] = useState({
    page_no: 1,
    page_size: 25,
    searchText: '',
    sortBy: 'created_on',
    sortDir: 'DESC',
    inventoryId: '',
  })

  const {
    data: activitesData,
    isLoading: transactionsLoading,
    isFetching: activitiesFetching,
  } = useGetInventoryByIdQuery(
    {
      plantId: selectedItem.id,
      filters: activityFilters,
    },
    {
      skip: !selectedItem.id,
    },
  )

  const [activityTableData, setActivityTableData] = useState<any[]>([])

  useEffect(() => {
    if (activitesData?.data?.length) {
      if (activityFilters.page_no === 1) {
        setActivityTableData(activitesData.data)
      } else {
        setActivityTableData((prev) => [...prev, ...activitesData.data])
      }
    } else {
      setTableData([])
      setActivityTableData([])
    }
  }, [activitesData])

  const handleActivityTableScroll = () => {
    setActivityFilters((prevFilters) => ({
      ...prevFilters,
      page_no: prevFilters.page_no + 1,
    }))
  }

  /*************************************** selected Product ****************************************/

  const handleSelectedProduct = (product: any) => {
    if (product) {
      setSelectedProduct({
        showPanel: true,
        product: product,
      })
    }
  }

  /*************************************** selected Transaction ****************************************/
  const handleSelectedTransaction = (transaction: any) => {
    if (transaction) {
      setSelectedTransaction({
        showPanel: true,
        transaction: transaction,
      })
    }
  }

  const renderLoader = () => (
    <div className="flex h-full w-full items-center justify-center">
      <Loader size="xl" color="primary" />
    </div>
  )

  const renderNoDataMessage = () => (
    <div className="flex h-full w-full justify-center p-4 text-center p-md-regular">
      <p>No data to show</p>
    </div>
  )

  const shouldRenderLoader = () =>
    (!inventoryDetailsFormik.values.products && isUninitialized) ||
    graphLoading ||
    isUninitialized ||
    !inventoryDetailsFormik.values.products ||
    (graphData?.length > 0 && !formattedGraphData.length)

  /*************************************** Recent Activity End ****************************************/
  return (
    <div className="flex h-[85vh] w-full flex-col gap-2">
      <p className="text-2xl font-bold text-gray-900 ">Inventory Reporting Dashboard</p>
      {
        <>
          <div className="flex  flex-row gap-2">
            {/* product stock */}
            <div className="flex h-[86vh] w-[45%] flex-col gap-5 rounded-lg bg-white p-4 pe-4">
              <div className="flex w-full justify-between">
                <p className="flex pb-1 text-base font-bold">Product Stock</p>
                <SearchBox
                  value={inputValue}
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search Products"
                  onClearSearchBox={() => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      page_no: 1,
                      searchText: '',
                    }))
                    handleSearch('')
                  }}
                />
              </div>
              <ScrollableTable
                columns={stockColumns()}
                rows={tableData}
                pageSize={filters.page_size}
                viewRow={handleSelectedProduct}
                loading={inventoryLoading}
                fetching={isFetching}
                originalData={tableData}
                pagination={data?.paging}
                emptyMessage="No data to show"
                handleTableScroll={handleTableScroll}
              />
            </div>

            {/* stock level and recent activities */}
            <div className="flex h-[85vh] w-[55%]  flex-col gap-2">
              <div className="flex h-[50%] w-full flex-col  rounded-lg bg-white p-4">
                {productsLoading ? (
                  <div className="flex h-full w-full items-center justify-center">
                    <Loader size="xl" color="primary" />
                  </div>
                ) : (
                  <>
                    <p className=" text-base font-bold">Stock level</p>
                    <div className=" mb-2 flex gap-3">
                      <Field
                        required={true}
                        formik={inventoryDetailsFormik}
                        name="products"
                        placeholder={t('inventory_.product_name_placeholder')}
                        type="select"
                        options={productOptions}
                        defaultValue={
                          inventoryDetailsFormik?.values.products?.label
                            ? productOptions[0]
                            : {
                                label: inventoryDetailsFormik?.values.products?.name,
                                value: inventoryDetailsFormik?.values.products,
                              }
                        }
                      />
                      <Field
                        required={true}
                        formik={inventoryDetailsFormik}
                        name="date_range_type"
                        placeholder={'Select Date Range'}
                        type="select"
                        options={dateRangeTypeOptions}
                        defaultValue={
                          !inventoryDetailsFormik?.values.date_range_type?.label
                            ? dateRangeTypeOptions[1]
                            : {
                                label: inventoryDetailsFormik?.values.date_range_type?.label,
                                value: inventoryDetailsFormik?.values.date_range_type,
                              }
                        }
                      />
                    </div>

                    {productOptions.length > 0 ? (
                      <>{shouldRenderLoader() ? renderLoader() : <QuantityChart data={formattedGraphData || []} />}</>
                    ) : (
                      renderNoDataMessage()
                    )}
                  </>
                )}
              </div>
              <div className=" flex h-[50%] w-full flex-col   rounded-lg bg-white p-4 pe-2">
                <p className="pb-1 text-base font-bold">Recent Activities</p>
                <ScrollableTable
                  columns={activitiesColumns()}
                  rows={activityTableData}
                  pageSize={activityFilters.page_size}
                  viewRow={handleSelectedTransaction}
                  loading={transactionsLoading}
                  fetching={activitiesFetching}
                  originalData={activityTableData}
                  pagination={activitesData?.paging}
                  emptyMessage="No data to show"
                  handleTableScroll={handleActivityTableScroll}
                />
              </div>
            </div>
          </div>
          <Panel
            size="large"
            isOpen={selectedProduct.showPanel}
            title={selectedProduct?.product?.name}
            onClose={() => {
              setSelectedProduct((prev: any) => ({
                ...prev,
                showPanel: false,
                product: '',
              }))
            }}
          >
            <ViewProduct
              isOpen={selectedProduct.showPanel}
              closePanel={() => {
                setSelectedProduct((prev: any) => ({
                  ...prev,
                  showPanel: false,
                  product: '',
                }))
              }}
              data={selectedProduct.product}
            />
          </Panel>
          <Panel
            isOpen={selectedTransaction.showPanel}
            title={'View Transaction Details'}
            onClose={() => {
              setSelectedTransaction((prev) => ({
                ...prev,
                showPanel: false,
                transaction: '',
              }))
            }}
          >
            <ViewTransactions
              isOpen={selectedTransaction.showPanel}
              closePanel={() => {
                setSelectedProduct((prev: any) => ({
                  ...prev,
                  showPanel: false,
                  selectedTransaction: '',
                }))
              }}
              data={selectedTransaction.transaction}
            />
          </Panel>
        </>
      }
    </div>
  )
}

export default InventoryDashboard
