import { useEffect, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import CloseIcon from 'assets/icons/popup/CloseIcon'
import { largeMotionProps, mediumMotionProps, smallMotionProps, veryLargeMotionProps } from 'utils/FramerProps'

type PanelProps = {
  children: React.ReactNode
  onClose: () => void
  isOpen: boolean
  title: string
  subTitle?: string
  size?: 'small' | 'medium' | 'large' | 'veryLarge'
}
/**
 * `Panel` is a component that renders a panel with animation.
 *
 * @param {object} props - The properties that define the panel.
 * @param {boolean} props.isOpen - Determines whether the panel is open or closed.
 * @param {React.ReactNode} props.children - The content to be rendered inside the panel.
 * @param {function} props.onClose - The function to be called when the panel is closed.
 * @param {string} props.title - The title of the panel.
 * @param {string} props.subTitle - [Optional] The subtitle of the panel.
 * @param {string} props.size - [Optional] The size of the panel. It can be "small" | "medium" | "large" | "veryLarge";
 * @returns {ReactElement} A panel element that animates in and out based on the `isOpen` prop.
 */
const Panel = ({ isOpen, children, onClose, title, subTitle, size }: PanelProps) => {
  const popupRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const targetNode = event.target as Element
      if (
        popupRef.current &&
        !popupRef.current?.contains(targetNode) &&
        !targetNode.closest('.ignoreref') &&
        !targetNode.closest('.rs-picker-daterange-panel') &&
        !targetNode.closest('.rs-calendar')
      ) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [onClose])

  const mainContainerClasses = isOpen ? 'active bg-overlay' : ''

  let dynamicPropsForSize = mediumMotionProps

  switch (size) {
    case 'small':
      dynamicPropsForSize = smallMotionProps
      break
    case 'large':
      dynamicPropsForSize = largeMotionProps
      break

    case 'veryLarge':
      dynamicPropsForSize = veryLargeMotionProps
      break
    default:
      break
  }

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            {...dynamicPropsForSize}
            ref={popupRef}
            data-testid="panel-container"
            className={`rightSideNav ${mainContainerClasses} flex flex-col p-5`}
          >
            <div className="mb-4 flex flex-row justify-between">
              <div>
                <h2 className="h6">{title}</h2>
                {subTitle && <p className="p-xs-regular-dim">{subTitle}</p>}
              </div>

              <div className="cursor-pointer">
                <span data-testid="panel-close-button" onClick={onClose}>
                  <CloseIcon />
                </span>
              </div>
            </div>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
      <div className={isOpen ? 'sideNav-overlay-dark' : 'sideNav-overlay'} />
    </>
  )
}

export default Panel
