import LockIcon from '../assets/icons/dashboard/LockIcon.svg'
import TextSize from '../assets/icons/dashboard/TextSize.svg'
import Language from '../assets/icons/dashboard/Language.svg'
import DisplayTheme from '../assets/icons/dashboard/DisplayTheme.svg'
import NotificationIcon from '../assets/icons/dashboard/NotificationIcon.svg'
import AppSetting from './icons/dashboard/AppSetting'
import CloseIcon from '../assets/icons/container/CloseIcon.svg'
import InventoryDashboard from './icons/dashboard/InventoryDashboard.svg'
import WorkOrderDashboard from './icons/dashboard/WorkOrderDashboard.svg'

import SortData from '../assets/icons/dashboard/SortData.svg'
import Report from '../assets/icons/configure/Report.svg'
import CatalystLogo from '../assets/icons/top-navigation/CatalystLogo.svg'
import CheckIcon from '../assets/icons/configure/CheckIcon.svg'
import WhiteTickIcon from '../assets/icons/configure/WhiteTickIcon.svg'
import DatePickerIcon from '../assets/icons/productionPlanning/DatePickerIcon.svg'
import handyIcon from '../assets/icons/productionPlanning/handyIcon.svg'
import FoldIcon from '../assets/icons/configure/FoldIcon.svg'
import ShipmentIcon from './icons/inventory/Shipment.svg'
import ProcurementIcon from './icons/inventory/Procurement.svg'
import TransferIcon from './icons/inventory/Transfer.svg'
import AdjustmentIcon from './icons/inventory/Adjustment.svg'
import LastUpdatedIcon from './icons/inventory/LastUpdated.svg'
import ProductionTabCheckIcon from './icons/productionV2/check.svg'
import HelpIcon from './icons/productionV2/HelpIcon.svg'
import CsvIcon from './icons/dashboard/CsvIcon'
import ExcelIcon from './icons/dashboard/ExcelIcon'
import PdfIcon from './icons/dashboard/PdfIcon'
import PrintIcon from './icons/dashboard/PrintIcon'
import ArrowDown from './icons/camera-page/ArrowDown'
import Upload from './icons/container/Upload'
import Download from './icons/container/Download'
import Employee from './icons/employee/employee.svg'
import Designation from './icons/employee/designation.svg'
import TimeSheet from './icons/employee/schedule.svg'
import Vendor from './icons/vendor/Vendor.svg'
import Customer from './icons/customers/Customer.svg'
import EmployeeRoles from './icons/employee-roles/EmployeeRoles.svg'
import ManufactureOrderDashboard from './icons/dashboard/ManufactureOrderDashboard.svg'

export default {
  Download,
  ArrowDown,
  Upload,
  CsvIcon,
  ExcelIcon,
  PdfIcon,
  PrintIcon,
  LockIcon,
  TextSize,
  Language,
  DisplayTheme,
  CloseIcon,
  NotificationIcon,
  AppSetting,
  SortData,
  WhiteTickIcon,
  Report,
  CatalystLogo,
  CheckIcon,
  handyIcon,
  DatePickerIcon,
  FoldIcon,
  AdjustmentIcon,
  ProcurementIcon,
  TransferIcon,
  ShipmentIcon,
  LastUpdatedIcon,
  ProductionTabCheckIcon,
  HelpIcon,
  Employee,
  Vendor,
  Designation,
  TimeSheet,
  Customer,
  EmployeeRoles,
  InventoryDashboard,
  WorkOrderDashboard,
  ManufactureOrderDashboard,
}
