import React from 'react'
import { useState } from 'react'
import './sideMenu.css'
import { NavLink, useLocation } from 'react-router-dom'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { useTranslation } from 'react-i18next'
import icons from 'assets/index.ts'

const activeColor = '#1193F0'
const defaultColor = '#75838D'

const Sidenav = ({ item, role }: { item: any; role: any }) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const location = useLocation()
  const routeName = location.pathname
  const [isHovered, setIsHovered] = useState(false)
  const hasAccess = item.role.includes(role)
  const [linkIsActive, setLinkIsActive] = useState(false)

  if (!hasAccess) {
    return null
  }
  if (item.childrens) {
    return (
      <div className={`  ${open ? 'sidebar-item open' : 'sidebar-item item'}`}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => setOpen(!open)}
          className="flex items-center justify-between "
        >
          <div className="flex gap-2  ">
            <span>{item.icon && item.icon}</span>
            <span className="whitespace-nowrap p-xs-regular ">{t(item.title)}</span>
          </div>
          <div className={`bi-chevron-down toggle-btn ${open ? 'rotate-icon' : ''}`} onClick={() => setOpen(!open)}>
            <KeyboardArrowDownRoundedIcon fontSize="small" />
          </div>
        </div>
        <div className="sidebar-content  ">
          {item.childrens.map((child: any, index: number) => (
            <Sidenav key={index} item={child} role={role} />
          ))}
        </div>
      </div>
    )
  } else {
    return (
      <NavLink
        to={item.path || '#'}
        className={({ isActive }) =>
          `sidebar-item  plain flex items-center justify-center gap-2 p-xs-regular 
          ${isActive ? setLinkIsActive(true) : setLinkIsActive(false)}
          ${routeName === item.path ? '' : ''} 
          ${!item.value && isHovered ? 'bg-[#F8FCFF]' : ''} `
        }
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="flex flex-col items-center justify-center gap-2">
          <span>
            {item.icon &&
              React.cloneElement(item.icon, {
                color: !item.value ? '#75838D' : linkIsActive ? activeColor : defaultColor,
              })}
          </span>
          <span
            className={`${linkIsActive ? 'p-xs-bold' : 'p-xs-regular'} text-center`}
            style={{ color: !item.value ? '#75838D' : 'inherit' }}
          >
            {item.value ? t(item.title) : isHovered ? 'Under Development' : <span>{t(item.title)}</span>}
          </span>
        </div>
        {!item.value && (
          <div>
            <div>{!item.value && !isHovered ? <img src={icons.LockIcon} alt="" /> : null}</div>
          </div>
        )}
      </NavLink>
    )
  }
}

export default Sidenav
