import BlueTick from 'assets/icons/product/BlueTick'
import WhiteCard from 'libs/card/WhiteCard'
import { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import BasicDetails from './tabs/BasicDetails'
import AddressDetails from './tabs/AddressDetails'
import { useFormik } from 'formik'

import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { toast } from 'libs/toast'
import {
  usePostCustomerManagementMutation,
  useGetCustomersQuery,
  usePutCustomerMutation,
} from '../customer-endpoints/customer.endpoints'

import ViewBasicDetails from '../view-customer/ViewBasicDetails'
import ViewAddressDetails from '../view-customer/ViewAddressDetails'
import ViewSalesOrders from '../view-customer/ViewSalesOrders'
import FullPageLoader from 'libs/loader/FullPageLoader'
import useTabTitle from 'libs/hooks/useTabTitle'

const AddEditCustomer = () => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const customerID: string | undefined = params?.id
  const plantID = useSelector(selectSelectedItem)
  const [page, setPage] = useState(1)
  const isView = location?.pathname?.includes('view')

  useTabTitle(isView ? 'View Customer' : customerID ? 'Edit Customer' : 'Add New Customer')

  const steps = [
    { label: 'Basic Details', path: isView ? 'view-basic-details' : 'basic-details' },
    { label: 'Address Details', path: isView ? 'view-address-details' : 'address-details' },
    ...(isView ? [{ label: 'Sales Orders', path: 'view-sales-order' }] : []),
  ]

  const [addCustomer, { isLoading: addEmployeeLoading }] = usePostCustomerManagementMutation()
  const [updateCustomer, { isLoading: updateEmployeeLoading }] = usePutCustomerMutation()

  const {
    data: customersData,
    isLoading: customerLoading,
    isFetching: customerFetching,
  } = useGetCustomersQuery(
    { plantId: plantID?.id, filters: { page_no: 1, page_size: 10, customerID: customerID } },
    { skip: !plantID?.id && !customerID },
  )

  const [lastValidPage, setLastValidPage] = useState(1)
  const loading = addEmployeeLoading || updateEmployeeLoading

  useEffect(() => {
    if (page > lastValidPage) {
      setLastValidPage(lastValidPage + 1)
    }
  }, [page])

  const handleNavigation = (stepIndex: number) => {
    const stepPath = steps[stepIndex].path

    if (customerID) {
      setPage(stepIndex + 1)
      navigate(stepPath)
    } else if (lastValidPage === 2) {
      setPage(stepIndex + 1)
      navigate(stepPath)
    } else if (page === 1) {
      return
    }
  }

  const customerFormik = useFormik({
    initialValues: {
      BasicDetails: {},
      AddressDetails: {},
    },
    onSubmit: (values: any) => {
      const payload = {
        ...values?.BasicDetails,
        ...values?.AddressDetails,
      }
      if (!customerID) {
        addCustomer({ plant_id: plantID.id, data: payload })
          .unwrap()
          .then(() => {
            toast.success('The customer “' + payload.name + '” is added successfully.')
            navigate('/contacts/customer-management')
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong while creating the customer')
          })
      } else {
        updateCustomer({ plantId: plantID.id, customerID: customerID, data: payload })
          .unwrap()
          .then((res: any) => {
            toast.success('The customer “' + res.name + '” is updated successfully.')
            navigate('/contacts/customer-management')
          })
          .catch((error: any) => {
            toast.error(error?.data?.detail ?? 'Something went wrong while updating the customer')
          })
      }
    },
  })

  useEffect(() => {
    const data: any = customersData?.data?.[0]
    if (customerID && customersData) {
      customerFormik.resetForm({
        values: {
          BasicDetails: {
            customerType: data?.customerType,
            name: data?.name,
            businessEmailAddress: data?.businessEmailAddress,
            primaryContactNumber: data?.primaryContactNumber,
            secondaryContactNumber: data?.secondaryContactNumber,
            pointOfContact: data?.pointOfContact,
          },
          AddressDetails: {
            addresses: data?.addresses,
          },
        },
      })
    }
  }, [customersData, customerID])

  return (
    <div className="flex flex-col gap-2">
      <h4 className="h4-bold-black">
        {isView ? customersData?.data?.[0]?.name : customerID ? 'Edit Customer' : 'Add New Customer'}
      </h4>
      <WhiteCard>
        <div className="flex min-h-[70vh] w-full gap-6 rounded bg-white">
          <div className="relative w-1/6 border-r border-border-medium p-2">
            {steps.map((step, index) =>
              !isView ? (
                <div key={index}>
                  <button onClick={() => handleNavigation(index)} className="flex items-center">
                    <div
                      className={`h-6 w-6 rounded-3xl border p-2 ${
                        page === index + 1 ? 'border-primary ' : 'border-border-dark'
                      } ${
                        page > index + 1 ? 'border-primary bg-primary' : 'border-border-dark'
                      } flex items-center justify-center`}
                    >
                      <div className={`text-xs font-bold  `}>{page > index + 1 ? <BlueTick /> : index + 1}</div>
                    </div>
                    <p className={`whitespace-nowrap rounded px-2 text-sm`}>{step.label}</p>
                  </button>
                  {index < steps.length - 1 && (
                    <span
                      className={`ml-3 flex h-[16px] border-l-2 border-dashed ${
                        page > index + 1 ? 'border-primary' : 'border-border-dark'
                      }`}
                    ></span>
                  )}
                </div>
              ) : (
                <div
                  onClick={() => handleNavigation(index)}
                  key={index}
                  className={` rounded-lg hover:bg-inputBg  ${page === index + 1 ? ' rounded-lg bg-brand-primary-200 font-bold' : ''}`}
                >
                  <button className={`flex items-center   `}>
                    <p className="whitespace-nowrap rounded px-2 py-3 text-sm">{step.label}</p>
                  </button>
                </div>
              ),
            )}
          </div>

          {!customerLoading ? (
            <div className="w-4/5" style={{ overflowX: 'auto' }}>
              <Routes>
                <Route
                  path="basic-details"
                  element={<BasicDetails customerFormik={customerFormik} setPage={setPage} loading={loading} />}
                />
                <Route
                  path="address-details"
                  element={<AddressDetails customerFormik={customerFormik} setPage={setPage} loading={loading} />}
                />

                <Route
                  path="view-address-details"
                  element={<ViewAddressDetails customersData={customersData} setPage={setPage} />}
                />

                <Route
                  path="view-basic-details"
                  element={<ViewBasicDetails customersData={customersData} setPage={setPage} />}
                />

                <Route
                  path="view-sales-order"
                  element={
                    <ViewSalesOrders customersData={customersData} setPage={setPage} loading={customerFetching} />
                  }
                />
              </Routes>
            </div>
          ) : (
            <FullPageLoader />
          )}
        </div>
      </WhiteCard>
    </div>
  )
}

export default AddEditCustomer
