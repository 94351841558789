import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import arrowRight from 'assets/icons/misc/arrowRight.svg'
import processCardLight from 'assets/icons/misc/processCardLight.svg'
import { useDispatch, useSelector } from 'react-redux'
import { selectSelectedItem, setNavActive } from 'store/redux/navSlice'
import { useGetProcessesByGroupQuery } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'
import icons from 'assets/index.ts'
import MotionDiv from 'libs/motionDiv'
import { firstLetterUppercase } from 'pages/operational-processes/utils'
import Card from 'libs/card/Card'
import { NumberFormat } from 'utils/NumberFormat'
import FullPageLoader from 'libs/loader/FullPageLoader'

const DashboardTilesProdAndReview = ({ title, showCount }: { title: any; showCount: any }) => {
  const selectedItem = useSelector(selectSelectedItem)
  const { data: processesByGroup, isLoading: isProcessesByGroupLoading } = useGetProcessesByGroupQuery(
    {
      plantId: selectedItem?.id,
    },
    {
      skip: !selectedItem?.id,
    },
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavActive())
  }, [])

  return (
    <MotionDiv>
      <>
        <h1 className="text-2xl font-bold text-[#041220] ">{title}</h1>
        {processesByGroup?.length === 0 ? (
          <div className="text-gray-400">
            Currently you don't have any active operational process. Please ask admin to add one.{' '}
          </div>
        ) : (
          <div className="flex ">
            {isProcessesByGroupLoading ? (
              <FullPageLoader />
            ) : (
              <div>
                {processesByGroup?.map((department) => (
                  <div key={department?.id} className="mb-4 mt-1">
                    <h3 className="mb-1 max-w-40 truncate text-[#75838D]  h6-bold">
                      {firstLetterUppercase(department?.name)}
                    </h3>
                    <ProcessComponent data={department} showCount={showCount} />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </>
    </MotionDiv>
  )
}

const ProcessComponent = ({ data, showCount }: { data: any; showCount: any }) => {
  const navigate = useNavigate()
  const page = showCount ? 'review' : 'productionV2'

  const handleClick = (processId: any) => {
    navigate(`/${page}/${data.name}/${processId}`)
  }

  return (
    <div className="flex w-full flex-wrap gap-4 ">
      {data?.processes.map((process: any) => (
        <div className="w-80">
          <Card
            key={process.id}
            title={process.name}
            subtitle={
              showCount
                ? process.pending_entry_count !== undefined
                  ? `${NumberFormat(process.pending_entry_count)} entries left to review`
                  : '0 entries left to review'
                : ''
            }
            leftIcon={<img src={processCardLight} alt="" style={{ height: '13px' }} />}
            rightCenterIcon={<img src={arrowRight} alt="" style={{ height: '15px' }} />}
            onClick={() => handleClick(process.id)}
            reviewIcon={
              showCount && process.pending_entry_count === 0 ? (
                <img src={icons.CheckIcon} alt="" style={{ height: '16px' }} />
              ) : (
                <img src={icons.Report} alt="" style={{ height: '13px' }} />
              )
            }
          />
        </div>
      ))}
    </div>
  )
}

export default DashboardTilesProdAndReview
