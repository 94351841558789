import { useEffect, useMemo, useState } from 'react'
import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import Panel from 'libs/panel'
import StorageView from '../panel/StorageView'
import EditStorage from '../panel/StorageEdit'
import DeleteStorage from '../popup/StorageDelete'
import DeleteWareHouses from '../popup/WareHouseDelete'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetWarehouseByIdQuery, useDeleteStorageAreaMutation } from '../warehouses-endpoints/warehouses.endpoints'
import { FormatTimeDifference } from 'utils/FormatTimeDifference'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import Popup from 'libs/popup/Popup'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { t } from 'i18next'
import FullPageLoader from 'libs/loader/FullPageLoader'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import { NumberFormat } from 'utils/NumberFormat'
import useTabTitle from 'libs/hooks/useTabTitle'
import PaginatedTable from 'libs/table/PaginatedTable'
import HeaderCard from 'libs/card/HeaderCard'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'

type WarehouseData = {
  name: string
  storage_areas: any[]
}

const ViewDetails = () => {
  const selectedPlant = useSelector(selectSelectedItem)
  const { id } = useParams()
  const { data, isLoading } = useGetWarehouseByIdQuery({
    plant_id: selectedPlant?.id,
    warehouse_id: id,
  })

  const filters = {
    page_no: 1,
    page_size: 1000,
    sortBy: 'created_on',
    sortDir: 'DESC',
    searchText: '',
  }

  const { data: productsObj } = useGetProductQuery(
    {
      plantId: selectedPlant?.id,
      filters: filters,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  useTabTitle(`${data?.name ? data?.name : ''}`)

  const [deleteStorage, { isLoading: deleteStorageLoading }] = useDeleteStorageAreaMutation()

  const navigate = useNavigate()
  const [key, setKey] = useState(0)
  const [selectedStorageArea, setSelectedStorageArea] = useState([])
  const [editPanel, setEditPanel] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false)
  const [viewPanel, setViewPanel] = useState(false)
  const [deleteWarehousePopup, setDeleteWarehousePopup] = useState(false)
  const [warehouseData, setWarehouseData] = useState<WarehouseData>()

  const columns = useMemo(
    () => [
      {
        Header: t('warehouse.storage_area_name'),
        accessor: 'name',
        width: 200,
      },
      {
        Header: t('warehouse.allow_products_name'),
        width: 250,
        accessor: 'products',
        Cell: ({ value }: any) => {
          let products = value?.slice(0, 4)
          const remainingCount = value?.length - products?.length

          return products?.length > 0 ? (
            <div className="flex flex-wrap">
              {products?.map((product: any, index: any) => (
                <span key={index} className={`m-1 rounded-[28px] bg-primary-200 px-2 py-0.5 text-primary-700`}>
                  {product?.name ?? '-'} ({NumberFormat(product?.quantity)})
                </span>
              ))}
              <div>
                {remainingCount > 0 && (
                  <div
                    className="ml-4 mt-1 text-primary-700 underline underline-offset-2 hover:font-semibold"
                    // onClick={() => toggleViewBomPanel(row?.original)}
                  >
                    +{NumberFormat(remainingCount)} {t('warehouse.more')}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>-</div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'action',
        width: 1,
        align: 'right',

        Cell: ({ row }: any) => (
          <div className="flex gap-4">
            <div className="flex  gap-1">
              <button
                onClick={(event) => {
                  event.stopPropagation()
                  toggleEditPanel(row?.original)
                }}
              >
                <Pen />
              </button>
            </div>
            <div className="flex  gap-1">
              <button
                onClick={(event) => {
                  event.stopPropagation()
                  toggleDelete(row?.original)
                }}
              >
                <Bin />
              </button>
            </div>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )
  const toggleViewPanel = (entryData: any) => {
    setSelectedStorageArea(entryData)
    setViewPanel(!viewPanel)
  }
  const toggleDelete = (entryData: any) => {
    setSelectedStorageArea(entryData)
    setDeletePopup(!deletePopup)
  }
  const toggleDeleteEdit = () => {
    setDeletePopup(!deletePopup)
  }
  const toggleEditPanel = (entryData: any) => {
    setSelectedStorageArea(entryData)
    setEditPanel(!editPanel)
  }
  const toggleDeleteWareHouses = () => {
    setSelectedStorageArea(data)
    setDeleteWarehousePopup(!deleteWarehousePopup)
  }

  const countTotalProducts = function (data: any) {
    let products = 0
    data?.storage_areas?.forEach((storageArea: any) => {
      products += storageArea.products.length
    })
    const totalProducts = NumberFormat(products)
    return totalProducts
  }

  useEffect(() => {
    if (id && data) {
      const updateWareHouseData = {
        ...data,
        storage_areas: data.storage_areas.map((area: any) => ({
          id: area._id ? area._id : '',
          name: area.name,
          products: area.products.map((product: any) => ({
            _id: product._id,
            name: product.name,
            code: product.code,
            quantity: product.quantity,
          })),
        })),
      }

      setWarehouseData(updateWareHouseData)
    }
  }, [data])

  const handleDelete = (id: any) => {
    deleteStorage({
      wareHouse_id: data?._id,
      plant_id: selectedPlant?.id,
      storage_id: id,
    })
      .unwrap()
      .then(() => {
        toast.success(`The Storage Area “${data?.name}” is deleted successfully.`)
        setDeletePopup(false)
        setEditPanel(false)
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again.')
      })
  }

  if (isLoading) {
    return <FullPageLoader />
  }

  return (
    <MotionDiv>
      <WhiteCard className="min-h-[80vh]">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <div>
              <h1 className="h5">{t('warehouse.warehouse_name')}</h1>
            </div>
            <div className="flex gap-3">
              <Button color="error" onClick={toggleDeleteWareHouses}>
                {t('warehouse.delete_warehouse')}
              </Button>
              <Button color="primary" onClick={() => navigate(`/settings/configure/warehouses/edit/${data._id}`)}>
                {t('warehouse.edit_warehouse_details')}
              </Button>
            </div>
          </div>

          <div className="flex w-[390px] flex-col gap-2">
            <div className="flex justify-between ">
              <div className="p-xs-regular-dim">{t('warehouse.warehouse_name')}</div>
              <div>
                <div className="w-60 break-words p-xs-regular">{data?.name}</div>
              </div>
            </div>

            <div className="flex justify-between ">
              <div className=" p-xs-regular-dim">{t('warehouse.last_updated')}</div>
              <div>
                <div className="w-60 p-xs-regular">
                  {data?.updated_on ? FormatTimeDifference(data.updated_on) : '-'}
                </div>
              </div>
            </div>

            <div className="flex justify-between ">
              <div className=" p-xs-regular-dim">{t('warehouse.updated_by')}</div>
              <div>
                <div className="w-60 break-words p-xs-regular">
                  {data?.updated_by?.first_name + ' ' + data?.updated_by?.last_name}
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-2 flex gap-5">
            <HeaderCard
              value={data?.storage_areas?.length ? NumberFormat(data.storage_areas.length) : '-'}
              title={t('warehouse.total_storage_areas')}
            />
            <HeaderCard
              value={countTotalProducts(data) ? countTotalProducts(data) : '-'}
              title={t('warehouse.total_products')}
            />
          </div>
          <div className="mt-4 w-full flex-col">
            <PaginatedTable
              columns={columns}
              emptyMessage={t('warehouse.table_placeholder')}
              rows={warehouseData?.storage_areas || []}
              key={key}
              onSingleClick={toggleViewPanel}
              onPageNumberChange={() => {}}
              loading={false}
              hidePagination={true}
            />
          </div>

          <Panel
            isOpen={viewPanel}
            title={t('warehouse.storage_area')}
            onClose={() => {
              setSelectedStorageArea([])
              setViewPanel(false)
            }}
            size="veryLarge"
          >
            <StorageView data={selectedStorageArea} warehouseName={data?.name} />
          </Panel>
          <Panel
            isOpen={editPanel}
            title={t('warehouse.edit_storage_area')}
            onClose={() => {
              setSelectedStorageArea([])
              setEditPanel(false)
            }}
          >
            <EditStorage
              closePanel={() => {
                setSelectedStorageArea([])
                setEditPanel(false)
              }}
              warehouse={warehouseData}
              storageArea={selectedStorageArea}
              setKey={setKey}
              products={productsObj?.data}
              DeletePopUp={toggleDeleteEdit}
            />
          </Panel>
          <Popup
            isOpen={deletePopup}
            title={t('warehouse.delete_storage_area')}
            onClose={() => {
              setSelectedStorageArea([])
              setDeletePopup(false)
            }}
          >
            <DeleteStorage
              closePopUp={toggleDelete}
              data={selectedStorageArea}
              onDelete={() => {
                if (selectedStorageArea) {
                  const id = (selectedStorageArea as any)?.id
                  handleDelete(id)
                }
              }}
              isLoading={deleteStorageLoading}
            />
          </Popup>
          <Popup
            isOpen={deleteWarehousePopup}
            title={t('warehouse.delete_warehouse')}
            onClose={() => {
              setSelectedStorageArea([])
              setDeleteWarehousePopup(false)
            }}
          >
            <DeleteWareHouses closePopUp={toggleDeleteWareHouses} data={selectedStorageArea} />
          </Popup>
          <div
            className={
              viewPanel || editPanel || deletePopup || deleteWarehousePopup ? 'sideNav-overlay-dark' : 'sideNav-overlay'
            }
          ></div>
        </div>
      </WhiteCard>
    </MotionDiv>
  )
}

export default ViewDetails
