import { useMemo, useState } from 'react'
import MotionDiv from 'libs/motionDiv'
import { t } from 'i18next'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { FormatTimeDifference } from 'utils/FormatTimeDifference'
import { useGetWarehouseAndStorageCountQuery, useGetWarehouseQuery } from './warehouses-endpoints/warehouses.endpoints'
import FilterPanel from './panel/FilterPanel'
import Panel from 'libs/panel'
import FullPageLoader from 'libs/loader/FullPageLoader'
import WhiteCard from 'libs/card/WhiteCard'
import { NumberFormat } from 'utils/NumberFormat'
import useTabTitle from 'libs/hooks/useTabTitle'
import HeaderCard from 'libs/card/HeaderCard'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { useNavigate } from 'react-router-dom'

const additionalFilters = {
  createdBetween: [],
  updatedBetween: [],
  allowedProducts: [],
  noOfStorageAreaRange: [],
}
const Warehouse = () => {
  const navigate = useNavigate()
  useTabTitle(t('warehouses'))
  const selectedItem = useSelector(selectSelectedItem)
  const [isShowFilter, setIsShowFilter] = useState(false)

  const [filters, setFilters] = useState({
    page_no: 1,
    page_size: 10,
    searchText: '',
    create_start_date: 0,
    create_end_date: 0,
    update_start_date: 0,
    update_end_date: 0,
    createdBetween: [],
    updatedBetween: [],
    allowedProducts: [],
    noOfStorageAreaRange: [],
    sortBy: 'created_on',
    sortDir: 'DESC',
  })

  const translation = localStorage.getItem('i18nextLng')

  const { data: warehouseAndStorageCount, isLoading: getCountLoading } = useGetWarehouseAndStorageCountQuery({
    plant_id: selectedItem?.id,
  })

  const handleDoubleClick = (warehouse: any) => {
    navigate(`/settings/configure/warehouses/${warehouse._id}`)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Warehouse Names',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return (
            <div
              {...{
                style: {
                  cursor: 'pointer',
                  paddingLeft: `${row.depth * 3}rem`,
                },
              }}
            >
              <div className="flex gap-3">{row.original.name}</div>
            </div>
          )
        },
        width: 50,
      },
      {
        Header: 'Number of storage area',
        accessor: 'storageAreas',
        width: 150,
        Cell: ({ row }) => {
          return <div className="font-mono">{NumberFormat(row.original?.storage_areas?.length) ?? '-'}</div>
        },
      },
      {
        Header: 'Last updated',
        accessor: 'updated_on',
        Cell: ({ row }: any) => {
          const formattedDate = FormatTimeDifference(row.original?.updated_on)
          const userData = row.original?.updated_by !== null ? row.original?.updated_by : row.original?.created_by
          const firstName = userData?.first_name
          const lastName = userData?.last_name
          const fullName = `${
            translation === 'hi' ? `${firstName} ${lastName} ${t('by')}` : ` ${t('by')} ${firstName} ${lastName}`
          }`
          return formattedDate + ' ' + fullName
        },
      },
    ],
    [],
  )

  const handleAddWarehouse = () => {
    navigate('/settings/configure/warehouse/new')
  }

  return (
    <MotionDiv>
      <>
        {getCountLoading ? (
          <FullPageLoader />
        ) : (
          <div className="flex flex-col gap-4">
            <div>
              <h1 className="h4-bold-black ">{t('warehouses')}</h1>
              <div className="flex justify-between">
                <p className="w-4/5 p-sm-regular">{t('warehouse.warehouse_description')}</p>
              </div>
            </div>

            <>
              <WhiteCard>
                <div className="flex gap-4">
                  <HeaderCard
                    value={
                      warehouseAndStorageCount?.WarehouseCount > 0
                        ? NumberFormat(warehouseAndStorageCount?.WarehouseCount)
                        : '-'
                    }
                    title={t('warehouse.total_warehouses')}
                  />
                  <HeaderCard
                    value={
                      warehouseAndStorageCount?.StorageAreaCount > 0
                        ? NumberFormat(warehouseAndStorageCount?.StorageAreaCount)
                        : '-'
                    }
                    title={t('warehouse.total_storage_areas')}
                  />
                </div>
              </WhiteCard>
              <FilterableSearchTable
                useQuery={useGetWarehouseQuery}
                columns={columns}
                addButton={{ label: t('warehouse.add_warehouses'), onClick: handleAddWarehouse }}
                placeholders={{
                  search: 'Search by warehouse name',
                  emptyMessage: t('warehouse.table_warehouse_placeholder'),
                  filterEmptyMessage: 'No matching results found',
                }}
                onDoubleClick={handleDoubleClick}
                filterPanelComponent={FilterPanel}
                expandableRow={true}
                additionalFilters={additionalFilters}
              />
              <Panel
                isOpen={isShowFilter}
                title="Filters"
                size="large"
                subTitle="Filter the data according to your requirements"
                onClose={() => {
                  setIsShowFilter(false)
                }}
              >
                <FilterPanel
                  filters={filters}
                  closePanel={() => {
                    setIsShowFilter(false)
                  }}
                  setFilters={setFilters}
                />
              </Panel>
            </>
          </div>
        )}
      </>
    </MotionDiv>
  )
}

export default Warehouse
