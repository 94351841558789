import { useState, useEffect } from 'react'
const userLocale = navigator.language
// const userTimeZone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone

function getCurrentDateTime(userTimeZone: string) {
  const current = Date.now()
  const currentDate = new Intl.DateTimeFormat(userLocale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: userTimeZone,
  })
    .format(current)
    .replace(/\//g, '-')

  const currentTime = new Intl.DateTimeFormat(userLocale, {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'shortOffset',
    timeZone: userTimeZone,
  })
    .format(current)
    .toUpperCase()

  return { currentDate, currentTime }
}

export function getDateFormat() {
  const date = new Date(2020, 0, 1) // Arbitrary date
  const formatParts = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatToParts(date)
  let dateFormat = ''
  formatParts.forEach((part) => {
    switch (part.type) {
      case 'day':
        dateFormat += 'dd'
        break
      case 'month':
        dateFormat += 'MM'
        break
      case 'year':
        dateFormat += 'yyyy'
        break
      case 'literal':
        dateFormat += part.value
        break
    }
  })
  return dateFormat.replace(/\//g, '-')
}

export function getPlaceholder() {
  const date = new Date(2020, 0, 1) // Arbitrary date
  const formatParts = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatToParts(date)
  let dateFormat = ''
  formatParts.forEach((part) => {
    switch (part.type) {
      case 'day':
        dateFormat += 'DD'
        break
      case 'month':
        dateFormat += 'MM'
        break
      case 'year':
        dateFormat += 'YYYY'
        break
      case 'literal':
        dateFormat += part.value
        break
    }
  })
  return dateFormat.replace(/\//g, '-')
}

export function useCurrentDateTime(userTimeZone: string) {
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime(userTimeZone))

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime(userTimeZone))
    }, 1000)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, [userTimeZone]) // Empty dependency array ensures this effect runs only once

  return currentDateTime
}
