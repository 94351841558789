import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import Chip from 'libs/chip/Chip'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { combineAddressTypes } from '../add-edit-customer/tabs/AddressDetails'
import { formatString } from 'utils/trimStringValues'

const ViewAddressDetails = ({ customersData, setPage }: any) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const Customer = combineAddressTypes(customersData?.data?.[0]?.addresses)
  const columns = [
    {
      Header: 'Address Type',
      accessor: 'addressType',
      Cell: ({ row }: any) => (
        <>
          {formatString(row.values.addressType)}

          {row.original.isPrimary ? (
            <div className="ml-2">
              <Chip title="PRIMARY" />{' '}
            </div>
          ) : null}
        </>
      ),
    },
    {
      Header: 'Address line 1',
      accessor: 'addressLine1',
      Cell: ({ row }: any) => <>{row.values.addressLine1}</>,
    },
    {
      Header: 'Address line 2',
      accessor: 'addressLine2',
    },
    {
      Header: 'City',
      accessor: 'city',
      Cell: ({ value }: any) => {
        return <span>{value}</span>
      },
    },
    {
      Header: 'State',
      accessor: 'state',
      Cell: ({ value }: any) => {
        return <span>{value}</span>
      },
    },
    {
      Header: 'Zip Code',
      accessor: 'zipCode',
    },
    {
      Header: 'Country',
      accessor: 'country',
      Cell: ({ value }: any) => {
        return <span>{value}</span>
      },
    },
  ]

  useEffect(() => {
    setPage(2)
  }, [])

  return (
    <MotionDiv>
      <WhiteCard className=" flex min-h-[70vh] flex-col gap-6">
        <div className="flex w-full justify-between">
          <h5 className="h5">Address Details</h5>
          <div>
            <Button
              color="primary"
              startIcon={<PlusIcon />}
              onClick={() => {
                navigate(`/contacts/customer-management/${id}/edit/address-details`)
              }}
            >
              Edit Address Details
            </Button>
          </div>
        </div>

        <PaginatedTable
          columns={columns}
          rows={Customer}
          hidePagination={true}
          emptyMessage={'No address details have been added. Please add address details.'}
        />
      </WhiteCard>
    </MotionDiv>
  )
}

export default ViewAddressDetails
