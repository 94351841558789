import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import { t } from 'i18next'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { useDeleteCustomersMutation } from '../customer-endpoints/customer.endpoints'

interface Props {
  closePopUp: any
  data: any
}

const DeleteCustomer = ({ closePopUp, data }: Props) => {
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)

  const [DeleteCustomer, { isLoading: deleteEntryLoading }] = useDeleteCustomersMutation()

  const handleDelete = () => {
    DeleteCustomer({ customerID: data?.customer?._id, plantId: plant.id })
      .unwrap()
      .then(() => {
        closePopUp()
        toast.success(`Customer “${data?.customer?.name}” is removed successfully.`)
        navigate('/contacts/customer-management')
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }

  return (
    <>
      <p className="p-xs-regular">
        You are about to delete a{' '}
        <span className="break-words p-xs-bold">
          “{'Customer'} {data?.customer?.name}”
        </span>
        .Are you sure you want to proceed?
      </p>

      <div className=" mt-1 flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDelete} loading={deleteEntryLoading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </>
  )
}

export default DeleteCustomer
