import { useMemo } from 'react'
import { NumberFormat } from 'utils/NumberFormat'
import PaginatedTable from 'libs/table/PaginatedTable'

const ViewBom = ({ data }: any) => {
  const rows = [...data?.bill_of_materials]
  const BOMColumns = useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'bom_product_details',
        Cell: ({ value }: any) => (
          <div className="">
            <div className="p-xs-regular">{value?.name}</div>
            <div className="p-xs-regular-dim">{value?.code}</div>
          </div>
        ),
        disableSortBy: true,
        width: 60,
      },

      {
        Header: 'Quantity',
        accessor: 'quantity',
        align: 'right',
        Cell: ({ value }: any) => <span className="font-mono">{NumberFormat(value)}</span>,
        disableSortBy: true,
        width: 60,
      },
      {
        Header: 'Unit',
        accessor: 'bom_product_details.unit_of_measure',
        Cell: ({ row }) => <p>{row?.original?.bom_product_details?.unit_of_measure}</p>,
        disableSortBy: true,
        width: 50,
      },
    ],
    [],
  )
  return (
    <PaginatedTable
      columns={BOMColumns}
      emptyMessage={"You don't have any BOM created for this Product"}
      rows={rows}
      hidePagination={true}
    />
  )
}

export default ViewBom
