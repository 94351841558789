import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import appInsights from './appInsights'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

const PageTracker = () => {
  const { data } = useUserInfoQuery({})

  const location = useLocation()
  const startTime = useRef(Date.now())
  const pageTitle = useRef(document.title)
  const loadStartTime = useRef(Date.now()) // To track page load time

  window.addEventListener('error', (event) => {
    appInsights.trackException({
      exception: event.error,
      properties: {
        customSource: 'windowError',
        pageName: pageTitle.current,
        userName: data ? data?.username : 'Anonymous', // Include user name if logged in
      },
    })
  })

  useEffect(() => {
    const now = Date.now()
    const timeSpent = now - startTime.current

    // Track page load time when the component mounts
    const loadTime = Date.now() - loadStartTime.current
    appInsights.trackMetric({
      name: 'Page Load Time',
      average: loadTime / 1000, // Convert to seconds
      properties: {
        pageName: pageTitle.current,
        userName: data ? data?.username : 'Anonymous', // Include user name if logged in
      },
    })

    if (timeSpent > 0) {
      appInsights.trackMetric({
        name: 'Time Spent on Page',
        average: timeSpent / 1000, // Convert to seconds
        properties: {
          pageName: pageTitle.current,
          userName: data ? data?.username : 'Anonymous', // Include user name if logged in
        },
      })
    }

    // Track page view using document.title
    appInsights.trackPageView({
      name: pageTitle.current,
      properties: {
        userName: data ? data?.username : 'Anonymous', // Include user name if logged in
      },
    })

    // Update start time for the new page
    startTime.current = Date.now()
    pageTitle.current = document.title // Update page title for the new page
    loadStartTime.current = Date.now() // Reset load start time

    return () => {
      // Track time spent when leaving the page
      const timeSpentOnExit = Date.now() - startTime.current
      appInsights.trackMetric({
        name: 'Time Spent on Page',
        average: timeSpentOnExit / 1000, // Convert to seconds
        properties: {
          pageName: pageTitle.current,
          userName: data ? data?.username : 'Anonymous', // Include user name if logged in
        },
      })
    }
  }, [location, data])

  useEffect(() => {
    // Update the page title when the document title changes
    pageTitle.current = document.title
  }, [document.title])

  return null
}

export default PageTracker
