import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts'
import { formatDateRange, formatDateWithoutYear } from 'utils/unixConverter'

const COLORS = ['#87D4B1', '#F0DB9A', '#9DCCED', '#ECB0B6']

interface LegendEntry {
  name: string
  value: number
}

interface CustomLegendProps {
  formattedResult: LegendEntry[]
}

const CustomLegend: React.FC<CustomLegendProps> = ({ formattedResult }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginBottom: 20,
    }}
  >
    {formattedResult?.map((entry, index) => (
      <div
        key={`legend-${index}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '0 5px 2px 1px',
        }}
      >
        <div
          style={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: COLORS[index % COLORS.length],
            marginRight: 2,
          }}
        />
        <span className="whitespace-nowrap p-xs-regular">{entry.name}</span>
      </div>
    ))}
  </div>
)

interface CustomTickProps {
  x: number
  y: number
  payload: { value: string }
}

const CustomTick: React.FC<CustomTickProps> = ({ x, y, payload }:any) => {
  return (
    <text
      x={x}
      y={y}
      dy={-2}
      dx={-20}
      textAnchor="middle"
      transform={`rotate(-30, ${x}, ${y})`} // Rotate labels by -15 degrees
      fill="#666"
      fontSize="10px"
    >
      {payload.value}
    </text>
  )
}

interface DataItem {
  date_range: number[]
  counts: {
    COMPLETED?: number
    IN_PROGRESS?: number
    NOT_STARTED?: number
    CANCELLED?: number
  }
}

interface FormikValues {
  dateRange?: string[] | any
}

interface BarGraphProps {
  formik: { values: FormikValues }
  apiData: DataItem[]
}

const BarGraph: React.FC<BarGraphProps> = ({ formik, apiData }) => {
  const aggregateDataByDate = (data: DataItem[]) => {
    return data.reduce(
      (acc, item) => {
        const dateRange = item.date_range?.length
          ? formatDateWithoutYear(item.date_range[0]) === formatDateWithoutYear(item.date_range[1])
            ? formatDateWithoutYear(item.date_range[0])
            : formatDateRange(item.date_range[0], item.date_range[1])
          : ''

        if (!acc[dateRange]) {
          acc[dateRange] = {
            date: dateRange,
            Completed: 0,
            InProgress: 0,
            NotStarted: 0,
            Cancelled: 0,
          }
        }
        acc[dateRange].Completed += item.counts?.COMPLETED || 0
        acc[dateRange].InProgress += item.counts?.IN_PROGRESS || 0
        acc[dateRange].NotStarted += item.counts?.NOT_STARTED || 0
        acc[dateRange].Cancelled += item.counts?.CANCELLED || 0

        return acc
      },
      {} as Record<string, any>,
    )
  }

  const data = Object.values(aggregateDataByDate(apiData || []))

  const totals = apiData?.reduce(
    (acc, item) => {
      acc.Completed += item.counts?.COMPLETED || 0
      acc.InProgress += item.counts?.IN_PROGRESS || 0
      acc.NotStarted += item.counts?.NOT_STARTED || 0
      acc.Cancelled += item.counts?.CANCELLED || 0
      return acc
    },
    { Completed: 0, InProgress: 0, NotStarted: 0, Cancelled: 0 },
  )

  const formattedResult = [
    { name: 'Completed', value: totals?.Completed },
    { name: 'In Progress', value: totals?.InProgress },
    { name: 'Not Started', value: totals?.NotStarted },
    { name: 'Cancelled', value: totals?.Cancelled },
  ]

  return (
    <div className="w-full rounded-lg">
      <ResponsiveContainer width="100%" minHeight={250}>
        <BarChart
          data={formik?.values?.dateRange?.length > 1 ? data : formattedResult}
          margin={{ top: 20, right: 30, left: 0, bottom: 25 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={formik?.values?.dateRange?.length > 1 ? 'date' : 'name'}
            tick={<CustomTick x={0} y={0} payload={{
              value: ''
            }} />}
            interval={0} // Show every second label, adjust as needed
            tickMargin={10} // Increase the margin between the axis and the labels
            tickLine={false}
            axisLine={false}
          />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip cursor={false} contentStyle={{ borderRadius: '10px' }} />
          <Legend content={<CustomLegend formattedResult={formattedResult} />} verticalAlign="top" align="center" />
          {formik?.values?.dateRange?.length > 1 ? (
            <>
              <Bar dataKey="Completed" stackId="a" fill={COLORS[0]}>
                {data?.map((_, index) => <Cell key={`cell-completed-${index}`} fill={COLORS[0]} />)}
              </Bar>
              <Bar dataKey="InProgress" stackId="a" fill={COLORS[1]}>
                {data?.map((_, index) => <Cell key={`cell-inprogress-${index}`} fill={COLORS[1]} />)}
              </Bar>
              <Bar dataKey="NotStarted" stackId="a" fill={COLORS[2]}>
                {data?.map((_, index) => <Cell key={`cell-notstarted-${index}`} fill={COLORS[2]} />)}
              </Bar>
              <Bar dataKey="Cancelled" stackId="a" fill={COLORS[3]}>
                {data?.map((_, index) => <Cell key={`cell-cancelled-${index}`} fill={COLORS[3]} />)}
              </Bar>
            </>
          ) : (
            <Bar dataKey="value">
              {formattedResult?.map((_, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default BarGraph
