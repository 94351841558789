import { FormikContextType, useFormik } from 'formik'
import Button from 'libs/button/Button'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import NewEditableTable from 'libs/table/NewEditableTable'
import { toast } from 'libs/toast'

interface AddressDetailsProps {
  vendorFormik: FormikContextType<any>
  setPage: React.Dispatch<React.SetStateAction<number>>
  loading: boolean
  vendorID: string
}
const AddressDetails: React.FC<AddressDetailsProps> = ({ vendorFormik, vendorID, setPage, loading }) => {
  const navigate = useNavigate()
  const [data, setData] = useState([])

  const AddressDetailsFormik = useFormik({
    initialValues: {
      billingAddresses: [
        {
          addressType: '',
          addressLine1: '',
          addressLine2: '',
          country: '',
          state: '',

          city: '',
          zipCode: '',
          isPrimary: false,
        },
      ],
    },
    onSubmit: (values: any) => {
      let isEmpty = true

      if (values?.billingAddresses.length == 0) {
        isEmpty = false
      }

      values?.billingAddresses?.forEach((address: any) => {
        if (
          !address.city ||
          !address.addressLine1 ||
          !address.addressLine2 ||
          !address.zipCode ||
          !address.country ||
          !address.state
        ) {
          isEmpty = false
        }
      })

      if (isEmpty) {
        vendorFormik.setFieldValue('AddressDetails', values)
        vendorFormik.handleSubmit()
      } else {
        toast.error('Please fill in all the address fields of billing addresses')
      }
    },
  })

  useEffect(() => {
    setPage(2)
  }, [])
  useEffect(() => {
    AddressDetailsFormik.resetForm({
      values: {
        billingAddresses: vendorFormik?.values?.AddressDetails?.billingAddresses,
      },
    })
  }, [vendorFormik])

  const vendorsColumns = [
    {
      title: 'Address line 1',
      accessor: 'addressLine1',
      editable: true,
      flex: 2,
      require: true,
      placeholder: 'Enter address',
    },

    {
      title: 'Address line 2',
      accessor: 'addressLine2',
      editable: true,
      require: true,
      flex: 2,
      placeholder: 'Enter address',
    },
    {
      title: 'Country',
      accessor: 'country',
      editable: true,
      require: true,

      placeholder: 'Enter country',
    },
    {
      title: 'State',
      accessor: 'state',
      editable: true,

      require: true,
      placeholder: 'Enter state',
    },
    {
      title: 'City',
      accessor: 'city',
      editable: true,
      require: true,

      placeholder: 'Enter city',
    },

    {
      title: 'Zip Code',
      accessor: 'zipCode',
      require: true,
      editable: true,
      placeholder: 'Enter zip code',
    },

    {
      title: 'Primary',
      fieldType: 'radio',
      accessor: 'isPrimary',
      editable: true,
    },
  ]
  // useEffect(() => {
  //   vendorFormik.setFieldValue("billingAddresses", data)

  // }, [data])

  const handelAddress = () => {
    AddressDetailsFormik?.setFieldValue('billingAddresses', data ? data : [])
    AddressDetailsFormik.handleSubmit()
  }

  return (
    <div className="flex h-full flex-col gap-10">
      <div>
        <span>
          <h4 className="h4-bold-black">Billing Addresses</h4>
          <p className="text-sm text-primary-gray-4">
            {vendorID
              ? 'Edit the existing Vendor details to make necessary changes and updates.'
              : 'Add the basic details that are required to Add New Vendor.'}
          </p>
        </span>
      </div>

      <NewEditableTable
        defaultRows={vendorFormik?.values?.AddressDetails?.billingAddresses}
        columns={vendorsColumns}
        onChange={(values: any) => {
          setData(values)
        }}
        addButtonTitle="Add Address"
      />
      <div className="flex justify-between">
        <Button onClick={() => navigate('/contacts/vendor-management')}>Cancel</Button>

        <span className="flex gap-3">
          {' '}
          <Button
            onClick={() => {
              setPage(1)
              navigate(-1)
            }}
          >
            Previous
          </Button>
          <Button
            color="success"
            loading={loading}
            onClick={() => {
              handelAddress()
            }}
          >
            {vendorID ? 'Save' : 'Add'}
          </Button>
        </span>
      </div>
    </div>
  )
}

export default AddressDetails
