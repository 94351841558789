import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { instrumentationKey } from 'common/config'

// Create a singleton instance
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    enableAutoRouteTracking: false, // We will handle route tracking manually
    // enableAutoCorrelation: true,
    enableUnhandledPromiseRejectionTracking: true, // Track unhandled promise rejections
  },
})
appInsights.loadAppInsights()

export default appInsights
