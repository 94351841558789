import WhiteCard from 'libs/card/WhiteCard'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import Appearance from './appearance/Appearance'
import Configure from './configure/Configure'
import { getAuthTokenAndRole } from 'utils/CheckAuth'
import useTabTitle from 'libs/hooks/useTabTitle'
import UserManagement from './user-management/UserManagement'
import ShiftManagementPage from 'pages/shift-management/ShiftManagementPage'

const routes = [
  {
    label: 'Configure',
    route: 'configure',
  },
  {
    label: 'Appearance',
    route: 'appearance',
  },
  // {
  //   label: 'User Management',
  //   route: 'user-management',
  // },
  {
    label: 'Shift Management',
    route: 'shift-management',
  },
]

const AppSetting = () => {
  const params = useParams()
  const navigate = useNavigate()
  useTabTitle('Settings')
  const { role } = getAuthTokenAndRole()

  return (
    <div className="-mt-4 flex flex-col gap-4">
      <div>
        <h2 className="h4-bold-black">Settings</h2>
        <p className=" mt-1 p-sm-regular-1">
          Configure your preferences and manage system settings seamlessly with the following options under Settings
        </p>
      </div>

      <WhiteCard>
        <div className="flex min-h-[70vh] flex-row">
          <div className="flex w-1/4 flex-col gap-2 border-r-2 p-4">
            {routes.map((route) => {
              const isSelected = params['*'] === route.route
              // not showing configure to the operator
              if (role == 'operator' && route.route == 'configure') {
                return null
              }
              return (
                <div key={route.route}>
                  <p
                    className={`cursor-pointer rounded-lg  ${isSelected ? 'bg-brand-primary-200 p-md-regular-500' : ' p-sm-regular'}  px-3 py-1 hover:bg-gray-100`}
                    onClick={() => navigate(`./${route.route}`)}
                  >
                    {route.label}
                  </p>
                </div>
              )
            })}
          </div>
          <div className="flex h-full w-full flex-col gap-2 p-4">
            <Routes>
              {/* Routes for NEW WIZARD */}
              <Route path="*" element={<Navigate replace to={role == 'operator' ? 'appearance' : 'configure'} />} />
              {role != 'operator' && <Route path="configure" element={<Configure />} />}
              <Route path="appearance" element={<Appearance />} />
              <Route path="user-management" element={<UserManagement />} />
              <Route path="shift-management" element={<ShiftManagementPage />} />
            </Routes>
          </div>
        </div>
      </WhiteCard>
    </div>
  )
}

export default AppSetting
