import React from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: any
}

interface FormValues {
  vendorType: []
}

const FilterVendor: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const initialValues: FormValues = {
    vendorType: filters.vendorType ?? [],
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      vendorType: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })

    closePanel()
  }

  const filterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,

        vendorType: values.vendorType == null ? [] : values.vendorType,
      }))
    },
  })

  const vendorTypeOptions = [
    { label: 'Individual', value: 'INDIVIDUAL' },
    { label: 'Government', value: 'GOVERNMENT' },
    { label: 'Private Company', value: 'PRIVATE_COMPANY' },
  ]

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="Vendor Type"
          placeholder="Select vendor type"
          options={vendorTypeOptions}
          formik={filterFormik}
          name="vendorType"
        />

        <div className="flex justify-between pt-5 ">
          <div>
            <Button
              onClick={() => {
                handleReset()
              }}
            >
              Reset
            </Button>
          </div>
          <div className="flex gap-2">
            <Button onClick={closePanel}>Cancel</Button>

            <Button
              color="primary"
              type="submit"
              onClick={() => {
                filterFormik.handleSubmit()
                closePanel()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterVendor
