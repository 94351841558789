export function buildUrl(endpoint: any, params: any) {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        if (value.length === 0) return ''
        return value.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&')
      } else if (value == null) {
        return ''
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      }
    })
    .filter((param) => param)
    .join('&')
  if (endpoint.includes('?')) {
    return `${endpoint}&${queryString}`
  }
  return `${endpoint}?${queryString}`
}
