/**
 * This function checks if the end date is after the start date
 * @param startDate
 * @param endDate
 * @returns boolean value
 */

export const isEndDateAfterStartDate = (startDate: number, endDate: number) => {
  return endDate >= startDate
}
