import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/BuildUrl'

export const product = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postProduct: builder.mutation({
      query: ({ data, plantId }) => {
        return {
          url: `/product/?plant_id=${plantId}`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['productUpdate'],
    }),
    postBom: builder.mutation({
      query: ({ data, productId }) => {
        return {
          url: `/product/bom?product_id=${productId}`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['productUpdate', 'bomUpdate'],
    }),
    editBom: builder.mutation({
      query: ({ productId, bomId, data }) => ({
        url: `/product/bom?product_id=${productId}&bom_id=${bomId}`,
        method: 'PUT',
        headers: getToken(),
        body: data,
      }),
      invalidatesTags: ['productUpdate', 'bomUpdate'],
    }),
    postProperty: builder.mutation({
      query: ({ data, productId }) => {
        return {
          url: `/productpoperty/?product_id=${productId}`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['productUpdate', 'getProperty'],
    }),
    getProduct: builder.query({
      query: (params) => {
        const { plantId, filters } = params
       
        
        let URL = ''
        filters ? (URL = buildUrl(`/product/plant/${plantId}`, filters)) : (URL = `/product/plant/${plantId}`)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the product')
            }
            return response.json()
          },
        }
      },
      providesTags: ['productUpdate', 'inventoryUpdate'],
    }),
    getProductCount: builder.query<any, { plant_id: string }>({
      query: (params) => {
        return {
          url: `product/count?plant_id=${params.plant_id}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the product count ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['productUpdate'],
    }),
    getBom: builder.query({
      query: ({ productId }) => {
        return {
          // url: `/product/get_product?plant_id=${plantId}&skip=${skip}&limit=${limit}`,
          url: `/product/bom?product_id=${productId}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the BOM')
            }
            return response.json()
          },
        }
      },
      providesTags: ['bomUpdate', 'productUpdate'],
    }),
    getProperty: builder.query({
      query: ({ productId }) => {
        return {
          url: `/productpoperty/get_productproperty?product_id=${productId}&skip=0&limit=100`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the product property')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getProperty'],
    }),
    getProductCreatedBetween: builder.query({
      query: ({ plantId, start, end }) => {
        return {
          url: `/product/created_date_between?plant_id=${plantId}&start_date=${start}&end_date=${end}&skip=0&limit=1000`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the product created between')
            }
            return response.json()
          },
        }
      },
    }),
    getProductUpdatedBetween: builder.query({
      query: ({ plantId, start, end }) => {
        return {
          url: `/product/updated_date_between?plant_id=${plantId}&start_date=${start}&end_date=${end}&skip=0&limit=1000`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the product updated between')
            }
            return response.json()
          },
        }
      },
    }),

    editProduct: builder.mutation({
      query: ({ data, productId }) => ({
        url: `/product/?id=${productId}`,
        method: 'PUT',
        headers: getToken(),
        body: data,
      }),
      invalidatesTags: ['productUpdate', 'getProperty'],
    }),
    editProperty: builder.mutation({
      query: ({ data, propertyId }) => ({
        url: `/productpoperty/update_productproperty?productpro_id=${propertyId}`,
        method: 'PUT',
        headers: getToken(),
        body: data,
      }),
      invalidatesTags: ['productUpdate', 'getProperty'],
    }),

    deleteProduct: builder.mutation({
      query: ({ productId }) => ({
        url: `/product/?id=${productId}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['productUpdate'],
    }),

    deleteBom: builder.mutation({
      query: ({ productId, bomId }) => ({
        url: `/product/bom?product_id=${productId}&bom_id=${bomId}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['bomUpdate'],
    }),
    deleteProperty: builder.mutation({
      query: ({ propertyId }) => ({
        url: `/productpoperty/delete_productproperty?productpro_id=${propertyId}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['getProperty'],
    }),
    // product endpoints end
  }),
  overrideExisting: false,
})

export const {
  useGetProductQuery,
  useGetPropertyQuery,
  usePostProductMutation,
  usePostPropertyMutation,
  useEditProductMutation,
  useEditPropertyMutation,
  useDeleteProductMutation,
  useGetProductCountQuery,
  useDeletePropertyMutation,
  useGetProductCreatedBetweenQuery,
  useGetProductUpdatedBetweenQuery,
  useGetBomQuery,
  usePostBomMutation,
  useEditBomMutation,
  useDeleteBomMutation,
} = product
