import WhiteCard from 'libs/card/WhiteCard'
import Button from 'libs/button/Button'

import MotionDiv from 'libs/motionDiv'

import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PaginatedTable from 'libs/table/PaginatedTable'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { formatString } from 'utils/trimStringValues'

const ViewBasicDetails = ({ customersData, setPage }: any) => {
  const Customer = customersData?.data?.[0]

  const { id } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    setPage(1)
  }, [])

  const columns = [
    {
      Header: 'First Name',
      accessor: 'firstName',
    },

    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
    {
      Header: 'Contact Number',
      accessor: 'contactNumber',
    },
    {
      Header: 'Email Address',
      accessor: 'emailAddress',
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
    },
  ]

  const customerInfo = [
    { label: 'Customer Name', key: 'name' },
    { label: 'Customer Type', key: 'customerType' },
    { label: 'Business Email Address', key: 'businessEmailAddress' },
    { label: 'Primary Contact Number', key: 'primaryContactNumber' },
    { label: 'Secondary Contact Number', key: 'secondaryContactNumber' },
  ]

  return (
    <MotionDiv>
      <WhiteCard className=" flex min-h-[70vh] flex-col gap-6">
        <div className="flex w-full justify-between">
          <h5 className="h5">Basic Details</h5>
          <div>
            <Button
              color="primary"
              startIcon={<PlusIcon />}
              onClick={() => {
                navigate(`/contacts/customer-management/${id}/edit/basic-details`)
              }}
            >
              Edit Customer Details
            </Button>
          </div>
        </div>

        <div className="flex  max-w-[800px] justify-between">
          <div className="grid grid-cols-2 gap-4 text-xs">
            {customerInfo.map((field, fieldIndex) => (
              <React.Fragment key={fieldIndex}>
                <span className="text-gray-500">{field.label}</span>
                <span>
                  {field.key === 'customerType'
                    ? formatString(Customer?.[field.key] || '-')
                    : Customer?.[field.key] || '-'}
                </span>
              </React.Fragment>
            ))}
          </div>
        </div>

        <PaginatedTable
          columns={columns}
          rows={Customer?.pointOfContact}
          hidePagination={true}
          emptyMessage={'No contacts have been added. Please add a contact.'}
        />
      </WhiteCard>
    </MotionDiv>
  )
}

export default ViewBasicDetails
