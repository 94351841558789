import { useState, useRef, useEffect } from 'react'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { dropdownMotionProps } from 'utils/FramerProps'
import { useTranslation } from 'react-i18next'

const PredefindField = ({ onFormDataChange, errors, setErrors, PredefinedFieldData }: any) => {
  const { t } = useTranslation()
  const data: any = null
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [selectedTitle, setSelectedTitle] = useState(PredefinedFieldData?.predefined_field_label || '')
  const [formData, setFormData] = useState({
    predefined_field_label: selectedTitle,
    predefined_field_process_id: '',
    predefined_field_id: '',

    help_text: PredefinedFieldData?.help_text || '',
  })
  const popupRef = useRef<any>(null)

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Check if the click is outside the popup
      if (popupRef.current && !popupRef.current.contains(event.target) && !event.target.closest('.ignoreref')) {
        setIsDropdownVisible(false)
      }
    }

    // Adding event listener to detect clicks outside the popups
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleTitleClick = (title: any, department: any, fieldId: any, processId: any) => {
    setSelectedTitle(title)
    setSelectedDepartment(department)
    setFormData({
      ...formData,
      predefined_field_label: title,
      predefined_field_id: fieldId,
      predefined_field_process_id: processId,
    })

    setIsDropdownVisible(false)
    setErrors({ ...errors, predefined_field_label: null })
  }

  const handleInputClick = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }
  const handleInputChange = (event: any) => {
    const { name, value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))

    onFormDataChange(formData)
  }

  useEffect(() => {
    onFormDataChange(formData)
  }, [selectedTitle, formData])

  return (
    <div>
      <div className="relative mt-5">
        <label className={`mb-1 text-[#000] p-sm-regular-400`}>
          {t('choose_field')} <span className="text-[#DC3545]">*</span>
        </label>
        <p className={`w-[350px] p-xs-regular-dim `}>
          {t('choose_the_field_that_you_have_previously_defined_elsewhere_in_catalyst')}
        </p>
        <input
          required
          name="propertyType"
          type="text"
          autoComplete="off"
          className={`ignoreref h-[28px] w-[360px] ${errors.predefined ? 'border-[#DC3545]' : 'border-[#E0E0E0]'} ${
            isDropdownVisible ? 'bg-[#FFFFFF]' : 'bg-[#EBEFF3]'
          } mt-2 cursor-pointer 
         rounded-md border border-solid p-2 pr-8 text-sm outline-none p-xs-regular focus:border-[#1193F0]`}
          placeholder={t('select')}
          onClick={handleInputClick}
          value={`${selectedTitle}${selectedDepartment ? ` [${selectedDepartment}]` : ''}`}
          readOnly
        />
        <div className="absolute right-[1rem] top-[4.7rem]">
          <ArrowDown color={''} />
        </div>
        {errors.predefined && <p className="w-[360px] pt-1 text-xs font-normal text-[#DC3545]">{errors.predefined}</p>}
        <AnimatePresence>
          {isDropdownVisible && (
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={dropdownMotionProps}
              ref={popupRef}
              className="scroll-bar absolute max-h-[12rem] min-h-[20px] w-[360px] overflow-auto rounded bg-[#fff] "
              style={{
                zIndex: '3',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
              }}
            >
              {data?.map((item: any) => (
                <div key={item.process_id} className={`cursor-pointer border-b  border-[#EBEFF3] px-3 py-2 `}>
                  <div className="p-sm-bold1">{item.process_name}</div>
                  {item.fields && (
                    <div>
                      {item.fields?.map((child: any) => (
                        <div
                          key={child.field_id}
                          className={`cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3 py-2 hover:bg-[#f8f8f8] ${
                            selectedTitle === child.field_name ? 'bg-[#f8f8f8]' : ''
                          }`}
                          onClick={() =>
                            handleTitleClick(child.field_name, item.process_name, child.field_id, item.process_id)
                          }
                        >
                          <div className="p-xs-regular">{child.field_name}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="mt-5">
        <label className="mb-1 p-sm-regular ">{t('help_text')}</label>
        <div>
          <p className="p-xs-regular-dim">{t('guide_the_person_filling_this_field')}</p>
          <div className="relative py-1">
            <input
              type="text"
              name="help_text"
              value={formData.help_text}
              onChange={handleInputChange}
              className={`h-[28px]  w-[360px] rounded-md border border-solid border-[#E0E0E0] bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular`}
              placeholder={t('enter_help_text')}
              maxLength={101}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PredefindField
