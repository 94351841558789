import { useDeleteSalesOrderMutation } from '../sales-order-endpoints/salesOrders.endpoints'
import { t } from 'i18next'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  soID: any
  plant_id: string
}

function DeleteSalesOrder({ closePopUp, soID, plant_id }: Props) {
  const [deleteSalesOrder, { isLoading: deleteSalesOrderLoading }] = useDeleteSalesOrderMutation()
  const navigate = useNavigate()

  const handleDeleteSalesOrder = () => {
    deleteSalesOrder({ plant_id, soID: soID?._id })
      .unwrap()
      .then(() => {
        toast.success(`Sales Order “${soID?.salesOrderID}” is removed successfully.`)
        closePopUp()
        navigate('/orders/sales-order')
      })
      .catch((error: any) => {
        toast.error(error?.data?.detail ?? `Failed to remove Sales Order “${soID?.salesOrderID}”. Please try again.`)
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')}{' '}
        <span className="break-words pr-1 p-xs-bold">“Sales Order ID {soID?.salesOrderID}”.</span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDeleteSalesOrder} loading={deleteSalesOrderLoading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </div>
  )
}

export default DeleteSalesOrder
